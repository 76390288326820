import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../../hooks/useAuth";
import { setShowAddResellerForm } from "../../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../../utils/axios";
import AlertMessageComponent from "../../../components/AlertMessageComponent";
import AddReseller from "./AddReseller";
import ListReseller from "./ListReseller";

export default function ManageResellers() {
    const state = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();
    const [clientDetails, setClientDetails] = useState(null);
    const [editable, setEditable] = useState(false);
    const [billingDetailsByClientId, setBillingDetailsByClientId] = useState(null);
    const [errorResponse, setErrorResponse] = useState("");
    const [isLoadingClientDetails, setIsLoadingClientDetails] = useState({ status: false, id: null });
    const [isBrandNotVerified, setIsBrandNotVerified] = useState(true);
    const [searchParams, setSearchParams] = React.useState({
        orgName: "",
        name: "",
        email: "",
        mobileNumber: "",
        branchName: "",
        status: "",
    });
    const { user } = useAuth();

    const handleEditDetailsChange = async ({ clientData, val }) => {
        setEditable(val);
        await getBillingDetailsByClientId(clientData.orgId, clientData.registryId)
        setIsLoadingClientDetails({ status: true, id: clientData._id });
        setClientDetails({ ...clientData });
        // await getCustomizeRateByClientId(clientData.resellerDetails.supportedProviders[0], clientData.orgId, clientData.registryId);
        dispatch(setShowAddResellerForm(true));
        setIsLoadingClientDetails({ status: false, id: null });
    };

    const getBillingDetailsByClientId = async (orgId, registryId) => {
        try {
            const token = sessionStorage.getItem("accessToken");
            const response = await apiGatewayAxiosInstance.get(`/billing_management/customized-rate-card/get-billing-details`, {
                headers: { registryid: registryId },
            });

            if (response.status === 200) {
                const dataToSet = response.data.data;
                setBillingDetailsByClientId(dataToSet);
            } else {
                throw new Error(response.data);
            }
        } catch (error) {
            setIsLoadingClientDetails({ status: false, id: null });
            let errorMessage = "Failed to get customized rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        } finally {
            setIsLoadingClientDetails({ status: false, id: null });
        }
    };

    const onClickHandleClose = fieldName => {
        dispatch(onClickHandleClose(fieldName));
    };

    useEffect(() => {
        dispatch(setShowAddResellerForm(false));
    }, []);

    return (
        <div>
            {state.controls.showAddResellerForm ? (
                <>
                    <AddReseller
                        resellerData={clientDetails}
                        setClientDetails={setClientDetails}
                        isClientEditable={editable}
                        setEditable={setEditable}
                        isBrandNotVerified={isBrandNotVerified}
                        billingDetailsByClientId={billingDetailsByClientId}
                        setBillingDetailsByClientId={setBillingDetailsByClientId}
                        isLoadingClientDetails={isLoadingClientDetails}
                    />
                </>
            ) : (
                <ListReseller
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    setClientDetails={setClientDetails}
                    handleEditDetailsChange={handleEditDetailsChange}
                    isLoadingClientDetails={isLoadingClientDetails}
                    setIsBrandNotVerified={setIsBrandNotVerified}
                />
            )}

            {/* {state.controls.getClientEditDetailsErrorMessages && (
                <AlertMessageComponent
                    message={state.controls.getClientEditDetailsErrorMessages}
                    fieldName={"getClientEditDetailsErrorMessages"}
                    handleClose={onClickHandleClose}
                    show={Boolean(state.controls.getClientEditDetailsErrorMessages)}
                />
            )} */}

            <AlertMessageComponent
                message={errorResponse}
                fieldName={"errorResponse"}
                handleClose={() => setErrorResponse("")}
                show={Boolean(errorResponse)}
            />
        </div>
    );
}
