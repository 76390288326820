import { CloseOutlined } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorMessageForBaseRate, setErrorMessageForUser } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import useAuth from "../../../hooks/useAuth";

export default function ProviderBaseRatesModal({
    open,
    handleClose,
    providerData,
    providerDetailsByProviderName,
    otherFields,
    selectProviderName,
    getAllCustomizedRate,
    providerMasterRatesFields
}) {
    const dispatch = useDispatch();
    const [iseditMasterRatesByCountryLoading, setIseditMasterRatesByCountryLoading] = useState(false);
    const state = useSelector(state => state.userManagementSlice);
    const { user } = useAuth();
    const initialValues = {
        // registryId: providerData?._id,
        _id: providerData?._id,
        updatedTextMessageRate: providerMasterRatesFields?.textMessageRate ?? '',
        updatedMultiMediaMessageRate: providerMasterRatesFields?.multiMediaMessageRate ?? '',
        updatedA2pSessionConversationRate: providerMasterRatesFields?.A2pSessionConversationRate ?? '',
        updatedFeedbackRate: providerMasterRatesFields?.feedbackRate ?? '',
        otherFields: providerMasterRatesFields?.otherFields ?? otherFields,
    };


    const editMasterRatesByCountry = async ({ data }) => {
        try {
            setIseditMasterRatesByCountryLoading(true);
            const response = await apiGatewayAxiosInstance.post(`/billing_management/provider-csv-fields/update-provider-rate-fields-by-provider-id`,
                data,
                {
                    params: { providerId: providerData._id },
                });

            if (response.status === 200) {
                setIseditMasterRatesByCountryLoading(false);
                handleClose();
                getAllCustomizedRate(selectProviderName.name);
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Failed to update customized rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setIseditMasterRatesByCountryLoading(false);
            dispatch(
                setErrorMessageForUser({
                    fieldName: "editMasterRatesByCountryErrorMessage",
                    errorMessage,
                }),
            );
            throw error;
        }
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessageForBaseRate({ fieldName }));
    };
    const validateIfValueIsEmpty = fieldName => {
        const falsyValuesToValidate = [null, undefined, NaN];

        const fieldValue = formik.values[fieldName];
        if (falsyValuesToValidate.includes(fieldValue) || fieldValue === "") {
            formik.setFieldError(fieldName, `Required`);
            return false;
        }
        return true;
    };

    const validateIfValueIsEmptyForOtherFields = fieldName => {
        const fieldValue = formik.values.otherFields[fieldName];
        if (fieldValue === "" || fieldValue === null) {
            formik.setFieldError(`otherFields.${fieldName}`, `Required`);
            return false;
        } else {
            return true;
        }
    };
    const validation = () => {
        let result = true;

        if (!validateIfValueIsEmpty("updatedTextMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedMultiMediaMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedA2pSessionConversationRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedFeedbackRate")) {
            result = false;
        }

        // for (let index = 0; index < Object.keys(initialValues.otherFields).length; index++) {
        //     const otherRate = Object.keys(initialValues.otherFields)[index];
        //     if (!validateIfValueIsEmptyForOtherFields(otherRate)) {
        //         result = false;
        //     }
        // }

        return result;
    };
    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                if (validation()) {
                    let dataToSend = {
                        // registryId: user?.registryId ? user?.registryId : providerData._id,
                        // _id: providerData.orgId,
                        providerId: providerData._id,
                        // customizedRate: {
                        // A2pMessageRate: {
                        textMessageRate: values.updatedTextMessageRate,
                        multiMediaMessageRate: values.updatedMultiMediaMessageRate,
                        A2pSessionConversationRate: values.updatedA2pSessionConversationRate,
                        feedbackRate: values.updatedFeedbackRate,
                        // },

                        otherFields: values.otherFields,
                        // },
                    };
                    editMasterRatesByCountry({ data: dataToSend });
                }
            } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
            }
        },
    });

    useEffect(() => { }, [user, providerMasterRatesFields]);

    return (
        <Dialog open={open} onClose={handleClose} sx={{ padding: "1rem" }} fullWidth maxWidth={"xs"}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Grid display={"flex"}>
                        <Typography variant='h5'>
                            <b>Update Rates For</b>{" "}
                        </Typography>
                        <Typography variant='h5' className='text-primary'>
                            &nbsp;<b> {providerData.providerName}</b>
                        </Typography>
                    </Grid>
                    <IconButton onClick={() => handleClose()}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <Divider variant='middle' />
                <DialogContent>
                    <Grid>
                        <TextField
                            className='mb-2'
                            fullWidth
                            size='small'
                            type='number'
                            inputProps={{
                                step: "any",
                            }}
                            onWheel={e => e.target.blur()}
                            label='Text Message Rate '
                            name='updatedTextMessageRate'
                            defaultValue={providerMasterRatesFields?.textMessageRate}
                            // onChange={handleChangeUpdatedRateCardDetails}
                            error={Boolean(formik.touched.updatedTextMessageRate && formik.errors.updatedTextMessageRate)}
                            helperText={formik.touched.updatedTextMessageRate && formik.errors.updatedTextMessageRate}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid mt={3}>
                        <TextField
                            className='mb-2'
                            fullWidth
                            size='small'
                            type='number'
                            inputProps={{
                                step: "any",
                            }}
                            onWheel={e => e.target.blur()}
                            label='Multi Media Message Rate'
                            name='updatedMultiMediaMessageRate'
                            defaultValue={providerMasterRatesFields?.multiMediaMessageRate}
                            error={Boolean(formik.touched.updatedMultiMediaMessageRate && formik.errors.updatedMultiMediaMessageRate)}
                            helperText={formik.touched.updatedMultiMediaMessageRate && formik.errors.updatedMultiMediaMessageRate}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        // onChange={handleChangeUpdatedRateCardDetails}
                        />
                    </Grid>

                    <Grid mt={3}>
                        <TextField
                            className='mb-2'
                            fullWidth
                            size='small'
                            type='number'
                            inputProps={{
                                step: "any",
                            }}
                            onWheel={e => e.target.blur()}
                            label='A2p SessionConversation Rate'
                            name='updatedA2pSessionConversationRate'
                            defaultValue={providerMasterRatesFields?.A2pSessionConversationRate}
                            error={Boolean(formik.touched.updatedA2pSessionConversationRate && formik.errors.updatedA2pSessionConversationRate)}
                            helperText={formik.touched.updatedA2pSessionConversationRate && formik.errors.updatedA2pSessionConversationRate}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        // onChange={handleChangeUpdatedRateCardDetails}
                        />
                    </Grid>

                    <Grid mt={3}>
                        <TextField
                            className='mb-2'
                            fullWidth
                            size='small'
                            type='number'
                            inputProps={{
                                step: "any",
                            }}
                            onWheel={e => e.target.blur()}
                            label='Feedback rate'
                            name='updatedFeedbackRate'
                            defaultValue={providerMasterRatesFields?.feedbackRate}
                            error={Boolean(formik.touched.updatedFeedbackRate && formik.errors.updatedFeedbackRate)}
                            helperText={formik.touched.updatedFeedbackRate && formik.errors.updatedFeedbackRate}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        // onChange={handleChangeUpdatedRateCardDetails}
                        />
                    </Grid>
                    {providerMasterRatesFields?.otherFields && Object.keys(providerMasterRatesFields?.otherFields).length > 0 &&
                        Object.keys(providerMasterRatesFields?.otherFields).map((fieldElement, index) => (
                            <Grid mt={3} key={index}>
                                <TextField
                                    // className='mb-2'
                                    fullWidth
                                    size='small'
                                    type='number'
                                    inputProps={{
                                        step: "any",
                                    }}
                                    onWheel={e => e.target.blur()}
                                    label={fieldElement}
                                    name={`otherFields.${fieldElement}`}
                                    value={formik.values?.otherFields[fieldElement]}
                                    error={Boolean(formik.touched?.otherFields && formik.touched?.otherFields[fieldElement] && formik.errors?.otherFields && formik.errors?.otherFields[fieldElement])}
                                    helperText={formik.touched?.otherFields && formik.touched?.otherFields[fieldElement] && formik.errors?.otherFields && formik.errors?.otherFields[fieldElement]}

                                    // helperText={Boolean(
                                    //     `formik.errors.otherFields.${fieldElement}`
                                    // )}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                // onChange={handleChangeUpdatedRateCardDetails}
                                />
                            </Grid>
                        ))}

                    {/* <Grid mt={3}>
                        <TextField
                            className='mb-2'
                            fullWidth
                            size='small'
                            type='number'
                            inputProps={{
                                step: 'any'
                            }}
                            onWheel={e => e.target.blur()}
                            label='P2a Session Conversation Rate'
                            name='updatedP2aConversationRate'
                            defaultValue={
                                providerData?.baseRate?.otherFields
                                    ?.P2aConversationRate
                            }
                            error={Boolean(
                                formik.touched.updatedP2aConversationRate &&
                                    formik.errors.updatedP2aConversationRate
                            )}
                            helperText={
                                formik.touched.updatedP2aConversationRate &&
                                formik.errors.updatedP2aConversationRate
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // onChange={handleChangeUpdatedRateCardDetails}
                        />
                    </Grid> */}

                    <Grid container mt={2}>
                        {/* {isBotEditable && ( */}
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        padding: "1rem 1.5rem",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Grid container>
                        <Grid padding={1}>
                            {/* {isUpdateMasterRateLoading ? (
                                <Button variant='outlined'>
                                    <CircularProgress size={15} className='spinner' />
                                    Updating..
                                </Button>
                            ) : ( */}
                            <Button type='submit' variant='contained'>
                                Update
                            </Button>
                            {/* )} */}
                        </Grid>

                        <Grid padding={1}>
                            <Button variant='contained' color='error' onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
            {state.controls.updateMasterRateErrorMessage && (
                <AlertMessageComponent
                    message={state.controls.updateMasterRateErrorMessage}
                    fieldName={"updateMasterRateErrorMessage"}
                    handleClose={onCloseErrorMessage}
                    show={Boolean(state.controls.updateMasterRateErrorMessage)}
                />
            )}
        </Dialog>
    );
}
