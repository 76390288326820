import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProviderList, setShowAddClientForm } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import AddProvider from "./AddProvider";
import ListProviders from "./ListProviders";

export default function ManageProviders () {
    const state = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();
    const [errorResponse, setErrorResponse] = useState("");
    const [listProviderLoading, setListProviderLoading] = useState(false);
    const [providerTotalCount, setProviderTotalCount] = useState();
    // const [searchParams, setSearchParams] = React.useState({
    //     orgName: "",
    //     name: "",
    //     email: "",
    //     mobileNumber: "",
    //     branchName: "",
    //     status: "",
    // });

    const getAllProviderList = async () => {
        try {
            setListProviderLoading(true);
            const response = await apiGatewayAxiosInstance.get(`/billing_management/provider-csv-fields/get-all-providers`);
            setListProviderLoading(false);

            dispatch(setProviderList(response.data.data.providerList));
            setProviderTotalCount(response.data.data.totalCount);
        } catch (error) {
            setListProviderLoading(false);
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            // dispatch(
            //     setErrorMessageForUser({
            //         fieldName: "getClientEditDetailsErrorMessages",
            //         errorMessage,
            //     }),
            // );
        }
    };

    const onClickHandleClose = fieldName => {
        dispatch(onClickHandleClose(fieldName));
    };

    useEffect(async () => {
        dispatch(setShowAddClientForm(false));

        await getAllProviderList();
    }, []);

    return (
        <div>
            {state.controls.showAddProviderForm ? (
                <>
                    <AddProvider getAllProviderList={getAllProviderList} />
                </>
            ) : (
                <ListProviders
                    getAllProviderList={getAllProviderList}
                    providerTotalCount={providerTotalCount}
                    listProviderLoading={listProviderLoading}
                />
            )}

            {/* {state.controls.getClientEditDetailsErrorMessages && (
                <AlertMessageComponent
                    message={state.controls.getClientEditDetailsErrorMessages}
                    fieldName={"getClientEditDetailsErrorMessages"}
                    handleClose={onClickHandleClose}
                    show={Boolean(state.controls.getClientEditDetailsErrorMessages)}
                />
            )} */}

            <AlertMessageComponent
                message={errorResponse}
                fieldName={"errorResponse"}
                handleClose={() => setErrorResponse("")}
                show={Boolean(errorResponse)}
            />
        </div>
    );
}
