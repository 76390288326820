import { Button, Card, CardContent, CircularProgress, Grid, IconButton, TextField, Typography, styled } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import { ReactComponent as Logo } from "../../vendor/PinnacleTeleservicesLogo.svg";
import { REACT_APP_WHITELABEL_LOGO_URL } from "../../utils/axios";

const Brand = styled(Logo)`
    fill: ${props => props.theme.palette.primary.main};
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
`;

export default function SendOTP ({
    orgUserId,
    clientData,
    sendOTPTomobileNumber,
    mobileNumberOTP,
    verifyOTPTomobileNumber,
    setMobileNumberOTP,
    otpSent,
    otpLoading,
    otpVerificationLoading,
}) {
    const [otpErrorMessage, setOTPErrorMessage] = useState(null);

    const handleChangeOTP = value => {
        if (!isNaN(value) && value !== " ") {
            setMobileNumberOTP(value);
        }
    };

    const [timer, setTimer] = useState(60);
    const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

    useEffect(() => {
        if (otpSent) {
            timer > 0 && setTimeout(timeOutCallback, 1000);
        }
    }, [timer, timeOutCallback, otpSent]);

    const resetTimer = function () {
        sendOTPTomobileNumber();
        setTimer(60);
    };

    const onClickVerifyOTP = () => {
        if (mobileNumberOTP && mobileNumberOTP.length == 6) {
            verifyOTPTomobileNumber();
        } else {
            setOTPErrorMessage("Please enter valid OTP");
        }
    };

    return (
        <Grid sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <Grid>
                <Brand />
            </Grid>
            <Grid>
                <Card sx={{ boxShadow: "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)" }}>
                    <CardContent sx={{ margin: "10px" }}>
                        <Grid container gap={2} display={"flex"} flexDirection={"column"}>
                            <Grid mb={5} item gap={2} display={"flex"} alignItems={"center"} className='text-primary'>
                                {/* <PermPhoneMsgIcon /> */}
                                <img src={REACT_APP_WHITELABEL_LOGO_URL} className='sidebar-logo-img' alt='logo' />

                                <Typography variant='h3'>Verify phone number</Typography>
                            </Grid>

                            {otpSent ? (
                                <>
                                    <Grid item>
                                        <Typography variant='h6' align='center' gutterBottom>
                                            Please enter the 6-digit code from SMS <br /> sent to your phone.
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <TextField
                                            label='OTP'
                                            className='fw-bold'
                                            error={Boolean(otpErrorMessage)}
                                            helperText={otpErrorMessage}
                                            fullWidth
                                            variant='outlined'
                                            my={2}
                                            value={mobileNumberOTP}
                                            onChange={e => handleChangeOTP(e.target.value)}
                                            // defaultValue={ }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            // type='submit'
                                            onClick={onClickVerifyOTP}
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            disabled={otpVerificationLoading}
                                        >
                                            {otpVerificationLoading && <CircularProgress size={20} />}
                                            Verify OTP
                                        </Button>
                                    </Grid>

                                    {/* <Button
                        // type='submit'
                        onClick={() => sendOTPTomobileNumber()}
                        fullWidth
                        variant='contained'
                        color='primary'
                        disabled={otpLoading}
                    >
                        {otpLoading && <CircularProgress
                            size={20}
                        />}
                        Resend OTP
                    </Button> */}
                                    {/* {timer} */}
                                    <Button onClick={() => resetTimer()} disabled={timer > 0}>
                                        Resend OTP {timer > 0 && timer}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Grid item>
                                        <Typography variant='h6' align='center' gutterBottom>
                                            Sent OTP to registered phone number
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant='h5' align='center' gutterBottom>
                                            <b>{clientData.mobileNumber}</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            // type='submit'
                                            onClick={() => sendOTPTomobileNumber()}
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            disabled={otpLoading}
                                        >
                                            {otpLoading && <CircularProgress size={20} />}
                                            Send OTP
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
