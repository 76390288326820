import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGatewayAxiosInstance } from "../../utils/axios";

export const addNewBrandThunk = createAsyncThunk("add-new-brand", async ({ data }) => {
    try {
        const response = await apiGatewayAxiosInstance.post(`/bot_management/brand/create-brand`, data);

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllBrands = createAsyncThunk("get-all-brands", async () => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/brand/get-all-brands`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllBrandsByPageSize = createAsyncThunk("get-all-brands-by-pagesize", async ({ currentPage, pageSize }) => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/brand/get-all-brands-by-pagesize`, {
            params: {
                currentPage,
                pageSize,
            },
        });

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});
export const getAllBrandByFilterThunk = createAsyncThunk(
    "get-all-brand-by-filter,",
    async ({ pageSize, currentPage, searchParams, registryId, roleName }) => {
        try {
            const response = await apiGatewayAxiosInstance.get(`/bot_management/brand`, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    pageSize,
                    currentPage,
                    brandName: searchParams.brandName,
                    brandStatus: searchParams.brandStatus,
                    registryId,
                    roleName,
                },
            });
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            throw errorMessage;
        }
    },
);
