import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useFormik } from "formik";
import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useEffect, useRef, useState } from "react";
import { GetCity, GetCountries, GetState } from "react-country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { ClientStatus, ERoles } from "../../../../config";
import { clearErrorMessagesForAllUsers, setErrorMessageForUser, setShowAddResellerForm } from "../../../../redux/userManagement/userManagementSlice";
import { addNewResellerThunk, getMasterRatesThunk } from "../../../../redux/userManagement/userManagementThunk";
import { apiGatewayAxiosInstance } from "../../../../utils/axios";
import { Require } from "../../../botBuilder/addBot";
import FilePreviewComponent from "../../../botBuilder/FilePreviewComponent";
import AlertMessageComponent from "../../../components/AlertMessageComponent";
import BillingPaymentRadioButtons from "../../../components/paymentRadioButtons";
import StatusRadioButtons from "../../../components/StatusRadioButtons";

export default function AddReseller({
    resellerData,
    isBrandNotVerified,
    setClientDetails,
    isClientEditable,
    setEditable,
    billingDetailsByClientId,
    setBillingDetailsByClientId,
}) {
    const state = useSelector(state => state.userManagementSlice);
    const botState = useSelector(state => state.botManagementSlice);
    const [errorResponse, setErrorResponse] = useState("");
    const [otherFields, setOtherFields] = useState({});
    const [providerDetailsByProviderName, setProviderDetailsByProviderName] = useState(null);
    const [isUpdateClientLoading, setIsUpdateClientLoading] = useState(false);
    const dispatch = useDispatch();
    const [businessVerificationFormData, setBusinessVerificationFormData] = useState({});
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [brandLogoPreviewImage, setBrandLogoPreviewImage] = useState({ url: null, type: null, file: null, fieldName: null });
    const [getAllProviderList, setGetAllProviderList] = useState();
    const [IsEditDetailsLoading, setIsEditDetailsLoading] = useState(false);
    const [resellerLogoPreviewImage, setResellerLogoPreviewImage] = useState({ url: null, type: null, file: null, fieldName: null });
    const [backgroundImagePreview, setBackgroundImagePreview] = useState({ url: null, type: null, file: null, fieldName: null });
    const resellerLogoRef = useRef(null);
    const BackgroundImageRef = useRef(null);
    const [providerFields, setProviderFields] = useState({
        providerId: "",
        providerCode: "",
        providerName: "",
    });
    const [countryShortCode, setCountryShortCode] = useState();
    const verifyBusinessNameObjectToAdd = {
        documentType: "",
        selectedFile: {
            type: "file",
            url: "",
        },
    };

    const businessAddressObjectToAdd = {
        documentType: "",
        selectedFile: {
            type: "file",
            url: "",
        },
    };

    const [countryid, setCountryid] = useState(0);
    const [stateid, setStateid] = useState(0);
    const [cityid, setCityid] = useState(0);

    const handleCityName = e => {
        const { name, value } = e.target;
        formik.setFieldValue("companyAddress.city", value);
    };
    const onClickRadio = (name, e) => {
        formik.setFieldValue(name, e.target.value);
        if (name === "billingPaymentType" && !isClientEditable) {
            if (e.target.value === "Postpaid") {
                formik.setFieldValue("currentBalance", 0);
            } else {
                formik.setFieldValue("capCheck", false);
                formik.setFieldValue("maxBalanceLimit", 0);
            }
        }
    };

    const fileType = {
        DOC: "application/msword",

        DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        PDF: "application/pdf",
    };

    const onClickCancelButton = () => {
        setBillingDetailsByClientId(null);
        setClientDetails({});
        dispatch(setShowAddResellerForm(false));
    };

    const onClickCapCheck = e => {
        formik.setFieldValue("capCheck", e.target.checked);
    };
    const initialValues = {
        // selectedParentClientd: "",
        // selectedParentClient: null,
        domain: resellerData?.resellerDetails?.domain ? resellerData.resellerDetails.domain : "",
        apiDomain: resellerData?.resellerDetails?.apiDomain ? resellerData.resellerDetails.apiDomain : "",
        resellerName: resellerData?.resellerDetails ? resellerData.resellerDetails.resellerName : "",
        resellerWebsiteUrl: resellerData?.resellerDetails?.resellerWebsiteUrl ?? "",
        adminFirstName: resellerData ? resellerData?.name?.split(" ")[0] : "",
        adminLastName: resellerData ? resellerData?.name?.split(" ")[1] : "",
        // adminLastName: resellerData?.resellerDetails ? resellerData?.resellerDetails.lastName : "",
        _id: resellerData?._id ? resellerData?._id : "",
        email: resellerData?.email ? resellerData?.email : "",
        mobileNumber: resellerData?.mobileNumber ? resellerData?.mobileNumber : "",
        userName: resellerData?.userName ? resellerData?.userName : "",
        status: resellerData?.resellerDetails?.status ? resellerData?.resellerDetails?.status : ClientStatus.InActive,
        countryCode: resellerData?.countryCode ? resellerData?.countryCode : "",
        countryName: resellerData?.countryName ? resellerData?.countryName : "",

        supportedProvidersList: resellerData?.resellerDetails?.supportedProviders ? resellerData?.resellerDetails.supportedProviders : [],
        contactPersonFirstName: resellerData?.contactPersonDetails?.firstName ? resellerData?.contactPersonDetails?.firstName : "",

        contactPersonLastName: resellerData?.contactPersonDetails?.lastName ? resellerData?.contactPersonDetails?.lastName : "",
        contactPersonDesignation: resellerData?.resellerDetails?.contactPersonDesignation
            ? resellerData?.resellerDetails?.contactPersonDesignation
            : "",
        contactPersonEmailId: resellerData?.brandDetails?.contactPersonDetails?.emailId
            ? resellerData?.brandDetails?.contactPersonDetails?.emailId
            : "",
        companyAddress: {
            address: resellerData?.resellerDetails?.companyAddress.address ? resellerData?.resellerDetails?.companyAddress.address : "",
            country: resellerData?.resellerDetails?.companyAddress?.country ? resellerData?.resellerDetails?.companyAddress?.country : "",
            state: resellerData?.resellerDetails?.companyAddress?.state ? resellerData?.resellerDetails?.companyAddress?.state : "",
            city: resellerData?.resellerDetails?.companyAddress?.city ? resellerData?.resellerDetails?.companyAddress?.city : "",
            zipCode: resellerData?.resellerDetails?.companyAddress?.zipCode ? resellerData?.resellerDetails?.companyAddress?.zipCode : "",
            countryCode: resellerData?.resellerDetails?.companyAddress?.countryCode ? resellerData?.resellerDetails?.companyAddress?.countryCode : "",
        },
        smtpDetails: {
            smtpUsername: resellerData?.resellerDetails?.smtpDetails?.smtpUsername,
            password: resellerData?.resellerDetails?.smtpDetails?.password,
            nodeMailerHost: resellerData?.resellerDetails?.smtpDetails?.nodeMailerHost,
            nodeMailerPort: resellerData?.resellerDetails?.smtpDetails?.nodeMailerPort,
        },

        resellerLogo: resellerData?.resellerDetails?.resellerLogo
            ? resellerData?.resellerDetails?.resellerLogo
            : {
                type: "image",
                url: null,
            },

        buttonTextColor: {
            color: resellerData?.whiteLabelDetails ? resellerData.whiteLabelDetails.buttonTextColor?.color : "#ffffff",
            description: resellerData?.whiteLabelDetails ? resellerData.whiteLabelDetails.buttonTextColor?.description : "",
        },
        buttonBackgroundColor: {
            color: resellerData?.whiteLabelDetails ? resellerData.whiteLabelDetails.buttonBackgroundColor?.color : "#1984D9",
            description: resellerData?.whiteLabelDetails ? resellerData.whiteLabelDetails.buttonBackgroundColor?.description : "",
        },
        normalTextColor: {
            color: resellerData?.whiteLabelDetails ? resellerData.whiteLabelDetails.normalTextColor?.color : "#000000",
            description: resellerData?.whiteLabelDetails ? resellerData.whiteLabelDetails.normalTextColor?.description : "",
        },
        backgroundImage: resellerData?.whiteLabelDetails?.backgroundImage
            ? resellerData?.whiteLabelDetails?.backgroundImage
            : {
                type: "image",
                url: null,
            },

        // textMessageRate:
        //     customizeRatesByClientId &&
        //     customizeRatesByClientId?.customizedProviderRate[resellerData?.resellerDetails?.supportedProviders[0]]?.A2pMessageRate?.textMessageRate
        //         ? customizeRatesByClientId?.customizedProviderRate[resellerData?.resellerDetails?.supportedProviders[0]]?.A2pMessageRate
        //               ?.textMessageRate
        //         : 0.0,
        // multiMediaMessageRate:
        //     customizeRatesByClientId &&
        //     customizeRatesByClientId?.customizedProviderRate[resellerData?.resellerDetails?.supportedProviders[0]]?.A2pMessageRate
        //         ?.multiMediaMessageRate
        //         ? customizeRatesByClientId?.customizedProviderRate[resellerData?.resellerDetails?.supportedProviders[0]]?.A2pMessageRate
        //               ?.multiMediaMessageRate
        //         : 0.0,
        // A2pSessionConversationRate:
        //     customizeRatesByClientId &&
        //     customizeRatesByClientId?.customizedProviderRate[resellerData?.resellerDetails?.supportedProviders[0]]?.A2pMessageRate
        //         ?.A2pSessionConversationRate
        //         ? customizeRatesByClientId?.customizedProviderRate[resellerData?.resellerDetails?.supportedProviders[0]]?.A2pMessageRate
        //               ?.A2pSessionConversationRate
        //         : 0.0,
        // feedbackRate:
        //     customizeRatesByClientId &&
        //     customizeRatesByClientId?.customizedProviderRate[resellerData?.resellerDetails?.supportedProviders[0]]?.A2pMessageRate?.feedbackRate
        //         ? customizeRatesByClientId?.customizedProviderRate[resellerData?.resellerDetails?.supportedProviders[0]]?.A2pMessageRate?.feedbackRate
        //         : 0.0,
        billingPaymentType:
            billingDetailsByClientId && billingDetailsByClientId?.billingDetails?.billingPaymentType
                ? billingDetailsByClientId?.billingDetails?.billingPaymentType
                : "Prepaid",
        currentBalance:
            billingDetailsByClientId && billingDetailsByClientId?.billingDetails?.currentBalance
                ? billingDetailsByClientId?.billingDetails?.currentBalance
                : 0.0,
        maxBalanceLimit:
            billingDetailsByClientId && billingDetailsByClientId?.billingDetails?.maxBalanceLimit
                ? billingDetailsByClientId?.billingDetails?.maxBalanceLimit
                : 0.0,
        capCheck:
            billingDetailsByClientId && billingDetailsByClientId?.billingDetails?.capCheck
                ? billingDetailsByClientId?.billingDetails?.capCheck
                : false,
        currentUsage:
            billingDetailsByClientId && billingDetailsByClientId?.billingDetails?.currentUsage
                ? billingDetailsByClientId?.billingDetails?.currentUsage
                : 0,
        recharge:
            billingDetailsByClientId && billingDetailsByClientId?.billingDetails?.recharge ? billingDetailsByClientId?.billingDetails?.recharge : 0,

        // -----------------------------brandDetails-------------------------------------

        // contactPersonPhoneNumber: {
        //     number: resellerData?.brandDetails?.contactPersonDetails?.mobileNumber ? resellerData?.brandDetails?.contactPersonDetails?.mobileNumber : "",
        //     code: resellerData?.brandDetails?.contactPersonDetails?.countryCode ? resellerData?.brandDetails?.contactPersonDetails?.countryCode : "",
        //     country: resellerData?.brandDetails?.contactPersonDetails?.country ? resellerData?.brandDetails?.contactPersonDetails?.country : "",
        // },

        // -----------------------------------------------Business Verification Details-------------------------------------
        // verifyBusinessName: resellerData?.brandDetails?.businessVerificationDetails?.verifyBusinessName
        //     ? resellerData?.brandDetails?.businessVerificationDetails?.verifyBusinessName
        //     : [verifyBusinessNameObjectToAdd],
        // verifyBusinessAddress: resellerData?.brandDetails?.businessVerificationDetails?.verifyBusinessAddress
        //     ? resellerData?.brandDetails?.businessVerificationDetails?.verifyBusinessAddress
        //     : [businessAddressObjectToAdd],
        // termsAndConditions: false,
        // agreeToPayVerificationFee: false,
        // smsFallbackDetails: { senderIds: resellerData?.resellerDetails?.senderIds ?? [], apiKey: resellerData?.resellerDetails?.apiKey ?? "" },

        // otherFields:
        //     customizeRatesByClientId &&
        //     customizeRatesByClientId?.customizedProviderRate[resellerData?.resellerDetails?.supportedProviders[0]]?.otherFields
        //         ? customizeRatesByClientId?.customizedProviderRate[resellerData?.resellerDetails?.supportedProviders[0]]?.otherFields
        //         : Object.keys(otherFields).length > 0
        //         ? otherFields
        //         : {},
    };

    const onSelectImage = ({ file, url, fieldName }) => {
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = readerEvent => {
            if (file.type.includes("image")) {
                let mimeType = file.type;
                const supportedMimeTypeArray = ["image/jpeg", "image/png", "image/jpg"];
                if (supportedMimeTypeArray.includes(mimeType)) {
                    //   setting both the images in imageFormData state on fn call
                    setBrandLogoPreviewImage({
                        url: readerEvent.target.result,
                        type: file.type,
                        file: file,
                        fieldName: "brandLogoImageUrl",
                    });
                }
            }
        };
        // formik.setFieldValue("brandLogo.url", url);
        // formik.setFieldValue("brandLogo.type", file.type);
    };

    const updateClientDetails = async () => {
        try {
            setIsUpdateClientLoading(true);
            // const validateBrandDetailsResult = validateBrandDetails();
            // const validateBusinessVerificationResult = validateBusinessVerification();
            const validatePersonalDetailsResult = validatePersonalDetails();
            // const validateBrandDetailsResult = validateBrandDetails();
            // const validateBusinessVerificationResult = validateBusinessVerification();
            // const validateBillingDetailsResult = validateBillingDetails();
            if (!validatePersonalDetailsResult) {
                setIsUpdateClientLoading(false);
                return;
            }
            let clientDetailsToSend = {
                clientId: resellerData?._id,
                registryId: resellerData.resellerDetails._id,
                domain: formik.values.domain,
                apiDomain: formik.values.apiDomain,
                resellerName: formik.values.resellerName,
                adminFirstName: formik.values.adminFirstName,
                adminLastName: formik.values.adminLastName,
                email: formik.values.email,
                mobileNumber: formik.values.mobileNumber,
                userName: resellerData.userName,
                resellerLogo: formik.values.resellerLogo,
                resellerWebsiteUrl: formik.values.resellerWebsiteUrl,
                // adminFirstName: formik.values.adminFirstName,
                // adminLastName: formik.values.adminLastName,
                roleName: ERoles.RESELLER_ADMIN,
                status: formik.values.status,
                countryName: formik.values.countryName,
                countryCode: "+" + formik.values.countryCode,
                supportedProvidersList: formik.values.supportedProvidersList,
                contactPersonFirstName: formik.values.contactPersonLastName,
                contactPersonLastName: formik.values.contactPersonLastName,
                contactPersonDesignation: formik.values.contactPersonDesignation,
                buttonTextColor: {
                    color: formik.values.buttonTextColor.color,
                    description: formik.values.buttonTextColor.description,
                },
                buttonBackgroundColor: {
                    color: formik.values.buttonBackgroundColor.color,
                    description: formik.values.buttonBackgroundColor.description,
                },
                normalTextColor: {
                    color: formik.values.normalTextColor.color,
                    description: formik.values.normalTextColor.description,
                },
                backgroundImage: formik.values.backgroundImage,
                companyAddress: {
                    address: formik.values.companyAddress.address,
                    country: formik.values.companyAddress.country,
                    state: formik.values.companyAddress.state,
                    city: formik.values.companyAddress.city,
                    zipCode: formik.values.companyAddress.zipCode,
                },
                smtpDetails: {
                    smtpUsername: formik.values.smtpDetails.smtpUsername,
                    password: formik.values.smtpDetails.password,
                    nodeMailerHost: formik.values.smtpDetails.nodeMailerHost,
                    nodeMailerPort: formik.values.smtpDetails.nodeMailerPort,
                },
            };

            if (formik.values.billingPaymentType === "Postpaid") {
                clientDetailsToSend.billingDetails = {
                    billingPaymentType: formik.values.billingPaymentType,
                    currentBalance: 0,
                    maxBalanceLimit: formik.values.maxBalanceLimit,
                    capCheck: formik.values.capCheck,
                    currentUsage: formik.values.currentUsage,
                };
            } else {
                clientDetailsToSend.billingDetails = {
                    billingPaymentType: formik.values.billingPaymentType,
                    currentBalance: formik.values.currentBalance + formik.values.recharge,
                    maxBalanceLimit: 0,
                    capCheck: false,
                    currentUsage: 0,
                };
            }

            // let customizedProviderRate = {};
            // customizedProviderRate[`${formik.values.supportedProvidersList[0].providerCode}`] = {
            //     A2pMessageRate: {
            //         textMessageRate: formik.values.textMessageRate,
            //         multiMediaMessageRate: formik.values.multiMediaMessageRate,
            //         A2pSessionConversationRate: formik.values.A2pSessionConversationRate,
            //         feedbackRate: formik.values.feedbackRate,
            //     },
            //     // P2aMessageRate: {
            //     //     userMessageRate: formik.values.userMessageRate,
            //     //     P2aSessionConversationRate: formik.values.P2aSessionConversationRate,
            //     // },
            //     otherFields: formik.values.otherFields,
            // };

            // clientDetailsToSend["customizedProviderRate"] = customizedProviderRate;

            // brandData: brandDetailsToSend

            let formDataToSend = new FormData();

            // formDataToSend.append("brandLogo", brandLogoPreviewImage.file);

            for (let fieldName in businessVerificationFormData) {
                formDataToSend.append(fieldName, businessVerificationFormData[fieldName]);
            }

            // also, adding botdata(payload) to formDataToSend
            formDataToSend.append("resellerData", JSON.stringify(clientDetailsToSend));
            formDataToSend.append("resellerLogo", resellerLogoPreviewImage.file);
            formDataToSend.append("backgroundImage", backgroundImagePreview.file);

            // formDataToSend.append("brandData", JSON.stringify(brandDetailsToSend));
            const response = await apiGatewayAxiosInstance.post(`/user_management/clients/update-reseller`, formDataToSend, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                setIsUpdateClientLoading(false);
                onClickCancelButton();
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            setIsUpdateClientLoading(false);
            let errorMessage = "Failed to update reseller.";
            console.log(error);
            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    const onChangeMobileNumber = event => {
        const { value, name } = event.target;
        formik.setFieldValue("mobileNumber", value);
        formik.handleChange(event);
    };

    const onChangeEmailId = event => {
        const { value, name } = event.target;
        formik.setFieldValue("contactPersonEmailId", value);
        formik.handleChange(event);
    };

    const validateIfValueIsEmpty = fieldName => {
        const fieldValue = formik.values[fieldName]?.trim();
        if (fieldValue === undefined || fieldValue === "" || fieldValue === null) {
            formik.setFieldError(fieldName, `Required`);
            return true;
        } else {
            return false;
        }
    };

    const validateForUrl = fieldName => {
        const websiteRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

        if (!formik.values[fieldName].match(websiteRegex)) {
            formik.setFieldError(fieldName, "Invalid url");
            return true;
        } else {
            return false;
        }
    };

    const validateForEmail = fieldName => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

        if (!formik.values[fieldName].match(emailRegex)) {
            formik.setFieldError(fieldName, "Invalid Email");
            return true;
        } else {
            return false;
        }
    };

    const validateForPhoneNumber = fieldName => {
        const mobileNumberWithCountryCode = formik.values[fieldName]; //no need to send with countryCode
        if (formik.values[fieldName] === "" || formik.values[fieldName] === null) {
            formik.setFieldError(`mobileNumber`, `This field can’t be empty`);
            return true;
        }

        const numberRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        // const emailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

        const isValid = isValidPhoneNumber(mobileNumberWithCountryCode, countryShortCode);
        if (!isValid) {
            formik.setFieldError("mobileNumber", `Invalid number`);
            return true;
        }

        // if (!formik.values[fieldName].match(numberRegex)) {
        //     formik.setFieldError(`mobileNumber`, "Invalid number");
        //     return true;
        // }
        else {
            return false;
        }
    };


    const validateForCompanyAddress = () => {
        let result = true;

        if (validateIfValueIsEmpty(`companyAddress.address`)) {
            result = false;
        }
        if (validateIfValueIsEmpty("companyAddress.city")) {
            result = false;
        }
        if (validateIfValueIsEmpty("companyAddress.country")) {
            result = false;
        }

        if (validateIfValueIsEmpty("companyAddress.state")) {
            result = false;
        }

        // zipCode
        if (validateIfValueIsEmpty("companyAddress.zipCode")) {
            result = false;
        } else {
            const zipCodeRegex = /\b\d{6}\b/;
            if (!formik.values.companyAddress.zipCode.match(zipCodeRegex)) {
                formik.setFieldError(`companyAddress.zipCode`, "Invalid Zip Code");
                result = false;
            }
        }

        return result;
    };

    //Regex for api domain validation
    const domainRegex = /^(?!:\/\/)([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/;

    const validatePersonalDetails = () => {
        let result = true;
        const mobileWithCountryCode = "+" + formik.values.countryCode + formik.values.mobileNumber;

        if (validateIfValueIsEmpty("resellerName")) {
            result = false;
        }

        if (validateIfValueIsEmpty("resellerWebsiteUrl")) {
            result = false;
        }
        if (validateIfValueIsEmpty("adminFirstName")) {
            result = false;
        }

        if (validateIfValueIsEmpty("adminLastName")) {
            result = false;
        }

        if (validateIfValueIsEmpty("email")) {
            result = false;
        } else {
            if (validateForEmail("email")) {
                result = false;
            }
        }

        if (!(formik.values?.supportedProvidersList[0]?.providerCode !== "")) {
            formik.setFieldError("supportedProvidersList", `Required`);
            result = false;
        }

        if (validateIfValueIsEmpty("countryName")) {
            result = false;
        }

        if (validateForPhoneNumber("mobileNumber")) {
            result = false;
        }

        // if (isValidPhoneNumber(mobileWithCountryCode, "AF")) {
        //     result = false;
        // }

        if (validateIfValueIsEmpty("domain")) {
            result = false;
        } else if (validateForUrl("domain")) {
            formik.setFieldError("domain", "Please enter a valid domain ");
            result = false;
        }

        if (validateIfValueIsEmpty("apiDomain")) {
            result = false;
        } else if (validateForUrl("apiDomain")) {
            formik.setFieldError("apiDomain", "Please enter a valid API domain");
            result = false;
        }

        if (formik.values?.supportedProvidersList.length === 0) {
            formik.setFieldError("supportedProvidersList", "At least one provider must be selected");
            result = false;
        }

        if (formik.values.billingPaymentType === "Postpaid" && formik.values.capCheck) {
            if (formik.values.maxBalanceLimit === 0 || formik.values.maxBalanceLimit === "") {
                formik.setFieldError("maxBalanceLimit", "Balance Limit is required and cannot be zero.");
                result = false;
            }
        }

        if (!formik.values?.resellerLogo.url || !formik.values?.resellerLogo || formik.values?.resellerLogo.url == null) {
            formik.setFieldError("resellerLogo.url", "Reseller Logo is required");
            result = false;
        }

        // result = validateForCompanyAddress()

        return result;
    };

    const validateCurrentBalance = () => {
        const result = true;
        if (formik.values.billingPaymentType === "Prepaid") {
            if (formik.values.currentBalance === 0 || formik.values.currentBalance === "") {
                formik.setFieldError("currentBalance", "Balance is required and cannot be zero.");
                result = false;
            }
        }
        return result;
    };

    const validateSmtpDetails = () => {
        let result = true;
        if (formik.values.smtpDetails?.smtpUsername === "" || !formik.values.smtpDetails?.smtpUsername) {
            result = false;
            formik.setFieldError("smtpDetails.smtpUsername", "Required");
        }
        if (formik.values.smtpDetails?.password === "" || !formik.values.smtpDetails?.password) {
            result = false;
            formik.setFieldError("smtpDetails.password", "Required");
        }
        if (formik.values.smtpDetails?.nodeMailerHost === "" || !formik.values.smtpDetails?.nodeMailerHost) {
            result = false;
            formik.setFieldError("smtpDetails.nodeMailerHost", "Required");
        }
        if (formik.values.smtpDetails?.nodeMailerPort === "" || !formik.values.smtpDetails?.nodeMailerPort) {
            result = false;
            formik.setFieldError("smtpDetails.nodeMailerPort", "Required");
        }
        return result;
    };

    const validateBillingDetails = () => {
        let result = true;
        if (formik.values.supportedProvidersList[0].providerCode !== "") {
            if (validateIfValueIsEmpty("textMessageRate")) {
                result = false;
            }
            if (validateIfValueIsEmpty("multiMediaMessageRate")) {
                result = false;
            }
            if (validateIfValueIsEmpty("feedbackRate")) {
                result = false;
            }
            if (validateIfValueIsEmpty("A2pSessionConversationRate")) {
                result = false;
            }

            const otherFieldsName = Object.keys(providerDetailsByProviderName?.otherFields);
            for (let index = 0; index < otherFieldsName.length; index++) {
                const element = otherFieldsName[index];
                const data = formik.values.otherFields[element];

                if (!formik.values.otherFields[element] && formik.values.otherFields[element] !== 0) {
                    result = false;
                    formik.setFieldError(`otherFields.${element}`, `Required`);
                }
                if (formik.values.otherFields[element] === "") {
                    result = false;
                    formik.setFieldError(`otherFields.${element}`, `Required`);
                }
            }
            if (formik.values.billingPaymentType === "Prepaid") {
                if (validateIfValueIsEmpty("currentBalance")) {
                    result = false;
                }
            } else {
                if (formik.values.capCheck) {
                    if (validateIfValueIsEmpty("maxBalanceLimit")) {
                        result = false;
                    }
                }
            }
        }
        return result;
    };

    const validateBrandDetails = () => {
        let result = true;

        if (validateIfValueIsEmpty("resellerWebsiteUrl")) {
            result = false;
        } else {
            if (validateForUrl("resellerWebsiteUrl")) {
                result = false;
            }
        }

        if (validateIfValueIsEmpty("contactPersonDesignation")) {
            result = false;
        }

        if (validateIfValueIsEmpty("contactPersonEmailId")) {
            result = false;
        } else {
            if (validateForEmail("contactPersonEmailId")) {
                result = false;
            }
        }

        if (validateForPhoneNumber("contactPersonPhoneNumber")) {
            result = false;
        }

        if (!validateForCompanyAddress()) {
            result = false;
        }

        return result;
    };
    const validateBusinessVerification = () => {
        let result = true;

        // if (formik.values.termsAndConditions === false) {
        //   formik.setFieldError(`termsAndConditions`, `Please check the checkbox`);
        //   result = false;
        // }

        // if (formik.values.agreeToPayVerificationFee === false) {
        //   formik.setFieldError(`agreeToPayVerificationFee`, `Please check the checkbox`);
        //   result = false;
        // }

        formik.values.verifyBusinessName.map((document, index) => {
            if (document.documentType === "") {
                formik.setFieldError(`verifyBusinessName[${index}].documentType`, `This field can’t be empty`);
            }

            if (document.selectedFile.url === "" || document.selectedFile.url === null) {
                formik.setFieldError(`verifyBusinessName[${index}].selectedFile.url`, `Select File`);
            }
        });
        formik.values.verifyBusinessAddress.map((document, index) => {
            if (document.documentType === "") {
                formik.setFieldError(`verifyBusinessAddress[${index}].documentType`, `This field can’t be empty`);
            }

            if (document.selectedFile.url === "" || document.selectedFile.url === null) {
                formik.setFieldError(`verifyBusinessAddress[${index}].selectedFile.url`, `Select File`);
            }
        });
        return result;
    };

    const deleteDocumentForBusinessNameVerification = index => {
        //TODO:check if only 1 file is present then do not allow to delete

        // formik.setFieldValue(`verifyBusinessName[${index}].selectedFile.url`, "", true)
        let allDocuments = businessVerificationFormData;

        delete allDocuments[`verifyBusinessName[${index}].selectedFile.url`];
        let nextIndex = index;
        while (allDocuments[`verifyBusinessName[${nextIndex + 1}].selectedFile.url`]) {
            allDocuments[`verifyBusinessName[${nextIndex}].selectedFile.url`] = allDocuments[`verifyBusinessName[${nextIndex + 1}].selectedFile.url`];
            delete allDocuments[`verifyBusinessName[${nextIndex + 1}].selectedFile.url`];
            nextIndex = nextIndex + 1;
        }

        setBusinessVerificationFormData(allDocuments);
        const newPhones = [...formik.values.verifyBusinessName];

        newPhones.splice(index, 1);
        formik.setFieldValue("verifyBusinessName", newPhones);
    };

    // document type
    const onChangeAddDocumentForAddressVerification = ({ index, value, name }) => {
        // if (formik.values.verifyBusinessName.filter(document => document.documentType === value).length <= 0) {
        if (formik.values.verifyBusinessAddress.filter(document => document.documentType === value).length <= 0) {
            formik.setFieldValue(`verifyBusinessAddress[${index}].${name}`, value);
        } else {
            formik.setFieldError(`verifyBusinessAddress[${index}].${name}`, "Document Type Already Selected");
        }
    };

    const onChangeBusinessAddressVerification = ({ index, name, value }) => {
        let mimeType = value.type;

        const supportedMimeTypeArray = [".pdf", fileType.DOC, fileType.DOCX, fileType.PDF];
        if (!supportedMimeTypeArray.includes(mimeType)) {
            formik.setFieldError(`verifyBusinessAddress[${index}].selectedFile.url`, `Only pdf and .doc`);

            return;
        }
        formik.setFieldValue(`verifyBusinessAddress[${index}].${name}.url`, value);
        setBusinessVerificationFormData({
            ...businessVerificationFormData,
            [`verifyBusinessAddress[${index}].${name}.url`]: value,
        });
    };

    function deleteDocument(index) {
        //TODO:check if only 1 file is present then do not allow to delete
        // formik.setFieldValue(`verifyBusinessAddress[${index}].selectedFile.url`, "", true)

        let allDocuments = businessVerificationFormData;

        delete allDocuments[`verifyBusinessAddress[${index}].selectedFile.url`];
        let nextIndex = index;
        while (allDocuments[`verifyBusinessAddress[${nextIndex + 1}].selectedFile.url`]) {
            allDocuments[`verifyBusinessAddress[${nextIndex}].selectedFile.url`] =
                allDocuments[`verifyBusinessAddress[${nextIndex + 1}].selectedFile.url`];
            delete allDocuments[`verifyBusinessAddress[${nextIndex + 1}].selectedFile.url`];

            nextIndex = nextIndex + 1;
        }

        setBusinessVerificationFormData(allDocuments);
        const newPhones = [...formik.values.verifyBusinessAddress];
        newPhones.splice(index, 1);
        formik.setFieldValue("verifyBusinessAddress", newPhones);
    }

    const handleChangeCheckBox = e => {
        formik.setFieldValue(e.target.name, e.target.checked);
    };

    const addDocument = () => {
        formik.setFieldValue("verifyBusinessAddress", [...formik.values.verifyBusinessAddress, verifyBusinessNameObjectToAdd]);
    };

    const handleSubmit = async () => {
        try {
            const response = await apiGatewayAxiosInstance.post("/user_management/clients/create-reseller");
            // formik.submitForm();
            const validateBrandDetailsResult = validateBrandDetails();
            validateBusinessVerification();
            formik.handleSubmit();
            // if (validateBrandDetailsResult) {
            // }
        } catch (error) { }
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForAllUsers({ fieldName }));
    };

    const onChangeSelectProvider = async ({ name, value }) => {
        if (value && name) {
            const selectedProvider = getAllProviderList.find(provider => {
                if (provider.providerName == value) return provider;
            });
            // const providerListToUpdate = [...formik.values.supportedProvidersList, value];
            const providerDetails = {
                providerId: selectedProvider._id,
                providerCode: selectedProvider.providerCode,
                providerName: selectedProvider.providerName,
            };

            // Set the value in Formik as an array containing the provider object
            formik.setFieldValue(name, [providerDetails]);

            // formik.setFieldValue(name, [value]);
            setProviderFields({
                providerId: selectedProvider._id,
                providerCode: selectedProvider.providerCode,
                providerName: selectedProvider.providerName,
            });
            await getProviderFieldsDetails({ providerId: selectedProvider._id, providerCode: selectedProvider.providerCode });
            await getAllMasterRates({ providerId: selectedProvider._id, providerCode: selectedProvider.providerCode });
        }
    };

    const previewFile = (targetFile, urlFieldName, typeFieldName) => {
        // Reading New File (open file Picker Box)
        const reader = new FileReader();
        if (targetFile) {
            reader.readAsDataURL(targetFile);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = readerEvent => {
            if (targetFile.type.includes("image")) {
                formik.setFieldValue(urlFieldName, readerEvent.target.result);
                formik.setFieldValue(typeFieldName, targetFile.type);

                let mimeType = targetFile.type;
                const supportedMimeTypeArray = ["image/jpeg", "image/png", "image/jpg"];

                if (urlFieldName === "resellerLogo") {
                    if (supportedMimeTypeArray.includes(mimeType)) {
                        //   setting both the images in imageFormData state on fn call
                        setResellerLogoPreviewImage({
                            url: readerEvent.target.result,
                            type: targetFile.type,
                            file: targetFile,
                            fieldName: "resellerLogoImageUrl",
                        });
                    }
                }
                if (urlFieldName === "backgroundImage") {
                    if (supportedMimeTypeArray.includes(mimeType)) {
                        //   setting both the images in imageFormData state on fn call
                        setBackgroundImagePreview({
                            url: readerEvent.target.result,
                            type: targetFile.type,
                            file: targetFile,
                            fieldName: "backgroundImageUrl",
                        });
                    }
                }
            }
        };
    };

    const handleCountryName = value => {
        // const { name, value } = e.target;
        const country = countriesList.filter(country => {
            return country.name === value;
        })[0]; //here you will get full country object.
        GetState(country.id).then(result => {
            setStateList(result);
        });

        setCountryid(country.id);
        // onChangeSelectCountryForBrandDetails({
        //     value: country,
        //     // name: e.target.name,
        // })
        formik.setFieldValue("contactPersonPhoneNumber.country", country.name);
        formik.setFieldValue("companyAddress.country", country.name);
        formik.setFieldValue(`countryCode`, country.phone_code);
        formik.setFieldValue(`countryName`, country.name);
        formik.setFieldValue(`contactPersonPhoneNumber.code`, country.phone_code);
        setCountryShortCode(country.iso2);
    };

    const handleStateName = e => {
        const { name, value } = e.target;
        const state = stateList.filter(state => state.name == value)[0]; //here you will get full state object.
        GetCity(countryid, state.id).then(result => {
            setCityList(result);
        });

        formik.setFieldValue("companyAddress.state", value);
    };

    const getCountriesName = async () => {
        try {
            setIsEditDetailsLoading(true);
            await GetCountries().then(result => {
                setCountriesList(result);

                // handleCountryName("India")
                let countryName = "";
                if (resellerData?.resellerDetails?.companyAddress?.country) {
                    countryName = resellerData?.resellerDetails?.companyAddress?.country;

                    const country = result.filter(country => {
                        return country.name === countryName;
                    })[0]; //here you will get full country object.
                    GetState(country.id).then(result => {
                        if (resellerData?.resellerDetails?.companyAddress?.state) {
                            setStateList(result);
                            const state = result.filter(state => {
                                return state.name === resellerData?.resellerDetails?.companyAddress?.state;
                            })[0];
                            GetCity(country.id, state.id).then(result => {
                                setCityList(result);
                            });
                        }
                    });

                    setCountryid(country.id);

                    // onChangeSelectCountryForBrandDetails({
                    //     value: country,
                    //     // name: e.target.name,
                    // })
                    formik.setFieldValue("contactPersonPhoneNumber.country", country.name);
                    formik.setFieldValue("companyAddress.country", country.name);
                    formik.setFieldValue(`countryCode`, country.phone_code);
                    formik.setFieldValue(`countryName`, country.name);
                    formik.setFieldValue(`contactPersonPhoneNumber.code`, country.phone_code);
                    setCountryShortCode(country.iso2);
                }
            });

            // if (resellerData && resellerData?.resellerDetails?.supportedProviders[0]) {
            //     getProviderFieldsDetails(resellerData.resellerDetails.supportedProviders[0]);
            // }
            setIsEditDetailsLoading(false);
        } catch (error) {
            let errorMessage = "Failed to get countries.";
            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
            dispatch(
                setErrorMessageForUser({
                    fieldName: "getClientEditDetailsErrorMessages",
                    errorMessage,
                }),
            );
        }
    };

    function onDeleteImage(url, mimeType, fieldName) {
        formik.setFieldValue(url, null);
        formik.setFieldValue(mimeType, "");

        if (fieldName === "resellerLogo") {
            setResellerLogoPreviewImage({
                url: null,
                file: null,
                fieldName: null,
                type: null,
            });
        }
        if (fieldName === "backgroundImage") {
            setBackgroundImagePreview({
                url: null,
                file: null,
                fieldName: null,
                type: null,
            });
        }
    }

    const getProviderFieldsDetails = async ({ providerId, providerCode }) => {
        try {
            setIsEditDetailsLoading(true);
            const response = await apiGatewayAxiosInstance.get(`/bot_management/config/get-provider-details-by-provider`, {
                params: { providerId, providerCode },
            });

            if (response.status === 200) {
                setIsEditDetailsLoading(false);
                const dataToSet = response.data.data;
                setProviderDetailsByProviderName(dataToSet);
                let providerOtherFieldToNumber = {};

                if (Object.keys(dataToSet?.otherFields).length > 0) {
                    Object.keys(dataToSet?.otherFields).map((fieldElement, index) => {
                        providerOtherFieldToNumber[fieldElement] = 0;
                        // providerOtherFieldToNumber[fieldElement] = dataToSet?.otherFields[fieldElement]
                    });
                }
                if (!formik.values.otherFields || Object.keys(formik.values.otherFields).length <= 0) {
                    formik.setFieldValue("otherFields", providerOtherFieldToNumber);
                }
                setOtherFields(providerOtherFieldToNumber);
                return providerOtherFieldToNumber;
            }
        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "getClientEditDetailsErrorMessages",
                    errorMessage,
                }),
            );
        }
    };
    const getAllProvidersList = async () => {
        try {
            setIsEditDetailsLoading(true);
            // const response = await apiGatewayAxiosInstance.get(`/bot_management/config/get-all-providers-from-db`);
            const response = await apiGatewayAxiosInstance.get(`/billing_management/provider-csv-fields/get-all-providerId-providerCode`);
            const successStatusCodes = [200, 304]; // 304 is a success status saying data is not changed from previous success status [not modified]
            if (successStatusCodes.includes(response?.status)) {
                setIsEditDetailsLoading(false);
                setGetAllProviderList(response.data.data);
                // getCountriesName();
            }
        } catch (error) {
            let errorMessage = "Server not reachable";
            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "getClientEditDetailsErrorMessages",
                    errorMessage,
                }),
            );
        }
    };

    const getAllMasterRates = async ({ providerId, providerCode }) => {
        try {
            // setLoading(true);
            dispatch(getMasterRatesThunk({ providerId, providerCode }));
        } catch (error) {
            let errorMessage = "Failed to get master rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                const validateForCompanyAddressResult = validateForCompanyAddress();
                const validatePersonalDetailsResult = validatePersonalDetails();
                const validateSmtpDetailsResult = validateSmtpDetails();
                const validateCurrentBalanceResult = validateCurrentBalance();
                // const validateBrandDetailsResult = validateBrandDetails();
                // const validateBusinessVerificationResult = validateBusinessVerification();
                // const validateBillingDetailsResult = validateBillingDetails();

                if (validatePersonalDetailsResult && validateSmtpDetailsResult && validateCurrentBalanceResult && validateForCompanyAddressResult) {
                    let clientDetailsToSend = {
                        domain: values.domain,
                        apiDomain: values.apiDomain,
                        resellerName: values.resellerName,
                        adminFirstName: values.adminFirstName,
                        email: values.email,
                        adminLastName: values.adminLastName,
                        mobileNumber: values.mobileNumber,
                        userName: values.email,
                        // resellerName: values.resellerName,
                        resellerWebsiteUrl: values.resellerWebsiteUrl,
                        adminFirstName: values.adminFirstName,
                        adminLastName: values.adminLastName,
                        roleName: ERoles.RESELLER_ADMIN,
                        email: values.email,
                        mobileNumber: values.mobileNumber,
                        userName: values.email,
                        status: values.status,
                        countryName: values.countryName,
                        countryCode: "+" + values.countryCode,
                        supportedProvidersList: values.supportedProvidersList,
                        // contactPersonFirstName: values.contactPersonLastName,
                        // contactPersonLastName: values.contactPersonLastName,
                        contactPersonDesignation: values.contactPersonDesignation,
                        companyAddress: {
                            address: values.companyAddress.address,
                            country: values.companyAddress.country,
                            state: values.companyAddress.state,
                            city: values.companyAddress.city,
                            zipCode: values.companyAddress.zipCode,
                            countryCode: "+" + values.countryCode,
                        },
                        buttonTextColor: {
                            color: values.buttonTextColor.color,
                            description: values.buttonTextColor.description,
                        },
                        buttonBackgroundColor: {
                            color: values.buttonBackgroundColor.color,
                            description: values.buttonBackgroundColor.description,
                        },
                        normalTextColor: {
                            color: values.normalTextColor.color,
                            description: values.normalTextColor.description,
                        },
                        backgroundImage: values.backgroundImage,
                        smtpDetails: {
                            smtpUsername: values.smtpDetails.smtpUsername,
                            password: values.smtpDetails.password,
                            nodeMailerHost: values.smtpDetails.nodeMailerHost,
                            nodeMailerPort: values.smtpDetails.nodeMailerPort,
                        },
                    };

                    if (values.billingPaymentType === "Postpaid") {
                        clientDetailsToSend.billingDetails = {
                            billingPaymentType: values.billingPaymentType,
                            currentBalance: 0,
                            maxBalanceLimit: values.maxBalanceLimit,
                            capCheck: values.capCheck,
                            currentUsage: 0,
                        };
                    } else {
                        clientDetailsToSend.billingDetails = {
                            billingPaymentType: values.billingPaymentType,
                            currentBalance: values.currentBalance,
                            maxBalanceLimit: 0,
                            capCheck: false,
                            currentUsage: 0,
                        };
                    }

                    // let customizedProviderRate = {}; // Initialize as an object

                    // for (let index = 0; index < values.supportedProvidersList.length; index++) {
                    //     const element = values.supportedProvidersList[index];

                    //     // Use providerCode as a key in the object
                    //     let providerCode = element.providerCode;
                    //     await getProviderFieldsDetails({ providerId: element._id, providerCode: element.providerCode });

                    //     customizedProviderRate[providerCode] = {
                    //         providerId: element.providerId,
                    //         providerCode: element.providerCode,
                    //         A2pMessageRate: {
                    //             textMessageRate: 0,
                    //             multiMediaMessageRate: 0,
                    //             A2pSessionConversationRate: 0,
                    //             feedbackRate: 0,
                    //         },
                    //         otherFields: otherFields,
                    //     };
                    // }

                    let customizedProviderRate = []; // Initialize as an object

                    for (let index = 0; index < values.supportedProvidersList.length; index++) {
                        const element = values.supportedProvidersList[index];

                        // Use providerCode as a key in the object
                        let providerCode = element.providerCode;
                        const providerDetails = await getProviderFieldsDetails({
                            providerId: element.providerId,
                            providerCode: element.providerCode,
                        });

                        const providerObjectToAdd = {
                            providerId: element.providerId,
                            providerCode: element.providerCode,
                            A2pMessageRate: {
                                textMessageRate: 0,
                                multiMediaMessageRate: 0,
                                A2pSessionConversationRate: 0,
                                feedbackRate: 0,
                            },
                            otherFields: providerDetails,
                        };
                        customizedProviderRate = [...customizedProviderRate, { [providerCode]: providerObjectToAdd }];
                    }

                    // customizedProviderRate will now be an object with providerCode as keys

                    clientDetailsToSend["customizedProviderRate"] = customizedProviderRate;

                    // brandData: brandDetailsToSend
                    let formDataToSend = new FormData();

                    // formDataToSend.append("brandLogo", brandLogoPreviewImage.file);

                    for (let fieldName in businessVerificationFormData) {
                        formDataToSend.append(fieldName, businessVerificationFormData[fieldName]);
                    }

                    // also, adding botdata(payload) to formDataToSend
                    formDataToSend.append("resellerData", JSON.stringify(clientDetailsToSend));
                    formDataToSend.append("resellerLogo", resellerLogoPreviewImage.file);
                    formDataToSend.append("backgroundImage", backgroundImagePreview.file);

                    // formDataToSend.append("brandData", JSON.stringify(brandDetailsToSend));
                    dispatch(addNewResellerThunk({ formDataToSend }));
                }
            } catch (error) {
                // const message = error.message || "Something went wrong";
                // setStatus({ success: false });
                // setErrors({ submit: message });
                // setSubmitting(false);
                let errorMessage = "Failed to create reseller.";
                if (error.response) {
                    errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
                }
                // setErrorResponse(errorMessage);
            }
        },
    });

    const onSelectImages = ({ file, url, fieldName }) => {
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = readerEvent => {
            if (file.type.includes("image")) {
                let mimeType = file.type;
                const supportedMimeTypeArray = ["image/jpeg", "image/png", "image/jpg"];
                if (supportedMimeTypeArray.includes(mimeType)) {
                    if (fieldName === "resellerLogo") {
                        if (supportedMimeTypeArray.includes(mimeType)) {
                            //   setting both the images in imageFormData state on fn call
                            setResellerLogoPreviewImage({
                                url: readerEvent.target.result,
                                type: file.type,
                                file: file,
                                fieldName: "resellerLogoImageUrl",
                            });
                        }
                    }
                    if (fieldName === "backgroundImage") {
                        if (supportedMimeTypeArray.includes(mimeType)) {
                            //   setting both the images in imageFormData state on fn call
                            setBackgroundImagePreview({
                                url: readerEvent.target.result,
                                type: file.type,
                                file: file,
                                fieldName: "backgroundImageUrl",
                            });
                        }
                    }
                }
            }
        };

        if (fieldName === "resellerLogo") {
            formik.setFieldValue("resellerLogo.url", url);
            formik.setFieldValue("resellerLogo.type", file.type);
        }
        if (fieldName === "backgroundImage") {
            formik.setFieldValue("backgroundImage.url", url);
            formik.setFieldValue("backgroundImage.type", file.type);
        }
    };

    // const onChangeProviderHandler = event => {
    //     const { value } = event.target;
    //     let uniqueArray = [];
    //     Array.isArray(value) &&
    //         value.map(item => {
    //             if (item && item._id && item.providerCode) {
    //                 if (uniqueArray.length > 0) {
    //                     const isProviderExist = uniqueArray.filter(
    //                         provider => provider.providerId == item._id && provider.providerCode == item.providerCode,
    //                     );
    //                     if (!isProviderExist) {
    //                         uniqueArray.push({ providerId: item._id, providerCode: item.providerCode });
    //                     }
    //                 } else {
    //                     uniqueArray.push({ providerId: item._id, providerCode: item.providerCode });
    //                 }
    //             }
    //         });

    //     // // Filter out empty objects and map to desired structure
    //     // const nonEmptyValues = value
    //     //     .filter(item => item && Object.keys(item).length > 0) // Ensure item is not null and not empty
    //     //     .map(item => ({
    //     //         providerId: item._id,
    //     //         providerCode: item.providerCode,
    //     //     }));

    //     // // Remove duplicates based on providerId
    //     // const uniqueValues = Array.from(new Map(nonEmptyValues.map(item => [item.providerId, item])).values());

    //     // Set filtered unique values in formik
    //     formik.setFieldValue("supportedProvidersList", uniqueArray);
    // };

    const onChangeProviderHandler = event => {
        const { value } = event.target;

        // Get the current selected providers list from formik
        const currentValues = formik.values.supportedProvidersList || [];

        // Determine if the selected item is already in the current list
        const updatedValues = currentValues.some(selectedProvider => selectedProvider.providerCode === value[value.length - 1]?.providerCode)
            ? currentValues.filter(selectedProvider => selectedProvider.providerCode !== value[value.length - 1]?.providerCode) // Remove if it's already selected
            : [
                ...currentValues,
                {
                    providerId: value[value.length - 1]?._id,
                    providerCode: value[value.length - 1]?.providerCode,
                },
            ]; // Add if it's not selected

        // Set the updated list in formik
        formik.setFieldValue("supportedProvidersList", updatedValues);
    };

    useEffect(async () => {
        // dispatch(getAllProvidersThunk());
        await getAllProvidersList();
        await getCountriesName();
        // if (resellerData?.resellerDetails?.supportedProviders[0]) {
        // getProviderFieldsDetails(resellerData.resellerDetails.supportedProviders[0]);
        // getAllMasterRates(resellerData?.resellerDetails?.supportedProviders[0]);
        // }
    }, [resellerData?.resellerDetails?.supportedProviders[0]]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid item md={6} display={"flex"} alignItems={"center"}>
                <IconButton color="secondary" onClick={onClickCancelButton} size="medium">
                    <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                </IconButton>

                <Grid role="presentation">
                    <Breadcrumbs aria-label="breadcrumb" fontSize="large">
                        <Link
                            underline="hover"
                            color="inherit"
                            className="breadcrumbItem breadcrumbFontSize"
                            // href="/rcs-management/manage-bots/"
                            onClick={onClickCancelButton}
                        >
                            Resellers
                        </Link>
                        {resellerData?.name && isClientEditable ? (
                            <Typography className="breadcrumbItem" color="text.primary">
                                Update {resellerData.resellerDetails.orgName} Details
                            </Typography>
                        ) : (
                            <Typography className="breadcrumbItem" color="text.primary">
                                Add New Reseller
                            </Typography>
                        )}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Card sx={{ padding: "0.8rem" }}>
                <CardContent sx={{ padding: "10px" }}>
                    <Grid className="d-flex" gap={5}>
                        <FormControl fullWidth className="field-spacing" error={Boolean(formik.touched.resellerName && formik.errors.resellerName)}>
                            <TextField
                                disabled={state.controls.isCreateResellerLoading || !isBrandNotVerified}
                                name="resellerName"
                                fullWidth
                                // label="Reseller Name"
                                label={<span>Reseller Name <span style={{ color: 'red' }}>*</span></span>}
                                id="resellerName"
                                error={Boolean(formik.touched.resellerName && formik.errors.resellerName)}
                                helperText={formik.touched.resellerName && formik.errors.resellerName}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.resellerName}
                            />
                        </FormControl>
                        <FormControl
                            fullWidth
                            className="field-spacing"
                            error={Boolean(formik.touched.resellerWebsiteUrl && formik.errors.resellerWebsiteUrl)}
                        >
                            <TextField
                                disabled={state.controls.isCreateResellerLoading || !isBrandNotVerified}
                                name="resellerWebsiteUrl"
                                fullWidth
                                // label="Reseller Website Url"
                                label={<span>Reseller Website Url <span style={{ color: 'red' }}>*</span></span>}
                                id="resellerWebsiteUrl"
                                error={Boolean(formik.touched.resellerWebsiteUrl && formik.errors.resellerWebsiteUrl)}
                                helperText={formik.touched.resellerWebsiteUrl && formik.errors.resellerWebsiteUrl}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.resellerWebsiteUrl}
                            />
                        </FormControl>
                    </Grid>
                    <Grid className="d-flex" gap={5}>
                        <TextField
                            disabled={state.controls.isCreateResellerLoading || !isBrandNotVerified}
                            name="adminFirstName"
                            fullWidth
                            label={<span>Reseller Admin First Name <span style={{ color: 'red' }}>*</span></span>}
                            variant="outlined"
                            className="field-spacing"
                            error={Boolean(formik.touched.adminFirstName && formik.errors.adminFirstName)}
                            helperText={formik.touched.adminFirstName && formik.errors.adminFirstName}
                            onBlur={formik.handleBlur}
                            value={formik.values.adminFirstName}
                            onChange={formik.handleChange}
                        />

                        <TextField
                            disabled={state.controls.isCreateResellerLoading || !isBrandNotVerified}
                            name="adminLastName"
                            fullWidth
                            label={<span>Reseller Admin Last Name <span style={{ color: 'red' }}>*</span></span>}
                            variant="outlined"
                            className="field-spacing"
                            error={Boolean(formik.touched.adminLastName && formik.errors.adminLastName)}
                            helperText={formik.touched.adminLastName && formik.errors.adminLastName}
                            onBlur={formik.handleBlur}
                            value={formik.values.adminLastName}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid className="d-flex" gap={5}>
                        <TextField
                            disabled={state.controls.isCreateResellerLoading || !isBrandNotVerified}
                            name="email"
                            fullWidth
                            // label="Reseller Admin Email"
                            label={<span>Reseller Admin Email <span style={{ color: 'red' }}>*</span></span>}
                            variant="outlined"
                            className="field-spacing"
                            error={Boolean(formik.touched.email && formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            onChange={e => onChangeEmailId(e)}
                        />
                        <FormControl fullWidth disabled={state.controls.isCreateResellerLoading || !isBrandNotVerified}>
                            <TextField
                                focused
                                className="field-spacing"
                                disabled={true}
                                name="userName"
                                fullWidth
                                label="Username"
                                variant="outlined"
                                // error={Boolean(
                                //     formik.touched.userName &&
                                //         formik.errors.userName
                                // )}
                                // helperText={
                                //     formik.touched.userName &&
                                //     formik.errors.userName
                                // }

                                onBlur={formik.handleBlur}
                                // value={formik.values.userName}
                                value={resellerData?.userName ? resellerData?.userName : formik.values.email}
                            // onChange={formik.handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Box display={"flex"}>
                        <FormControl fullWidth>
                            {/* <Typography variant="body1" mb={3} fontWeight={"bold"}>
                              Contact Person Phone Number{" "}
                              <span className="text-danger">*</span>
                          </Typography> */}

                            <Box container gap={5} display={"flex"} width={"100%"} className="field-spacing">
                                <Grid width={"49%"} container sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <FormControl error={Boolean(formik.errors.contactPersonDesignation)} fullWidth>
                                        <TextField
                                            // className='mt-2'
                                            name="contactPersonDesignation"
                                            fullWidth
                                            label="Company Person Designation"
                                            variant="outlined"
                                            disabled={!isBrandNotVerified}
                                            error={Boolean(formik.errors.contactPersonDesignation)}
                                            helperText={formik.errors.contactPersonDesignation}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            defaultValue={formik.values.contactPersonDesignation}
                                        />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item width={"49%"}>
                                    <FormControl
                                        fullWidth
                                        sx={{ margin: "auto", minWidth: "100%" }}
                                        disabled={state.controls.isCreateResellerLoading}
                                        error={Boolean(formik.errors.supportedProvidersList && formik.touched.supportedProvidersList)}
                                    >
                                        <InputLabel id='provider'>Select Provider</InputLabel>
                                        <Select
                                            // fullWidth
                                            disabled={state.controls.isCreateResellerLoading}
                                            name='supportedProvidersList'
                                            label='Select Provider'
                                            labelId='provider'
                                            id='provider'
                                            value={formik.values.supportedProvidersList[0].providerName}
                                            error={Boolean(formik.touched.supportedProvidersList && formik.errors.supportedProvidersList)}
                                            helperText={formik.touched.supportedProvidersList && formik.errors.supportedProvidersList}
                                            onBlur={formik.handleBlur}
                                            onChange={e =>
                                                onChangeSelectProvider({
                                                    value: e.target.value,
                                                    name: e.target.name,
                                                })
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                    },
                                                },
                                            }}
                                            // disabled={
                                            //   !isBotEditable
                                            // }
                                        >
                                            {getAllProviderList?.map((provider, index) => (
                                                <MenuItem key={index} value={provider.providerName}>
                                                    {provider.providerName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            {formik.touched.supportedProvidersList && formik.errors.supportedProvidersList}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid> */}
                                <Grid item width={"49%"}>
                                    <FormControl
                                        fullWidth
                                        sx={{ margin: "auto", minWidth: "100%" }}
                                        disabled={isClientEditable}
                                        error={Boolean(formik.errors.supportedProvidersList && formik.touched.supportedProvidersList)}
                                    >
                                        <InputLabel id="provider">Select Provider</InputLabel>
                                        <Select
                                            // sx={{ maxWidth: "230px", overflowX: "auto" }}
                                            name="supportedProvidersList"
                                            label="Select Provider"
                                            labelId="provider"
                                            id="provider"
                                            value={formik.values.supportedProvidersList}
                                            error={Boolean(formik.touched.supportedProvidersList && formik.errors.supportedProvidersList)}
                                            helperText={formik.touched.supportedProvidersList && formik.errors.supportedProvidersList}
                                            onBlur={formik.handleBlur}
                                            renderValue={selected =>
                                                selected
                                                    .filter(provider => provider?.providerCode)
                                                    .map(provider => provider.providerCode.split("_").join("-"))
                                                    .join(", ")
                                            }
                                            onChange={onChangeProviderHandler}
                                            multiple
                                        >
                                            {getAllProviderList &&
                                                getAllProviderList.map((provider, index) => {
                                                    return (
                                                        <MenuItem
                                                            sx={{ height: "25px", padding: "15px 0px !important" }}
                                                            key={index}
                                                            value={provider}
                                                            disabled={!provider.isMasterRatesFromProvider}
                                                        >
                                                            <Checkbox
                                                                checked={formik.values.supportedProvidersList.some(
                                                                    selectedProvider => selectedProvider.providerCode === provider.providerCode,
                                                                )}
                                                                fontSize={"small"}
                                                            />
                                                            {provider.providerCode.split("_").join("-")}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Box>
                        </FormControl>
                    </Box>
                    <Box container sx={{ width: "100%" }} display={"flex"} gap={5} className="field-spacing">
                        <Grid width={"49%"} container sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid item md={3.9} display={"flex"}>
                                <FormControl fullWidth error={formik.touched.countryName && formik.errors.countryName}>
                                    <InputLabel id="countryId">Country</InputLabel>
                                    <Select
                                        id="countryId"
                                        name="country"
                                        label="Country"
                                        disabled={!isBrandNotVerified}
                                        value={formik.values.countryName}
                                        fullWidth
                                        // labelId='countryId'
                                        // value={}
                                        onChange={e => {
                                            handleCountryName(e.target.value);
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "250px", // Adjust the maxHeight as per your requirement
                                                    width: "230px",
                                                },
                                            },
                                        }}
                                    >
                                        {/* <Grid height={'250px'} width={'225px'}> */}
                                        {/* {state.masterRatesList.map(item => ( */}
                                        {countriesList.map(item => (
                                            <MenuItem value={item.name} sx={{ display: "grid", placeItems: "center", fontSize: "0.8rem" }}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                        {/* </Grid> */}
                                    </Select>
                                    <FormHelperText>{formik.touched.countryName && formik.errors.countryName}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={3.9} display={"flex"}>
                                <FormControl fullWidth error={formik.touched?.companyAddress?.state && formik.errors?.companyAddress?.state}>
                                    <InputLabel
                                        id="state"
                                    // style={{
                                    //     marginRight: "20px",
                                    // }}
                                    >
                                        State
                                    </InputLabel>

                                    <Select
                                        id="state"
                                        name="state"
                                        label="Country"
                                        fullWidth
                                        disabled={!isBrandNotVerified}
                                        onChange={e => {
                                            handleStateName(e);
                                        }}
                                        defaultValue={formik.values.companyAddress.state}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "250px", // Adjust the maxHeight as per your requirement
                                                    width: "230px",
                                                },
                                            },
                                        }}
                                    >
                                        {stateList.map((item, key) => (
                                            <MenuItem key={key} value={item.name} sx={{ display: "grid", placeItems: "center", fontSize: "0.8rem" }}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{formik.touched?.companyAddress?.state && formik.errors?.companyAddress?.state}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={3.9} display={"flex"}>
                                <FormControl fullWidth error={formik.touched?.companyAddress?.city && formik.errors?.companyAddress?.city}>
                                    <InputLabel id="city">City</InputLabel>
                                    <Select
                                        fullWidth
                                        id="city"
                                        name="city"
                                        label="City"
                                        defaultValue={formik.values.companyAddress?.city}
                                        disabled={!isBrandNotVerified}
                                        onChange={e => {
                                            handleCityName(e);
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "250px", // Adjust the maxHeight as per your requirement
                                                    width: "230px",
                                                },
                                            },
                                        }}
                                    >
                                        {cityList.map((item, key) => (
                                            <MenuItem key={key} value={item.name} sx={{ display: "grid", placeItems: "center", fontSize: "0.8rem" }}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{formik.touched?.companyAddress?.city && formik.errors?.companyAddress?.city}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid width={"49%"} container display={"flex"} justifyContent={"space-between"}>
                            <Grid item md={2}>
                                <TextField
                                    fullWidth
                                    name="companyAddress.countryCode"
                                    label="Code"
                                    error={Boolean(formik.errors?.countryCode && formik.errors?.countryCode)}
                                    disabled={!isBrandNotVerified}
                                    helperText={formik.errors?.countryCode && formik.errors?.countryCode}
                                    value={formik.values?.countryCode}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item md={9.9}>
                                <TextField
                                    disabled={!countryShortCode}
                                    fullWidth
                                    name="mobileNumber"
                                    // label="Mobile Number"
                                    label={<span>Mobile Number <span style={{ color: 'red' }}>*</span></span>}
                                    error={Boolean(formik.errors?.mobileNumber && formik.errors?.mobileNumber)}
                                    helperText={formik.errors?.mobileNumber && formik.errors?.mobileNumber}
                                    defaultValue={formik.values.mobileNumber}
                                    value={formik.values.mobileNumber}
                                    onBlur={formik.handleBlur}
                                    onChange={e => {
                                        onChangeMobileNumber(e);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <TextField
                                disabled={!isBrandNotVerified}
                                className="mt-2"
                                name="companyAddress.address"
                                fullWidth
                                label={<span>Company Address <span style={{ color: 'red' }}>*</span></span>}

                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.address}
                                error={Boolean(formik.errors?.companyAddress?.address)}
                                helperText={formik.errors?.companyAddress?.address}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                disabled={!isBrandNotVerified}
                                className="mt-2"
                                name="companyAddress.zipCode"
                                fullWidth
                                label={<span>Zip Code <span style={{ color: 'red' }}>*</span></span>}
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.zipCode}
                                error={Boolean(formik.errors?.companyAddress?.zipCode)}
                                helperText={formik.errors?.companyAddress?.zipCode}
                            />
                        </FormControl>
                    </Box>

                    <Box paddingBottom={2} display={"flex"} gap={5} mt={5}>
                        <>
                            <FormControl error={Boolean(formik.errors.officialBrandWebsite)} className="w-50">
                                <TextField
                                    // autoFocus
                                    disabled={!isBrandNotVerified}
                                    fullWidth
                                    name="domain"
                                    // label="Domain for Reseller"
                                    label={<span>Domain for Reseller <span style={{ color: 'red' }}>*</span></span>}
                                    variant="outlined"
                                    placeholder="http://abc.com"
                                    error={Boolean(formik.errors?.domain)}
                                    helperText={formik.errors?.domain}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    defaultValue={formik.values.domain}
                                />
                            </FormControl>
                            <FormControl error={Boolean(formik.errors.officialBrandWebsite)} className="w-50">
                                <TextField
                                    // autoFocus
                                    disabled={!isBrandNotVerified}
                                    fullWidth
                                    name="apiDomain"
                                    // label="API Domain for Reseller"
                                    label={<span>API Domain for Reseller <span style={{ color: 'red' }}>*</span></span>}
                                    variant="outlined"
                                    placeholder="http://api.abc.com"
                                    error={Boolean(formik.errors?.apiDomain)}
                                    helperText={formik.errors?.apiDomain}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    defaultValue={formik.values.apiDomain}
                                />
                            </FormControl>
                        </>
                    </Box>
                    <Box paddingBottom={2} display={"flex"} gap={5} mt={5}>
                        <FormControl error={Boolean(formik.errors.resellerLogo)} className="w-100">
                            <Typography variant="h6" fontWeight={"bold"}>
                                <Grid container alignItems="center">
                                    <Grid item>Reseller Logo</Grid>
                                    <Grid item>
                                        <Require />
                                    </Grid>
                                </Grid>
                            </Typography>
                            <Typography className="text-muted mb-3">Provide a Reseller logo for WhiteLabel.</Typography>
                            {(formik.values.resellerLogo.url || resellerLogoPreviewImage?.url) && (
                                <Grid sx={{ display: "flex" }}>
                                    <FilePreviewComponent
                                        type={resellerLogoPreviewImage.type}
                                        url={resellerLogoPreviewImage.url}
                                        previewImage={resellerLogoPreviewImage}
                                        formikImage={formik.values.resellerLogo}
                                        aspect={1 / 1}
                                        onSelectImage={onSelectImages}
                                        imageHeightRatio={1}
                                        imageWidthRatio={1}
                                        fieldName={"resellerLogo"}
                                        // isDisabled={!isBrandNotVerified}
                                        deleteFile={() => onDeleteImage("resellerLogo.url", "resellerLogo.type", "resellerLogo")}
                                        width={"240px"}
                                        height={"100px"}
                                    />
                                </Grid>
                            )}

                            {!(formik.values.resellerLogo.url || resellerLogoPreviewImage?.url) && (
                                <Button variant="outlined" sx={{ width: "250px" }} onClick={() => resellerLogoRef.current.click()}>
                                    Choose
                                </Button>
                            )}
                            <FormHelperText>{formik.errors?.resellerLogo?.url}</FormHelperText>

                            <Box className="btn-container">
                                <input
                                    name="resellerLogo"
                                    ref={resellerLogoRef}
                                    accept="image/*"
                                    onChange={e => previewFile(e.target.files[0], "resellerLogo", "resellerLogo.type")}
                                    onClick={e => (e.target.value = "")}
                                    type="file"
                                    hidden
                                />
                            </Box>
                        </FormControl>
                    </Box>

                    <hr style={{ marginTop: "10px !important" }} />
                    <Typography variant="h6" marginTop={"10px"} marginBottom={"10px"}>
                        <b className="text-primary">WhiteLabel Details</b>
                    </Typography>

                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                <Grid display={"flex"}>
                                    Button Text Color
                                    <Require />
                                </Grid>
                            </Typography>
                            {/* <Typography className='text-muted mb-4'>
                                Specify a color for your agent with a minimum 4.5:1 contrast ratio relative to white.
                            </Typography> */}
                            <Box display={"inline-flex"} gap={5} alignItems={"center"} mt={5}>
                                <input
                                    type="color"
                                    id="color_whitelabel_org"
                                    name="buttonTextColor.color"
                                    value={formik.values.buttonTextColor.color}
                                    onChange={formik.handleChange}
                                    className="border border-1 p-1"
                                />

                                <TextField
                                    size="small"
                                    name="buttonTextColor.color"
                                    id="color-value"
                                    label="Button Text Color (# value)"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.buttonTextColor.color}
                                    onChange={formik.handleChange}
                                />

                                <TextField
                                    size="small"
                                    name="buttonTextColor.description"
                                    label="Description"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    defaultValue={formik.values.buttonTextColor.description}
                                />
                            </Box>
                        </FormControl>

                        <FormControl fullWidth>
                            <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                <Grid display={"flex"}>
                                    Button Background Color
                                    <Require />
                                </Grid>
                            </Typography>
                            {/* <Typography className='text-muted mb-4'>
                                Specify a color for your agent with a minimum 4.5:1 contrast ratio relative to white.
                            </Typography> */}
                            <Box display={"inline-flex"} gap={5} alignItems={"center"} mt={5}>
                                {/* Color picker input */}
                                <input
                                    type="color"
                                    id="color"
                                    name="buttonBackgroundColor.color"
                                    value={formik.values.buttonBackgroundColor.color}
                                    onChange={formik.handleChange}
                                    className="border border-1 p-1"
                                />

                                <TextField
                                    size="small"
                                    name="buttonBackgroundColor.color"
                                    id="color-value"
                                    label="Button Background Color (# value)"
                                    onBlur={formik.handleBlur}
                                    value={formik.values.buttonBackgroundColor.color}
                                    onChange={formik.handleChange}
                                />

                                <TextField
                                    size="small"
                                    name="buttonBackgroundColor.description"
                                    label="Description"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    defaultValue={formik.values.buttonBackgroundColor.description}
                                />
                            </Box>
                        </FormControl>
                    </Box>

                    <Box paddingBottom={2} display={"flex"} gap={5} mt={5}>
                        <>
                            <FormControl className="w-50">
                                <Typography className="subHeadings" style={{ flexWrap: "wrap" }} variant="h6" fontStyle={"bold"}>
                                    <Grid display={"flex"}>
                                        Normal Text Color
                                        <Require />
                                    </Grid>
                                </Typography>
                                {/* <Typography className='text-muted mb-4'>
                                    Specify a color for your agent with a minimum 4.5:1 contrast ratio relative to white.
                                </Typography> */}
                                <Box display={"inline-flex"} gap={5} alignItems={"center"} mt={5}>
                                    {/* Color picker input */}
                                    <input
                                        type="color"
                                        id="color"
                                        name="normalTextColor.color"
                                        value={formik.values.normalTextColor.color}
                                        onChange={formik.handleChange}
                                        className="border border-1 p-1"
                                    />

                                    {/* TextField to show color hex value */}
                                    <TextField
                                        size="small"
                                        name="normalTextColor.color" // Make sure this matches formik field name
                                        id="color-value"
                                        label="Normal Text Color (# value)"
                                        onBlur={formik.handleBlur}
                                        value={formik.values.normalTextColor?.color} // Show hex value
                                        onChange={formik.handleChange} // Allow manual input of hex value
                                    />

                                    <TextField
                                        size="small"
                                        name="normalTextColor.description"
                                        label="Description"
                                        variant="outlined"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        defaultValue={formik.values.normalTextColor?.description}
                                    />
                                </Box>
                            </FormControl>

                            {/* DON'T REMOVE THIS CODE  */}
                            {/* <FormControl error={Boolean(formik.errors.resellerLogo)} className='w-50'>
                                <Typography variant='h6' fontWeight={"bold"}>
                                    Background Image
                                </Typography>
                                <Typography className='text-muted mb-3'>Provide a Reseller logo for WhiteLabel.</Typography>
                                {(formik.values.backgroundImage.url || backgroundImagePreview?.url) && (
                                    <Grid sx={{ display: "flex" }}>
                                        <FilePreviewComponent
                                            type={backgroundImagePreview.type}
                                            url={backgroundImagePreview.url}
                                            previewImage={backgroundImagePreview}
                                            formikImage={formik.values.backgroundImage}
                                            aspect={2 / 1}
                                            onSelectImage={onSelectImages}
                                            imageHeightRatio={1}
                                            imageWidthRatio={2}
                                            fieldName={"backgroundImage"}
                                            // isDisabled={!isBrandNotVerified}
                                            deleteFile={() => onDeleteImage("backgroundImage.url", "backgroundImage.type", "backgroundImage")}
                                            // width={"240px"}
                                            height={"100px"}
                                        />
                                    </Grid>
                                )}

                                {!(formik.values.backgroundImage.url || backgroundImagePreview?.url) && (
                                    <Button
                                        variant='outlined'
                                        sx={{ width: "250px", marginTop: "10px" }}
                                        onClick={() => BackgroundImageRef.current.click()}
                                    >
                                        Choose
                                    </Button>
                                )}
                                <FormHelperText>{formik.errors?.backgroundImage?.url}</FormHelperText>

                                <Box className='btn-container'>
                                    <input
                                        name='backgroundImage'
                                        ref={BackgroundImageRef}
                                        accept='image/*'
                                        onChange={e => previewFile(e.target.files[0], "backgroundImage", "backgroundImage.type")}
                                        onClick={e => (e.target.value = "")}
                                        type='file'
                                        hidden
                                    />
                                </Box>
                            </FormControl> */}
                        </>
                    </Box>

                    <hr style={{ marginTop: "10px !important" }} />
                    <Typography variant="h6" marginTop={"10px"} marginBottom={"10px"}>
                        <b className="text-primary">Email Account Details</b>
                    </Typography>

                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <TextField
                                disabled={!isBrandNotVerified}
                                className="mt-2"
                                name="smtpDetails.smtpUsername"
                                fullWidth
                                // label="SMTP Username"
                                label={<span>SMTP Username <span style={{ color: 'red' }}>*</span></span>}
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.smtpDetails?.smtpUsername}
                                error={Boolean(formik.errors?.smtpDetails?.smtpUsername)}
                                helperText={formik.errors?.smtpDetails?.smtpUsername}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                disabled={!isBrandNotVerified}
                                className="mt-2"
                                name="smtpDetails.password"
                                fullWidth
                                // label="SMTP Password"
                                label={<span>SMTP Password <span style={{ color: 'red' }}>*</span></span>}
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.smtpDetails.password}
                                error={Boolean(formik.errors?.smtpDetails?.password)}
                                helperText={formik.errors?.smtpDetails?.password}
                            />
                        </FormControl>
                    </Box>

                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <TextField
                                disabled={!isBrandNotVerified}
                                className="mt-2"
                                name="smtpDetails.nodeMailerHost"
                                fullWidth
                                // label="Node Mailer Host"
                                label={<span>Node Mailer Host <span style={{ color: 'red' }}>*</span></span>}
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.smtpDetails.nodeMailerHost}
                                error={Boolean(formik.errors?.smtpDetails?.nodeMailerHost)}
                                helperText={formik.errors?.smtpDetails?.nodeMailerHost}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                disabled={!isBrandNotVerified}
                                className="mt-2"
                                name="smtpDetails.nodeMailerPort"
                                fullWidth
                                // label="Node Mailer Port"
                                label={<span>Node Mailer Port <span style={{ color: 'red' }}>*</span></span>}
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.smtpDetails.nodeMailerPort}
                                error={Boolean(formik.errors?.smtpDetails?.nodeMailerPort)}
                                helperText={formik.errors?.smtpDetails?.nodeMailerPort}
                            />
                        </FormControl>
                    </Box>

                    <>
                        <hr style={{ marginTop: "10px !important" }} />
                        <Grid className="d-flex" gap={5}>
                            <Grid style={{ width: "49%" }}>
                                <BillingPaymentRadioButtons
                                    onChangeRadio={onClickRadio}
                                    isDisabled={state.controls.isCreateResellerLoading}
                                    billingPaymentType={formik.values.billingPaymentType}
                                />
                            </Grid>

                            {formik.values.billingPaymentType === "Prepaid" ? (
                                <Grid className="d-flex" gap={3} style={{ width: "49%" }}>
                                    {!(resellerData?.name && isClientEditable) ? (
                                        <TextField
                                            disabled={state.controls.isCreateResellerLoading}
                                            type="number"
                                            inputProps={{
                                                step: "any",
                                            }}
                                            onWheel={e => e.target.blur()}
                                            name="currentBalance"
                                            style={{ width: "100%" }}
                                            className="field-spacing"
                                            // label="Add Balance (INR)"
                                            label={<span>Add Balance (INR) <span style={{ color: 'red' }}>*</span></span>}
                                            variant="outlined"
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.errors.currentBalance)}
                                            helperText={formik.errors.currentBalance}
                                            value={formik.values.currentBalance}
                                            onChange={formik.handleChange}
                                        />
                                    ) : (
                                        <>
                                            <Grid style={{ width: "49%" }}>
                                                <TextField
                                                    disabled={true}
                                                    type="number"
                                                    inputProps={{
                                                        step: "any",
                                                    }}
                                                    onWheel={e => e.target.blur()}
                                                    name="currentBalance"
                                                    style={{ width: "100%" }}
                                                    className="field-spacing"
                                                    label="Current Balance(INR)"
                                                    variant="outlined"
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.errors.currentBalance)}
                                                    helperText={formik.errors.currentBalance}
                                                    value={formik.values.currentBalance}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                            <Grid style={{ width: "49%" }}>
                                                <TextField
                                                    disabled={state.controls.isCreateResellerLoading}
                                                    type="number"
                                                    inputProps={{ step: "any" }}
                                                    onWheel={e => e.target.blur()}
                                                    name="recharge"
                                                    style={{ width: "100%" }}
                                                    className="field-spacing"
                                                    label="Recharge (INR)"
                                                    variant="outlined"
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.errors.recharge)}
                                                    helperText={formik.errors.recharge}
                                                    value={formik.values.recharge}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            ) : (
                                <Grid minHeight={"100%"} display={"flex"} flexDirection={"column"} alignItems={"start"} marginTop={3}>
                                    <Typography variant="body1" color={"gray"} sx={{ maxHeight: "35px", fontWeight: "300px" }}>
                                        Currently your Usage Limit is <b>Unlimited. </b>
                                        <br />
                                        If you want to set Maximum Usage Limit, please enable Cap Check &nbsp;
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={false}
                                                checked={formik.values.capCheck}
                                                onChange={onClickCapCheck}
                                                name="capCheck"
                                                size={"small"}
                                            />
                                        }
                                        label="Cap Check"
                                    />

                                    <Grid display={"flex"} gap={3} style={{ width: "100%" }}>
                                        <Grid>
                                            <TextField
                                                fullWidth
                                                style={{ width: "100%" }}
                                                type="number"
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                // disabled
                                                name="currentUsage"
                                                defaultValue={formik.values.currentUsage}
                                                className="field-spacing"
                                                label="Total Usage (INR)"
                                                variant="outlined"
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.currentUsage && formik.errors.currentUsage)}
                                                helperText={formik.touched.currentUsage && formik.errors.currentUsage}
                                                value={formik.values.currentUsage}
                                                // onChange={formik.handleChange}
                                                disabled={true} // this should be calculated and fetched from DB,it should not be updatable by client
                                            />
                                        </Grid>

                                        {formik.values.billingPaymentType === "Postpaid" && formik.values.capCheck && (
                                            <Grid display={"flex"} flexDirection={"column"}>
                                                <Grid style={{ width: "100%" }}>
                                                    <TextField
                                                        fullWidth
                                                        style={{ width: "100%" }}
                                                        type="number"
                                                        inputProps={{
                                                            step: "any",
                                                        }}
                                                        onWheel={e => e.target.blur()}
                                                        // disabled
                                                        name="maxBalanceLimit"
                                                        defaultValue={1000}
                                                        className="field-spacing"
                                                        // label="Balance Limit (INR)"
                                                        label={<span>Balance Limit (INR) <span style={{ color: 'red' }}>*</span></span>}
                                                        variant="outlined"
                                                        onBlur={formik.handleBlur}
                                                        error={Boolean(formik.touched.maxBalanceLimit && formik.errors.maxBalanceLimit)}
                                                        helperText={formik.touched.maxBalanceLimit && formik.errors.maxBalanceLimit}
                                                        value={formik.values.maxBalanceLimit}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </>
                    {/* )} */}

                    <Grid className="d-flex" gap={5}>
                        <Grid style={{ width: "49%" }}>
                            <StatusRadioButtons onChangeRadio={onClickRadio} isDisabled={!resellerData?.name} status={formik.values.status} />
                        </Grid>
                    </Grid>
                </CardContent>

                <CardActions sx={{ padding: "0px" }}>
                    <Grid container sx={{ alignItems: "center" }}>
                        <Grid item className="d-flex">
                            {state.controls.isCreateResellerLoading ? (
                                <Button variant="outlined">
                                    <CircularProgress size={15} className="spinner" />
                                    Adding..
                                </Button>
                            ) : isUpdateClientLoading ? (
                                <Button variant="outlined">
                                    <CircularProgress size={15} className="spinner" />
                                    Updating..
                                </Button>
                            ) : resellerData?.name && isClientEditable ? (
                                <Button
                                    variant="contained"
                                    className="m-2"
                                    // type="submit"
                                    onClick={() => {
                                        updateClientDetails();
                                    }}
                                >
                                    Update
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    className="m-2"
                                    type="submit"
                                // onClick={handleSubmit}
                                >
                                    Add Reseller
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={onClickCancelButton}
                                className="m-2"
                                disabled={state.controls.isCreateResellerLoading}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>

                {state.controls.addNewResellerErrorMessage && (
                    <AlertMessageComponent
                        message={state.controls.addNewResellerErrorMessage}
                        fieldName={"addNewResellerErrorMessage"}
                        handleClose={onCloseErrorMessage}
                        show={Boolean(state.controls.addNewResellerErrorMessage)}
                    />
                )}
                <AlertMessageComponent
                    message={errorResponse}
                    fieldName={"errorResponse"}
                    handleClose={() => setErrorResponse("")}
                    show={Boolean(errorResponse)}
                />
            </Card>
        </form>
    );
}
