import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";

// import "./mocks";

import { ThemeProvider } from "./contexts/ThemeContext";

import "./assets/style.css";
import "./assets/dashboardStyles.css";
import { BACKGROUND_IMAGE } from "./utils/axios";

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider >
            <App />
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById("root"),
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
