import { CloseOutlined } from "@mui/icons-material";

import { useTheme } from "@emotion/react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";

import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorMessageForBaseRate, setErrorMessageForUser } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import { DatePicker, DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { showSnackbar } from '../store/snackbarSlice';

export default function EditCountryRateDetails({
    updateCountryRateCardModel,
    setEditCountryRateDetails,
    countryRateDetails,
    selectProviderName,
    getAllMasterRates,
    otherFields,
    providerDetailsByProviderName,
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const currentDateTime = new Date();
    const [isUpdateMasterRateLoading, setIsUpdateMasterRateLoading] = useState(false);
    const state = useSelector(state => state.userManagementSlice);
    const [selectedDate, setSelectedDate] = useState(currentDateTime);
    const initialValues = {
        providerId: countryRateDetails?.providerId,
        providerCode: countryRateDetails?.providerCode,
        updatedTextMessageRate: countryRateDetails?.baseRate?.A2pMessageRate?.textMessageRate ?? 0,
        updatedMultiMediaMessageRate: countryRateDetails?.baseRate?.A2pMessageRate?.multiMediaMessageRate ?? 0,
        updatedA2pSessionConversationRate: countryRateDetails?.baseRate?.A2pMessageRate?.A2pSessionConversationRate ?? 0,
        updatedFeedbackRate: countryRateDetails?.baseRate?.A2pMessageRate?.feedbackRate ?? 0,
        otherFields: countryRateDetails?.baseRate?.otherFields ?? {},
    };

    const editMasterRatesByCountry = async ({ data }) => {
        try {
            setIsUpdateMasterRateLoading(true);
            const response = await apiGatewayAxiosInstance.post(`/user_management/rate-card/update-by-countryId`, data);

            if (response.status === 200) {
                setIsUpdateMasterRateLoading(false);
                await getAllMasterRates({ providerId: countryRateDetails.providerId });
                handleClose();
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Failed to update master rate.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setIsUpdateMasterRateLoading(false);
            dispatch(
                setErrorMessageForUser({
                    fieldName: "updateMasterRateErrorMessage",
                    errorMessage,
                }),
            );
        }
    };

    const validateIfValueIsEmpty = fieldName => {
        const falsyValuesToValidate = [null, undefined, NaN];

        const fieldValue = formik.values[fieldName];
        if (falsyValuesToValidate.includes(fieldValue) || fieldValue === "") {
            formik.setFieldError(fieldName, `Required`);
            return false;
        }
        return true;
    };

    const validateIfValueIsEmptyForOtherFields = fieldName => {
        const fieldValue = formik.values.otherFields[fieldName];
        if (fieldValue === "" || fieldValue === null) {
            formik.setFieldError(`otherFields.${fieldName}`, `Required`);
            return false;
        } else {
            return true;
        }
    };

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const validation = () => {
        let result = true;

        if (!validateIfValueIsEmpty("updatedTextMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedMultiMediaMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedA2pSessionConversationRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedFeedbackRate")) {
            result = false;
        }

        // if (!validateIfValueIsEmpty("updatedUserMessageRate")) {
        //     result = false;
        // }
        // if (!validateIfValueIsEmpty("updatedP2aConversationRate")) {
        //     result = false;
        // }

        for (let index = 0; index < Object.keys(initialValues.otherFields).length; index++) {
            const otherRate = Object.keys(initialValues.otherFields)[index];
            if (!validateIfValueIsEmptyForOtherFields(otherRate)) {
                result = false;
            }
        }

        return result;
    };

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                if (validation()) {
                    let dataToSend = {
                        providerId: countryRateDetails.providerId,
                        providerCode: countryRateDetails.providerCode,
                        countryCode: countryRateDetails.countryCode,
                        // countryId: countryRateDetails._id,
                        effectiveFrom: selectedDate,
                        baseRate: {
                            A2pMessageRate: {
                                textMessageRate: values.updatedTextMessageRate,
                                multiMediaMessageRate: values.updatedMultiMediaMessageRate,
                                A2pSessionConversationRate: values.updatedA2pSessionConversationRate,
                                feedbackRate: values.updatedFeedbackRate,
                            },
                            otherFields: values.otherFields,
                        },
                    };

                    editMasterRatesByCountry({ data: dataToSend });
                }
            } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
            }
        },
    });

    const handleClose = () => {
        setEditCountryRateDetails(false);
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessageForBaseRate({ fieldName }));
    };


    return (
        <Dialog open={updateCountryRateCardModel} onClose={handleClose} sx={{ padding: "1rem" }} fullWidth maxWidth={"xs"}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Grid display={"flex"}>
                        <Typography variant='h5'>
                            <b>Update Rates For</b>
                        </Typography>
                        <Typography variant='h5' className='text-primary'>
                            &nbsp;<b> {countryRateDetails.countryName}</b>
                        </Typography>
                    </Grid>
                    <IconButton onClick={() => handleClose()}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <Divider variant='middle' />
                <DialogContent>
                    <Grid>
                        <TextField
                            sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                },
                                "& input[type=number]": {
                                    MozAppearance: "textfield",
                                },
                            }}
                            className='mb-2'
                            fullWidth
                            size='small'
                            type='number'
                            inputProps={{
                                step: "any",
                            }}
                            onWheel={e => e.target.blur()}
                            label='Text Message Rate '
                            name='updatedTextMessageRate'
                            defaultValue={countryRateDetails?.baseRate?.A2pMessageRate?.textMessageRate}
                            // onChange={handleChangeUpdatedRateCardDetails}
                            error={Boolean(formik.touched.updatedTextMessageRate && formik.errors.updatedTextMessageRate)}
                            helperText={formik.touched.updatedTextMessageRate && formik.errors.updatedTextMessageRate}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid mt={3}>
                        <TextField
                            sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                },
                                "& input[type=number]": {
                                    MozAppearance: "textfield",
                                },
                            }}
                            className='mb-2'
                            fullWidth
                            size='small'
                            type='number'
                            inputProps={{
                                step: "any",
                            }}
                            onWheel={e => e.target.blur()}
                            label='Multi Media Message Rate'
                            name='updatedMultiMediaMessageRate'
                            defaultValue={countryRateDetails?.baseRate?.A2pMessageRate?.multiMediaMessageRate}
                            error={Boolean(formik.touched.updatedMultiMediaMessageRate && formik.errors.updatedMultiMediaMessageRate)}
                            helperText={formik.touched.updatedMultiMediaMessageRate && formik.errors.updatedMultiMediaMessageRate}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        // onChange={handleChangeUpdatedRateCardDetails}
                        />
                    </Grid>

                    <Grid mt={3}>
                        <TextField
                            sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                },
                                "& input[type=number]": {
                                    MozAppearance: "textfield",
                                },
                            }}
                            className='mb-2'
                            fullWidth
                            size='small'
                            type='number'
                            inputProps={{
                                step: "any",
                            }}
                            onWheel={e => e.target.blur()}
                            label='A2p SessionConversation Rate'
                            name='updatedA2pSessionConversationRate'
                            defaultValue={countryRateDetails?.baseRate?.A2pMessageRate?.A2pSessionConversationRate}
                            error={Boolean(formik.touched.updatedA2pSessionConversationRate && formik.errors.updatedA2pSessionConversationRate)}
                            helperText={formik.touched.updatedA2pSessionConversationRate && formik.errors.updatedA2pSessionConversationRate}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        // onChange={handleChangeUpdatedRateCardDetails}
                        />
                    </Grid>

                    <Grid mt={3}>
                        <TextField
                            sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                },
                                "& input[type=number]": {
                                    MozAppearance: "textfield",
                                },
                            }}
                            className='mb-2'
                            fullWidth
                            size='small'
                            type='number'
                            inputProps={{
                                step: "any",
                            }}
                            onWheel={e => e.target.blur()}
                            label='Feedback rate'
                            name='updatedFeedbackRate'
                            defaultValue={countryRateDetails?.baseRate?.A2pMessageRate?.feedbackRate}
                            error={Boolean(formik.touched.updatedFeedbackRate && formik.errors.updatedFeedbackRate)}
                            helperText={formik.touched.updatedFeedbackRate && formik.errors.updatedFeedbackRate}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        // onChange={handleChangeUpdatedRateCardDetails}
                        />
                    </Grid>
                    {Object.keys(providerDetailsByProviderName?.otherFields).length > 0 &&
                        Object.keys(providerDetailsByProviderName?.otherFields).map((fieldElement, index) => (
                            <Grid mt={3} key={index}>
                                <TextField
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                    }}
                                    className='mb-2'
                                    fullWidth
                                    size='small'
                                    type='number'
                                    inputProps={{
                                        step: "any",
                                    }}
                                    onWheel={e => e.target.blur()}
                                    label={fieldElement}
                                    name={`otherFields.${fieldElement}`}
                                    value={formik.values.otherFields[fieldElement]}
                                    error={Boolean(
                                        formik.touched?.otherFields &&
                                        formik.touched?.otherFields[fieldElement] &&
                                        formik.errors?.otherFields &&
                                        formik.errors?.otherFields[fieldElement],
                                    )}
                                    helperText={
                                        formik.touched?.otherFields &&
                                        formik.touched?.otherFields[fieldElement] &&
                                        formik.errors?.otherFields &&
                                        formik.errors?.otherFields[fieldElement]
                                    }
                                    // helperText={Boolean(
                                    //     `formik.errors.otherFields.${fieldElement}`
                                    // )}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                // onChange={handleChangeUpdatedRateCardDetails}
                                />
                            </Grid>
                        ))}

                    {/* <Grid mt={3}>
                        <TextField
                            className='mb-2'
                            fullWidth
                            size='small'
                            type='number'
                            inputProps={{
                                step: 'any'
                            }}
                            onWheel={e => e.target.blur()}
                            label='P2a Session Conversation Rate'
                            name='updatedP2aConversationRate'
                            defaultValue={
                                countryRateDetails?.baseRate?.otherFields
                                    ?.P2aConversationRate
                            }
                            error={Boolean(
                                formik.touched.updatedP2aConversationRate &&
                                    formik.errors.updatedP2aConversationRate
                            )}
                            helperText={
                                formik.touched.updatedP2aConversationRate &&
                                formik.errors.updatedP2aConversationRate
                            }
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            // onChange={handleChangeUpdatedRateCardDetails}
                        />
                    </Grid> */}

                    <Grid mt={3}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label='Select Effective From Date and Time'
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    // minDate={new Date()}
                                    // maxDate={new Date(new Date().setMonth(new Date().getMonth() + 6))}
                                    minTime={
                                        selectedDate && selectedDate.getDate() === currentDateTime.getDate()
                                            ? new Date(
                                                currentDateTime.getFullYear(),
                                                currentDateTime.getMonth(),
                                                currentDateTime.getDate(),
                                                currentDateTime.getHours(),
                                                currentDateTime.getMinutes(),
                                            )
                                            : null
                                    }
                                    renderInput={params => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        padding: "1rem 1.5rem",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Grid container>
                        <Grid padding={1}>
                            {isUpdateMasterRateLoading ? (
                                <Button variant='outlined'>
                                    <CircularProgress size={15} className='spinner' />
                                    Updating..
                                </Button>
                            ) : (
                                <Button type='submit' variant='contained'>
                                    Update
                                </Button>
                            )}
                        </Grid>

                        <Grid padding={1}>
                            <Button variant='contained' color='error' onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
            {state.controls.updateMasterRateErrorMessage && (
                <AlertMessageComponent
                    message={state.controls.updateMasterRateErrorMessage}
                    fieldName={"updateMasterRateErrorMessage"}
                    handleClose={onCloseErrorMessage}
                    show={Boolean(state.controls.updateMasterRateErrorMessage)}
                />
            )}
        </Dialog>
    );
}
