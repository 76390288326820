import {
    Box,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    FormControl,
    Button,
    FormHelperText,
    Tooltip,
    Grid,
    IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SuggestionsComponent from "./SuggestionsComponent";
import { useRef } from "react";
import CharCounter from "../../../components/CharCounter";
import FilePreviewComponent from "../../botBuilder/FilePreviewComponent";
import { ColorizeSharp } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { uuid } from "short-uuid";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { config } from "../../../config";

export default function CardComponent ({
    cardWidth,
    mediaHeight,
    data,
    index,
    handleChange,
    handleRemoveCard,
    cardErrors,
    cardTouched,
    cardHandleBlur,
    cardCount,
    formik,
    disabled,
    cardsPreviewImageList,
    setCardsPreviewImageList,
    setCardsPreviewThumbnailImageList,
    cardsPreviewThumbnailImageList,
    aspectRatio,
    imageHeightRatio,
    imageWidthRatio,
}) {
    const [imageFormData, setImageFormData] = useState({});
    const filePicekerRef = useRef(null);
    const thumnailInputRef = useRef(null);

    const onFieldChange = e => {
        const { name, value } = e.target;

        handleChange({ field: name, value, index });
    };
    const [reload, setReload] = useState(true);

    const onClickRemoveCarouselCard = (e, cardIndex) => {
        onDeleteThumbnail();
        handleRemoveCard({ cardIndex });
    };

    const suggestionObjectToAdd = {
        typeOfAction: "reply",
        suggestionText: "",
        suggestionId: uuid(),
        suggestionPostback: `[${config.REACT_APP_POSTBACK_VARIABLE}]`,
    };

    const onClickAddSuggestionCarouselCard = () => {
        if (data?.suggestionsList.length < 4) {
            const newArray = [...data?.suggestionsList, suggestionObjectToAdd];
            handleChange({ field: "suggestionsList", value: newArray, index });
        }
    };

    const handleChangeSuggestionCarouselCard = ({ field, value, indexOfSuggstion, cardErrors }) => {
        if (field === "typeOfAction") {
            if (value === "dialer_action") {
                const obj = {
                    typeOfAction: value,
                    suggestionText: "",
                    suggestionId: uuid(),
                    suggestionPostback: `[${config.REACT_APP_POSTBACK_VARIABLE}]`,
                    phoneNumberToDial: {
                        country: "India",
                        countryCode: "+91",
                        number: "",
                    },
                };
                data.suggestionsList[indexOfSuggstion] = obj;
            } else {
                data.suggestionsList[indexOfSuggstion].typeOfAction = value;
            }
        } else if (field === "phoneNumberToDial.number") {
            data.suggestionsList[indexOfSuggstion].phoneNumberToDial.number = value;
        } else if (field === "suggestionPostback") {
            data.suggestionsList[indexOfSuggstion].suggestionPostback = value;
        } else if (field === "suggestionId") {
            data.suggestionsList[indexOfSuggstion].suggestionId = value;
        } else if (field === "suggestionText") {
            data.suggestionsList[indexOfSuggstion].suggestionText = value;
        } else if (field === "urlAction") {
            data.suggestionsList[indexOfSuggstion].urlAction = value;
        } else if (field === "query") {
            data.suggestionsList[indexOfSuggstion].query = value;
        }

        handleChange({
            field: "suggestionsList",
            value: data.suggestionsList,
            index,
        });
    };

    const handleChangeRemoveSuggestionCarouselCard = ({ indexOfSuggstion }) => {
        data.suggestionsList.splice(indexOfSuggstion, 1);

        handleChange({
            field: "suggestionsList",
            value: data.suggestionsList,
            index,
        });
    };

    const previewFile = ({ targetFile, urlFieldName, typeFieldName }) => {
        // Reading New File (open file Picker Box)
        const reader = new FileReader();
        // Gettting Selected File (user can select multiple but we are choosing only one)
        const selectedFile = targetFile;
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = readerEvent => {
            if (selectedFile.type.includes("image")) {
                let fileSizeInMB = selectedFile.size / 1024 / 1024;
                let fileSizeInKB = selectedFile.size / 1024;

                let mimeType = selectedFile.type;
                const supportedMimeTypeArray = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
                if (!supportedMimeTypeArray.includes(mimeType)) {
                    formik.setFieldError(`richCardCarouselDetails.cardsList[${index}].cardMedia`, `Image type should be JPEG , JPG , PNG , or GIF.`);
                    return;
                }
                if (supportedMimeTypeArray.includes(mimeType)) {
                    if (fileSizeInMB > 1 && urlFieldName === `richCardCarouselDetails.cardsList[${index}].cardMedia.url`) {
                        formik.setFieldError(`richCardCarouselDetails.cardsList[${index}].cardMedia`, `Image should have max file size of 1MB.`);
                        return;
                    }
                    // if (fileSizeInKB > 40 && urlFieldName === `richCardCarouselDetails.cardsList[${index}].thumbnail.url`) {

                    // }

                    if (
                        formik.values.richCardCarouselDetails.cardsList[index].cardMedia.url &&
                        formik.values.richCardCarouselDetails.cardsList[index].cardMedia.type === "video/mp4"
                    ) {
                        if (fileSizeInKB > 100 && urlFieldName === `richCardCarouselDetails.cardsList[${index}].cardThumbnail`) {
                            formik.setFieldError(
                                `richCardCarouselDetails.cardsList[${index}].cardThumbnail`,
                                `Image should have max file size of 100kb.`,
                            );
                            return;
                        }
                        // if (fileSizeInKB > 40 && urlFieldName === `richCardCarouselDetails.cardsList[${index}].thumbnail.url`) {

                        // }
                        if (!supportedMimeTypeArray.includes(mimeType)) {
                            formik.setFieldError(
                                `richCardCarouselDetails.cardsList[${index}].cardThumbnail`,
                                `Image type should be JPEG , JPG , PNG , or GIF.`,
                            );
                            return;
                        }
                        let cardThumbnail = {};
                        cardThumbnail[data.cardId] = { url: readerEvent.target.result, type: mimeType, file: targetFile, fieldName: urlFieldName };

                        setCardsPreviewThumbnailImageList({ ...cardsPreviewThumbnailImageList, ...cardThumbnail });

                        // setThumbnailPreviewImage({ url: readerEvent.target.result, type: mimeType, file: targetFile, fieldName: urlFieldName })
                    } else {
                        let allCardImageList = structuredClone(cardsPreviewImageList);

                        allCardImageList[index] = { url: readerEvent.target.result, type: mimeType, file: targetFile, fieldName: urlFieldName };

                        setCardsPreviewImageList(allCardImageList);
                        if (mimeType === "image/gif") {
                            formik.setFieldValue(urlFieldName, readerEvent.target.result);
                            formik.setFieldValue(typeFieldName, targetFile.type);
                        }
                    }
                    // setReload(!reload);

                    // setImageFormData({
                    //     ...imageFormData,
                    //     [urlFieldName]: targetFile,
                    // });
                }
            } else if (selectedFile.type.includes("video/mp4")) {
                // handleChange({
                //     field: urlFieldName,
                //     value: readerEvent.target.result,
                //     index,
                // });

                // handleChange({
                //     field: "mediaData",
                //     value: targetFile,
                //     index,
                // });
                // handleChange({
                //     field: typeFieldName,
                //     value: targetFile.type,
                //     index,
                // });

                let mimeType = selectedFile.type;

                let fileSizeInMB = selectedFile.size / 1024 / 1024;
                if (fileSizeInMB > 5 && urlFieldName === `richCardCarouselDetails.cardsList[${index}].cardMedia.url`) {
                    formik.setFieldError(`richCardCarouselDetails.cardsList[${index}].cardMedia`, `Video should have max file size of 5MB.`);
                    return;
                }
                const supportedMimeTypeArray = ["video/mp4"];
                if (supportedMimeTypeArray.includes(mimeType)) {
                    let allCardImageList = cardsPreviewImageList;

                    allCardImageList[index] = { url: readerEvent.target.result, type: mimeType, file: targetFile, fieldName: urlFieldName };

                    setCardsPreviewImageList(cardsPreviewImageList => allCardImageList);
                    formik.setFieldValue(urlFieldName, readerEvent.target.result);
                    formik.setFieldValue(typeFieldName, targetFile.type);
                }
            }
        };
    };
    const onDeleteImageaCardComponent = () => {
        handleChange({
            field: "cardMedia.url",
            value: "",
            index,
        });
        handleChange({ field: "cardMedia.type", value: "", index });

        let allCardImageList = cardsPreviewImageList;

        allCardImageList[index] = { url: null, type: null, file: null, fieldName: null };

        setCardsPreviewImageList(allCardImageList);
        onDeleteThumbnail();
    };

    const onSelectImage = ({ file, url, fieldName }) => {
        if (
            formik.values.richCardCarouselDetails.cardsList[index].cardMedia.url &&
            formik.values.richCardCarouselDetails.cardsList[index].cardMedia.type === "video/mp4"
        ) {
            let cardThumbnail = {};
            cardThumbnail[data.cardId] = { url: url, type: file.type, file: file, fieldName: fieldName };

            setCardsPreviewThumbnailImageList({ ...cardsPreviewThumbnailImageList, ...cardThumbnail });
            formik.setFieldValue(`${fieldName}.url`, url);
            formik.setFieldValue(`${fieldName}.type`, file.type);

            // setThumbnailPreviewImage({ url: readerEvent.target.result, type: mimeType, file: targetFile, fieldName: urlFieldName })
        } else {
            let allCardImageList = cardsPreviewImageList;

            allCardImageList[index] = { url, type: file.type, file: file, fieldName: fieldName };

            setCardsPreviewImageList(allCardImageList);

            formik.setFieldValue(`${fieldName}.url`, url);
            formik.setFieldValue(`${fieldName}.type`, file.type);
        }
    };

    const onDeleteThumbnail = () => {
        if (cardsPreviewThumbnailImageList[data.cardId]) {
            let object = cardsPreviewThumbnailImageList;
            delete object[data.cardId];

            setCardsPreviewThumbnailImageList(object);
        }
        handleChange({ field: "cardThumbnail.type", value: "", index });
        handleChange({
            field: "cardThumbnail.url",
            value: "",
            index,
        });
    };

    return (
        <Box key={index} sx={{ boxShadow: 6 }}>
            <Grid width={"100%"} display={"flex"} justifyContent={"center"}>
                <Grid display={"flex"} alignItems={"center"} className='text-dark  p-2 m-2 text-center '>
                    <Typography variant='h3'>Card {index + 1}</Typography>
                    <Box>
                        {disabled && (
                            <>
                                {cardCount > 2 && (
                                    <IconButton
                                        // variant="contained"
                                        size='small'
                                        color='error'
                                        onClick={e => onClickRemoveCarouselCard(e, index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>

            <Typography display={"flex"}>
                <b className='text-dark m-1'>Image/Video/Gif</b>
                <Typography color={"red"}>*</Typography>
            </Typography>
            <Box className='ml-3'>
                <Box>
                    <Box className='preview'>
                        <FilePreviewComponent
                            fieldName={`richCardCarouselDetails.cardsList[${index}].cardMedia`}
                            url={cardsPreviewImageList[index]?.url}
                            type={cardsPreviewImageList[index]?.type}
                            previewImage={cardsPreviewImageList[index]}
                            deleteFile={() => onDeleteImageaCardComponent()}
                            formikImage={data.cardMedia}
                            isDisabled={!disabled ? true : false}
                            aspect={aspectRatio}
                            onSelectImage={onSelectImage}
                            width={"300px"}
                            height={"100px"}
                            imageHeightRatio={imageHeightRatio}
                            imageWidthRatio={imageWidthRatio}
                        />
                    </Box>
                    {!cardsPreviewImageList[index]?.url && !data?.cardMedia.url && (
                        <Box className='btn-container mt-2'>
                            <FormControl error={Boolean(formik.errors?.richCardCarouselDetails?.cardsList[index]?.cardMedia)}>
                                {disabled && (
                                    <Button type='button' variant='outlined' onClick={() => filePicekerRef.current.click()}>
                                        Choose
                                    </Button>
                                )}
                                <input
                                    name={data?.media?.url}
                                    ref={filePicekerRef}
                                    accept='/*'
                                    onChange={e =>
                                        previewFile({
                                            targetFile: e.target.files[0],
                                            urlFieldName: `richCardCarouselDetails.cardsList[${index}].cardMedia.url`,
                                            typeFieldName: `richCardCarouselDetails.cardsList[${index}].cardMedia.type`,
                                        })
                                    }
                                    onClick={e => (e.target.value = "")}
                                    type='file'
                                    hidden
                                />
                                <FormHelperText>{formik.errors?.richCardCarouselDetails?.cardsList[index]?.cardMedia}</FormHelperText>
                            </FormControl>
                        </Box>
                    )}

                    {data.cardMedia?.type == "video/mp4" && !data?.cardThumbnail?.url && (
                        <Box className='btn-container'>
                            <FormControl>
                                <Button
                                    className='m-2 gap-3'
                                    type='button'
                                    variant='outlined'
                                    onClick={() => thumnailInputRef.current.click()}
                                    // disabled={Boolean(
                                    //     !isTempalteEditable ||
                                    //     state
                                    //         .controls
                                    //         .isCreateTemplateLoading
                                    // )}
                                >
                                    Select Thumbnail
                                </Button>
                                <input
                                    // disabled={Boolean(
                                    //     !isTempalteEditable ||
                                    //     state
                                    //         .controls
                                    //         .isCreateTemplateLoading
                                    // )}
                                    name={`richCardCarouselDetails.cardsList[${index}].cardThumbnail`}
                                    ref={thumnailInputRef}
                                    accept='image/jpg,image/png,image/jpeg'
                                    onChange={e =>
                                        previewFile({
                                            targetFile: e.target.files[0],
                                            urlFieldName: `richCardCarouselDetails.cardsList[${index}].cardThumbnail`,
                                            // typeFieldName: `richCardCarouselDetails.cardsList[${index}].cardThumbnail.type`,
                                        })
                                    }
                                    type='file'
                                    hidden
                                    onClick={e => (e.target.value = "")}
                                />
                            </FormControl>
                        </Box>
                    )}

                    <Box className='preview'>
                        {(cardsPreviewThumbnailImageList[data.cardId] || data?.cardThumbnail?.url) && (
                            <FilePreviewComponent
                                fieldName={`richCardCarouselDetails.cardsList[${index}].cardThumbnail`}
                                url={cardsPreviewThumbnailImageList[data.cardId]?.url}
                                type={cardsPreviewThumbnailImageList[data.cardId]?.type}
                                previewImage={cardsPreviewThumbnailImageList[data.cardId]}
                                deleteFile={() => onDeleteThumbnail()}
                                formikImage={data?.cardThumbnail}
                                isDisabled={!disabled ? true : false}
                                // formikImage={data?.}
                                // isDisabled={Boolean(
                                //     !isTempalteEditable ||
                                //     state
                                //         .controls
                                //         .isCreateTemplateLoading
                                // )}
                                aspect={aspectRatio}
                                onSelectImage={onSelectImage}
                                width={"300px"}
                                height={"100px"}
                                imageHeightRatio={imageHeightRatio}
                                imageWidthRatio={imageWidthRatio}
                            />
                        )}
                    </Box>
                    <FormControl error={Boolean(formik.errors?.richCardCarouselDetails?.cardsList[index]?.cardThumbnail)}>
                        <FormHelperText>
                            {formik.errors?.richCardCarouselDetails?.cardsList[index]?.cardThumbnail &&
                                formik.errors?.richCardCarouselDetails.cardsList[index].cardThumbnail}
                        </FormHelperText>
                    </FormControl>
                </Box>
            </Box>
            <Typography display={"flex"}>
                <b className='text-dark m-1'>Card Title</b>
                <Typography color={"red"}>*</Typography>
            </Typography>
            <TextField
                className='m-2'
                name='cardTitle'
                fullWidth
                label='Card Title'
                variant='outlined'
                value={data?.cardTitle}
                disabled={!disabled}
                onChange={onFieldChange}
                error={Boolean(cardErrors?.cardTitle && cardErrors?.cardTitle)}
                helperText={cardErrors?.cardTitle}
                // onBlur={cardHandleBlur}
            />
            <React.Fragment>
                <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <FormHelperText sx={{color:"#e90e0ede"}}  >
              {formik.touched?.richCardCarouselDetails?.cardsList[index]?.cardTitle && formik.errors?.richCardCarouselDetails?.cardsList[index]?.cardTitle}
            </FormHelperText> */}
                    <Box
                        sx={{
                            px: 1,
                            mx: 1,
                            // fontSize: "0.875rem",
                            // fontWeight: "700",
                            display: "flex",
                            flexDirection: "row-reverse",
                        }}
                    >
                        {CharCounter(data?.cardTitle)}
                        /200
                    </Box>
                </Grid>
            </React.Fragment>
            <Typography className='mt-3' display={"flex"}>
                <b className='text-dark m-1'>Card Description</b>
                <Typography color={"red"}>*</Typography>
            </Typography>
            <TextField
                className='m-2'
                fullWidth
                sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                }}
                multiline
                rows={4}
                maxRows={4}
                placeholder='Enter card description here'
                error={Boolean(cardErrors?.cardDescription)}
                helperText={cardErrors?.cardDescription}
                // onBlur={cardHandleBlur}
                value={data?.cardDescription}
                disabled={!disabled}
                name='cardDescription'
                onChange={onFieldChange}
            />
            <React.Fragment>
                <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <FormHelperText sx={{color:"#e90e0ede"}}  >
              {formik.touched?.richCardCarouselDetails?.cardsList[index]?.cardDescription && formik.errors?.richCardCarouselDetails?.cardsList[index]?.cardDescription}
            </FormHelperText> */}
                    <Box
                        sx={{
                            px: 1,
                            mx: 1,
                            // fontSize: "0.875rem",
                            // fontWeight: "700",
                            display: "flex",
                            flexDirection: "row-reverse",
                        }}
                    >
                        {CharCounter(data?.cardDescription)}
                        /2000
                    </Box>
                </Grid>
            </React.Fragment>
            <hr />
            <Box>
                <div className='gap-3 mt-3' style={{ display: "flex", alignItems: "center", marginTop: 10 }}>
                    {disabled && (
                        <>
                            <Button
                                type='button'
                                variant='outlined'
                                onClick={onClickAddSuggestionCarouselCard}
                                disabled={data?.suggestionsList.length >= 4}
                            >
                                Add Button
                            </Button>
                            <Tooltip title={<p style={{ fontSize: ".7rem" }}> Maximum 4 Suggestions.</p>} className='cursor-pointer'>
                                <InfoOutlinedIcon sx={{ opacity: "50%" }} />
                            </Tooltip>
                        </>
                    )}
                </div>

                <Box>
                    {data?.suggestionsList?.map((suggestion, index) => (
                        <Box key={index} className='m-3'>
                            <SuggestionsComponent
                                handleChange={handleChangeSuggestionCarouselCard}
                                handleRemoveSuggestion={handleChangeRemoveSuggestionCarouselCard}
                                suggestion={suggestion}
                                indexOfSuggstion={index}
                                SuggestionErrors={cardErrors?.suggestionsList && cardErrors?.suggestionsList[index]}
                                SuggestionTouched={cardTouched?.suggestionsList[index]}
                                SuggestionBlur={cardHandleBlur}
                                isDisabled={!disabled}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}
