import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGatewayAxiosInstance } from "../../utils/axios";

export const addNewTemplateThunk = createAsyncThunk("add-new-template", async ({ data, botId, templateType, templateId, registryId }) => {
    try {
        const response = await apiGatewayAxiosInstance.post(`/bot_management/template/create-template`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                botId,
                templateType,
                templateId,
            },
            params: { registryId },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            if (error.response.data && error.response.data.errorMessage) {
                errorMessage = error.response.data.errorMessage;
            }
        }

        throw errorMessage;
    }
});

export const getAllTemplatesByPageSize = createAsyncThunk("get-all-templates-by-pagesize", async ({ currentPage, pageSize, botId, registryId }) => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/template/get-all-templates-by-pagesize`, {
            params: {
                pageSize,
                currentPage,
                botId,
                registryId
            },
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});
// export const getAllTemplatesByFilterThunk = createAsyncThunk(
//     "get-all-templates-by-Filter",
//     async ({ currentPage, pageSize, searchParams }) => {
//         try {
// const response = await apiGatewayAxiosInstance.get(
//                 `template`,
//                 {
//                     params: {
//                         pageSize,
//                         currentPage,
//                         templateType: searchParams.templateType,
//                         templateName: searchParams.templateName,
//                         createdAt: searchParams.createdAt,
//                         status: searchParams.status,
//                     },
//                 }
//             );

//             if (response.status === 200) {
//                 return response.data;
//             } else {
//                 return new Error(response);
//             }
//         } catch (error) {
//             if (error.response) {
//                 throw error.response.data;
//             } else {
//                 throw new Error("Server not reachable");
//             }
//         }
//     }
// );

export const getAllTemplatesByFilterThunk = createAsyncThunk(
    "get-all-templates-by-filter",
    async ({ currentPage, pageSize, searchParams, botId }) => {
        try {
            const response = await apiGatewayAxiosInstance.get(`/bot_management/template`, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    pageSize,
                    currentPage,
                    templateType: searchParams.templateType,
                    templateName: searchParams.templateName,
                    startDate: searchParams.startDate,
                    // endDate: searchParams.endDate,
                    status: searchParams.status,
                    botId,
                },
            });

            if (response.status === 200) {
                return response.data;
            } else {
                return new Error(response);
            }
        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            throw errorMessage;
        }
    },
);
