import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import React from "react";

export default function WhiteLabelRadioButtons ({ onChangeRadio, isDisabled, isWhiteLabel }) {
    return (
        <FormControl className='field-spacing' sx={{ display: "flex", color: "grey" }}>
            <FormLabel id='demo-controlled-radio-buttons-group'>
                <Typography variant='subtitle2'>WhiteLabel:</Typography>
            </FormLabel>
            <RadioGroup
                aria-labelledby='demo-controlled-radio-buttons-group'
                sx={{ display: "flex !important", WebkitFlexDirection: "row", gap: "50px" }}
                defaultValue='female'
                name='radio-buttons-group'
            >
                <FormControlLabel
                    value='Enabled'
                    disabled={isDisabled}
                    control={
                        <Radio
                            checked={isWhiteLabel === "Enabled"}
                            color='primary'
                            sx={{
                                color: blue[800],
                                "&.Mui-checked": {
                                    color: blue[600],
                                },
                            }}
                            size='small'
                        />
                    }
                    label={<Typography variant='subtitle2'>Enabled</Typography>}
                    sx={{ color: blue[800] }}
                    onChange={e => onChangeRadio("privileges.isWhiteLabel", e)}
                />

                <FormControlLabel
                    value='Disabled'
                    disabled={isDisabled}
                    control={
                        <Radio
                            checked={isWhiteLabel === "Disabled"}
                            sx={{
                                color: blue[800],
                                "&.Mui-checked": {
                                    color: blue[600],
                                },
                            }}
                            size='small'
                        />
                    }
                    label={<Typography variant='subtitle2'>Disabled</Typography>}
                    sx={{ color: blue[800] }}
                    onChange={e => onChangeRadio("privileges.isWhiteLabel", e)}
                />
            </RadioGroup>
        </FormControl>
    );
}
