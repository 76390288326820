import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import { Box, Button, Card, CardContent, Grid, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ERoles } from "../../../../config";
import useAuth from "../../../../hooks/useAuth";
import { clearErrorMessagesForAllUsers, setShowAddApikeyForm } from "../../../../redux/userManagement/userManagementSlice";
import { getAllApiKeysByFilterThunk } from "../../../../redux/userManagement/userManagementThunk";
import TablePaginationShowPage from "../../../../utils/tablePagination";
import AlertMessageComponent from "../../../components/AlertMessageComponent";
import FetchingSpinner from "../../../components/FetchingSpinner";
import NoRecords from "../../../components/NoRecords";
import DeprecateApiKeysModel from "./DeprecateApiKeysModel";
import FilterAllApiKeysModel from '../ApiKeysComponents/FilterAllApiKeysModel'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ListApiKeys({ listApikeysLoading, searchParams, setSearchParams }) {
    const state = useSelector(state => state.userManagementSlice);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [open, setOpen] = useState({
        filter: false,
        deprecateKey: false
    });
    const dispatch = useDispatch();
    const { user } = useAuth();
    const [apikeyDetails, setApikeyDetails] = useState();

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    useEffect(() => {
        if (!open.deprecateKey && !open.filter) {
            dispatch(
                getAllApiKeysByFilterThunk({
                    currentPage: currentPage + 1,
                    pageSize,
                    roleName: user?.roleName,
                    registryId: user?.registryId,
                    orgId: user?.orgId,
                    searchParams
                }),
            );

        }
    }, [open.deprecateKey, open.filter, state.controls.showAddApiKeysForm, user?.roleName, currentPage, pageSize]);

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForAllUsers({ fieldName }));
    };


    const onClickAddNewApikey = () => {
        dispatch(setShowAddApikeyForm(true));
    };

    const onClickDeprecateApikey = (apikeyDetails) => {
        setOpen({ filter: false, deprecateKey: true });
        setApikeyDetails(apikeyDetails);
    }



    const handleClose = () => {
        setOpen({ filter: false, deprecateKey: false });
    };

    const handleClickSearch = async (searchParams) => {
        setCurrentPage(0);
        setSearchParams(searchParams);
        handleClose();
        dispatch(
            getAllApiKeysByFilterThunk({
                currentPage: 1,
                pageSize,
                roleName: user.roleName,
                registryId: user.registryId,
                orgId: user.orgId,
                searchParams
            }),
        );
    };

    const onClickApiKeyFilter = () => {
        setOpen({ filter: true, deprecateKey: false });
    }

    return (
        <Box>
            <Grid container mb={4} className='listHeader'>
                <Grid item md={6}>
                    <Typography variant='h3'>API Keys</Typography>
                </Grid>
                <Grid item md={6} className='listHeaderFilter'>
                    <Grid width={"100%"} justifyContent={"flex-end"} display={"flex"}>
                        <Button sx={{ marginRight: "10px" }} variant='contained' onClick={onClickApiKeyFilter}>
                            Filter
                        </Button>
                        {(user?.roleName === ERoles.SUPER_ADMIN || user?.roleName === ERoles.RESELLER_ADMIN) && (
                            <Button variant='contained' onClick={onClickAddNewApikey}>
                                Add API Key
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            {listApikeysLoading ? (
                <FetchingSpinner />
            ) :
                state.apiKeyListByPageSize && state.apiKeyListByPageSize?.length > 0 ? (
                    <Grid>
                        <Card className='border border-secondary-50'>
                            <CardContent sx={{ padding: 0 }}>
                                {listApikeysLoading ? (
                                    <FetchingSpinner />
                                ) : (
                                    <TableContainer component={Paper} className='table-container' sx={{ maxHeight: "70vh" }}>
                                        <Table stickyHeader aria-label='customized table'>
                                            <TableHead className='p-3 mb-2 row'>
                                                <TableRow>
                                                    <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                        API Key Unique Id
                                                    </StyledTableCell>
                                                    <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                        User Role
                                                    </StyledTableCell>
                                                    <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                        Name
                                                    </StyledTableCell>
                                                    <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                        Description
                                                    </StyledTableCell>
                                                    <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                        Status
                                                    </StyledTableCell>

                                                    {user?.roleName !== 'org_admin' &&
                                                        <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                            Deprecate API Keys
                                                        </StyledTableCell>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {state.apiKeyListByPageSize?.map((apikey, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                            {apikey.uniqueId}
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                            {apikey.role === ERoles.RESELLER_ADMIN ? String(ERoles.RESELLER_ADMIN).toUpperCase() : String(ERoles.ORG_ADMIN).toUpperCase()}
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                            {apikey.role === ERoles.RESELLER_ADMIN ? apikey.resellerName : apikey.orgName}
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                            {apikey.role === ERoles.RESELLER_ADMIN ? apikey.description : apikey.description}
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                            {apikey.status === 'active' ? (
                                                                <label
                                                                    className='text-success centered'
                                                                    style={{
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    <Grid container>
                                                                        <Grid item className='active-dot'></Grid>
                                                                        <Grid item>{"Active"}</Grid>
                                                                    </Grid>
                                                                </label>
                                                            ) : (
                                                                <label
                                                                    className='text-danger centered'
                                                                    style={{
                                                                        fontWeight: "600",
                                                                    }}
                                                                >
                                                                    <Grid container>
                                                                        <Grid item className='inactive-dot'></Grid>
                                                                        <Grid item>{"InActive"}</Grid>
                                                                    </Grid>
                                                                </label>
                                                            )}
                                                        </StyledTableCell>
                                                        {user?.roleName !== 'org_admin' &&
                                                            <StyledTableCell className='tableContentFont' align='left'>
                                                                {
                                                                    apikey.status === 'active' ?
                                                                        (<Button sx={{ padding: '0px !important' }}
                                                                            onClick={() => {
                                                                                onClickDeprecateApikey(apikey);
                                                                            }}
                                                                        >
                                                                            < DeleteForeverIcon />
                                                                        </Button>) : 'Deprecated'
                                                                }
                                                            </StyledTableCell>
                                                        }
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}

                                <Grid
                                    className='my-2'
                                    style={{
                                        zIndex: "2",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: 2,
                                    }}
                                >
                                    <Grid
                                        style={{
                                            zIndex: "2",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            padding: 5,
                                        }}
                                    >

                                    </Grid>
                                </Grid>

                                {state.totalApiKeysCount > 0 && (
                                    <Grid
                                        className='my-2'
                                        style={{
                                            zIndex: "2",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: 2,
                                        }}
                                    >
                                        <Grid
                                            style={{
                                                marginTop: "15px",
                                                padding: 5,
                                            }}
                                        >
                                            Total Api keys: {state.totalApiKeysCount}
                                        </Grid>
                                        <Grid>
                                            <TablePaginationShowPage
                                                rowsPerPageOptions={[5, 10, 25, 100]}
                                                colSpan={3}
                                                count={state.totalApiKeysCount}
                                                rowsPerPage={pageSize}
                                                page={currentPage}
                                                slotProps={{
                                                    select: {
                                                        "aria-label": "Rows per page",
                                                    },
                                                    actions: {
                                                        showFirstButton: true,
                                                        showLastButton: true,
                                                        slots: {
                                                            firstPageIcon: FirstPageRoundedIcon,
                                                            lastPageIcon: LastPageRoundedIcon,
                                                            nextPageIcon: ChevronRightRoundedIcon,
                                                            backPageIcon: ChevronLeftRoundedIcon,
                                                        },
                                                    },
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                )
                    : (
                        <NoRecords />
                    )
            }
            {state.controls.getAlllCientsErrorMessage && (
                <AlertMessageComponent
                    message={state.controls.getAlllCientsErrorMessage}
                    fieldName={"getAlllCientsErrorMessage"}
                    handleClose={onCloseErrorMessage}
                    show={Boolean(state.controls.getAlllCientsErrorMessage)}
                />
            )}
            {open.deprecateKey && (
                <DeprecateApiKeysModel
                    open={open.deprecateKey}
                    handleClose={handleClose}
                    apikeyDetails={apikeyDetails}
                />
            )}
            {open.filter && (
                <FilterAllApiKeysModel
                    open={open.filter}
                    searchParams={searchParams}
                    handleClose={handleClose}
                    setSearchParams={setSearchParams}
                    handleClickSearch={handleClickSearch}
                />
            )}
        </Box>
    );
}
