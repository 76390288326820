import styled from "@emotion/styled";
import { Formik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Button, CircularProgress, Grid, IconButton, Alert as MuiAlert, TextField as MuiTextField, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { TaskAltOutlined } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import AlertMessageComponent from "../../pages/components/AlertMessageComponent";
import { apiGatewayAxiosInstance } from "../../utils/axios";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
    const navigate = useNavigate();
    const { resetPassword } = useAuth();
    const [resendEmail, setResendEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState("");

    const handleForgetPassword = async (email, errors) => {

        if (!email || errors) {
            setErrorResponse("Email Address is required");
            return
        }
        try {
            setIsLoading(true);
            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/forget`, { headers: { email } });
            // const response = { status: 2000 }
            if (response.status === 200) {
                setResendEmail(true);
            }
        } catch (error) {
            setIsLoading(false);

            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    return (
        <div>
            {resendEmail ? (
                <Grid style={{ color: "green" }} align='center'>
                    <IconButton style={{ color: "green" }}>
                        <TaskAltOutlined />
                    </IconButton>
                    <Typography component='h2' variant='h4'>
                        Reset Password link Send Successfully on your Email
                    </Typography>
                </Grid>
            ) : (
                <>
                    <Typography component='h1' variant='h4' align='center' gutterBottom>
                        Reset Password
                    </Typography>
                    <Typography component='h2' variant='body1' align='center'>
                        Enter your email to reset your password
                    </Typography>
                    <Formik
                        initialValues={{
                            email: "",
                            submit: false,
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
                        })}
                        onSubmit={async (values, { errors, setErrors, setStatus, setSubmitting }) => {
                            try {
                                resetPassword(values.email);
                                // const a = errors;
                                await handleForgetPassword(values.email, errors);
                                // navigate("/auth/login");
                            } catch (error) {
                                const message = error.message || "Something went wrong";

                                setStatus({ success: false });
                                setErrors({ submit: message });
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                            // resendEmail? <div>Failed to send reset password link</div>:(

                            <form noValidate onSubmit={handleSubmit}>
                                {errors.submit && (
                                    <Alert mt={2} mb={1} severity='warning'>
                                        {errors.submit}
                                    </Alert>
                                )}
                                <Grid alignItems={"center"} display={"flex"} flexDirection={"column"}>
                                    <TextField
                                        type='email'
                                        name='email'
                                        label='Email Address'
                                        value={values.email}
                                        error={Boolean(touched.email && errors.email)}
                                        fullWidth
                                        helperText={touched.email && errors.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        my={3}
                                        sx={{ alignItems: "center !important" }}
                                    />
                                    {isLoading ?
                                        <Button variant='outlined' sx={{ alignItems: "center !important" }}>
                                            <CircularProgress size={15} className='spinner' />
                                            Sending Mail to reset password
                                        </Button>
                                        :
                                        <Button
                                            // type='submit'
                                            fullWidth
                                            variant='contained'
                                            color='primary'
                                            disabled={isSubmitting}
                                            onClick={() => handleForgetPassword(values.email)}
                                        >
                                            Reset password
                                        </Button>
                                    }
                                </Grid>
                            </form>
                            // )
                        )}
                    </Formik>
                </>
            )}
            <AlertMessageComponent
                message={errorResponse}
                fieldName={"getAllTemplatesByPageSizeErrorMessage"}
                handleClose={() => setErrorResponse("")}
                show={Boolean(errorResponse)}
            />
        </div>
    );
}

export default ResetPassword;
