import { ArrowBackIos, Edit } from "@mui/icons-material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    IconButton,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import _debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ERoles } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import { clearErrorMessagesForAllUsers, setShowAddProviderForm } from "../../../redux/userManagement/userManagementSlice";
import {
    // getAllBranchesThunk,
    getAllClientByFilterThunk,
} from "../../../redux/userManagement/userManagementThunk";
import { canShowForSuperAdminOrResellerAdmin } from "../../../utils/canShowForSuperAdminOrResellerAdmin";
import TablePaginationShowPage from "../../../utils/tablePagination";
import Page401 from "../../auth/Page401";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import ProviderBaseRatesModal from "../Provider/ProviderBaseRatesModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ListResellerProviders({ getAllProviderList, providerTotalCount, registryId, resellerName }) {
    const [currentPage, setCurrentPage] = useState(0);
    const [debouncedValue, setDebouncedValue] = useState("");
    const [open, setOpen] = React.useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [showProviderBaseRatesModal, setShowProviderBaseRatesModal] = useState(false);
    const [providerDetailsByProviderName, setProviderDetailsByProviderName] = useState();
    const [otherFields, setOtherFields] = useState({});
    const [providerData, setProviderData] = useState();
    const [providerMasterRatesFields, setProviderMasterRatesFields] = useState({});
    const [selectProviderName, setSelectProviderName] = useState();

    const [editBaseRateLoading, setEditBaseRateLoading] = useState({ status: false, id: null });
    const navigate = useNavigate();
    const userState = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();
    const { user } = useAuth();
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    // const { state } = useLocation(); //receiving data from listProvider on navigate
    // const { registryId } = state || {};

    const onClickResellerCustomizedRates = provider => {
        const dataToSend = {
            registryId: registryId ? registryId : user?.registryId,
            resellerName: resellerName ? resellerName : user?.whiteLabelName,
            providerId: provider.providerId,
            providerCode: provider.providerCode,
            countryCode: provider.countryCode,
            providerName: provider.providerName,
            providerCurrency: provider.currency,
        };
        navigate("/billing/customized-rates", { state: dataToSend });
    };

    const handleClose = () => {
        // setSearchParams({});
        setOpen(false);
    };

    const handleCloseProviderBaseRatesModal = () => {
        setShowProviderBaseRatesModal(false);
    };

    const debouncedSearch = _debounce(value => {
        setDebouncedValue(value);
    }, 1000);

    useEffect(() => {
        // dispatch(
        //     getAllClientsByPageSizeThunk({
        //         currentPage: currentPage + 1,
        //         pageSize,
        //     })
        // );
        if (user) {
            const registryId = user?.registryId;
            // dispatch(
            //     getAllClientByFilterThunk({
            //         currentPage: currentPage + 1,
            //         pageSize,
            //         searchParams,
            //         registryId: registryId,
            //     }),
            // );
        }
    }, [currentPage, pageSize, user]);

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForAllUsers({ fieldName }));
    };
    const handleClickSearch = async searchParams => {
        setCurrentPage(0);

        // setSearchParams(searchParams);

        dispatch(
            getAllClientByFilterThunk({
                currentPage: currentPage + 1,
                pageSize,
                searchParams,
            }),
        );
        handleClose();
    };

    const onClickAddNewProvider = () => {
        dispatch(setShowAddProviderForm(true));
    };

    const onClickCancelButton = () => {
        navigate("/admin/manage-reseller");
    };

    return canShowForSuperAdminOrResellerAdmin(user) ? (
        <Box>
            <Grid container mb={4} className='listHeader'>
                {user?.roleName === ERoles.RESELLER_ADMIN && (
                    <Grid item md={6}>
                        <Typography variant='h3'>Providers</Typography>
                    </Grid>
                )}
                {user?.roleName === ERoles.SUPER_ADMIN && (
                    <Grid item md={6} display={"flex"} alignItems={"center"}>
                        <IconButton color='secondary' onClick={onClickCancelButton} size='medium'>
                            <ArrowBackIos sx={{ cursor: "pointer" }} />
                        </IconButton>

                        <Grid role='presentation'>
                            <Breadcrumbs aria-label='breadcrumb' fontSize='large'>
                                <Link
                                    underline='hover'
                                    color='inherit'
                                    className='breadcrumbItem breadcrumbFontSize'
                                    // href="/rcs-management/manage-bots/"
                                    onClick={onClickCancelButton}
                                >
                                    Resellers
                                </Link>
                                <Typography className='breadcrumbItem' color='text.primary'>
                                    {resellerName}
                                </Typography>
                                <Typography className='breadcrumbItem' color='text.primary'>
                                    Providers
                                </Typography>
                                {/* <Typography className='breadcrumbItem' color='text.primary'>
                                        {resellerName ? resellerName : orgName}
                                    </Typography> */}
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid>
                <Card className='border border-secondary-50'>
                    <CardContent sx={{ padding: 0 }}>
                        {/* {state.controls.isGetAllClientsLoading ? (
                            <FetchingSpinner />
                        ) : state.clientsListByPageSize && state.clientsListByPageSize.length > 0 ? ( */}
                        <TableContainer component={Paper} className='table-container' sx={{ maxHeight: "70vh" }}>
                            <Table stickyHeader aria-label='customized table'>
                                <TableHead className='p-3 mb-2 row'>
                                    <TableRow>
                                        {/* <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                            Sr No
                                        </StyledTableCell> */}
                                        <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                            Provider Name
                                        </StyledTableCell>

                                        <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                            Country
                                        </StyledTableCell>
                                        <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                            Country Code
                                        </StyledTableCell>

                                        <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                            Currency
                                        </StyledTableCell>
                                        <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                            Is Active
                                        </StyledTableCell>
                                        {/* <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                            Action
                                        </StyledTableCell> */}
                                        <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                            {user?.roleName === ERoles.SUPER_ADMIN ? "Customized Rates" : "Applicable Rates"}
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {userState.resellerProviderList?.map((provider, index) => (
                                        <StyledTableRow key={index}>
                                            {/* <StyledTableCell className='tableContentFont' align='center'>
                                                {currentPage * pageSize + index + 1}
                                            </StyledTableCell> */}
                                            <StyledTableCell className='tableContentFont' align='left'>
                                                {provider.providerName}
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='left'>
                                                {provider.country}
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='left'>
                                                {provider.countryCode}
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='left'>
                                                {provider.currency}
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='left'>
                                                {provider.isActive ? (
                                                    <label
                                                        className='text-success centered'
                                                        style={{
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        <Grid container>
                                                            <Grid item className='active-dot'></Grid>
                                                            <Grid item>{"Active"}</Grid>
                                                        </Grid>
                                                    </label>
                                                ) : (
                                                    <label
                                                        className='text-danger centered'
                                                        style={{
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        <Grid container>
                                                            <Grid item className='inactive-dot'></Grid>
                                                            <Grid item>{"InActive"}</Grid>
                                                        </Grid>
                                                    </label>
                                                )}
                                            </StyledTableCell>

                                            <StyledTableCell className='tableContentFont' align='left'>
                                                <Stack
                                                    direction='row'
                                                    spacing={2}
                                                    sx={{
                                                        alignItems: "left",
                                                        display: "flex",
                                                        justifyContent: "left",
                                                    }}
                                                >
                                                    {editBaseRateLoading.status && editBaseRateLoading.id == provider._id ? (
                                                        <CircularProgress size={20} />
                                                    ) : (
                                                        <Button
                                                            onClick={() => {
                                                                onClickResellerCustomizedRates(
                                                                    provider,
                                                                    // isEditable: true,
                                                                );
                                                            }}
                                                        >
                                                            <Edit />
                                                        </Button>
                                                    )}
                                                </Stack>
                                            </StyledTableCell>

                                            {/* <StyledTableCell className='tableContentFont' align='center'>
                                                    <Stack
                                                        direction='row'
                                                        spacing={2}
                                                        sx={{
                                                            alignItems: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        {isLoadingClientDetails.status && isLoadingClientDetails.id == client._id ? (
                                                            <CircularProgress size={20} />
                                                        ) : (
                                                            <Button
                                                                onClick={() => {
                                                                    onClickEditClientDetails({
                                                                        client,
                                                                        isEditable: true,
                                                                    });
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </Button>
                                                        )}
                                                    </Stack>
                                                </StyledTableCell> */}
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* ) : ( */}
                        {/* <NoRecords /> */}
                        {/* )} */}
                        {/* {state.totalClientsCount > 0 && ( */}
                        <Grid
                            className='my-2'
                            style={{
                                zIndex: "2",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 2,
                            }}
                        >
                            <Grid
                                style={{
                                    zIndex: "2",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    padding: 5,
                                }}
                            >
                                {/* <Grid
                                        style={{
                                            marginTop: "15px",
                                        }}
                                    >
                                        Total Clients:{state.totalClientsCount}
                                    </Grid> */}
                                {/* <Grid justifyContent={"center"} mt={3}>

                                        <TablePaginationShowPage
                                            rowsPerPageOptions={[5, 10, 25, 100]}
                                            colSpan={3}
                                            count={state.totalClientsCount}
                                            rowsPerPage={pageSize}
                                            page={currentPage}
                                            slotProps={{
                                                select: {
                                                    "aria-label": "Rows per page",
                                                },
                                                actions: {
                                                    showFirstButton: true,
                                                    showLastButton: true,
                                                    slots: {
                                                        firstPageIcon: FirstPageRoundedIcon,
                                                        lastPageIcon: LastPageRoundedIcon,
                                                        nextPageIcon: ChevronRightRoundedIcon,
                                                        backPageIcon: ChevronLeftRoundedIcon,
                                                    },
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid> */}
                            </Grid>
                        </Grid>
                        {/* )} */}

                        {providerTotalCount > 0 && (
                            <Grid
                                className='my-2'
                                style={{
                                    zIndex: "2",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: 2,
                                }}
                            >
                                <Grid
                                    style={{
                                        marginTop: "15px",
                                        padding: 5,
                                    }}
                                >
                                    Total Providers: {providerTotalCount}
                                </Grid>
                                <Grid>
                                    <TablePaginationShowPage
                                        rowsPerPageOptions={[5, 10, 25, 100]}
                                        colSpan={3}
                                        count={providerTotalCount}
                                        rowsPerPage={pageSize}
                                        page={currentPage}
                                        slotProps={{
                                            select: {
                                                "aria-label": "Rows per page",
                                            },
                                            actions: {
                                                showFirstButton: true,
                                                showLastButton: true,
                                                slots: {
                                                    firstPageIcon: FirstPageRoundedIcon,
                                                    lastPageIcon: LastPageRoundedIcon,
                                                    nextPageIcon: ChevronRightRoundedIcon,
                                                    backPageIcon: ChevronLeftRoundedIcon,
                                                },
                                            },
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>

                    {userState.controls.getAlllCientsErrorMessage && (
                        <AlertMessageComponent
                            message={userState.controls.getAlllCientsErrorMessage}
                            fieldName={"getAlllCientsErrorMessage"}
                            handleClose={onCloseErrorMessage}
                            show={Boolean(userState.controls.getAlllCientsErrorMessage)}
                        />
                    )}
                    {/* {showProviderBaseRatesModal && (
                        <ProviderBaseRatesModal
                            open={showProviderBaseRatesModal}
                            handleClose={handleCloseProviderBaseRatesModal}

                            updateCountryRateCardModel={editCountryRateDetails}
                            setEditCountryRateDetails={setEditCountryRateDetails}
                            countryRateDetails={countryRateDetails}
                            selectProviderName={selectProviderName}
                            getAllMasterRates={getAllMasterRates}
                            otherFields={otherFields}
                            providerDetailsByProviderName={providerDetailsByProviderName}
                        />
                    )} */}

                    {showProviderBaseRatesModal && (
                        <ProviderBaseRatesModal
                            open={showProviderBaseRatesModal}
                            handleClose={handleCloseProviderBaseRatesModal}
                            providerData={providerData}
                            // selectProviderName={selectProviderName}
                            providerMasterRatesFields={providerMasterRatesFields}
                            providerDetailsByProviderName={providerDetailsByProviderName}
                            otherFields={otherFields}
                        // getAllCustomizedRate={getAllCustomizedRate}
                        />
                    )}
                </Card>
            </Grid>
        </Box>
    ) : (
        <Page401 />
    );
}
