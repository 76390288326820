import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setProviderList, setResellerProviderList, setShowAddClientForm } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import AddProvider from "./AddProvider";
import ListResellerProviders from "./ListResellerProviders";
import useAuth from "../../../hooks/useAuth";

export default function ManageResellerProviders () {
    const userState = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();
    const [errorResponse, setErrorResponse] = useState("");
    const [editBaseRateLoading, setEditBaseRateLoading] = useState({ status: false, id: null });
    const [providerTotalCount, setProviderTotalCount] = useState();
    // const { resellerData } = useLocation();

    const { state } = useLocation(); //receiving data from listProvider on navigate
    const { registryId, resellerName, countryCode, supportedProviders } = state || {};
    const { user } = useAuth();

    const getAllProviderList = async () => {
        try {
            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/get-provider-by-registry-id`, {
                params: { registryId: registryId ? registryId : user?.registryId },
            });
            dispatch(setResellerProviderList(response.data.data));
            // setProviderTotalCount(response.data.data.totalCount);
        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            // dispatch(
            //     setErrorMessageForUser({
            //         fieldName: "getClientEditDetailsErrorMessages",
            //         errorMessage,
            //     }),
            // );
        }
    };

    const onClickHandleClose = fieldName => {
        dispatch(onClickHandleClose(fieldName));
    };

    useEffect(async () => {
        dispatch(setShowAddClientForm(false));

        await getAllProviderList();
    }, [user]);

    return (
        <div>
            {userState.controls.showAddProviderForm ? (
                <>
                    <AddProvider getAllProviderList={getAllProviderList} />
                </>
            ) : (
                <ListResellerProviders
                    getAllProviderList={getAllProviderList}
                    providerTotalCount={providerTotalCount}
                    registryId={registryId}
                    countryCode={countryCode}
                    supportedProviders={supportedProviders}
                    resellerName={resellerName}
                />
            )}

            {/* {state.controls.getClientEditDetailsErrorMessages && (
                <AlertMessageComponent
                    message={state.controls.getClientEditDetailsErrorMessages}
                    fieldName={"getClientEditDetailsErrorMessages"}
                    handleClose={onClickHandleClose}
                    show={Boolean(state.controls.getClientEditDetailsErrorMessages)}
                />
            )} */}

            <AlertMessageComponent
                message={errorResponse}
                fieldName={"errorResponse"}
                handleClose={() => setErrorResponse("")}
                show={Boolean(errorResponse)}
            />
        </div>
    );
}
