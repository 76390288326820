import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rgba } from "polished";

import {
  Box,
  Divider,
  Grid,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Typography as MuiTypography,
} from "@mui/material";
import { display, spacing } from "@mui/system";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";

const illustrationCardStyle = (props) => css`
  ${props.illustration &&
  props.theme.palette.mode !== "dark" &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};

  ${illustrationCardStyle}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;

const Chip = styled(MuiChip)`
  position: absolute;
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;
  background-color: ${(props) => props.theme.palette.secondary.main};
  color: ${(props) => props.theme.palette.common.white};
  margin-bottom: ${(props) => props.theme.spacing(4)};

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const illustrationPercentageStyle = (props) => css`
  ${props.illustration &&
  props.theme.palette.mode !== "dark" &&
  `
    color: ${rgba(props.theme.palette.primary.main, 0.85)};
  `}
`;

const Percentage = styled(MuiTypography)`
  span {
    color: ${(props) => props.percentagecolor};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    background: ${(props) => rgba(props.percentagecolor, 0.1)};
    padding: 2px;
    border-radius: 3px;
    margin-right: ${(props) => props.theme.spacing(2)};
  }

  ${illustrationPercentageStyle}
`;

const IllustrationImage = styled.img`
  height: 100px;
  position: absolute;
  right: ${(props) => props.theme.spacing(1)};
  bottom: ${(props) => props.theme.spacing(1)};
  display: none;

  ${(props) => props.theme.breakpoints.between("xs", "lg")} {
    display: block;
  }

  @media (min-width: 1600px) {
    display: block;
  }
`;

const StatsDouble = ({
  title1,
  title2,
  header,
  amount1,
  amount2,

  chip,
  percentagetext,
  percentagecolor,
  illustration,
  textColor,
  backgroundColor,
  loader1,
  loader2

}) => {
  const state = useSelector(state => state.botManagementSlice);
  // const loader = state.controls.isLoadingTestDevices;

  return (
    <Card illustration={illustration} sx={{ backgroundColor: backgroundColor }}>
      <CardContent >
        <Typography variant="h4" mb={5} textAlign={'center'} sx={{ color: textColor }} >
          {header}
        </Typography>

        <Grid container display={"flex"} width={"100%"} justifyContent={"space-between"}>
          <Grid item md={5} >
            <Typography variant="subtitle1" mb={2} textAlign={'center'} fontWeight={'bold'} sx={{ color: textColor }} >
              {title1}
            </Typography>
            <Typography variant="h3" mb={3} textAlign={'center'} sx={{ color: textColor }}>
              {!loader1 && <Box fontWeight="fontWeightRegular">{amount1}</Box>}
              {loader1 && <Loader size={30} color="inherit" />}
            </Typography>
            {percentagetext &&
              <Percentage
                variant="subtitle2"
                color="textSecondary"
                percentagecolor={percentagecolor}
                illustration={illustration}
              >
                <span>{percentagetext}</span>
              </Percentage>
            }
            {(!illustration && chip) && <Chip label={chip} />}
          </Grid>
          <Divider orientation="vertical" flexItem />

          <Grid item md={5}>
            <Typography variant="subtitle1" mb={2} textAlign={'center'} fontWeight={'bold'} sx={{ color: textColor }} >
              {title2}
            </Typography>
            <Typography variant="h3" mb={3} textAlign={'center'} sx={{ color: textColor }}>
              {!loader2 && <Box fontWeight="fontWeightRegular">{amount2}</Box>}
              {loader2 && <Loader size={30} color="inherit" />}
            </Typography>
            {percentagetext &&
              <Percentage
                variant="subtitle2"
                color="textSecondary"
                percentagecolor={percentagecolor}
                illustration={illustration}
              >
                <span>{percentagetext}</span>
              </Percentage>
            }
            {(!illustration && chip) && <Chip label={chip} />}
          </Grid>
        </Grid>

      </CardContent>

      {!!illustration && (
        <IllustrationImage src={illustration} alt="Illustration" />
      )}
    </Card>
  );
};

export default StatsDouble;
