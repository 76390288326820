import { Add as AddIcon } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    Chip,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CountryCode from "../../assets/CountryCode.json";
import { BotMessageType, BotStatus, ERoles } from "../../config";
import { EMAIL_OBJECT, PHONE_NUMBER_OBJECT, WEBSITE_OBJECT } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { clearErrorMessagesForBots, setShowAddBotForm } from "../../redux/botManagement/botManagementSlice";
import { addNewBotThunk } from "../../redux/botManagement/botManagementThunk";
import { getAllBrands } from "../../redux/brandManagement/brandManagementThunk";
import AlertMessageComponent from "../components/AlertMessageComponent";
import FilePreviewComponent from "./FilePreviewComponent";

export function Require() {
    return (
        <Typography color={"red"} className="subHeadings" variant="h6">
            {" "}
            &nbsp;*
        </Typography>
    );
}

export default function AddBot({ botDetails, setBotDetailsToShow, isBotEditable, setEditable }) {
    const navigate = useNavigate();
    const messagesEndRef = useRef(null);
    // const { user } = useAuth();

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
    };
    const [previewImageOfBotLogo, setPreviewImageOfBotLogo] = useState({
        url: null,
        type: null,
        file: null,
        fieldName: null,
    });
    const [previewImageOfBannerImage, setPreviewImageOfBannerImage] = useState({
        url: null,
        type: null,
        file: null,
        fieldName: null,
    });

    let filePicekerRefbotLogoImageUrl = useRef(null);
    let filePicekerRefbotBannerImageUrl = useRef(null);
    const dispatch = useDispatch();
    const state = useSelector(state => state.botManagementSlice);
    const brandState = useSelector(state => state.brandManagementSlice);
    const { user } = useAuth();

    useEffect(() => {
        dispatch(getAllBrands());
        scrollToBottom();
    }, []);

    const onChangeBrandName = e => {
        const brandName = e.target.value;
        formik.setFieldValue("brandName", brandName);
        const brandDetails = brandState.brandList.find(brand => brand.brandName === brandName);
        formik.setFieldValue("brandId", brandDetails._id);
    };

    const previewFile = (targetFile, urlFieldName, typeFieldName) => {
        // Reading New File (open file Picker Box)

        const reader = new FileReader();
        if (targetFile) {
            reader.readAsDataURL(targetFile);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = readerEvent => {
            if (targetFile.type.includes("image")) {
                const supportedMimeTypeArray = ["image/jpeg", "image/png", "image/jpg"];
                let mimeType = targetFile.type;
                if (supportedMimeTypeArray.includes(mimeType)) {
                    if (urlFieldName === "botLogoImageUrl.url") {
                        setPreviewImageOfBotLogo({
                            url: readerEvent.target.result,
                            type: targetFile.type,
                            file: targetFile,
                            fieldName: "botLogoImageUrl",
                        });
                    } else {
                        setPreviewImageOfBannerImage({
                            url: readerEvent.target.result,
                            type: targetFile.type,
                            file: targetFile,
                            fieldName: "botBannerImageUrl",
                        });
                    }
                }
            }
        };
    };

    const onSelectImage = ({ file, url, fieldName }) => {
        if (fieldName === "botBannerImageUrl") {
            setPreviewImageOfBannerImage({
                url: url,
                type: file.type,
                file: file,
                fieldName: `${fieldName}`,
            });
        } else {
            setPreviewImageOfBotLogo({
                url: url,
                type: file.type,
                file: file,
                fieldName: `${fieldName}`,
            });
        }

        formik.setFieldValue(`${fieldName}.url`, url);
        formik.setFieldValue(`${fieldName}.type`, file.type);
    };

    function onDeleteImage(fieldName) {
        formik.setFieldValue(fieldName, {
            type: "",
            url: null,
        });
        if (fieldName === "botLogoImageUrl") {
            setPreviewImageOfBotLogo({
                url: null,
                type: null,
                file: null,
                fieldName: null,
            });

            filePicekerRefbotLogoImageUrl = {};
        } else {
            setPreviewImageOfBannerImage({
                url: null,
                type: null,
                file: null,
                fieldName: null,
            });

            filePicekerRefbotLogoImageUrl = {};
        }
    }

    const handleChangeFormik = ({ name, value }) => {
        formik.setFieldValue(name, value);
    };

    const validate = fields => {
        let result = true;

        fields.forEach(fieldName => {
            const fieldValue = formik.values[fieldName];
            switch (fieldName) {
                case "botName": {
                    if (fieldValue === "") {
                        formik.setFieldError(fieldName, `Required Bot Name`);
                        result = false;
                    }
                    break;
                }

                case "botType": {
                    if (fieldValue === "") {
                        formik.setFieldError(fieldName, `Enter Bot Type`);
                        result = false;
                    }
                    break;
                }

                case "brandName": {
                    if (fieldValue === "") {
                        formik.setFieldError(fieldName, `Select Brand Name`);
                        result = false;
                    }
                    break;
                }

                case "botLogoImageUrl": {
                    if (fieldValue.url === null || fieldValue.url === "") {
                        formik.setFieldError(fieldName, `Select Image`);
                        result = false;
                    }
                    break;
                }

                case "botBannerImageUrl": {
                    if (fieldValue.url === null || fieldValue.url === "") {
                        formik.setFieldError(fieldName, `Select Image`);
                        result = false;
                    }
                    break;
                }

                case "shortDescription": {
                    if (fieldValue === "") {
                        formik.setFieldError(fieldName, `Enter message`);
                        result = false;
                    }
                    break;
                }

                case "color": {
                    const hexColorPattern = /^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/;

                    if (formik.values.color) {
                        const color = String(formik.values.color);

                        // Validate hex color code
                        if (!hexColorPattern.test(color)) {
                            formik.setFieldError("color", "Invalid color code. Please enter a valid hex color code (e.g., #FF0000).");
                            result = false;
                            break;
                        }

                        // Validate contrast ratio
                        if (contrastRatio(color) < 4.5) {
                            formik.setFieldError(
                                "color",
                                "Selected color has a contrast ratio below 4.5:1. Please choose a color with better contrast.",
                            );
                            result = false;
                        }
                    }
                    break;
                }

                // case "phoneNumbers": {

                //     fieldValue.map((phone,index) => {

                //         if (phone.number === "") {
                //             formik.setFieldError(fieldName, `Enter Phone Number`)
                //             result = false;
                //         } else {
                //             const PHONE_NUMBER_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
                //             if (!phone.number.match(PHONE_NUMBER_REGEX)) {
                //                 formik.setFieldError(fieldName[index], `Invalide number`);
                //                 result = false;
                //             }
                //         }
                //     })

                //     break;
                // }

                case "emails": {
                    fieldValue.map((email, index) => {
                        if (email.email === "") {
                            formik.setFieldError(`${fieldName}[${index}].email`, "Enter Email");
                        } else {
                            const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                            if (!email.email.match(emailRegex)) {
                                formik.setFieldError(`${fieldName}[${index}].email`, "Invalid Email");
                                result = false;
                            }
                        }

                        if (email.label === "") {
                            formik.setFieldError(`${fieldName}[${index}].label`, "Enter Label");
                        }
                    });
                    break;
                }

                case "phoneNumbers": {
                    fieldValue.map((phone, index) => {
                        if (phone.number === "") {
                            formik.setFieldError(`${fieldName}[${index}].number`, "Enter number");
                        } else {
                            const numberRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
                            // const emailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

                            if (!phone.number.match(numberRegex)) {
                                formik.setFieldError(`${fieldName}[${index}].number`, "Invalid number");
                                result = false;
                            }
                        }

                        if (phone.label === "") {
                            formik.setFieldError(`${fieldName}[${index}].label`, "Enter Label");
                        }
                    });
                    break;
                }

                case "websites": {
                    fieldValue.map((website, index) => {
                        if (website.url === "") {
                            formik.setFieldError(`${fieldName}[${index}].url`, "Enter Url");
                        } else {
                            const websiteRegex =
                                /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                            // const emailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

                            if (!website.url.match(websiteRegex)) {
                                formik.setFieldError(`${fieldName}[${index}].url`, "Invalid url");
                                result = false;
                            }
                        }

                        if (website.label === "") {
                            formik.setFieldError(`${fieldName}[${index}].label`, "Enter Label");
                        }
                    });
                    break;
                }

                case "termsofUseUrl": {
                    if (fieldValue === "") {
                        formik.setFieldError(fieldName, `Enter  Url`);
                        result = false;
                    } else {
                        const websiteRegex =
                            /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

                        if (!fieldValue.match(websiteRegex)) {
                            formik.setFieldError(fieldName, "Invalid website");
                            result = false;
                        }
                    }
                    break;
                }

                case "chatbotWebhook": {
                    if (fieldValue === "") {
                        formik.setFieldError("chatbotWebhook", `Enter  Url`);
                        result = false;
                    } else {
                        const websiteRegex =
                            /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

                        if (!fieldValue.match(websiteRegex)) {
                            formik.setFieldError("chatbotWebhook", "Invalid website");
                            result = false;
                        }
                    }

                    break;
                }

                case "privacyPolicyUrl": {
                    if (fieldValue === "") {
                        formik.setFieldError(fieldName, `Enter  Url`);
                        result = false;
                    } else {
                        const websiteRegex =
                            /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

                        if (!fieldValue.match(websiteRegex)) {
                            formik.setFieldError(fieldName, "Invalid website");
                            result = false;
                        }
                    }

                    break;
                }
                case "languagesSupported": {
                    if (fieldValue === "") {
                        formik.setFieldError(fieldName, `Enter Supported Language`);
                        result = false;
                    }

                    break;
                }
                default:
                    break;
            }
        });

        if (!formik.values.checkPolicy) {
            result = false;
            formik.setFieldError("checkPolicy", `Please Agree`);
        }
        return result;
    };
    const handleChangeMaxCharactersFields = (maxCharacters, event) => {
        const { value, name } = event.target;

        if (maxCharacters && typeof maxCharacters === "number") {
            if (value.length > maxCharacters) {
                formik.setFieldError(name, `Only ${maxCharacters} characters are allowed.`);
                return;
            }
        }
        formik.setFieldValue(name, value);
    };

    const handleChangeColorText = () => {};

    function addPhone() {
        formik.setFieldValue("phoneNumbers", [...formik.values.phoneNumbers, PHONE_NUMBER_OBJECT]);
    }

    function addWebsite() {
        formik.setFieldValue("websites", [...formik.values.websites, WEBSITE_OBJECT]);
    }

    function addEmails() {
        formik.setFieldValue("emails", [...formik.values.emails, EMAIL_OBJECT]);
    }

    function deletePhone(index) {
        const newPhones = [...formik.values.phoneNumbers];
        newPhones.splice(index, 1);
        formik.setFieldValue("phoneNumbers", newPhones);
    }

    function deleteEmails(index) {
        const newEmails = [...formik.values.emails];
        newEmails.splice(index, 1);
        formik.setFieldValue("emails", newEmails);
    }

    function deleteWebsite(index) {
        const newWebsites = [...formik.values.websites];
        newWebsites.splice(index, 1);
        formik.setFieldValue("websites", newWebsites);
    }

    const onChangePhoneNumber = ({ index, value, name }) => {
        if (name === "label") {
            handleChangeMaxCharactersFields(25, {
                target: { value, name: `phoneNumbers[${index}].${name}` },
            });
        } else {
            formik.setFieldValue(`phoneNumbers[${index}].${name}`, value);
        }
    };

    const onChangeWebsites = ({ index, value, name }) => {
        if (name === "label") {
            handleChangeMaxCharactersFields(25, {
                target: { value, name: `websites[${index}].${name}` },
            });
        } else {
            formik.setFieldValue(`websites[${index}].${name}`, value);
        }
    };

    const onChangeEmails = ({ index, value, name }) => {
        if (name === "label") {
            handleChangeMaxCharactersFields(25, {
                target: { value, name: `emails[${index}].${name}` },
            });
        } else {
            formik.setFieldValue(`emails[${index}].${name}`, value);
        }
    };

    const onChangeSelectCountry = ({ index, name, value }) => {
        const foundCountry = CountryCode.find(country => country.name === value);
        formik.setFieldValue(`phoneNumbers[${index}].countryCode`, foundCountry.dial_code);
    };

    const initialValues = {
        orgId: botDetails?.orgId ?? user.orgId,
        registryId: user?.registryId,
        botType: botDetails?.botType ? botDetails?.botType : "Domestic",
        botName: botDetails?.botName ? botDetails?.botName : "",
        brandName: botDetails?.brandName ? botDetails?.brandName : "",
        brandId: botDetails?.brandId ? botDetails?.brandId : "",
        botMessageType: botDetails?.botMessageType ? botDetails?.botMessageType : BotMessageType.Promotional,
        botLogoImageUrl: botDetails?.botLogoImage
            ? botDetails?.botLogoImage
            : {
                  type: "",
                  url: null,
              },
        botBannerImageUrl: botDetails?.botBannerImage
            ? botDetails?.botBannerImage
            : {
                  type: "",
                  url: null,
              },
        shortDescription: botDetails?.shortDescription ? botDetails?.shortDescription : "",
        color: botDetails?.color ? botDetails?.color : "#000000",
        phoneNumbers: botDetails?.phoneNumbers ? botDetails?.phoneNumbers : [PHONE_NUMBER_OBJECT],
        websites: botDetails?.websites ? botDetails?.websites : [WEBSITE_OBJECT],
        emails: botDetails?.emails ? botDetails?.emails : [EMAIL_OBJECT],
        termsofUseUrl: botDetails?.termsofUseUrl ? botDetails?.termsofUseUrl : "",
        privacyPolicyUrl: botDetails?.privacyPolicyUrl ? botDetails?.privacyPolicyUrl : "",
        developmentPlatform: botDetails?.developmentPlatform ? botDetails?.developmentPlatform : "Google API",
        chatbotWebhook: "https://rcsgateway.pinnacle.in:444/", // TODO: take from env
        languagesSupported: botDetails?.languagesSupported ? botDetails?.languagesSupported : "",
        checkPolicy: botDetails ? true : false,
        status: "new",
    };

    const onClickCancelButton = () => {
        dispatch(setShowAddBotForm(false));
        setBotDetailsToShow(null);
        setEditable(true);
    };

    const formik = useFormik({
        initialValues: initialValues,

        onSubmit: async values => {
            // alert(JSON.stringify(values, null, 2));
            try {
                const dataToSend = {
                    orgId: values.orgId,
                    registryId: user?.registryId,
                    botType: formik.values.botType,
                    botName: formik.values.botName,
                    brandName: formik.values.brandName,
                    brandId: formik.values.brandId,
                    botMessageType: formik.values.botMessageType,
                    botLogoImageUrl: "",
                    botBannerImageUrl: "",
                    shortDescription: formik.values.shortDescription,
                    color: formik.values.color,
                    phoneNumbers: formik.values.phoneNumbers,
                    websites: formik.values.websites,
                    emails: formik.values.emails,
                    termsofUseUrl: formik.values.termsofUseUrl,
                    privacyPolicyUrl: formik.values.privacyPolicyUrl,
                    developmentPlatform: formik.values.developmentPlatform,
                    chatbotWebhook: formik.values.chatbotWebhook,
                    languagesSupported: formik.values.languagesSupported,
                    status: values.status,
                };

                if (validate(Object.keys(dataToSend))) {
                    let formDataToSend = new FormData();

                    // for (let fieldName in imageFormData) {
                    //     formDataToSend.append(fieldName, imageFormData[fieldName]);
                    // }

                    formDataToSend.append(previewImageOfBotLogo.fieldName, previewImageOfBotLogo.file);
                    formDataToSend.append(previewImageOfBannerImage.fieldName, previewImageOfBannerImage.file);

                    formDataToSend.append("botData", JSON.stringify(dataToSend));
                    // //    setTimeout(() => {

                    // //    }, 2000);

                    // const headers = { "Content-Type": "multipart/form-data", orgId: "652fc4f9782f5c27642e1a5c" };
                    // const createdBotDetails = await axios.post(`${rcsAppIntegrationBackendUrl}RCS/add-bot`, formDataToSend, { headers });
                    // // const createdBotDetails = await axios.post(`${process.env.REACT_APP_BOT_MANAGEMENT_BACKEND_URL}bot/create-bot`, formDataToSend, { headers });
                    // if (createdBotDetails.status === 200) {
                    //     navigate("/RCS");
                    // }
                    // }

                    dispatch(
                        addNewBotThunk({
                            formDataToSend,
                            keyNameForBotLogo: "botLogoImageUrl.url",
                            keyNameForBotBanner: "botBannerImageUrl.url",
                            keyNameForBotData: "botData",
                            botId: botDetails?._id ? botDetails?._id : "",
                            registryId: user?.registryId,
                        }),
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },
    });

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForBots({ fieldName }));
    };

    function handleClick(event) {
        event.preventDefault();
        console.info("You clicked a breadcrumb.");
    }

    const handleColorChange = event => {
        const color = event.target.value;
        formik.setFieldValue("color", color);
    };

    function contrastRatio(hexColor) {
        const rgb = parseInt(hexColor.slice(1), 16);
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >> 8) & 0xff;
        const b = (rgb >> 0) & 0xff;

        const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
        const whiteLuminance = 1;

        return (whiteLuminance + 0.05) / (luminance + 0.05);
    }

    return (
        <Grid>
            <div ref={messagesEndRef} />

            <form onSubmit={formik.handleSubmit}>
                <Grid container className="listHeader">
                    {!isBotEditable ? (
                        <>
                            <Grid item md={6} display={"flex"} alignItems={"center"}>
                                <IconButton color="secondary" onClick={onClickCancelButton} size="medium">
                                    <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                                </IconButton>
                                <Grid role="presentation">
                                    <Breadcrumbs aria-label="breadcrumb" fontSize="large">
                                        <Link
                                            underline="hover"
                                            color="inherit"
                                            className="breadcrumbItem"
                                            // href="/rcs-management/manage-bots/"
                                            onClick={onClickCancelButton}
                                        >
                                            Bots
                                        </Link>
                                        <Typography className="breadcrumbItem" color="text.primary">
                                            {botDetails.botName}
                                        </Typography>
                                    </Breadcrumbs>
                                </Grid>
                                <Grid margin={2}>
                                    {botDetails.status ? (
                                        botDetails.status === BotStatus.New ? (
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                className="chip"
                                                label={botDetails.status.replaceAll("_", " ").toUpperCase()}
                                                color="primary"
                                            />
                                        ) : botDetails.status === BotStatus.Development ? (
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                className="chip"
                                                label={botDetails.status.replaceAll("_", " ").toUpperCase()}
                                                color="info"
                                            />
                                        ) : botDetails.status === BotStatus.VerificationInProcess ? (
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                className="chip"
                                                label={botDetails.status.replaceAll("_", " ").toUpperCase()}
                                                color="warning"
                                            />
                                        ) : botDetails.status === BotStatus.Verified ? (
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                className="chip"
                                                label={botDetails.status.replaceAll("_", " ").toUpperCase()}
                                                color="success"
                                            />
                                        ) : botDetails.status === BotStatus.Launched ? (
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                className="chip"
                                                label={botDetails.status.replaceAll("_", " ").toUpperCase()}
                                                color="success"
                                            />
                                        ) : botDetails.status === BotStatus.VerificationSubmitted ? (
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                className="chip"
                                                label={botDetails.status.replaceAll("_", " ").toUpperCase()}
                                                color="secondary"
                                            />
                                        ) : botDetails.status === BotStatus.LaunchingInProcess ? (
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                className="chip"
                                                label={botDetails.status.replaceAll("_", " ").toUpperCase()}
                                                color="warning"
                                            />
                                        ) : (
                                            <Chip
                                                size="small"
                                                variant="outlined"
                                                className="chip"
                                                label={botDetails.status.replaceAll("_", " ").toUpperCase()}
                                                color="error"
                                            />
                                        )
                                    ) : (
                                        "-"
                                    )}
                                </Grid>
                            </Grid>
                            {user?.roleName && user?.roleName == ERoles.SUPER_ADMIN && (
                                <Grid display={"flex"} alignItems={"center"} justifyContent={"end"}>
                                    <Typography variant="button" style={{ fontWeight: "600" }}>
                                        Organization Name :&nbsp;
                                    </Typography>
                                    <Typography variant="button">{botDetails.clientName}</Typography>
                                </Grid>
                            )}

                            {botDetails.config?.apiKey && (
                                <Grid item md={6} display={"flex"} alignItems={"end"} justifyContent={"space-between"}>
                                    <Grid display={"flex"} alignItems={"center"}>
                                        <Typography
                                            variant="button"
                                            style={{
                                                fontWeight: "600",
                                            }}
                                        >
                                            Bot Id :
                                        </Typography>
                                        <input
                                            type={"password"}
                                            disabled
                                            value={botDetails._id}
                                            style={{
                                                backgroundColor: "transparent !important",
                                                border: "none",
                                                margin: "5px",
                                                cursor: "pointer !important",
                                            }}
                                        />
                                        <CopyToClipboard text={botDetails._id}>
                                            <ContentCopyIcon
                                                fontSize="small"
                                                color="primary"
                                                sx={{
                                                    cursor: "pointer !important",
                                                }}
                                            />
                                        </CopyToClipboard>
                                    </Grid>
                                    <Grid display={"flex"} alignItems={"center"}>
                                        <Typography
                                            variant="button"
                                            style={{
                                                fontWeight: "600",
                                            }}
                                        >
                                            API Key : &nbsp;
                                        </Typography>
                                        <input
                                            type={"password"}
                                            disabled
                                            value={botDetails.config.apiKey}
                                            style={{
                                                backgroundColor: "transparent !important",
                                                border: "none",
                                                margin: "5px",
                                            }}
                                        />
                                        <CopyToClipboard text={botDetails.config.apiKey}>
                                            <ContentCopyIcon
                                                fontSize="small"
                                                color="primary"
                                                sx={{
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </CopyToClipboard>
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    ) : (
                        <Grid item md={6} display={"flex"} alignItems={"center"}>
                            <IconButton color="secondary" onClick={onClickCancelButton} size="medium">
                                <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                            </IconButton>
                            {/* <Typography variant='h3'>
                                        &nbsp; Add Bot
                                    </Typography> */}
                            <Grid role="presentation" onClick={handleClick}>
                                <Breadcrumbs aria-label="breadcrumb" fontSize="large">
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                        className="breadcrumbItem"
                                        // href="/rcs-management/manage-bots/"
                                        onClick={onClickCancelButton}
                                    >
                                        Bots
                                    </Link>
                                    <Typography className="breadcrumbItem" color="text.primary">
                                        {botDetails ? `Update ${botDetails.botName}` : "Add Bot"}
                                    </Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                <Card
                    sx={{
                        marginTop: "20px",
                        borderRadius: "10px",
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                    }}
                >
                    <Grid container padding={5}>
                        <Grid container>
                            <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"} paddingBottom={"5px"}>
                                        Bot Type
                                    </Typography>
                                    <TextField
                                        size="small"
                                        name="botType"
                                        label={null}
                                        variant="outlined"
                                        // InputProps={fontSize}
                                        mb={4}
                                        value={formik.values.botType}
                                        disabled={true}
                                        error={Boolean(formik.errors?.botType)}
                                        helperText={formik.errors?.botType}
                                        // disabled={!isBotEditable}
                                        // onChange={formik.handleChange}
                                        // onChange={(event) => handleChangeMaxCharactersFields(max_50_characters.maxLength, event)}
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        Bot Message Type
                                    </Typography>
                                    <RadioGroup
                                        // aria-labelledby="demo-controlled-radio-buttons-group"
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="botMessageType"
                                        disabled={true}
                                        value={formik.values.botMessageType}
                                        // defaultValue={"OTP"}
                                        onChange={formik.handleChange}

                                        // onChange={(e) => { handleChangeModelType(e.target.value) }}
                                    >
                                        <Grid display={"flex"} sx={{ height: "80%" }}>
                                            <FormControlLabel value={BotMessageType.OTP} control={<Radio disabled={true} />} label="OTP" />
                                            <FormControlLabel
                                                value={BotMessageType.Promotional}
                                                control={<Radio disabled={true} />}
                                                label="Promotional"
                                            />
                                            <FormControlLabel
                                                value={BotMessageType.Transactional}
                                                control={<Radio disabled={true} />}
                                                label="Transactional"
                                            />
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Bot Name <Require />
                                        </Grid>
                                    </Typography>
                                    <Typography className="mb-3 text-muted">
                                        Enter the name of the bot that the user will see at the top of the message thread.
                                    </Typography>
                                    <TextField
                                        size="small"
                                        name="botName"
                                        label={null}
                                        variant="outlined"
                                        // InputProps={fontSize}
                                        mb={4}
                                        value={formik.values.botName}
                                        //  disabled={isSubmitting || operation === 'view'}
                                        error={Boolean(formik.errors.botName)}
                                        helperText={formik.errors.botName}
                                        disabled={!isBotEditable}
                                        // onChange={formik.handleChange}
                                        onChange={event => handleChangeMaxCharactersFields(40, event)}
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                    />
                                    <Grid sx={{ display: "flex", flexDirection: "row-reverse" }}>
                                        <Typography>{formik.values.botName.length}/40</Typography>
                                    </Grid>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Brand Name <Require />
                                        </Grid>
                                    </Typography>
                                    <Typography className="mb-3 text-muted">Enter the brand name with which your bot will be associated.</Typography>
                                    <FormControl fullWidth error={formik.errors.brandName ? true : false}>
                                        <InputLabel id="brandNameLabel">Select Brand</InputLabel>
                                        <Select
                                            name="brandName"
                                            label="Select Brand"
                                            fullWidth
                                            labelId="brandNameLabel"
                                            id="brand"
                                            // disabled={botDetails?.brandName ? true : false}
                                            error={Boolean(formik.touched.brandName && formik.errors.brandName)}
                                            helperText={formik.touched.brandName && formik.errors.brandName}
                                            defaultValue={formik.values.brandName}
                                            value={formik.values.brandName}
                                            onChange={onChangeBrandName}
                                            // onBlur={formik.handleBlur}
                                            disabled={!isBotEditable}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                    },
                                                },
                                            }}
                                        >
                                            {brandState.brandList?.map((brand, index) => (
                                                <MenuItem key={`${index}_${brand.brandName}`} value={brand.brandName}>
                                                    {brand.brandName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{formik.touched.brandName && formik.errors.brandName}</FormHelperText>
                                    </FormControl>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Bot Logo <Require />
                                        </Grid>
                                    </Typography>
                                    <Typography className="text-muted mb-4">
                                        Provide a logo for your bot that will be displayed in connection with the bot's name.
                                    </Typography>
                                    <Box className="preview">
                                        <FilePreviewComponent
                                            type={previewImageOfBotLogo.type}
                                            url={previewImageOfBotLogo.url}
                                            previewImage={previewImageOfBotLogo}
                                            formikImage={formik.values.botLogoImageUrl}
                                            deleteFile={() => onDeleteImage("botLogoImageUrl")}
                                            width={"100px"}
                                            height={"100px"}
                                            isDisabled={!isBotEditable}
                                            aspect={1 / 1}
                                            imageHeightRatio={1}
                                            imageWidthRatio={1}
                                            onSelectImage={onSelectImage}
                                            fieldName={"botLogoImageUrl"}
                                        />
                                    </Box>

                                    {isBotEditable && (
                                        <>
                                            {!previewImageOfBotLogo?.url && !formik.values.botLogoImageUrl.url && (
                                                <Button variant="contained" onClick={() => filePicekerRefbotLogoImageUrl.current.click()}>
                                                    Choose
                                                </Button>
                                            )}

                                            <Box className="btn-container" sx={{ backgroundColor: "red" }}>
                                                <input
                                                    name="botLogoImageUrl"
                                                    ref={filePicekerRefbotLogoImageUrl}
                                                    accept="image/*"
                                                    onChange={e => previewFile(e.target.files[0], "botLogoImageUrl.url", "botLogoImageUrl.type")}
                                                    onClick={e => {
                                                        e.target.value = "";
                                                    }}
                                                    type="file"
                                                    hidden
                                                />
                                            </Box>
                                        </>
                                    )}

                                    <FormHelperText sx={{ color: "#d32f2f" }}>
                                        {formik.touched.botLogoImageUrl && formik.errors.botLogoImageUrl}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Banner Image
                                            <Require />
                                        </Grid>
                                    </Typography>
                                    <Typography className="text-muted mb-4">
                                        Provide a brand image for your bot that will be displayed in the bot's 'Info & options' screen. Note: Your
                                        logo will be overlaid on the Banner Image (bottom centre) so be careful with your design.
                                    </Typography>
                                    <Box className="preview" sx={{}}>
                                        <FilePreviewComponent
                                            type={previewImageOfBannerImage.type}
                                            url={previewImageOfBannerImage.url}
                                            previewImage={previewImageOfBannerImage}
                                            deleteFile={() => onDeleteImage("botBannerImageUrl")}
                                            width={"100px"}
                                            height={"100px"}
                                            isDisabled={!isBotEditable}
                                            aspect={3 / 1}
                                            formikImage={formik.values.botBannerImageUrl}
                                            imageHeightRatio={1}
                                            imageWidthRatio={3}
                                            onSelectImage={onSelectImage}
                                            fieldName={"botBannerImageUrl"}
                                        />
                                    </Box>

                                    {isBotEditable && (
                                        <>
                                            {!previewImageOfBannerImage?.url && !formik.values.botBannerImageUrl.url && (
                                                <Button variant="contained" onClick={() => filePicekerRefbotBannerImageUrl.current.click()}>
                                                    Choose
                                                </Button>
                                            )}

                                            <Box className="btn-container">
                                                <input
                                                    name="botBannerImageUrl"
                                                    ref={filePicekerRefbotBannerImageUrl}
                                                    accept="image/*"
                                                    onChange={e => previewFile(e.target.files[0], "botBannerImageUrl.url", "botBannerImageUrl.type")}
                                                    onClick={e => {
                                                        e.target.value = "";
                                                    }}
                                                    type="file"
                                                    hidden
                                                />
                                            </Box>
                                        </>
                                    )}

                                    <FormHelperText sx={{ color: "#d32f2f" }}>
                                        {formik.touched.botBannerImageUrl && formik.errors.botBannerImageUrl}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Short Message
                                            <Require />
                                        </Grid>
                                    </Typography>
                                    <Box>
                                        <FormControl fullWidth className="mt-2">
                                            <TextField
                                                size="small"
                                                label="Short Message"
                                                error={Boolean(formik.errors.shortDescription)}
                                                helperText={formik.errors.shortDescription}
                                                value={formik.values.shortDescription}
                                                name="shortDescription"
                                                // id='shortDescription'
                                                placeholder={"Enter your Short Message"}
                                                // onChange={formik.handleChange}
                                                onChange={event => handleChangeMaxCharactersFields(100, event)}
                                                disabled={!isBotEditable}
                                                // onBlur={formik.handleBlur}
                                            />
                                            <Grid sx={{ display: "flex", flexDirection: "row-reverse" }}>
                                                <Typography>{formik.values.shortDescription.length}/100</Typography>
                                            </Grid>
                                        </FormControl>
                                    </Box>
                                </FormControl>
                            </Grid>
                            {/* <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className='subHeadings' variant='h6' fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Color
                                            <Require />
                                        </Grid>
                                    </Typography>
                                    <Typography className='text-muted mb-4'>
                                        Specify a color for your agent with a minimum 4.5:1 contrast ratio relative to white.
                                    </Typography>
                                    <Box className='' display={"inline-flex"} gap={5} alignItems={"center"}>
                                        <input
                                            type='color'
                                            id='color'
                                            value={formik.values.color}
                                            onChange={formik.handleChange}
                                            defaultValue={formik.values.color}
                                            disabled={!isBotEditable}
                                            className='border border-1 p-1'
                                        />

                                        <TextField
                                            size='small'
                                            name='color-value'
                                            id='color-value'
                                            label='Color (# value)'
                                            onBlur={formik.handleBlur}
                                            value={formik.values.color}
                                            disabled={!isBotEditable}
                                        />
                                    </Box>
                                </FormControl>
                            </Grid> */}

                            <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Color
                                            <Require />
                                        </Grid>
                                    </Typography>
                                    <Typography className="text-muted mb-4">
                                        Specify a color for your agent with a minimum 4.5:1 contrast ratio relative to white.
                                    </Typography>
                                    <Box className="" display={"inline-flex"} gap={5} alignItems={"center"}>
                                        {/* <input
                                            type="color"
                                            id="color"
                                            value={formik.values.color}
                                            onChange={handleColorChange}
                                            defaultValue={formik.values.color}
                                            disabled={!isBotEditable}
                                            className="border border-1 p-1"
                                        />
                                        <TextField
                                            size="small"
                                            name="color-value"
                                            id="color-value"
                                            label="Color (# value)"
                                            onBlur={formik.handleBlur}
                                            onChange={handleChangeColorText}
                                            value={formik.values.color}
                                            disabled={!isBotEditable}   
                                            helperText={formik.errors.color}
                                            error={Boolean(formik.errors.color)}
                                        /> */}

                                        <Box display={"inline-flex"} gap={5} alignItems={"center"} mt={5}>
                                            {/* Color picker input */}
                                            <input
                                                type="color"
                                                id="color"
                                                value={formik.values.color}
                                                onChange={handleColorChange}
                                                className="border border-1 p-1"
                                                defaultValue={formik.values.color}
                                                disabled={!isBotEditable}
                                            />

                                            <TextField
                                                size="small"
                                                name="color-value"
                                                id="color-value"
                                                label="Color (# value)"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.color}
                                                onChange={handleColorChange}
                                                disabled={!isBotEditable}
                                                helperText={formik.errors.color}
                                                error={Boolean(formik.errors.color)}
                                                inputProps={{
                                                    maxLength: 7,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container fullWidth padding={2}>
                            <Grid className="d-flex item-center justify-center flex-column" item md={12}>
                                {formik.values.phoneNumbers?.map((phone, index) => (
                                    <Box key={index} display={"flex"} flexDirection={"column"} gap={2} className="mt-3">
                                        <Typography className="subHeadings" variant="h6">
                                            {index === 0 ? (
                                                <Grid display={"flex"}>
                                                    Primary Phone Number <Require />
                                                </Grid>
                                            ) : (
                                                "Other Phone Number"
                                            )}
                                        </Typography>

                                        <Grid container paddingBottom={1}>
                                            <Grid item md={4} padding={1} height={"10px"}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="country">Country</InputLabel>
                                                    <Select
                                                        name="country"
                                                        label="Country"
                                                        labelId="country"
                                                        id="country"
                                                        error={Boolean(formik.touched.country && formik.errors.country)}
                                                        helperText={formik.touched.country && formik.errors.country}
                                                        onBlur={formik.handleBlur}
                                                        onChange={e =>
                                                            onChangeSelectCountry({
                                                                index,
                                                                value: e.target.value,
                                                                name: e.target.name,
                                                            })
                                                        }
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                                },
                                                            },
                                                        }}
                                                        disabled={!isBotEditable}
                                                        defaultValue={"India"}
                                                    >
                                                        {/* <Select
                                                name="brandName"
                                                label="Select Brand"
                                                fullWidth
                                                labelId="brandNameLabel"
                                                id="brand"
                                                // disabled={botDetails?.brandName ? true : false}
                                                error={Boolean(
                                                    formik.touched.brandName &&
                                                    formik.errors.brandName
                                                )}
                                                helperText={
                                                    formik.touched.brandName &&
                                                    formik.errors.brandName
                                                }
                                                value={formik.values.brandName}
                                                onChange={formik.handleChange}
                                                // onBlur={formik.handleBlur}
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            boxShadow:
                                                                " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                        },
                                                    },
                                                }}
                                            > */}
                                                        {CountryCode?.map((country, index) => (
                                                            <MenuItem key={index} value={country.name}>
                                                                {country.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={2} padding={1}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        name="countryCode"
                                                        label={"code"}
                                                        error={Boolean(formik.errors.phoneNumbers && formik.errors.phoneNumbers[index]?.countryCode)}
                                                        helperText={formik.errors.phoneNumbers && formik.errors.phoneNumbers[index]?.countryCode}
                                                        defaultValue={formik.values.phoneNumbers[0].countryCode}
                                                        onBlur={formik.handleBlur}
                                                        disabled={!isBotEditable}
                                                        value={formik.values.phoneNumbers[index]?.countryCode}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={3} padding={1}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        name="number"
                                                        label={`Phone Number ${index + 1}`}
                                                        error={Boolean(formik.errors?.phoneNumbers && formik.errors?.phoneNumbers[index]?.number)}
                                                        helperText={formik.errors?.phoneNumbers && formik.errors?.phoneNumbers[index]?.number}
                                                        value={formik.values.phoneNumbers[index].number}
                                                        onBlur={formik.handleBlur}
                                                        // type='number'
                                                        disabled={!isBotEditable}
                                                        onChange={e =>
                                                            onChangePhoneNumber({
                                                                index,
                                                                value: e.target.value,
                                                                name: e.target.name,
                                                            })
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={3} padding={1}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        name="label"
                                                        label="Label"
                                                        value={formik.values.phoneNumbers[index].label}
                                                        error={Boolean(formik.errors?.phoneNumbers && formik.errors.phoneNumbers[index]?.label)}
                                                        helperText={formik.errors?.phoneNumbers && formik.errors?.phoneNumbers[index]?.label}
                                                        onBlur={formik.handleBlur}
                                                        disabled={!isBotEditable}
                                                        onChange={e =>
                                                            onChangePhoneNumber({
                                                                index,
                                                                value: e.target.value,
                                                                name: e.target.name,
                                                            })
                                                        }
                                                    />
                                                    <Grid sx={{ display: "flex", flexDirection: "row-reverse" }}>
                                                        <Typography>{formik.values.phoneNumbers[index].label.length}/25</Typography>
                                                    </Grid>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        {/*
                                    <FormHelperText sx={{ color: "#d32f2f" }}>
                                        {formik.touched.phoneNumbers[index] &&
                                            formik.errors.phoneNumbers[index]}
                                    </FormHelperText> */}
                                    </Box>
                                ))}
                                {isBotEditable && (
                                    <Box
                                        fullWidth
                                        sx={{
                                            display: "flex",
                                            justifyContent: "end",
                                        }}
                                    >
                                        {formik.values.phoneNumbers.length < 3 && (
                                            <Button
                                                type="button"
                                                variant="contained"
                                                onClick={addPhone}
                                                mr={2}
                                                style={{
                                                    marginRight: 10,
                                                    marginTop: 10,
                                                }}
                                            >
                                                <AddIcon />
                                                Add Another Phone
                                            </Button>
                                        )}
                                        {formik.values.phoneNumbers.length > 1 && (
                                            <Button
                                                variant="outlined"
                                                type="button"
                                                onClick={() => deletePhone(formik.values.phoneNumbers.length - 1)}
                                                style={{
                                                    marginRight: 10,
                                                    marginTop: 10,
                                                }}
                                            >
                                                Delete Phone
                                            </Button>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container fullWidth>
                            <Grid container fullWidth sx={{ mt: 3, display: "flex" }}>
                                {formik.values.emails?.map((email, index) => (
                                    <Grid container fullWidth>
                                        <Grid item md={6} padding={2}>
                                            <FormControl item fullWidth>
                                                <Typography className="subHeadings" variant="h6" paddingBottom={2}>
                                                    {index === 0 ? (
                                                        <Grid display={"flex"}>
                                                            Primary Email
                                                            <Require />
                                                        </Grid>
                                                    ) : (
                                                        "Other Email"
                                                    )}
                                                </Typography>
                                                <TextField
                                                    size="small"
                                                    name="email"
                                                    label={`Email ${index + 1}`}
                                                    value={formik.values.emails[index].email}
                                                    disabled={!isBotEditable}
                                                    placeholder="abcd@email.com"
                                                    onChange={e =>
                                                        onChangeEmails({
                                                            index,
                                                            value: e.target.value,
                                                            name: e.target.name,
                                                        })
                                                    }
                                                    error={Boolean(formik.errors?.emails && formik.errors?.emails[index]?.email)}
                                                    helperText={formik.errors?.emails && formik.errors?.emails[index]?.email}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6} padding={2}>
                                            <FormControl fullWidth item>
                                                <Typography className="subHeadings" variant="h6" paddingBottom={2}>
                                                    {index === 0 ? (
                                                        <Grid display={"flex"}>
                                                            Label for Primary Email
                                                            <Require />
                                                        </Grid>
                                                    ) : (
                                                        "Label for Other Email"
                                                    )}
                                                </Typography>
                                                <TextField
                                                    size="small"
                                                    name="label"
                                                    label={`Email Label ${index + 1}`}
                                                    value={formik.values.emails[index].label}
                                                    onBlur={formik.handleBlur}
                                                    disabled={!isBotEditable}
                                                    onChange={e =>
                                                        onChangeEmails({
                                                            index,
                                                            value: e.target.value,
                                                            name: e.target.name,
                                                        })
                                                    }
                                                    error={Boolean(formik.errors?.emails && formik.errors?.emails[index]?.label)}
                                                    helperText={formik.errors?.emails && formik.errors?.emails[index]?.label}
                                                />
                                                <Grid sx={{ display: "flex", flexDirection: "row-reverse" }}>
                                                    <Typography>{formik.values.emails[index].label.length}/25</Typography>
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                ))}

                                {isBotEditable && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "end",
                                            width: "100%",
                                        }}
                                        padding={2}
                                    >
                                        {formik.values.emails.length < 3 && (
                                            <Button
                                                type="button"
                                                variant="contained"
                                                onClick={addEmails}
                                                style={{
                                                    marginRight: 10,
                                                    marginTop: 10,
                                                }}
                                            >
                                                <AddIcon />
                                                Add Email
                                            </Button>
                                        )}
                                        {formik.values.emails.length > 1 && (
                                            <Button
                                                variant="outlined"
                                                type="button"
                                                onClick={() => deleteEmails(formik.values.emails.length - 1)}
                                                style={{
                                                    marginRight: 10,
                                                    marginTop: 10,
                                                }}
                                            >
                                                Delete Email
                                            </Button>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container fullWidth>
                            <Grid
                                container
                                sx={{
                                    mt: 3,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                                fullWidth
                            >
                                {formik.values.websites?.map((website, index) => (
                                    <Grid container fullWidth>
                                        <Grid item md={6} padding={2}>
                                            <FormControl item fullWidth>
                                                <Typography className="subHeadings" variant="h6" paddingBottom={2}>
                                                    {index === 0 ? (
                                                        <Grid display={"flex"}>
                                                            Primary Website
                                                            <Require />
                                                        </Grid>
                                                    ) : (
                                                        "Other Website"
                                                    )}
                                                </Typography>
                                                <TextField
                                                    size="small"
                                                    name="url"
                                                    label={`Website ${index + 1}`}
                                                    value={formik.values.websites[index].url}
                                                    onBlur={formik.handleBlur}
                                                    disabled={!isBotEditable}
                                                    onChange={e =>
                                                        onChangeWebsites({
                                                            index,
                                                            value: e.target.value,
                                                            name: e.target.name,
                                                        })
                                                    }
                                                    error={Boolean(formik.errors?.websites && formik.errors?.websites[index]?.url)}
                                                    helperText={formik.errors?.websites && formik.errors?.websites[index]?.url}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item md={6} padding={2}>
                                            <FormControl item fullWidth>
                                                <Typography className="subHeadings" variant="h6" paddingBottom={2}>
                                                    {index === 0 ? (
                                                        <Grid display={"flex"}>
                                                            Label for Primary Website
                                                            <Require />
                                                        </Grid>
                                                    ) : (
                                                        "Label for Other Website"
                                                    )}
                                                </Typography>
                                                <TextField
                                                    size="small"
                                                    name="label"
                                                    disabled={!isBotEditable}
                                                    value={formik.values.websites[index].label}
                                                    onBlur={formik.handleBlur}
                                                    onChange={e =>
                                                        onChangeWebsites({
                                                            index,
                                                            value: e.target.value,
                                                            name: e.target.name,
                                                        })
                                                    }
                                                    label={`Website Label`}
                                                    error={Boolean(formik.errors?.websites && formik.errors?.websites[index]?.label)}
                                                    helperText={formik.errors?.websites && formik.errors?.websites[index]?.label}
                                                />
                                                <Grid sx={{ display: "flex", flexDirection: "row-reverse" }}>
                                                    <Typography>{formik.values.websites[index].label.length}/25</Typography>
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                ))}

                                {isBotEditable && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "end",
                                        }}
                                    >
                                        {formik.values.websites.length < 3 && (
                                            <Button
                                                type="button"
                                                variant="contained"
                                                onClick={addWebsite}
                                                style={{
                                                    marginRight: 10,
                                                    marginTop: 10,
                                                }}
                                            >
                                                <AddIcon />
                                                Add Website
                                            </Button>
                                        )}
                                        {formik.values.websites.length > 1 && (
                                            <Button
                                                variant="outlined"
                                                type="button"
                                                onClick={() => deleteWebsite(formik.values.websites.length - 1)}
                                                style={{
                                                    marginRight: 10,
                                                    marginTop: 10,
                                                }}
                                            >
                                                Delete Website
                                            </Button>
                                        )}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Terms of Use URL
                                            <Require />
                                        </Grid>
                                    </Typography>
                                    <Typography className="text-muted mb-3">Enter the URL of the website</Typography>

                                    <TextField
                                        size="small"
                                        name="termsofUseUrl"
                                        label={null}
                                        variant="outlined"
                                        // InputProps={fontSize}
                                        mb={4}
                                        value={formik.values.termsofUseUrl}
                                        //  disabled={isSubmitting || operation === 'view'}
                                        error={Boolean(formik.errors.termsofUseUrl)}
                                        helperText={formik.errors.termsofUseUrl}
                                        onChange={formik.handleChange}
                                        placeholder="https://www.example.com"
                                        disabled={!isBotEditable}
                                        // onChange={(event) => handleChangeMaxCharactersFields(max_50_characters.maxLength, event)}
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Privacy Policy URL
                                            <Require />
                                        </Grid>
                                    </Typography>
                                    <Typography className="text-muted mb-3">Enter the URL of the website</Typography>
                                    <TextField
                                        size="small"
                                        name="privacyPolicyUrl"
                                        label={null}
                                        variant="outlined"
                                        // InputProps={fontSize}
                                        mb={4}
                                        disabled={!isBotEditable}
                                        value={formik.values.privacyPolicyUrl}
                                        //  disabled={isSubmitting || operation === 'view'}
                                        error={Boolean(formik.errors.privacyPolicyUrl)}
                                        helperText={formik.errors.privacyPolicyUrl}
                                        onChange={formik.handleChange}
                                        // onChange={(event) => handleChangeMaxCharactersFields(max_50_characters.maxLength, event)}
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container>
                            {user?.roleName === ERoles.SUPER_ADMIN && (
                                <Grid item md={6} padding={2}>
                                    <FormControl fullWidth>
                                        <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                            Chatbot Webhook
                                        </Typography>
                                        <Typography className="text-muted mb-3">
                                            Enter the webhook that your bot will receive messages. NOTE: The webhook needs to be active and be able to
                                            respond with a 200 OK to POST requests.
                                        </Typography>
                                        <TextField
                                            size="small"
                                            name="chatbotWebhook"
                                            label={null}
                                            variant="outlined"
                                            // InputProps={fontSize}
                                            mb={4}
                                            // disabled={!isBotEditable}
                                            disabled
                                            value={"https://rcsgateway.pinnacle.in:444/"}
                                            error={Boolean(formik.errors?.chatbotWebhook)}
                                            helperText={formik.errors?.chatbotWebhook}
                                            onChange={e => {
                                                handleChangeFormik({
                                                    name: e.target.name,
                                                    value: e.target.value,
                                                });
                                            }}
                                            // onChange={(event) => handleChangeMaxCharactersFields(max_50_characters.maxLength, event)}
                                            sx={{
                                                "& legend": { display: "none" },
                                                "& fieldset": { top: 0 },
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            )}
                            <Grid item md={6} padding={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Language Supported
                                            <Require />
                                        </Grid>
                                    </Typography>
                                    <Typography className="text-muted mb-3">Please specify the languages supported by the bot</Typography>
                                    <TextField
                                        size="small"
                                        name="languagesSupported"
                                        label={null}
                                        variant="outlined"
                                        // InputProps={fontSize}
                                        mb={4}
                                        disabled={!isBotEditable}
                                        value={formik.values.languagesSupported}
                                        //  disabled={isSubmitting || operation === 'view'}
                                        error={Boolean(formik.errors.languagesSupported)}
                                        helperText={formik.errors.languagesSupported}
                                        onChange={formik.handleChange}
                                        // onChange={(event) => handleChangeMaxCharactersFields(max_50_characters.maxLength, event)}
                                        sx={{
                                            "& legend": { display: "none" },
                                            "& fieldset": { top: 0 },
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        {user?.roleName === ERoles.SUPER_ADMIN && (
                            <Grid container padding={2}>
                                <Typography className="subHeadings" variant="h6" paddingBottom={2}>
                                    Select the development platform that you will use to create your bot (Google API or GSMA API )
                                </Typography>

                                <FormControl fullWidth>
                                    <InputLabel id="developmentPlatform">Development Platform</InputLabel>
                                    <Select
                                        name="developmentPlatform"
                                        label="Development Platform"
                                        fullWidth
                                        id="developmentPlatform"
                                        error={Boolean(formik.touched.developmentPlatform && formik.errors.developmentPlatform)}
                                        helperText={formik.touched.developmentPlatform && formik.errors.developmentPlatform}
                                        defaultValue={formik.values.developmentPlatform}
                                        value={formik.values.developmentPlatform}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        disabled={true}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                },
                                            },
                                        }}
                                    >
                                        {["Google API", "GSMA API"]?.map((type, index) => (
                                            <MenuItem key={index} value={type}>
                                                {type}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {/* <FormHelperText>
                                                {formik.touched.buttonType &&
                                                    formik.errors.buttonType}
                                            </FormHelperText> */}
                                </FormControl>
                            </Grid>
                        )}
                        <Grid container padding={2}>
                            <FormControl error={Boolean(formik.errors.checkPolicy)}>
                                <Box item display={"flex"} alignItems={"center"} gap={3}>
                                    <input
                                        type="checkbox"
                                        name="checkPolicy"
                                        onChange={formik.handleChange}
                                        value={formik.values.checkPolicy ? true : false}
                                        error={Boolean(formik.touched.checkPolicy && formik.errors.checkPolicy)}
                                        checked={formik.values.checkPolicy}
                                        disabled={!isBotEditable}
                                    />
                                    <Typography className="subHeadings">I agree to launch the bot on all Indian carriers.</Typography>
                                </Box>
                                <FormHelperText>{formik.errors?.checkPolicy}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid container>
                            {isBotEditable && botDetails ? (
                                <Grid padding={1}>
                                    {state.controls.isCreateBotLoading ? (
                                        <Button variant="contained">
                                            <CircularProgress size={20} className="spinner" />
                                            Updating Bot..
                                        </Button>
                                    ) : (
                                        <Button type="submit" variant="contained">
                                            Update
                                        </Button>
                                    )}
                                </Grid>
                            ) : (
                                <Grid padding={1}>
                                    {isBotEditable && (
                                        <>
                                            {state.controls.isCreateBotLoading ? (
                                                <Button variant="contained">
                                                    <CircularProgress size={20} className="spinner" />
                                                    Creating Bot..
                                                </Button>
                                            ) : (
                                                <Button type="submit" variant="contained">
                                                    Create
                                                </Button>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            )}

                            <Grid padding={1}>
                                <Button variant="contained" color="error" onClick={() => onClickCancelButton()}>
                                    {isBotEditable ? "Cancel" : "Back"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                <AlertMessageComponent
                    message={state.controls.addNewBotErrorMessage}
                    fieldName={"addNewBotErrorMessage"}
                    handleClose={onCloseErrorMessage}
                    show={Boolean(state.controls.addNewBotErrorMessage)}
                />
            </form>
        </Grid>
    );
}
