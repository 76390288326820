import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import React, { useEffect } from "react";
import BadgeIcon from '@mui/icons-material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LaunchIcon from "@mui/icons-material/Launch";


export default function AddBusinessVerificationDetails(
    { addDocument,
        isBrandNotVerified,
        formik,
        handleChangeCheckBox,
        deleteDocument,
        onChangeBusinessAddressVerification,
        fileRef2,
        fileRef1,
        onChangeAddDocumentForAddressVerification,
        deleteDocumentForBusinessNameVerification,
        addDocumentForBusinessNameVerification,
        onChangeAddDocumentTypeForNameVerification,
        onChangeSelectDocumentForBusinessNameVerification
    }) {
    // const [reload, setReload] = useState(true);

    const DocumentList = ["PAN card of Company",
        "GST document",
        "CST / VAT /Service Tax Certificate or letter",
        "Certificate of incorporation given by Ministry of Corporate Affairs",
        "Memorandum of Association / Articles of Association", "Bank account details", "Address proof of principal place of business",
        "Property tax receipt", "Municipal Khata copy", "Electricity bill copy", "Ownership deed/document (owned property)", "Lease / rent agreement ( leased / rented property)",
        "Consent letter / NOC from the owner (in case of consent arrangement or shared property)", "Company Registration Proof", "Company Brand Registration Proof", "Other"
    ];
    const deleteDocumentForBusinessName = (index) => {

        deleteDocumentForBusinessNameVerification(index)

    }

    // useEffect(() => {

    // }, [formik.values])
    return (
        <>
            <Box marginTop={2}>
                <Box>
                    <Grid className="text-primary" sx={{ alignItems: 'center !important', display: "flex" }}>
                        <BadgeIcon /> &nbsp;
                        <Typography variant="subtitle" fontWeight={"bold"} sx={{ alignItems: 'center !important' }}>
                            Verify the Legal Business Name
                        </Typography>
                    </Grid>
                    <Typography className="my-2 text-muted w-75">
                        Please provide official documentation of your business’s legal
                        name. Upload an official document that matches the business’s
                        legal name such as a business license,article of incorporation
                        or business tax registration.
                    </Typography>
                    <Box className="d-flex item-center justify-center flex-column">
                        {formik.values.verifyBusinessName?.map((business, index) => (
                            <Box
                                key={`${business.documentType}-${index}`}
                                display={"flex"}
                                alignItems={"center"}
                                gap={2}
                                className="mt-3"
                            >
                                <FormControl className="w-50" error={Boolean(
                                    formik.errors.verifyBusinessName &&
                                    formik.errors?.verifyBusinessName[index]
                                        ?.documentType
                                )}>
                                    {/* <Typography className="fw-semibold mb-2">
                                        Document Type
                                    </Typography> */}
                                    <InputLabel id="documentTypeLabel1">
                                        Select Document Type
                                    </InputLabel>
                                    <Select

                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    boxShadow:
                                                        " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                },
                                            },
                                        }}
                                        labelId="documentTypeLabel1"
                                        label="Select Document Type"
                                        name="documentType"
                                        error={Boolean(
                                            formik.errors.verifyBusinessName &&
                                            formik.errors?.verifyBusinessName[index]
                                                ?.documentType
                                        )}
                                        helperText={
                                            formik.errors.verifyBusinessName &&
                                            formik.errors?.verifyBusinessName[index]
                                                ?.documentType
                                        }
                                        onBlur={formik.handleBlur}
                                        onChange={(e) =>
                                            onChangeAddDocumentTypeForNameVerification({
                                                index,
                                                value: e.target.value,
                                                name: e.target.name,
                                            })
                                        }
                                        defaultValue={business.documentType}
                                        disabled={!isBrandNotVerified}

                                    >



                                        {DocumentList.map((documentType, index) => (


                                            <MenuItem key={index} value={documentType}>
                                                {documentType}
                                            </MenuItem>


                                        )
                                        )}
                                    </Select>
                                    <FormHelperText style={{ color: "#E90E0EDE" }}>

                                        {formik.errors.verifyBusinessName ? formik.errors?.verifyBusinessName[index]?.documentType : ""}

                                    </FormHelperText>
                                </FormControl>

                                <FormControl style={{ width: "auto" }}>
                                    <Typography className="fw-semibold mb-2">
                                        Select File
                                    </Typography>
                                    {formik.values.verifyBusinessName[index]?.selectedFile
                                        ?.url === "" ? (
                                        <>
                                            <Button
                                                disabled={!formik.values?.verifyBusinessName[index]?.documentType || !isBrandNotVerified}
                                                variant="outlined"
                                                sx={{ width: "250px" }}

                                                onClick={() => document.getElementById(`verifyBusinessName_${index}`).click()}
                                            >
                                                Choose
                                                {/* <input
                                                  
                                                 
                                                  type="file"
                                                  hidden
                                                  defaultValue={business.url}
                                              /> */}


                                                <input
                                                    // name={formiAdd Botk.values?.verifyBusinessName[index]?.documentType.trim()}
                                                    name="selectedFile"
                                                    id={`verifyBusinessName_${index}`}
                                                    ref={fileRef1}
                                                    accept={[".docx", ".pdf"]}
                                                    multiple
                                                    onChange={(e) =>
                                                        onChangeSelectDocumentForBusinessNameVerification(
                                                            {
                                                                index,
                                                                name: e.target.name,
                                                                value: e.target.files[0],
                                                                documentType: formik.values?.verifyBusinessName[index]?.documentType.split(" ").join("")

                                                            }
                                                        )
                                                    }
                                                    type="file"
                                                    hidden
                                                />
                                            </Button>
                                            <FormHelperText sx={{ color: "#E90E0EDE" }}>
                                                {formik.errors?.verifyBusinessName ? formik.errors?.verifyBusinessName[index]
                                                    ?.selectedFile?.url : ""}

                                            </FormHelperText>
                                        </>
                                    ) :

                                        !isBrandNotVerified ?
                                            (
                                                <>
                                                    <Grid
                                                        sx={{
                                                            height: "100%",
                                                            display: "flex",
                                                            alignItems: "center",

                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                border: "1px solid gray",
                                                                height: "80%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                padding: 2,
                                                                borderRadius: "10px",
                                                            }}
                                                        >
                                                            <Typography color={"gray"}>{formik.values.verifyBusinessName[index]?.selectedFile.url.name ?? formik.values.verifyBusinessName[index]?.selectedFile.fileName ?? "Open Document"}</Typography>
                                                        </Box>

                                                        <IconButton
                                                            aria-label='close'
                                                            color='primary'
                                                            sx={{ p: 2 }}
                                                            onClick={() => {
                                                                window.open(formik.values.verifyBusinessName[index].selectedFile?.url);
                                                            }}
                                                        >
                                                            <LaunchIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </>
                                            )
                                            :

                                            (
                                                <Chip
                                                    label={
                                                        formik.values.verifyBusinessName[index]
                                                            ?.selectedFile.url.name || formik.values.verifyBusinessName[index]
                                                                ?.selectedFile.fileName || ""
                                                    }
                                                // onDelete={() =>
                                                //     formik.setFieldValue(
                                                //         `verifyBusinessName[${index}].selectedFile.url`,
                                                //         "",
                                                //         true
                                                //     )
                                                // }

                                                />
                                            )}

                                </FormControl>
                                <FormControl>

                                    <Box className="btn-container" sx={{ alignItems: "center !important" }}>
                                        {formik.values.verifyBusinessName.length > 1 && isBrandNotVerified &&

                                            <DeleteIcon
                                                variant="outlined"
                                                type="button"
                                                onClick={() => {

                                                    deleteDocumentForBusinessNameVerification(index)
                                                }
                                                }
                                                style={{ marginRight: 10, marginTop: 10, alignItems: "center !important" }}
                                                className="text-danger" />
                                            // (<Button
                                            //     variant="outlined"
                                            //     type="button"
                                            //     onClick={() =>
                                            //         deleteDocumentForBusinessNameVerification(index

                                            //         )
                                            //     }
                                            //     style={{ marginRight: 10, marginTop: 10 }}
                                            // >
                                            //     Delete Document
                                            // </Button>)
                                        }
                                    </Box>
                                </FormControl>


                            </Box>
                        ))}
                        <Box className="d-flex gap-5">
                            {formik.values.verifyBusinessName.length < 3 && (
                                <Button
                                    disabled={!isBrandNotVerified}
                                    type="button"
                                    variant="outlined"
                                    onClick={addDocumentForBusinessNameVerification}
                                    style={{ marginRight: 10, marginTop: 10 }}
                                >
                                    Add Document
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
                {/* Verify the Business Address */}
                <Box marginY={5}>
                    <Grid className="text-primary" sx={{ alignItems: 'center !important', display: "flex" }}>
                        <ContactMailIcon /> &nbsp;
                        <Typography variant="subtitle" fontWeight={"bold"} sx={{ alignItems: 'center !important' }}>
                            Verify the Business Address
                        </Typography>
                    </Grid>

                    <Typography className="mt-2 text-muted w-75">
                        Upload a document that shows both the legal name of your
                        business and the mailing address entered while filling up
                        brand details.
                    </Typography>
                    <Box className="d-flex item-center justify-center flex-column">
                        {formik.values.verifyBusinessAddress.map(
                            (business, index) => (
                                <Box
                                    key={index}
                                    display={"flex"}
                                    alignItems={'center'}
                                    gap={2}
                                    className="mt-3"
                                >
                                    <FormControl className="w-50" error={Boolean(
                                        formik.errors?.verifyBusinessAddress &&
                                        formik.errors?.verifyBusinessAddress[index]
                                            ?.documentType
                                    )}>
                                        {/* <Typography className="fw-semibold mb-2">
                                            Document Type
                                        </Typography> */}
                                        <InputLabel id="documentTypeLabel">
                                            Select Document Type
                                        </InputLabel>
                                        <Select

                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        boxShadow:
                                                            " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                    },
                                                },
                                            }}
                                            labelId="documentTypeLabel"
                                            label="Select Document Type"
                                            name="documentType"
                                            error={Boolean(
                                                formik.errors?.verifyBusinessAddress &&
                                                formik.errors?.verifyBusinessAddress[index]
                                                    ?.documentType
                                            )}
                                            helperText={
                                                formik.errors?.verifyBusinessAddress &&
                                                formik.errors?.verifyBusinessAddress[index]
                                                    ?.documentType
                                            }
                                            onBlur={formik.handleBlur}
                                            onChange={(e) =>
                                                onChangeAddDocumentForAddressVerification({
                                                    index,
                                                    value: e.target.value,
                                                    name: e.target.name,
                                                })
                                            }
                                            value={business.documentType}
                                            disabled={!isBrandNotVerified}

                                        >
                                            {DocumentList.map(
                                                (documentType, index) => (
                                                    <MenuItem key={index} value={documentType}>
                                                        {documentType}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                        <FormHelperText sx={{ color: "#E90E0EDE" }}>
                                            {formik.errors?.verifyBusinessAddress ? formik.errors?.verifyBusinessAddress[index]
                                                ?.documentType : ""}

                                        </FormHelperText>
                                    </FormControl>

                                    <FormControl style={{ width: "auto" }}>
                                        <Typography className="fw-semibold mb-2">
                                            Select File
                                        </Typography>
                                        {formik.values?.verifyBusinessAddress[index]?.selectedFile?.url === "" ? (<>
                                            <Button
                                                disabled={!formik.values?.verifyBusinessAddress[index]?.documentType || !isBrandNotVerified}
                                                variant="outlined"
                                                sx={{ width: "250px" }}
                                                // disabled={!isBrandNotVerified}
                                                // onClick={() => fileRef2.current.click()}



                                                onClick={() => document.getElementById(`verifyBusinessAddress${index}`).click()}

                                            >
                                                Choose

                                                <input
                                                    name="selectedFile"
                                                    id={`verifyBusinessAddress${index}`}
                                                    multilple
                                                    ref={fileRef2}
                                                    accept={[".docx", ".pdf"]}
                                                    onChange={(e) =>
                                                        onChangeBusinessAddressVerification({
                                                            index,
                                                            name: e.target.name,
                                                            value: e.target.files[0],
                                                            documentType: formik.values?.verifyBusinessAddress[index]?.documentType.split(" ").join("")
                                                        })
                                                    }
                                                    type="file"
                                                    hidden
                                                />
                                            </Button>
                                            <FormHelperText sx={{ color: "#E90E0EDE" }}>
                                                {formik.errors?.verifyBusinessAddress ? formik.errors?.verifyBusinessAddress[index]
                                                    ?.selectedFile?.url : ""}

                                            </FormHelperText>

                                        </>
                                        ) :


                                            !isBrandNotVerified ?
                                                (
                                                    <>
                                                        <Grid
                                                            sx={{
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems: "center",

                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    border: "1px solid gray",
                                                                    height: "80%",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    padding: 2,
                                                                    borderRadius: "10px",
                                                                }}
                                                            >
                                                                <Typography color={"gray"}>{formik.values.verifyBusinessAddress[index]?.selectedFile.url.name ?? formik.values.verifyBusinessAddress[index]?.selectedFile.fileName ?? "Open Document"}</Typography>
                                                            </Box>

                                                            <IconButton
                                                                aria-label='close'
                                                                color='primary'
                                                                sx={{ p: 2 }}
                                                                onClick={() => {
                                                                    window.open(formik.values.verifyBusinessAddress[index].selectedFile?.url);
                                                                }}
                                                            >
                                                                <LaunchIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </>
                                                )
                                                : (
                                                    <Chip
                                                        label={
                                                            formik.values?.verifyBusinessAddress[index]
                                                                ?.selectedFile?.url?.name || formik.values.verifyBusinessAddress[index]
                                                                    ?.selectedFile.fileName || ""
                                                        }
                                                    // onDelete={() =>
                                                    //     formik.setFieldValue(
                                                    //         `verifyBusinessAddress[${index}].selectedFile.url`,
                                                    //         "",
                                                    //         true
                                                    //     )
                                                    // }


                                                    />
                                                )}

                                    </FormControl>
                                    <FormControl>
                                        <Box className="btn-container" sx={{ alignItems: "center !important" }}>
                                            {formik.values.verifyBusinessAddress.length > 1 &&

                                                <DeleteIcon
                                                    variant="outlined"
                                                    type="button"
                                                    onClick={() => {


                                                        deleteDocument(index)
                                                    }
                                                    }
                                                    style={{ marginRight: 10, marginTop: 10 }}
                                                    className="text-danger" />
                                            }
                                        </Box>
                                    </FormControl>
                                </Box>
                            )
                        )}
                        <Box className="d-flex gap-5">
                            {formik.values.verifyBusinessAddress.length < 5 && (
                                <Button
                                    type="button"
                                    variant="outlined"
                                    onClick={addDocument}
                                    style={{ marginRight: 10, marginTop: 10 }}
                                    disabled={!isBrandNotVerified}
                                >
                                    Add Document
                                </Button>
                            )}
                            {/* {formik.values.verifyBusinessAddress.length > 1 && (
                                  <Button
                                      variant="outlined"
                                      type="button"
                                      onClick={() =>
                                          deleteDocument(
                                              formik.values.verifyBusinessAddress.length - 1
                                          )
                                      }
                                      style={{ marginRight: 10, marginTop: 10 }}
                                  >
                                      Delete Document
                                  </Button>
                              )} */}
                        </Box>
                    </Box>
                </Box>



            </Box >
        </>
    )

}