import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import _debounce from "lodash/debounce";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ERoles } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import { setShowAddRoleForm } from "../../../redux/userManagement/userManagementSlice";
import { getAllRolesByFilterThunk, getAllRolesByPageSizeThunk } from "../../../redux/userManagement/userManagementThunk";
import { formatDataToShowInUI } from "../../../utils/formatDataToShowInUI";
import { canShowForSuperAdminOrResellerAdmin } from "../../../utils/canShowForSuperAdminOrResellerAdmin";
import TablePaginationShowPage from "../../../utils/tablePagination";
import Page401 from "../../auth/Page401";
import FilterAllRolesModel from "./FilterRolesModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ListRoles({ searchParams, setSearchParams }) {
    const state = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(0);
    const [searchedRoleName, setSearchedRoleName] = useState("");
    const [debouncedValue, setDebouncedValue] = useState("");
    const [open, setOpen] = React.useState(false);

    const [pageSize, setPageSize] = React.useState(10);
    const { user } = useAuth();
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // setSearchParams({});
        setOpen(false);
    };

    const onClickAddRoleButton = () => {
        dispatch(setShowAddRoleForm(true));
    };

    const handlePageChange = page => {
        setCurrentPage(page);
        dispatch(getAllRolesByPageSizeThunk({ currentPage: page + 1, pageSize }));
    };

    const debouncedSearch = _debounce(value => {
        setDebouncedValue(value);
    }, 1000);

    const onClickSearch = () => {
        dispatch(
            getAllRolesByPageSizeThunk({
                searchedRoleName,
                pageSize: 10,
                currentPage,
            }),
        );
        debouncedSearch(searchedRoleName);
        return () => {
            debouncedSearch.cancel();
        };
    };

    useEffect(() => {
        // dispatch(
        //     getAllRolesByPageSizeThunk({
        //         pageSize,
        //         currentPage: currentPage + 1,
        //     }),
        // );

        dispatch(
            getAllRolesByFilterThunk({
                currentPage: currentPage + 1,
                pageSize,
                searchParams,
            }),
        );
    }, [searchedRoleName, currentPage, pageSize]);

    const handleClickSearch = (searchParams) => {
        setCurrentPage(0);
        setPageSize(10);
        setSearchParams(searchParams)
        dispatch(
            getAllRolesByFilterThunk({
                currentPage: currentPage + 1,
                pageSize,
                searchParams,
            }),
        );
        handleClose();
    };
    return canShowForSuperAdminOrResellerAdmin(user) ? (
        <Box>
            <Grid container mb={4} className='listHeader'>
                <Grid item md={6}>
                    <Typography variant='h3'>Roles</Typography>
                </Grid>
                <Grid item md={6} className="listHeaderFilter">

                    <Grid item width={"100%"} justifyContent={"flex-end"} display={"flex"}>
                        <Button sx={{ marginRight: "10px" }} variant='contained' onClick={handleClickOpen}>
                            Filter
                        </Button>

                        {/* <Button
                                        variant="contained"
                                        onClick={onClickAddRoleButton}
                                    >
                                        Add New Role
                                    </Button> */}
                    </Grid>
                </Grid>
            </Grid>
            <div>
                <Card className='border border-secondary-50'>
                    <CardContent sx={{
                        padding: 0,
                    }}>

                        <TableContainer component={Paper} className='table-container'>
                            <Table stickyHeader aria-label='simple table'>
                                <TableHead className='p-3 mb-2 row'>
                                    <TableRow>
                                        <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                            Sr No
                                        </StyledTableCell>
                                        <StyledTableCell className='col-4 tableHeaderFont' align='left'>
                                            Role Names
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.rolesListByPageSize.map((role, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell className='tableContentFont' align='center'>
                                                {currentPage * 10 + index + 1}
                                            </StyledTableCell>
                                            <StyledTableCell className='tableContentFont' align='left'>
                                                {/* {role.roleName.replace("_", " ").replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); })} */}
                                                {formatDataToShowInUI(role.roleName)}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <div></div>
                        </TableContainer>
                        {state.totalRolesListCount > 0 && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: 10
                                }}
                            >
                                <Grid
                                    style={{
                                        marginTop: "15px",
                                        padding: 5,
                                    }}
                                >
                                    Total Roles: {state.totalRolesListCount}
                                </Grid>
                                {/* <TablePagination
                                    count={state.totalRolesListCount}
                                    component='div'
                                    variant='outlined'
                                    color='primary'
                                    page={currentPage}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={pageSize}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                /> */}

                                <TablePaginationShowPage
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    colSpan={3}
                                    count={state.totalRolesListCount}
                                    rowsPerPage={pageSize}
                                    page={currentPage}
                                    slotProps={{
                                        select: {
                                            "aria-label": "Rows per page",
                                        },
                                        actions: {
                                            showFirstButton: true,
                                            showLastButton: true,
                                            slots: {
                                                firstPageIcon: FirstPageRoundedIcon,
                                                lastPageIcon: LastPageRoundedIcon,
                                                nextPageIcon: ChevronRightRoundedIcon,
                                                backPageIcon: ChevronLeftRoundedIcon,
                                            },
                                        },
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        )}
                    </CardContent>
                    {state.controls.getAllRolesErrorMessage && (
                        <Alert mt={2} mb={3} severity='warning'>
                            {state.controls.getAllRolesErrorMessage}
                        </Alert>
                    )}
                    {open && (
                        <FilterAllRolesModel
                            open={open}
                            searchParams={searchParams}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            setSearchParams={setSearchParams}
                            handleClickSearch={handleClickSearch}
                        />
                    )}
                </Card>
            </div>
        </Box>
    ) : (
        <Page401 />
    );
}
