import { Edit, ErrorOutline, Info } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import _debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import { clearErrorMessagesForAllUsers, setShowAddProviderForm } from "../../../redux/userManagement/userManagementSlice";
import {
    // getAllBranchesThunk,
    getAllClientByFilterThunk,
    getMasterRatesThunk,
} from "../../../redux/userManagement/userManagementThunk";
import { canShowForSuperAdminOrResellerAdmin } from "../../../utils/canShowForSuperAdminOrResellerAdmin";
import Page401 from "../../auth/Page401";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import ProviderBaseRatesModal from "../Provider/ProviderBaseRatesModal";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import TablePaginationShowPage from "../../../utils/tablePagination";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FetchingSpinner from "../../components/FetchingSpinner";
import { ERoles } from "../../../config";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ListProviders ({ getAllProviderList, providerTotalCount, listProviderLoading }) {
    const [currentPage, setCurrentPage] = useState(0);
    const [debouncedValue, setDebouncedValue] = useState("");
    const [open, setOpen] = React.useState(false);
    const [pageSize, setPageSize] = React.useState(10);
    const [showProviderBaseRatesModal, setShowProviderBaseRatesModal] = useState(false);
    const [providerDetailsByProviderName, setProviderDetailsByProviderName] = useState();
    const [otherFields, setOtherFields] = useState({});
    const [providerData, setProviderData] = useState();
    const [providerMasterRatesFields, setProviderMasterRatesFields] = useState({});
    const [selectProviderName, setSelectProviderName] = useState();

    const [editBaseRateLoading, setEditBaseRateLoading] = useState({ status: false, id: null });

    const navigate = useNavigate();
    const state = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();
    const { user } = useAuth();

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // setSearchParams({});
        setOpen(false);
    };

    const onClickProviderBaseRates = async ({ provider, isEditable }) => {
        try {
            setEditBaseRateLoading({ status: true, id: provider._id });
            setSelectProviderName(provider.providerName);
            dispatch(getMasterRatesThunk({ providerId: provider._id, providerCode: provider.providerCode, currentPage: currentPage + 1, pageSize }));

            let dataToSend = {
                providerId: provider._id,
                providerCode: provider.providerCode,
                providerName: provider.providerName,
                providerCurrency: provider.currency,
            };
            setEditBaseRateLoading({ status: false, id: provider._id });

            if (user?.roleName === ERoles.SUPER_ADMIN) {
                // navigate("/billing/master-rates", { state: dataToSend });
                navigate("master-rates", { state: dataToSend });
            } else {
                const dataToSend = {
                    registryId: user?.registryId,
                    resellerName: user?.whiteLabelName,
                    countryCode: user?.countryCode,
                    orgName: user?.orgDetails?.orgName,
                };
                navigate("/admin/manage-reseller-provider", { state: dataToSend });
            }
        } catch (error) {
            let errorMessage = "Failed to get master rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            // setErrorResponse(errorMessage);
        }
    };

    const handleCloseProviderBaseRatesModal = () => {
        setShowProviderBaseRatesModal(false);
    };

    const debouncedSearch = _debounce(value => {
        setDebouncedValue(value);
    }, 1000);

    useEffect(() => {
        // dispatch(
        //     getAllClientsByPageSizeThunk({
        //         currentPage: currentPage + 1,
        //         pageSize,
        //     })
        // );
        if (user) {
            const registryId = user?.registryId;
            // dispatch(
            //     getAllClientByFilterThunk({
            //         currentPage: currentPage + 1,
            //         pageSize,
            //         searchParams,
            //         registryId: registryId,
            //     }),
            // );
        }
    }, [currentPage, pageSize, user]);

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForAllUsers({ fieldName }));
    };
    const handleClickSearch = async searchParams => {
        setCurrentPage(0);

        // setSearchParams(searchParams);

        dispatch(
            getAllClientByFilterThunk({
                currentPage: currentPage + 1,
                pageSize,
                searchParams,
            }),
        );
        handleClose();
    };

    const onClickAddNewProvider = () => {
        dispatch(setShowAddProviderForm(true));
    };

    return canShowForSuperAdminOrResellerAdmin(user) ? (
        <Box>
            <Grid container mb={4} className='listHeader'>
                <Grid item md={6}>
                    <Typography variant='h3'>Providers</Typography>
                </Grid>
                {user?.roleName === ERoles.SUPER_ADMIN && (
                    <Grid item md={6} className='listHeaderFilter'>
                        <Grid width={"100%"} justifyContent={"flex-end"} display={"flex"}>
                            {/* <Button sx={{ marginRight: "10px" }} variant='contained' onClick={handleClickOpen}>
                            Filter
                        </Button> */}
                            <Button variant='contained' onClick={onClickAddNewProvider}>
                                Add New Provider
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {/* {listProviderLoading ? (
                <FetchingSpinner  />
            ) : ( */}
            <Grid>
                <Card className='border border-secondary-50'>
                    <CardContent sx={{ padding: 0 }}>
                        {listProviderLoading ? (
                            <FetchingSpinner />
                        ) : (
                            <TableContainer component={Paper} className='table-container' sx={{ maxHeight: "70vh" }}>
                                <Table stickyHeader aria-label='customized table'>
                                    <TableHead className='p-3 mb-2 row'>
                                        <TableRow>
                                            {/* <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                            Sr No
                                        </StyledTableCell> */}
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Provider Name
                                            </StyledTableCell>

                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Country
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Country Code
                                            </StyledTableCell>

                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Currency
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Is Active
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Base Rates
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='center'></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.providerList?.map((provider, index) => (
                                            <StyledTableRow key={index}>
                                                {/* <StyledTableCell className='tableContentFont' align='center'>
                                                {currentPage * pageSize + index + 1}
                                            </StyledTableCell> */}
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {provider.providerName}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {provider.country}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {provider.countryCode}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {provider.currency}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {provider.isActive ? (
                                                        <label
                                                            className='text-success centered'
                                                            style={{
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            <Grid container>
                                                                <Grid item className='active-dot'></Grid>
                                                                <Grid item>{"Active"}</Grid>
                                                            </Grid>
                                                        </label>
                                                    ) : (
                                                        <label
                                                            className='text-danger centered'
                                                            style={{
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            <Grid container>
                                                                <Grid item className='inactive-dot'></Grid>
                                                                <Grid item>{"InActive"}</Grid>
                                                            </Grid>
                                                        </label>
                                                    )}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    <Stack
                                                        direction='row'
                                                        spacing={2}
                                                        sx={{
                                                            alignItems: "left",
                                                            display: "flex",
                                                            justifyContent: "left",
                                                        }}
                                                    >
                                                        {editBaseRateLoading.status && editBaseRateLoading.id == provider._id ? (
                                                            <CircularProgress size={20} />
                                                        ) : (
                                                            <Button
                                                                onClick={() => {
                                                                    onClickProviderBaseRates({
                                                                        provider,
                                                                        isEditable: true,
                                                                    });
                                                                }}
                                                            >
                                                                <Edit />
                                                            </Button>
                                                        )}
                                                    </Stack>
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    <Stack
                                                        direction='row'
                                                        spacing={2}
                                                        sx={{
                                                            alignItems: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        {!provider.isMasterRatesFromProvider && (
                                                            <Tooltip title={`Please add Base Rates for ${provider.providerName}`}>
                                                                <ErrorOutline color='error' />
                                                            </Tooltip>
                                                        )}
                                                    </Stack>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        <Grid
                            className='my-2'
                            style={{
                                zIndex: "2",
                                display: "flex",
                                justifyContent: "space-between",
                                padding: 2,
                            }}
                        >
                            <Grid
                                style={{
                                    zIndex: "2",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    padding: 5,
                                }}
                            >
                                {/* <Grid
                                        style={{
                                            marginTop: "15px",
                                        }}
                                    >
                                        Total Clients:{state.totalClientsCount}
                                    </Grid> */}
                                {/* <Grid justifyContent={"center"} mt={3}>

                                        <TablePaginationShowPage
                                            rowsPerPageOptions={[5, 10, 25, 100]}
                                            colSpan={3}
                                            count={state.totalClientsCount}
                                            rowsPerPage={pageSize}
                                            page={currentPage}
                                            slotProps={{
                                                select: {
                                                    "aria-label": "Rows per page",
                                                },
                                                actions: {
                                                    showFirstButton: true,
                                                    showLastButton: true,
                                                    slots: {
                                                        firstPageIcon: FirstPageRoundedIcon,
                                                        lastPageIcon: LastPageRoundedIcon,
                                                        nextPageIcon: ChevronRightRoundedIcon,
                                                        backPageIcon: ChevronLeftRoundedIcon,
                                                    },
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid> */}
                            </Grid>
                        </Grid>

                        {providerTotalCount > 0 && (
                            <Grid
                                className='my-2'
                                style={{
                                    zIndex: "2",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: 2,
                                }}
                            >
                                <Grid
                                    style={{
                                        marginTop: "15px",
                                        padding: 5,
                                    }}
                                >
                                    Total Providers: {providerTotalCount}
                                </Grid>
                                <Grid>
                                    <TablePaginationShowPage
                                        rowsPerPageOptions={[5, 10, 25, 100]}
                                        colSpan={3}
                                        count={providerTotalCount}
                                        rowsPerPage={pageSize}
                                        page={currentPage}
                                        slotProps={{
                                            select: {
                                                "aria-label": "Rows per page",
                                            },
                                            actions: {
                                                showFirstButton: true,
                                                showLastButton: true,
                                                slots: {
                                                    firstPageIcon: FirstPageRoundedIcon,
                                                    lastPageIcon: LastPageRoundedIcon,
                                                    nextPageIcon: ChevronRightRoundedIcon,
                                                    backPageIcon: ChevronLeftRoundedIcon,
                                                },
                                            },
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>

                    {state.controls.getAlllCientsErrorMessage && (
                        <AlertMessageComponent
                            message={state.controls.getAlllCientsErrorMessage}
                            fieldName={"getAlllCientsErrorMessage"}
                            handleClose={onCloseErrorMessage}
                            show={Boolean(state.controls.getAlllCientsErrorMessage)}
                        />
                    )}
                    {/* {showProviderBaseRatesModal && (
                        <ProviderBaseRatesModal
                            open={showProviderBaseRatesModal}
                            handleClose={handleCloseProviderBaseRatesModal}

                            updateCountryRateCardModel={editCountryRateDetails}
                            setEditCountryRateDetails={setEditCountryRateDetails}
                            countryRateDetails={countryRateDetails}
                            selectProviderName={selectProviderName}
                            getAllMasterRates={getAllMasterRates}
                            otherFields={otherFields}
                            providerDetailsByProviderName={providerDetailsByProviderName}
                        />
                    )} */}

                    {showProviderBaseRatesModal && (
                        <ProviderBaseRatesModal
                            open={showProviderBaseRatesModal}
                            handleClose={handleCloseProviderBaseRatesModal}
                            providerData={providerData}
                            // selectProviderName={selectProviderName}
                            providerMasterRatesFields={providerMasterRatesFields}
                            providerDetailsByProviderName={providerDetailsByProviderName}
                            otherFields={otherFields}
                            // getAllCustomizedRate={getAllCustomizedRate}
                        />
                    )}
                </Card>
            </Grid>
        </Box>
    ) : (
        <Page401 />
    );
}
