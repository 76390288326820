import { ArrowBackIos, CurrencyRupee, Edit, History } from "@mui/icons-material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Dialog,
    Grid,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import { clearErrorMessagesForAllUsers, setAllCustomizedData, setErrorMessageForUser } from "../../../redux/userManagement/userManagementSlice";
import { getAllProvidersThunk } from "../../../redux/botManagement/botManagementThunk";
import { ERoles, OtherFields } from "../../../config";
import FetchingSpinner from "../../components/FetchingSpinner";
import NoRecords from "../../components/NoRecords";
import TablePaginationShowPage from "../../../utils/tablePagination";
import UpdateRateByClientIdModal from "./UpdateRateByClientIdModal";
import ViewHistoryOfCustomizedRates from "../RateCardComponents/ViewHistoryOfCustomizedRates";
import IncreaseDecreaseCustomizeRateForSelectedClients from "./IncreaseDecreaseCustomizeRateForSelectedClients";
import AlertMessageComponent from "../../components/AlertMessageComponent";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
    },
}));

const StyledTableCellSticky = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
        backgroundColor: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function OrgApplicableRates() {
    const userState = useSelector(state => state.userManagementSlice);
    const botState = useSelector(state => state.botManagementSlice);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [clientData, setClientData] = useState({});
    const [updateCustomizedRateModel, setUpdateCustomizedRateModel] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [errorResponse, setErrorResponse] = useState("");
    const [selectProviderName, setSelectProviderName] = useState({});
    const [providerDetailsByProviderName, setProviderDetailsByProviderName] = useState();
    const [otherFields, setOtherFields] = useState({});
    const [searchedClientName, setSearchedClientName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [getAllProviderList, setGetAllProviderList] = useState();
    const [openUpdateRateCardForSelectedClient, setOpenUpdateRateCardForSelectedClient] = useState(false);
    const [viewHistoryOfCustomizedRates, setViewHistoryOfCustomizedRates] = useState(false);
    const { user } = useAuth();
    const providerCode = user?.orgDetails?.supportedProviders[0]?.providerCode;

    const [pageSize, setPageSize] = React.useState(10);
    const [selected, setSelected] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [customizeRateCardForClients, setCustomizeRateCardForClients] = useState([]);
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };


    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // setSearchParams({});
        setOpen(false);
    };
    const onChangeSearchedClientName = e => {
        setSearchedClientName(e.target.value);
    };

    const onClickEditCustomizedRate = client => {
        setClientData(client);
        setUpdateCustomizedRateModel(true);
    };

    const handleCloseEditCustomizedRate = () => {
        setUpdateCustomizedRateModel(false);
    };

    const onClickViewHistoryOfCustomizedRate = client => {
        setViewHistoryOfCustomizedRates(true);
        setClientData(client);
    };

    const closeViewHistoryCustomizedRates = () => {
        setViewHistoryOfCustomizedRates(false);
    };

    const openViewHistoryCustomizedRates = () => {
        setViewHistoryOfCustomizedRates(true);
    };


    // const getAllCustomizedRate = async () => {
    //     try {
    //         setIsLoading(true);
    //         const token = sessionStorage.getItem("accessToken");
    //         const response = await apiGatewayAxiosInstance.get(`/user_management/customized-rate/get-by-client-id`, {
    //             headers: { token },
    //             params: {
    //                 providerId: user?.orgDetails?.supportedProviders[0]?.providerId,
    //                 providerCode: user?.orgDetails?.supportedProviders[0]?.providerCode,
    //                 pageSize: pageSize,
    //                 currentPage: currentPage + 1,
    //                 orgId: user.orgDetails._id,
    //                 registryId: user.registryId
    //             },
    //         });
    //         if (response.status === 200) {
    //             dispatch(setAllCustomizedData(response.data.data.clientData));
    //             setTotalCount(response.data.data.totalCount);
    //             setIsLoading(false);
    //         } else {
    //             // setIsLoading(false);
    //             throw new Error(response.data);
    //         }
    //     } catch (error) {
    //         setIsLoading(false);
    //         // let errorMessage = "Failed to get all customized rates.";

    //         // if (error.response) {
    //         //     errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
    //         // }
    //         // setErrorResponse(errorMessage);
    //     }
    // };

    const checkCurrentRate = (fromDate, toDate) => {
        //This function is to show current rates
        const currentDate = new Date();
        fromDate = fromDate ? new Date(fromDate) : null;
        toDate = toDate ? new Date(toDate) : null;

        // if (!isNaN(fromDate) && !isNaN(toDate)) {

        if (currentDate >= fromDate && currentDate <= toDate) {
            // Condition for Future present rates
            return true;
        } else if (!isNaN(fromDate) && (!toDate || isNaN(toDate)) && currentDate >= fromDate) {
            // Condition for current rates
            return true;
        } else {
            return false;
        }
    };

    const getAllCustomizedRate = async () => {
        try {
            setIsLoading(true);

            const token = sessionStorage.getItem("accessToken");
            const response = await apiGatewayAxiosInstance.get(`/user_management/customized-rate/get-by-client-id`, {
                headers: { token },
                params: {
                    providerId: user?.orgDetails?.supportedProviders[0]?.providerId,
                    providerCode: user?.orgDetails?.supportedProviders[0]?.providerCode,
                    pageSize: pageSize,
                    currentPage: currentPage + 1,
                    orgId: user.orgDetails._id,
                    registryId: user.registryId
                },
            });
            if (response.status === 200) {
                const clientData = response.data.data.clientData;
                await getHistoryOfClientCustomizedRates({ providerId: user?.orgDetails?.supportedProviders[0]?.providerId, providerCode: user?.orgDetails?.supportedProviders[0]?.providerCode, orgId: user.orgDetails._id, registryId: user.registryId, clientData, countryCode: user?.countryCode });
                setTotalCount(response.data.data.totalCount);
                setIsLoading(false);
            } else {
                // setIsLoading(false);
                throw new Error(response.data);
            }
        } catch (error) {
            setIsLoading(false);
            let errorMessage = "Failed to get all customised rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    const getHistoryOfClientCustomizedRates = async ({ providerId, providerCode, countryCode, orgId, clientData }) => {
        const response = await apiGatewayAxiosInstance.get(`/billing_management/customized-rate-card/get-provider-customized-rate-history`, {
            params: {
                providerId: user?.orgDetails?.supportedProviders[0]?.providerId,
                providerCode: user?.orgDetails?.supportedProviders[0]?.providerCode,
                pageSize: pageSize,
                currentPage: currentPage,
                orgId: user.orgDetails._id,
                registryId: user.registryId,
                countryCode: user.countryCode,

            },
        });
        let clientCustomizedRates = response.data.data.findCurrentRates;
        let currentCustomizedRate = null;
        for (let index = 0; index < clientCustomizedRates.length; index++) {
            const element = clientCustomizedRates[index];
            let isCurrentCustomizedRate = checkCurrentRate(element.effectiveFrom, element.effectiveTo);
            if (isCurrentCustomizedRate) {
                currentCustomizedRate = element;
            }
        }
        if (userState.customizedRate && currentCustomizedRate) {
            clientData[0] = { ...clientData[0], currentRate: currentCustomizedRate };
            dispatch(setAllCustomizedData(clientData));
            // setClientCurrentCustomizedRate(dataToSave);
        }

        setTotalCount(response.data.data.totalCount);
    };

    useEffect(async () => {
        if (user?.orgDetails) {
            await getAllCustomizedRate();
        }
    }, [currentPage, pageSize, user]);




    const handleSelectAllClick = () => {
        if (selected.length === userState?.customizedRate?.length) {
            setSelected([]);
            setCustomizeRateCardForClients([]);
            for (let index = 0; index < userState?.customizedRate.length; index++) {
                const element = userState?.customizedRate[index];
            }
        } else {
            setSelected(Array.from({ length: userState?.customizedRate?.length }, (_, index) => index));
            setCustomizeRateCardForClients([...userState?.customizedRate]);
        }
    };

    const handleSelect = (index, client, e) => {
        const selectedIndex = selected.indexOf(index);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        if (e.target.checked) {
            setCustomizeRateCardForClients([...customizeRateCardForClients, client]);
        } else {
            const a = customizeRateCardForClients.filter(
                selectedItem => selectedItem._id !== client._id && selectedItem.rate.countryCode === client.rate.countryCode,
            );
            setCustomizeRateCardForClients(a);
        }
    };

    const getProviderFieldsDetails = async ({ providerId, providerCode }) => {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/config/get-provider-details-by-provider`, {
            params: { providerId: providerId, providerCode: providerCode },
        });

        if (response.status === 200) {
            setProviderDetailsByProviderName(response.data.data);
            let providerOtherFieldToNumber = {};
            if (Object.keys(response.data.data?.otherFields).length > 0) {
                Object.keys(response.data.data?.otherFields).map((fieldElement, index) => {
                    providerOtherFieldToNumber[fieldElement] = 0;
                });
            }

            setOtherFields(providerOtherFieldToNumber);
        }
    };


    const updateRateForSelectedClients = () => {
        setOpenUpdateRateCardForSelectedClient(true);
    };
    const handleCloseUpdateRateCardForSelectedClient = () => {
        setOpenUpdateRateCardForSelectedClient(false);
    };


    return (
        <>
            <Box width={"100%"}>
                <Grid container mb={4} className='listHeader'>
                    <Grid item md={6}>
                        <Typography variant='h3'>
                            Applicable Rates
                        </Typography>

                    </Grid>

                </Grid>
                <Grid>
                    <Card className='border border-secondary-50' sx={{ padding: 0, margin: 0 }}>
                        <CardContent sx={{ padding: 0, margin: 0 }}>
                            {selectProviderName ? (
                                isLoading || !user?.orgDetails ? (
                                    <FetchingSpinner />
                                ) : (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            // margin: "10px",
                                            minWidth: "100%",
                                            // maxWidth: "90rem",
                                            overflowX: "scroll",
                                        }}
                                    >
                                        {userState.customizedRate.length > 0 ? (
                                            <TableContainer component={Paper} className='table-container'>
                                                <Table aria-label='customized table'>
                                                    <>
                                                        <TableHead className='p-3 mb-2 row'>
                                                            <TableRow>


                                                                <StyledTableCell className=' tableHeaderFont' align='left'>
                                                                    Organisation Name
                                                                </StyledTableCell>

                                                                <StyledTableCell className=' tableHeaderFont' align='left'>
                                                                    Country
                                                                </StyledTableCell>
                                                                <StyledTableCell className=' tableHeaderFont' align='left'>
                                                                    Text Message Rate (INR)
                                                                </StyledTableCell>
                                                                <StyledTableCell className=' tableHeaderFont' align='left'>
                                                                    Multi Media Message Rate (INR)
                                                                </StyledTableCell>

                                                                <StyledTableCell className=' tableHeaderFont' align='left'>
                                                                    A2P Session Conversation Rate (INR)
                                                                </StyledTableCell>
                                                                <StyledTableCell className=' tableHeaderFont' align='left'>
                                                                    Feedback Rate (INR)
                                                                </StyledTableCell>

                                                                {userState.customizedRate && userState.customizedRate[0]?.rate?.baseRate &&
                                                                    Object.keys(userState.customizedRate[0]?.rate?.baseRate?.otherFields).map(
                                                                        (provider, index) => (
                                                                            <StyledTableCell className='col-1 tableHeaderFont'>
                                                                                {OtherFields[provider] ?? provider} (INR)
                                                                            </StyledTableCell>
                                                                        ),
                                                                    )}


                                                                <StyledTableCell className=' tableHeaderFont' align='left'>
                                                                    Payment Type
                                                                </StyledTableCell>


                                                                <StyledTableCell className=' tableHeaderFont' align='left'>
                                                                    History
                                                                </StyledTableCell>

                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            <>
                                                                {userState.customizedRate?.map((client, index) => (
                                                                    <StyledTableRow key={index}>
                                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                                            {client?.name ? client?.name : client?.resellerName}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                                            {client?.rate?.countryName}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                                            <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                            {client?.currentRate?.customizedProviderRate[
                                                                                providerCode
                                                                            ]?.A2pMessageRate?.textMessageRate?.toFixed(4) ?? "-"}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                                            <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                            {client?.currentRate?.customizedProviderRate[
                                                                                providerCode
                                                                            ]?.A2pMessageRate?.multiMediaMessageRate?.toFixed(4) ?? "-"}
                                                                        </StyledTableCell>{" "}

                                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                                            <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                            {client?.currentRate?.customizedProviderRate[
                                                                                providerCode
                                                                            ]?.A2pMessageRate
                                                                                ?.A2pSessionConversationRate?.toFixed(4) ?? "-"}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                                            <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                            {client?.currentRate?.customizedProviderRate[
                                                                                providerCode
                                                                            ]?.A2pMessageRate?.feedbackRate?.toFixed(4) ?? "-"}
                                                                        </StyledTableCell>
                                                                        {userState.customizedRate && userState.customizedRate[0]?.rate?.baseRate &&
                                                                            Object.keys(userState.customizedRate[0]?.rate?.baseRate?.otherFields).map(
                                                                                (provider, index) => (
                                                                                    <StyledTableCell
                                                                                        className='tableContentFont'
                                                                                        align='left'
                                                                                    >
                                                                                        <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                                        {client?.currentRate?.customizedProviderRate[
                                                                                            providerCode
                                                                                        ]?.otherFields[provider]?.toFixed(4) ?? "-"}
                                                                                    </StyledTableCell>
                                                                                ),
                                                                            )}

                                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                                            {client?.rate?.billingPaymentType ??
                                                                                client?.rate?.billingDetails?.billingPaymentType ??
                                                                                "-"}
                                                                        </StyledTableCell>

                                                                        <StyledTableCell>
                                                                            <Tooltip title='History Customize Rate'>
                                                                                <IconButton
                                                                                    onClick={() => onClickViewHistoryOfCustomizedRate(client)}
                                                                                >
                                                                                    <History />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </StyledTableCell>

                                                                    </StyledTableRow>
                                                                ))}
                                                            </>
                                                        </TableBody>
                                                    </>
                                                </Table>
                                            </TableContainer>
                                        ) : (

                                            <NoRecords />
                                        )}
                                    </Box>
                                )
                            ) : (
                                <Grid sx={{ display: "flex", justifyContent: "left" }}>
                                    <Typography variant='h6'>
                                        <b>PLEASE SELECT PROVIDER</b>
                                    </Typography>
                                </Grid>
                            )}

                            {(user?.roleName === ERoles.SUPER_ADMIN || user?.roleName === ERoles.RESELLER_ADMIN) && totalCount > 0 && (
                                <Grid
                                    className='my-2'
                                    style={{
                                        zIndex: "2",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: 2,
                                    }}
                                >
                                    <Grid
                                        style={{
                                            marginTop: "15px",
                                            padding: 5,
                                        }}
                                    >
                                        Total Customize Rates: {totalCount}
                                    </Grid>
                                    <Grid>
                                        <TablePaginationShowPage
                                            rowsPerPageOptions={[1, 5, 10, 25, 100]}
                                            colSpan={3}
                                            count={totalCount}
                                            rowsPerPage={pageSize}
                                            page={currentPage}
                                            slotProps={{
                                                select: {
                                                    "aria-label": "Rows per page",
                                                },
                                                actions: {
                                                    showFirstButton: true,
                                                    showLastButton: true,
                                                    slots: {
                                                        firstPageIcon: FirstPageRoundedIcon,
                                                        lastPageIcon: LastPageRoundedIcon,
                                                        nextPageIcon: ChevronRightRoundedIcon,
                                                        backPageIcon: ChevronLeftRoundedIcon,
                                                    },
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </CardContent>
                        <CardActions sx={{ padding: 0, margin: 0 }}></CardActions>
                    </Card>
                </Grid>
                <AlertMessageComponent message={errorResponse} handleClose={() => setErrorResponse("")} show={Boolean(errorResponse)} />
            </Box>

            {viewHistoryOfCustomizedRates && (
                <ViewHistoryOfCustomizedRates
                    open={openViewHistoryCustomizedRates}
                    handleClose={closeViewHistoryCustomizedRates}
                    clientData={clientData}
                />
            )}

        </>
    );
}
