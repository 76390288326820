import { createSlice } from "@reduxjs/toolkit";
import {
    addNewCampaignThunk,
    getAllCampaignByFilterThunk,
    getAllCampaignsByPageSize,
    getCampaignReportData,
    runCampaign,
    sendTestMessageThunk,
} from "./campaignManagmentThunk";

export const campaignManagmentSlice = createSlice({
    name: "campaignManagmentSlice",
    initialState: {
        campaignList: [],
        campaignListByPageSize: [],
        totalCampaignsCount: 0,
        capaignReportDataState: { totalCount: 0, TTL_EXPIRATION_REVOKED: 0, PENDING: 0, SENT: 0, DELIVERED: 0, READ: 0, RETRY: 0, FAILED: 0, SMS: 0, CAMPAIGN_LAST_TIME: 0, CAMPAIGN_FIRST_TIME: 0 },
        isLoadingCapaignReport: { totalCount: false, TTL_EXPIRATION_REVOKED: false, PENDING: false, SENT: false, DELIVERED: false, READ: false, RETRY: false, FAILED: false, SMS: false, CAMPAIGN_LAST_TIME: false, CAMPAIGN_FIRST_TIME: false },
        controls: {
            showAddCampaignForm: false,
            isRunCampaignLoading: { status: "", id: "" },
            isCreatingCampaignLoading: false,
            isGetAllCampaignsLoading: false,
            showViewCampaignForm: false,
            isSendingTestMessage: false,
            sentTestMessage: "",
            // Error messages
            addNewCampaignErrorMessage: "",
            getAllCampaignsByPageSizeErrorMessage: "",
            runCampaignServerErrorMessage: "",
            getSampleCsvSignedUrlErrorMessage: "",
            getAllocatedAmountForCampaignErrorMessage: "",
            getCampaignReportDetailsErrorMessage: "",
            getCampaignReportCSVDataErrorMessage: "",
            sendTestMessageError: "",
        },
    },
    reducers: {
        setShowAddCampaignForm: (state, action) => {
            state.controls.showAddCampaignForm = action.payload;
            state.controls.runCampaignServerErrorMessage = "";
            state.controls.addNewCampaignErrorMessage = "";
            state.controls.isRunCampaignLoading = { status: "", id: "" };
        },
        setShowViewCampaignForm: (state, action) => {
            state.controls.showViewCampaignForm = action.payload;
            state.controls.runCampaignServerErrorMessage = "";
            state.controls.addNewCampaignErrorMessage = "";
            state.controls.isRunCampaignLoading = { status: "", id: "" };
        },
        setShowRunCampaingLoading: (state, action) => {
            state.controls.isRunCampaignLoading = { status: "", id: "" };
        },
        clearErrorMessagesForCampaigns: (state, action) => {
            state.controls[`${action.payload.fieldName}`] = "";
        },
        setGetSampleCsvSignedUrlErrorMessage: (state, action) => {
            state.controls.addNewCampaignErrorMessage = action.payload;
        },
        setErroMessageForField: (state, action) => {
            state.controls[`${action.payload.fieldName}`] = action.payload.errorMessage;
        },
        clearErrorMessagesForSendTestMessage: (state, action) => {
            state.controls[`${action.payload.fieldName}`] = "";
            state.controls.isSendingTestMessage = false;
            state.controls.sendTestMessageError = false;
        },
    },

    extraReducers: (builder) => {
        //#region -------------------------- Add New Campaign ---------------------------------------------------

        builder.addCase(addNewCampaignThunk.pending, (state, action) => {
            state.controls.isCreatingCampaignLoading = true;
        });

        builder.addCase(addNewCampaignThunk.fulfilled, (state, action) => {
            state.controls.isCreatingCampaignLoading = false;
            state.controls.showAddCampaignForm = false;
            state.controls.addNewCampaignErrorMessage = "";
        });

        builder.addCase(addNewCampaignThunk.rejected, (state, action) => {
            state.controls.isCreatingCampaignLoading = false;
            state.controls.addNewCampaignErrorMessage = action.error.message;
        });
        //#endregion

        //#region -------------------------- Get All Campaigns By Page Size -------------------------------------

        builder.addCase(getAllCampaignsByPageSize.pending, (state, action) => {
            state.controls.isGetAllCampaignsLoading = true;
        });
        builder.addCase(
            getAllCampaignsByPageSize.fulfilled,
            (state, action) => {
                state.controls.isGetAllCampaignsLoading = false;
                state.campaignListByPageSize = action.payload.allCampaigns;
                state.totalCampaignsCount = action.payload.totalCount;
            }
        );

        builder.addCase(getAllCampaignsByPageSize.rejected, (state, action) => {
            state.controls.isGetAllCampaignsLoading = false;
            state.controls.getAllCampaignsByPageSizeErrorMessage =
                action.error.message;
            state.campaignListByPageSize = [];
            state.totalCampaignsCount = 0;
        });

        //#region -------------------------- Get All Campaigns By Filter -------------------------------------

        builder.addCase(
            getAllCampaignByFilterThunk.pending,
            (state, action) => {
                state.controls.isGetAllCampaignsLoading = true;
            }
        );
        builder.addCase(
            getAllCampaignByFilterThunk.fulfilled,
            (state, action) => {
                state.controls.isGetAllCampaignsLoading = false;
                state.campaignListByPageSize = action.payload.allCampaigns;
                state.totalCampaignsCount = action.payload.totalCount;
            }
        );

        builder.addCase(
            getAllCampaignByFilterThunk.rejected,
            (state, action) => {
                state.controls.isGetAllCampaignsLoading = false;
                state.controls.getAllCampaignsByPageSizeErrorMessage =
                    action.error.message;
                state.campaignListByPageSize = [];
                state.totalCampaignsCount = 0;
            }
        );
        //#endregion

        //#region -------------------------- Run Campaign -------------------------------------

        builder.addCase(runCampaign.pending, (state, action) => {
            state.controls.runCampaignServerErrorMessage = "";
            state.controls.isRunCampaignLoading = {
                status: "pending",
                id: action.meta.arg.campaignId,
            };
        });
        builder.addCase(runCampaign.fulfilled, (state, action) => {
            state.controls.runCampaignServerErrorMessage = "";
            state.controls.isRunCampaignLoading = {
                status: "success",
                id: action.meta.arg.campaignId,
            };
        });

        builder.addCase(runCampaign.rejected, (state, action) => {
            state.controls.runCampaignServerErrorMessage = action.error.message;
            state.controls.isRunCampaignLoading = {
                status: "rejected",
                id: action.meta.arg.campaignId,
            };
        });
        //#endregion

        //#region -------------------------- Get Campaign Report Data Thunk -------------------------------------

        builder.addCase(getCampaignReportData.pending, (state, action) => {
            state.isLoadingCapaignReport = { totalCount: true, TTL_EXPIRATION_REVOKED: true, PENDING: true, SENT: true, DELIVERED: true, READ: true, RETRY: true, FAILED: true, SMS: true, CAMPAIGN_LAST_TIME: true, CAMPAIGN_FIRST_TIME: true };
            state.capaignReportDataState = { totalCount: 0, TTL_EXPIRATION_REVOKED: 0, PENDING: 0, SENT: 0, DELIVERED: 0, READ: 0, RETRY: 0, FAILED: 0, SMS: 0, CAMPAIGN_LAST_TIME: 0, CAMPAIGN_FIRST_TIME: 0 };

        });
        builder.addCase(getCampaignReportData.fulfilled, (state, action) => {
            state.isLoadingCapaignReport[Object.keys(action.payload.campaignReportDetails)[0]] = false;
            state.capaignReportDataState = { ...state.capaignReportDataState, ...action.payload.campaignReportDetails }
        });

        builder.addCase(getCampaignReportData.rejected, (state, action) => {
            if (action.meta.arg['fetchCampgainStatus'] === 'TOTAL_COUNT') {
                state.isLoadingCapaignReport.totalCount = false;
            }
            state.isLoadingCapaignReport[action.meta.arg['fetchCampgainStatus']] = false;
        });
        //#endregion

        //#region -------------------------- Send Test Message -------------------------------------

        builder.addCase(sendTestMessageThunk.pending, (state, action) => {
            state.controls.isSendingTestMessage = true;
        });
        builder.addCase(sendTestMessageThunk.fulfilled, (state, action) => {
            state.controls.isSendingTestMessage = false;
            state.controls.sentTestMessage = action.payload;
        });

        builder.addCase(sendTestMessageThunk.rejected, (state, action) => {
            state.controls.isSendingTestMessage = false;
            state.controls.sendTestMessageError = action.error.message;
        });
        //#endregion
    },
});

export const {
    setShowViewCampaignForm,
    setShowAddCampaignForm,
    clearErrorMessagesForCampaigns,
    setGetSampleCsvSignedUrlErrorMessage,
    setShowRunCampaingLoading,
    setErroMessageForField,
    sendTestMessageError,
    clearErrorMessagesForSendTestMessage,
    sentTestMessage,
} = campaignManagmentSlice.actions;
