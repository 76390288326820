import {
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    Link,
    TextField,
    Typography,
} from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorMessagesForAllUsers, setShowAddProviderForm } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import AddVariablesForSendMessage from "./AddVariablesForSendMessage";
import AddVariablesForBulkCapCheckURL from "./AddVariablesForBulkCapCheckURL";

export default function AddProvider({ providerData, isClientEditable, getAllProviderList }) {
    const state = useSelector(state => state.userManagementSlice);
    const botState = useSelector(state => state.botManagementSlice);
    const [errorResponse, setErrorResponse] = useState("");
    const [isUpdateClientLoading, setIsUpdateClientLoading] = useState(false);
    const dispatch = useDispatch();
    const [providerFields, setProviderFields] = useState({
        providerId: "",
        providerCode: "",
        providerName: "",
    });

    const [isAddProviderLoading, setIsAddProviderLoading] = useState(false);

    const onClickCancelButton = e => {
        // setCustomizeRatesByClientId(null);
        // setClientDetails({});
        dispatch(setShowAddProviderForm(false));
    };

    const initialValues = {
        providerCode: providerData ? providerData.providerCode : "",
        providerName: providerData ? providerData.providerName : "",
        countryCode: providerData ? providerData.countryCode : "",
        country: providerData ? providerData.country : "",
        currency: providerData ? providerData.currency : "",
        description: providerData ? providerData.description : "",
        authTokenURL: providerData ? providerData.authTokenURL : "",
        clientAPIKey: providerData ? providerData.clientAPIKey : "",
        clientSecretKey: providerData ? providerData.clientSecretKey : "",
        totalTPSFromProvider: providerData ? providerData.totalTPSFromProvider : "",
        totalTPMFromProvider: providerData ? providerData.totalTPMFromProvider : "",
        totalNoPerApiCallForTPM: providerData ? providerData.totalNoPerApiCallForTPM : "",
        // rbmBotId: providerData ? providerData.rbmBotId : "",
        // rbmSecretKey: providerData ? providerData.rbmSecretKey : "",
        templateStatusWebhookURL: providerData ? providerData.templateStatusWebhookURL : "",
        messageStatusWebhookURL: providerData ? providerData.messageStatusWebhookURL : "",
        sendMessageURL: providerData ? providerData.sendMessageURL : "",
        sendMessageVariables: providerData ? providerData.sendMessageVariables : [],
        bulkCapCheckURL: providerData ? providerData.bulkCapCheckURL : "",
        bulkCapCheckURLVariables: providerData ? providerData.bulkCapCheckURLVariables : []
    };

    // const updateClientDetails = async () => {
    //     try {
    //         setIsUpdateClientLoading(true);

    //         let providerDateToSend = {
    //             _id: providerData?._id,
    //             providerCode: formik.values.providerCode,
    //             providerName: formik.values.providerName,
    //             countryCode: formik.values.countryCode,
    //             country: formik.values.country,
    //             currency: formik.values.currency,
    //             description: formik.values.description,
    //             authTokenURL: formik.values.authTokenURL,
    //             clientAPIKey: formik.values.clientAPIKey,
    //             clientSecretKey: formik.values.clientSecretKey,
    //             totalTPSFromProvider: formik.values.totalTPSFromProvider,
    //             totalTPMFromProvider: formik.values.totalTPMFromProvider,
    //             totalNoPerApiCallForTPM: formik.values.totalNoPerApiCallForTPM,
    //             rbmBotId: formik.values.rbmBotId,
    //             rbmSecretKey: formik.values.rbmSecretKey,
    //             sendMessageURL: formik.values.sendMessageURL,
    //             bulkCapCheckURL: formik.values.bulkCapCheckURL,
    //             templateStatusWebhookURL: formik.values.templateStatusWebhookURL,
    //             messageStatusWebhookURL: formik.values.messageStatusWebhookURL,
    //         };

    //         const response = await apiGatewayAxiosInstance.post(`/billing_management/clients/update-reseller`, providerDateToSend);

    //         if (response.status === 200) {
    //             setIsUpdateClientLoading(false);
    //             onClickCancelButton();
    //         } else {
    //             throw new Error(response.data.message);
    //         }
    //     } catch (error) {
    //         setIsUpdateClientLoading(false);
    //         let errorMessage = "Failed to update reseller.";

    //         if (error.response) {
    //             errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
    //         }
    //         setErrorResponse(errorMessage);
    //     }
    // };

    const validateIfValueIsEmpty = fieldName => {
        const fieldValue = formik.values[fieldName];
        if (fieldValue === "" || fieldValue === null) {
            formik.setFieldError(fieldName, `Required`);
            return true;
        } else {
            return false;
        }
    };

    const validateProviderDetails = () => {
        let result = true;

        if (validateIfValueIsEmpty("providerCode")) {
            result = false;
        }

        if (validateIfValueIsEmpty("providerName")) {
            result = false;
        }

        if (validateIfValueIsEmpty("countryCode")) {
            result = false;
        }

        if (validateIfValueIsEmpty("country")) {
            result = false;
        }

        if (validateIfValueIsEmpty("currency")) {
            result = false;
        }

        // if (validateIfValueIsEmpty("description")) {
        //     result = false;
        // }

        if (validateIfValueIsEmpty("authTokenURL")) {
            result = false;
        }

        if (validateIfValueIsEmpty("clientAPIKey")) {
            result = false;
        }

        if (validateIfValueIsEmpty("clientSecretKey")) {
            result = false;
        }

        if (validateIfValueIsEmpty("totalTPSFromProvider")) {
            result = false;
        }

        if (validateIfValueIsEmpty("totalTPMFromProvider")) {
            result = false;
        }

        if (validateIfValueIsEmpty("totalNoPerApiCallForTPM")) {
            result = false;
        }

        // if (validateIfValueIsEmpty("rbmBotId")) {
        //     result = false;
        // }

        // if (validateIfValueIsEmpty("rbmSecretKey")) {
        //     result = false;
        // }

        if (validateIfValueIsEmpty("sendMessageURL")) {
            result = false;
        }

        if (validateIfValueIsEmpty("bulkCapCheckURL")) {
            result = false;
        }

        if (validateIfValueIsEmpty("templateStatusWebhookURL")) {
            result = false;
        }

        if (validateIfValueIsEmpty("messageStatusWebhookURL")) {
            result = false;
        }

        if (validateIfValueIsEmpty("sendMessageURL")) {
            result = false;
        }

        return result;
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForAllUsers({ fieldName }));
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                setIsAddProviderLoading(true);
                const validatePersonalDetailsResult = validateProviderDetails();

                if (validatePersonalDetailsResult) {
                    let providerDetails = {
                        providerCode: values.providerCode,
                        providerName: values.providerName,
                        countryCode: values.countryCode,
                        country: values.country,
                        currency: values.currency,
                        description: values.description,
                        authTokenURL: values.authTokenURL,
                        clientAPIKey: values.clientAPIKey,
                        clientSecretKey: values.clientSecretKey,
                        totalTPSFromProvider: values.totalTPSFromProvider,
                        totalTPMFromProvider: values.totalTPMFromProvider,
                        totalNoPerApiCallForTPM: values.totalNoPerApiCallForTPM,
                        // rbmBotId: values.rbmBotId,
                        // rbmSecretKey: values.rbmSecretKey,
                        templateStatusWebhookURL: values.templateStatusWebhookURL,
                        messageStatusWebhookURL: values.messageStatusWebhookURL,
                        sendMessageURL: values.sendMessageURL,
                        sendMessageVariables: values.sendMessageVariables,
                        bulkCapCheckURL: values.bulkCapCheckURL,
                        bulkCapCheckURLVariables: values.bulkCapCheckURLVariables
                    };

                    const response = await apiGatewayAxiosInstance.post(`/billing_management/provider-csv-fields/create-provider`, providerDetails);
                    await getAllProviderList();
                    dispatch(setShowAddProviderForm(false));
                }
            } catch (error) {
                // const message = error.message || "Something went wrong";
                // setStatus({ success: false });
                // setErrors({ submit: message });
                // setSubmitting(false);
                setIsAddProviderLoading(false);
                let errorMessage = "Failed to add Provider.";

                if (error.response) {
                    errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
                }
                setErrorResponse(errorMessage);
            }
            setIsAddProviderLoading(false);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid item md={6} display={"flex"} alignItems={"center"}>
                <IconButton color='secondary' onClick={onClickCancelButton} size='medium'>
                    <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                </IconButton>

                <Grid role='presentation'>
                    <Breadcrumbs aria-label='breadcrumb' fontSize='large'>
                        <Link
                            underline='hover'
                            color='inherit'
                            className='breadcrumbItem breadcrumbFontSize'
                            // href="/rcs-management/manage-bots/"
                            onClick={onClickCancelButton}
                        >
                            Provider
                        </Link>
                        {providerData?.name && isClientEditable ? (
                            <Typography className='breadcrumbItem' color='text.primary'>
                                Update {providerData.resellerDetails.orgName} Details
                            </Typography>
                        ) : (
                            <Typography className='breadcrumbItem' color='text.primary'>
                                Add New Provider
                            </Typography>
                        )}
                    </Breadcrumbs>
                </Grid>
            </Grid>

            <Card sx={{ padding: "0.8rem" }}>
                <CardContent sx={{ padding: "10px" }}>
                    <Grid className='d-flex' gap={5}>
                        <FormControl fullWidth className='field-spacing' error={Boolean(formik.touched.resellerName && formik.errors.resellerName)}>
                            <TextField
                                disabled={isAddProviderLoading}
                                name='providerCode'
                                fullWidth
                                label='Provider Code'
                                id='providerCode'
                                error={Boolean(formik.touched.providerCode && formik.errors.providerCode)}
                                helperText={formik.touched.providerCode && formik.errors.providerCode}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.providerCode}
                            />
                        </FormControl>
                        <FormControl fullWidth className='field-spacing' error={Boolean(formik.touched.providerName && formik.errors.providerName)}>
                            <TextField
                                disabled={isAddProviderLoading}
                                name='providerName'
                                fullWidth
                                label='Provider Name'
                                id='providerName'
                                error={Boolean(formik.touched.providerName && formik.errors.providerName)}
                                helperText={formik.touched.providerName && formik.errors.providerName}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.providerName}
                            />
                        </FormControl>
                    </Grid>
                    <Grid className='d-flex' gap={5}>
                        <TextField
                            disabled={isAddProviderLoading}
                            name='countryCode'
                            fullWidth
                            label='Country Code'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.countryCode && formik.errors.countryCode)}
                            helperText={formik.touched.countryCode && formik.errors.countryCode}
                            onBlur={formik.handleBlur}
                            value={formik.values.countryCode}
                            onChange={formik.handleChange}
                        />

                        <TextField
                            disabled={isAddProviderLoading}
                            name='country'
                            fullWidth
                            label='Country'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.country && formik.errors.country)}
                            helperText={formik.touched.country && formik.errors.country}
                            onBlur={formik.handleBlur}
                            value={formik.values.country}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid className='d-flex' gap={5}>
                        <TextField
                            disabled={isAddProviderLoading}
                            name='currency'
                            fullWidth
                            label='Currency'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.currency && formik.errors.currency)}
                            helperText={formik.touched.currency && formik.errors.currency}
                            onBlur={formik.handleBlur}
                            value={formik.values.currency}
                            onChange={formik.handleChange}
                        />
                        <FormControl fullWidth>
                            <TextField
                                disabled={isAddProviderLoading}
                                name='description'
                                fullWidth
                                label='Description'
                                variant='outlined'
                                className='field-spacing'
                                // error={Boolean(formik.touched.description && formik.errors.description)}
                                // helperText={formik.touched.description && formik.errors.description}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                onChange={formik.handleChange}
                            />
                        </FormControl>
                    </Grid>

                    <Grid className='d-flex' gap={5}>
                        <TextField
                            disabled={isAddProviderLoading}
                            name='authTokenURL'
                            fullWidth
                            label='Auth Token URL'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.authTokenURL && formik.errors.authTokenURL)}
                            helperText={formik.touched.authTokenURL && formik.errors.authTokenURL}
                            onBlur={formik.handleBlur}
                            value={formik.values.authTokenURL}
                            onChange={formik.handleChange}
                        />

                        <TextField
                            disabled={isAddProviderLoading}
                            name='clientAPIKey'
                            fullWidth
                            label='Client API Key'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.clientAPIKey && formik.errors.clientAPIKey)}
                            helperText={formik.touched.clientAPIKey && formik.errors.clientAPIKey}
                            onBlur={formik.handleBlur}
                            value={formik.values.clientAPIKey}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid className='d-flex' gap={5}>
                        <TextField
                            disabled={isAddProviderLoading}
                            name='clientSecretKey'
                            fullWidth
                            label='Client Secret Key'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.clientSecretKey && formik.errors.clientSecretKey)}
                            helperText={formik.touched.clientSecretKey && formik.errors.clientSecretKey}
                            onBlur={formik.handleBlur}
                            value={formik.values.clientSecretKey}
                            onChange={formik.handleChange}
                        />

                        <TextField
                            disabled={isAddProviderLoading}
                            type='number'
                            name='totalTPSFromProvider'
                            fullWidth
                            label='Total TPS From Provider'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.totalTPSFromProvider && formik.errors.totalTPSFromProvider)}
                            helperText={formik.touched.totalTPSFromProvider && formik.errors.totalTPSFromProvider}
                            onBlur={formik.handleBlur}
                            value={formik.values.totalTPSFromProvider}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    <Grid className='d-flex' gap={5}>
                        <TextField
                            type='number'
                            disabled={isAddProviderLoading}
                            name='totalTPMFromProvider'
                            fullWidth
                            label='Total TPM From Provider'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.totalTPMFromProvider && formik.errors.totalTPMFromProvider)}
                            helperText={formik.touched.totalTPMFromProvider && formik.errors.totalTPMFromProvider}
                            onBlur={formik.handleBlur}
                            value={formik.values.totalTPMFromProvider}
                            onChange={formik.handleChange}
                        />

                        <TextField
                            disabled={isAddProviderLoading}
                            type='number'
                            name='totalNoPerApiCallForTPM'
                            fullWidth
                            label='Total No Per Api Call For TPM'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.totalNoPerApiCallForTPM && formik.errors.totalNoPerApiCallForTPM)}
                            helperText={formik.touched.totalNoPerApiCallForTPM && formik.errors.totalNoPerApiCallForTPM}
                            onBlur={formik.handleBlur}
                            value={formik.values.totalNoPerApiCallForTPM}
                            onChange={formik.handleChange}
                        />
                    </Grid>

                    {/* <Grid className='d-flex' gap={5}>
                        <TextField
                            disabled={isAddProviderLoading}
                            name='rbmBotId'
                            fullWidth
                            label='RBM Bot Id'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.rbmBotId && formik.errors.rbmBotId)}
                            helperText={formik.touched.rbmBotId && formik.errors.rbmBotId}
                            onBlur={formik.handleBlur}
                            value={formik.values.rbmBotId}
                            onChange={formik.handleChange}
                        />

                        <TextField
                            disabled={isAddProviderLoading}
                            name='rbmSecretKey'
                            fullWidth
                            label='Rbm Secret Key'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.rbmSecretKey && formik.errors.rbmSecretKey)}
                            helperText={formik.touched.rbmSecretKey && formik.errors.rbmSecretKey}
                            onBlur={formik.handleBlur}
                            value={formik.values.rbmSecretKey}
                            onChange={formik.handleChange}
                        />
                    </Grid> */}

                    <Grid className='d-flex' gap={5}>
                        <TextField
                            disabled={isAddProviderLoading}
                            name='templateStatusWebhookURL'
                            fullWidth
                            label='Template Status Webhook URL'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.templateStatusWebhookURL && formik.errors.templateStatusWebhookURL)}
                            helperText={formik.touched.templateStatusWebhookURL && formik.errors.templateStatusWebhookURL}
                            onBlur={formik.handleBlur}
                            value={formik.values.templateStatusWebhookURL}
                            onChange={formik.handleChange}
                        />

                        <TextField
                            disabled={isAddProviderLoading}
                            name='messageStatusWebhookURL'
                            fullWidth
                            label='Message Status Webhook URL'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.messageStatusWebhookURL && formik.errors.messageStatusWebhookURL)}
                            helperText={formik.touched.messageStatusWebhookURL && formik.errors.messageStatusWebhookURL}
                            onBlur={formik.handleBlur}
                            value={formik.values.messageStatusWebhookURL}
                            onChange={formik.handleChange}
                        />

                    </Grid>

                    <Grid className='d-flex' gap={5}>

                        <TextField
                            disabled={isAddProviderLoading}
                            name='sendMessageURL'
                            label='Send Message URL'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.sendMessageURL && formik.errors.sendMessageURL)}
                            helperText={formik.touched.sendMessageURL && formik.errors.sendMessageURL}
                            onBlur={formik.handleBlur}
                            value={formik.values.sendMessageURL}
                            onChange={formik.handleChange}
                            sx={{ width: '120%' }}
                        />
                        <Typography marginTop={"10px"} marginBottom={"10px"} >
                            <b className='text-primary'>Variables Required for Send Message URL</b>
                        </Typography>
                        <AddVariablesForSendMessage formik={formik} state={state} />

                    </Grid>
                    <Grid className='d-flex' gap={5}>
                        <TextField
                            disabled={isAddProviderLoading}
                            name='bulkCapCheckURL'
                            fullWidth
                            label='Bulk Cap Check URL'
                            variant='outlined'
                            className='field-spacing'
                            error={Boolean(formik.touched.bulkCapCheckURL && formik.errors.bulkCapCheckURL)}
                            helperText={formik.touched.bulkCapCheckURL && formik.errors.bulkCapCheckURL}
                            onBlur={formik.handleBlur}
                            value={formik.values.bulkCapCheckURL}
                            onChange={formik.handleChange}
                            sx={{ width: '120%' }}
                        />
                        <Typography marginTop={"10px"} marginBottom={"10px"}>
                            <b className='text-primary'>Variables Required for Bulk Cap Check URL</b>
                        </Typography>
                        <AddVariablesForBulkCapCheckURL formik={formik} state={state} />
                    </Grid>

                </CardContent>

                <CardActions sx={{ padding: "0px" }}>
                    <Grid container sx={{ alignItems: "center" }}>
                        <Grid item className='d-flex'>
                            {isAddProviderLoading ? (
                                <Button variant='outlined'>
                                    <CircularProgress size={15} className='spinner' />
                                    Adding..
                                </Button>
                            ) : isUpdateClientLoading ? (
                                <Button variant='outlined'>
                                    <CircularProgress size={15} className='spinner' />
                                    Updating..
                                </Button>
                            ) : providerData?.name && isClientEditable ? (
                                <Button
                                    variant='contained'
                                    className='m-2'
                                // type="submit"
                                // onClick={() => {
                                //     updateClientDetails();
                                // }}
                                >
                                    Update
                                </Button>
                            ) : (
                                <Button
                                    variant='contained'
                                    className='m-2'
                                    type='submit'
                                >
                                    Add Provider
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='error' onClick={onClickCancelButton} className='m-2' disabled={isAddProviderLoading}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>

                {errorResponse && (
                    <AlertMessageComponent
                        message={errorResponse}
                        fieldName={"errorResponse"}
                        handleClose={() => {
                            setErrorResponse("");
                        }}
                        show={Boolean(errorResponse)}
                    />
                )}
            </Card>
        </form>
    );
}
