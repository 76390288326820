import { useTheme } from "@emotion/react";
import { Box, Button, Card, CardContent, CircularProgress, FormControl, Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorMessageForBaseRate, setErrorMessageForUser } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import { showSnackbar } from '../store/snackbarSlice';

export default function UpdateAllCountryRatesCard ({
    setUpdateRateCard,
    setCountriesToCustomizeRateCard,
    countriesToCustomizeRateCard,
    selectProviderName,
    getAllMasterRates,
    providerId,
    providerCode,
    otherFields,
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const currentDateTime = new Date();
    const state = useSelector(state => state.userManagementSlice);
    const [isUpdateRatesLoading, setIsUpdateRatesLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(currentDateTime);

    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const validateIfValueIsEmpty = fieldName => {
        const fieldValue = formik.values[fieldName];
        if (fieldValue === "" || fieldValue === null) {
            formik.setFieldError(fieldName, `Required`);
            return false;
        }
        return true;
    };

    const validateIfValueIsEmptyForOtherFields = fieldName => {
        const fieldValue = formik.values.updatedOtherFields[fieldName];
        if (fieldValue === "" || fieldValue === null) {
            formik.setFieldError(`otherFields.${fieldName}`, `Required`);
            return false;
        } else {
            return true;
        }
    };

    const validation = () => {
        let result = true;

        if (!validateIfValueIsEmpty("updatedTextMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedMultiMediaMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedA2pSessionConversationRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedFeedbackRate")) {
            result = false;
        }
        // if (!validateIfValueIsEmpty("updatedUserMessageRate")) {
        //     result = false;
        // }
        // if (!validateIfValueIsEmpty("updatedP2aConversationRate")) {
        //     result = false;
        // }

        for (let index = 0; index < Object.keys(otherFields).length; index++) {
            const otherRate = Object.keys(otherFields)[index];
            if (!validateIfValueIsEmptyForOtherFields(otherRate)) {
                result = false;
            }
        }
        return result;
    };

    const updateMasterRateForAllCountry = async ({ data }) => {
        try {
            setIsUpdateRatesLoading(true);
            const response = await apiGatewayAxiosInstance.post(`/user_management/rate-card/update`, data);
            if (response.status === 200) {
                setIsUpdateRatesLoading(false);
                onClickCancelButton();
                getAllMasterRates({ providerId: providerId, providerCode: providerCode });
            } else {
                throw new Error(response.data);
            }
        } catch (error) {
            setIsUpdateRatesLoading(false);
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "updateMasterRateForAllCountryErrorMessage",
                    errorMessage,
                }),
            );
        }
    };

    const initialValues = {
        updatedTextMessageRate: 0,
        updatedMultiMediaMessageRate: 0,
        updatedA2pSessionConversationRate: 0,
        updatedFeedbackRate: 0,
        updatedOtherFields: otherFields,
        // updatedUserMessageRate: 0,
        // updatedP2aConversationRate: 0,
    };

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                if (validation()) {
                    let countriesToUpdateRates = [];

                    countriesToCustomizeRateCard.map(countryElement => {
                        const objectOfCountriesToAdd = {
                            // _id: countryElement._id,
                            countryName: countryElement.countryName,
                            countryCode: countryElement.countryCode,
                            providerId: countryElement.providerId,
                            providerCode: countryElement.providerCode,
                            // effectiveFrom: selectedDate,
                        };

                        countriesToUpdateRates.push(objectOfCountriesToAdd);
                    });

                    let dataToSend = {
                        updatedTextMessageRate: values.updatedTextMessageRate,
                        updatedMultiMediaMessageRate: values.updatedMultiMediaMessageRate,
                        updatedA2pSessionConversationRate: values.updatedA2pSessionConversationRate,
                        updatedFeedbackRate: values.updatedFeedbackRate,
                        updatedOtherFields: values.updatedOtherFields,
                        // updatedUserMessageRate: values.updatedUserMessageRate,
                        // updatedP2aConversationRate: values.updatedP2aConversationRate,

                        countriesToUpdateRates: countriesToUpdateRates,
                    };
                    updateMasterRateForAllCountry({
                        data: dataToSend,
                    });
                }
            } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
                dispatch(
                    setErrorMessageForUser({
                        fieldName: "updateMasterRateForAllCountryErrorMessage",
                        errorMessage: message,
                    }),
                );
            }
        },
    });

    const onClickCancelButton = () => {
        setCountriesToCustomizeRateCard([]);
        setUpdateRateCard(false);
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessageForBaseRate({ fieldName }));
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <Grid>
                    <Card className='border border-secondary-50 '>
                        <CardContent>
                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Grid item>
                                    <Typography variant='h4' className='text-primary'>
                                        <b>Increase / Decrease All Master Rates</b>
                                    </Typography>
                                </Grid>

                                <Grid container display={"flex"}>
                                    <Grid item md={5} mt={5}>
                                        <Typography
                                            variant='h6'
                                            // className='text-primary'
                                        >
                                            Updating rates for below countries
                                        </Typography>
                                        {countriesToCustomizeRateCard.map(countriesDetails => (
                                            <>
                                                <Grid>{countriesDetails.countryName}</Grid>
                                            </>
                                        ))}
                                    </Grid>

                                    <Grid item md={5}>
                                        <Grid mt={4}>
                                            <TextField
                                                className='mb-2'
                                                fullWidth
                                                size='small'
                                                type='number'
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                label='Text Message Rate '
                                                name='updatedTextMessageRate'
                                                defaultValue={0}
                                                error={Boolean(formik.touched.updatedTextMessageRate && formik.errors.updatedTextMessageRate)}
                                                helperText={formik.touched.updatedTextMessageRate && formik.errors.updatedTextMessageRate}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>

                                        <Grid mt={3}>
                                            <TextField
                                                className='mb-2'
                                                fullWidth
                                                size='small'
                                                type='number'
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                label='Multi Media Message Rate'
                                                name='updatedMultiMediaMessageRate'
                                                defaultValue={0}
                                                error={Boolean(
                                                    formik.touched.updatedMultiMediaMessageRate && formik.errors.updatedMultiMediaMessageRate,
                                                )}
                                                helperText={formik.touched.updatedMultiMediaMessageRate && formik.errors.updatedMultiMediaMessageRate}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>

                                        <Grid mt={3}>
                                            <TextField
                                                className='mb-2'
                                                fullWidth
                                                size='small'
                                                type='number'
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                label='A2p SessionConversation Rate'
                                                name='updatedA2pSessionConversationRate'
                                                defaultValue={0}
                                                error={Boolean(
                                                    formik.touched.updatedA2pSessionConversationRate &&
                                                        formik.errors.updatedA2pSessionConversationRate,
                                                )}
                                                helperText={
                                                    formik.touched.updatedA2pSessionConversationRate &&
                                                    formik.errors.updatedA2pSessionConversationRate
                                                }
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>

                                        <Grid mt={3}>
                                            <TextField
                                                className='mb-2'
                                                fullWidth
                                                size='small'
                                                type='number'
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                label='Feedback rate'
                                                name='updatedFeedbackRate'
                                                defaultValue={0}
                                                error={Boolean(formik.touched.updatedFeedbackRate && formik.errors.updatedFeedbackRate)}
                                                helperText={formik.touched.updatedFeedbackRate && formik.errors.updatedFeedbackRate}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                        {Object.keys(otherFields).length > 0 &&
                                            Object.keys(otherFields).map((fieldElement, index) => (
                                                <Grid key={index} mt={3}>
                                                    {/* <Typography>{fieldElement} :</Typography> */}
                                                    <TextField
                                                        fullWidth
                                                        className='mb-2'
                                                        size='small'
                                                        type='number'
                                                        inputProps={{
                                                            step: "any",
                                                        }}
                                                        onWheel={e => e.target.blur()}
                                                        label={fieldElement}
                                                        name={`updatedOtherFields.${fieldElement}`}
                                                        defaultValue={0}
                                                        error={Boolean(formik.errors?.updatedOtherFields && formik.errors?.updatedOtherFields[fieldElement])}
                                                        helperText={formik.errors?.updatedOtherFields && formik.errors?.updatedOtherFields[fieldElement]}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                            ))}
                                        {/* <Grid mt={3}>
                                            <TextField
                                                className='mb-2'
                                                fullWidth
                                                size='small'
                                                type='number'
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                label='P2a Session Conversation Rate'
                                                name='updatedP2aConversationRate'
                                                defaultValue={0}
                                                error={Boolean(formik.touched.updatedP2aConversationRate && formik.errors.updatedP2aConversationRate)}
                                                helperText={formik.touched.updatedP2aConversationRate && formik.errors.updatedP2aConversationRate}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                        <Grid mt={3}>
                                            <TextField
                                                className='mb-2'
                                                fullWidth
                                                size='small'
                                                type='number'
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                label='User Message Rate'
                                                name='updatedUserMessageRate'
                                                defaultValue={0}
                                                error={Boolean(formik.touched.updatedUserMessageRate && formik.errors.updatedUserMessageRate)}
                                                helperText={formik.touched.updatedUserMessageRate && formik.errors.updatedUserMessageRate}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid> */}
                                        {/* <Grid mt={3}>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} mt={5}>
                                                    <DateTimePicker
                                                        fullWidth
                                                        size='small'
                                                        label='Select Date and Time'
                                                        value={selectedDate}
                                                        onChange={handleDateChange}
                                                        // minDate={new Date()}
                                                        // maxDate={new Date(new Date().setMonth(new Date().getMonth() + 6))}
                                                        minTime={
                                                            selectedDate && selectedDate.getDate() === currentDateTime.getDate()
                                                                ? new Date(
                                                                      currentDateTime.getFullYear(),
                                                                      currentDateTime.getMonth(),
                                                                      currentDateTime.getDate(),
                                                                      currentDateTime.getHours(),
                                                                      currentDateTime.getMinutes(),
                                                                  )
                                                                : null
                                                        }
                                                        renderInput={params => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid> */}

                                        <Grid
                                            container
                                            mt={2}
                                            style={{
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Grid padding={1}>
                                                {isUpdateRatesLoading ? (
                                                    <Button variant='outlined'>
                                                        <CircularProgress size={15} className='spinner' />
                                                        Updating..
                                                    </Button>
                                                ) : (
                                                    <Button type='submit' variant='contained'>
                                                        Update
                                                    </Button>
                                                )}
                                            </Grid>

                                            <Grid padding={1}>
                                                <Button variant='contained' color='error' onClick={() => onClickCancelButton()}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Box>
            {state.controls.updateMasterRateForAllCountryErrorMessage && (
                <AlertMessageComponent
                    message={state.controls.updateMasterRateForAllCountryErrorMessage}
                    fieldName={"updateMasterRateForAllCountryErrorMessage"}
                    handleClose={onCloseErrorMessage}
                    show={Boolean(state.controls.updateMasterRateForAllCountryErrorMessage)}
                />
            )}
        </form>
    );
}
