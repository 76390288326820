import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { BotMessageType, config, TemplateTypes } from "../../config";
import useAuth from '../../hooks/useAuth';
import { getAllVerifiedBotsToRunCampaignThunk } from "../../redux/botManagement/botManagementThunk";
import { clearErrorMessagesForCampaigns, clearErrorMessagesForSendTestMessage, setGetSampleCsvSignedUrlErrorMessage, setShowAddCampaignForm } from "../../redux/campaignManagment/campaignManagmentSlice"; import { addNewCampaignThunk, sendTestMessageThunk } from "../../redux/campaignManagment/campaignManagmentThunk";
import { apiGatewayAxiosInstance } from "../../utils/axios";
import AlertMessageComponent from "../components/AlertMessageComponent";
import SimulatorComponent from '../rcsManagement/rcsTemplates/simulator/SimulatorComponent';
import VariablesModal from './modals/VariablesModal';


function AddCampaign() {
  const dispatch = useDispatch();
  const botState = useSelector((state) => state.botManagementSlice);
  const campaignState = useSelector((state) => state.campaignManagmentSlice);
  const uploadFileRef = useRef(null);
  const [csvFileFormData, setCSVFileFormData] = useState({});
  const [csvVariables, setCsvVariables] = useState([]);
  // const [smsVariables, setSMSVariables] = useState([]);
  const [smsVariablesCount, setSMSVariablesCount] = useState([]);
  const [smsMessage, setSMSMessage] = useState('');
  const [csvUploaded, setCSVUploaded] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [selectedTestNumber, setSelectedTestNumber] = useState({});
  const [selectedBot, setSelectedBot] = useState({})
  const [showVariablesModal, setShowVariablesModal] = useState({
    open: false,
    data: [],
  });
  const [currentTestMessage, setCurrentTestMessage] = useState({
    botId: null,
    templateId: null,
    number: '',
  });
  const [variablesValues, setVariablesValues] = useState({});


  const { user } = useAuth();

  useEffect(() => {
    dispatch(getAllVerifiedBotsToRunCampaignThunk());
  }, []);

  const onClickCancel = () => {
    dispatch(setShowAddCampaignForm(false));
  };


  //=============================== Send Test Message==================================================================


  // const getTemplateVariablesByTemplateId = async (templateId) => {
  //   try {
  //     const response = await apiGatewayAxiosInstance.get(`/bot_management/template/get-template-variables-by-templateId/${templateId}`);
  //     if (response.status === 200) {
  //       const variables = response.data.data.templateVariables;
  //       console.log(variables)
  //       if (variables.length > 0) {
  //         setShowVariablesModal({ open: true, data: variables });
  //         return variablesValues;
  //       }

  //     } else {
  //       throw new Error(`Error fetching template variables: ${response.status}`);
  //     }
  //   } catch (error) {
  //     console.error('Error in getTemplateVariablesByTemplateId:', error);
  //     throw error;
  //   }
  // };

  const sendTestMessage = async (botId, templateId, number, variables) => {
    if (!botId) {
      formik.setFieldError('bot', "Please Select Bot")
      return;
    }
    dispatch(
      sendTestMessageThunk({
        botId: botId,
        templateId: templateId,
        number: number,
        variables: variables
      })
    );
  }

  const handleVariablesSubmit = async (variablesFromModal) => {
    if (variablesFromModal) {
      setVariablesValues(variablesFromModal);
      setShowVariablesModal({ open: false, data: [] });
      const response = await sendTestMessage(currentTestMessage.botId, currentTestMessage.templateId, currentTestMessage.number, variablesFromModal);
      console.log(currentTestMessage.botId, currentTestMessage.templateId, currentTestMessage.number, variablesFromModal);
    }
  };

  const handleVariablesModalClose = () => {
    setShowVariablesModal({ open: false, data: [] });
  };

  const handleSendTestMessage = async (botId, templateId, number) => {

    try {
      const response = await apiGatewayAxiosInstance.get(`/bot_management/template/get-template-variables-by-templateId/${templateId}`);
      if (response.status === 200) {
        const variables = response.data.data.templateVariables;
        if (variables.length > 0) {
          setShowVariablesModal({ open: true, data: variables });
          setCurrentTestMessage({ botId, templateId, number });
        } else {
          const response = await sendTestMessage(botId, templateId, number, variables);
          console.log(botId, templateId, number, variables)
        }
      }
    } catch (error) {
      console.error('Error in getTemplateVariablesByTemplateId:', error);
      throw error;
    }
  };
  //=============================== Send Test Message==================================================================


  const previewFile = async (targetFile, urlFieldName, typeFieldName) => {
    // Reading New File (open file Picker Box);
    formik.setFieldValue("smsVariables", []);
    // setSMSVariables([]);
    if (formik.values.selectedTemplate.isSMSFallbackRequired) {
      setSMSMessage(formik.values.selectedTemplate.smsFallbackTemplateDetails.message);
    }
    const reader = new FileReader();
    if (targetFile) {
      reader.readAsDataURL(targetFile);
    }
    const isFirefox = typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase().includes('firefox');

    // As the File loaded then set the stage as per the file type
    reader.onload = async (readerEvent) => {

      if (isFirefox && targetFile.type.includes('application/vnd.ms-excel')) {
        // Firefox gives the mime type of excel file, identify the browser and create a new file with the correct mime type
        targetFile = new File(
          [targetFile],
          targetFile.name,
          {
            type: 'text/csv',
          }
        );


      }


      if (targetFile.type.includes("csv")) {
        formik.setFieldValue(urlFieldName, readerEvent.target.result);
        formik.setFieldValue(typeFieldName, targetFile.type);
        formik.setFieldValue("uploadedCSV.csvFileName", targetFile.name);

        let mimeType = targetFile.type;
        const supportedMimeTypeArray = ["text/csv"];
        if (supportedMimeTypeArray.includes(mimeType)) {
          //   setting both the images in imageFormData state on fn call\
          const fileUrl = URL.createObjectURL(targetFile);
          const response = await fetch(fileUrl);
          const text = await response.text();
          const lines = text.split("\n");
          let _data = lines.map((line) => line.replace('\r', '').split(","))[0];


          setCsvVariables(_data);

          setCSVFileFormData({
            ...csvFileFormData,
            [urlFieldName]: targetFile,
          });

          setCSVUploaded(true);
        }
      }
    };
  };

  const handleFileChange = (e) => {

    const file = e.target.files[0];
    if (file && file.size > 50 * 1024 * 1024) {
      // File size is more than 100MB, set error message
      formik.setFieldError('uploadedCSV.csvFileUrl', 'File size exceeds 50MB limit.');
    } else {
      // Clear any previous error message
      formik.setFieldError('uploadedCSV', '');
      // Proceed with previewing the file
      previewFile(
        file,
        "uploadedCSV.csvFileUrl",
        "uploadedCSV.csvFileType"
      );

    }
  };


  const getTemplatesByBotId = async (botId) => {
    const response = await apiGatewayAxiosInstance.get(`/bot_management/template/get-all-templates-by-botid`, { params: { botid: botId } });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response);
    }
  };


  const onChangeSelectBot = async (e) => {

    const bot = e.target.value;
    setSelectedBot(bot)
    const botId = bot._id;
    const botName = bot.botName;
    formik.setFieldTouched("selectedBot", true, true);
    formik.setFieldValue("selectedBotId", botId, true);
    formik.setFieldValue("selectedBotName", botName, true);
    formik.setFieldValue("selectedBot", bot, true);
    const templatesListByBotId = await getTemplatesByBotId(botId);
    formik.setFieldValue("templatesListByBotId", templatesListByBotId);



    formik.setFieldValue("selectedTemplateId", "",);

    // get signed url for the selected template csv file


    formik.setFieldValue("selectedTemplateName", "");
    formik.setFieldValue("selectedTemplate", {});
    formik.setFieldValue("isSMSFallbackRequired", false);
    formik.setFieldValue(
      "campaignSampleCSVUrl",
      ""
    );
    // formik.setFieldValue("uploadedCSV.csvFileUrl", template.sampleS3FileUrl, true);


    // this just for testing remove this later with actual logic
    formik.setFieldValue("campaignCSVUrl", "", true);




  };

  const validateSelectTemplate = () => {
    let error;
    if (!formik.touched.selectedBot) {
      error = "Please select the bot";
    }



    return error;
  };

  const onChangeSelectTestNumber = async (e) => {
    const number = e.target.value;
    setSelectedTestNumber(number);
    formik.setFieldValue("selectedTestNumber", number, true);
  }


  const onChangeSelectTemplate = async (e) => {
    const template = e.target.value;
    setSelectedTemplate(template);
    const templateId = template._id;
    const selectedTemplateName = template.templateName;
    formik.setFieldValue("selectedTemplateId", templateId, true);

    // get signed url for the selected template csv file


    formik.setFieldValue("selectedTemplateName", selectedTemplateName, true);
    formik.setFieldValue("selectedTemplate", template, true);
    formik.setFieldValue("isSMSFallbackRequired", false);
    formik.setFieldValue(
      "campaignSampleCSVUrl",
      template.sampleS3File.url,
      true
    );
    // formik.setFieldValue("uploadedCSV.csvFileUrl", template.sampleS3FileUrl, true);


    // this just for testing remove this later with actual logic
    formik.setFieldValue("campaignCSVUrl", template.sampleS3FileUrl, true);


    variablesForTemplates(template)

  };


  const variablesForTemplates = (template) => {


    // setCsvVariables(template.variables);


    if (template.isSMSFallbackRequired) {

      const count = template.smsFallbackTemplateDetails.message.match(/{#var#}/g)
      if (count) {

        setSMSVariablesCount(template.smsFallbackTemplateDetails.message.match(/{#var#}/g));
      }


    } else {
      setSMSVariablesCount([])
    }
  }


  const onCloseErrorMessage = ({ fieldName }) => {
    dispatch(clearErrorMessagesForCampaigns({ fieldName }));
  }

  const onCloseSendMessageError = ({ fieldName }) => {
    dispatch(clearErrorMessagesForSendTestMessage({ fieldName }));
  }

  const getSampleCsvSignedUrl = async () => {
    try {
      const signedUrlData = await apiGatewayAxiosInstance.get(`/bot_management/template/get-signed-url-sample-csv`, { params: { botid: formik.values.selectedBotId, templateid: formik.values.selectedTemplateId } });

      const signedUrl = signedUrlData.data;
      if (!signedUrl) {
        throw new Error("Could not get url to download sample csv file")
      }
      window.location.href = signedUrl;
    } catch (error) {
      let errorMessage = "Failed to get sample csv file.";

      if (error.response) {
        errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
      }
      dispatch(setGetSampleCsvSignedUrlErrorMessage(errorMessage))

    }


  }

  const smsVariablesValidation = () => {

    let result = true;
    for (let i = 0; i < smsVariablesCount.length; i++) {

      if (!formik.values.smsVariables[i]) {
        formik.setFieldError(`smsVariables[${i}]`, "Required");
        result = false;
      }

    }
    return result;

  }

  const initialValues = {
    selectedBot: {},
    selectedBotId: "",
    selectedBotName: "",
    selectedTemplate: {},
    selectedTemplateId: "",
    selectedTemplateName: "",
    templatesListByBotId: [],
    isSMSFallbackRequired: false,
    isDuplicateNumberAccepted: false,
    smsVariables: [],
    ttlInHours: true,
    selectedTestNumber: "",

    campaignSampleCSVUrl: "",
    campaignCSVUrl: "",
    campaignName: "",
    uploadedCSV: {},
    ttl: Number(config.REACT_APP_CAMPAIGN_TTL),
  };

  const campaignCSVSchema = Yup.object().shape({
    csvFileUrl: Yup.string().required("Please upload csvFile"),
    csvFileType: Yup.string().required("CSV file type not found"),
    csvFileName: Yup.string().notRequired()
  });
  const selectedBotSchema = Yup.object().shape({
    cardOrientation: Yup.string().required(
      "please select Card Orientation"
    ),
    mediaHeight: Yup.string().when("cardOrientation", {
      is: "VERTICAL",
      then: Yup.string().required("please select Media Height"),
    }),
    cardAlignment: Yup.string().when("cardOrientation", {
      is: "HORIZONTAL",
      then: Yup.string().required("please select Card Alignemt"),
    }),
    media: Yup.object().required("cardMedia is required"),
    thumbnail: Yup.object().when("media.type", {
      is: "video/mp4",
      then: Yup.object().required("Video thumbnail is required.")
    }),

  });

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      campaignName: Yup.string().required("Campaign Name is required"),
      selectedBotName: Yup.string().required("Bot Name is required"),
      selectedTemplateName: Yup.string().required("Template Name is required"),
      ttl: Yup.mixed().when("ttlInHours", {
        is: (val) => val === true,
        then: Yup.number().max(Number(config.REACT_APP_MAX_TTL_IN_HOURS), `Number must be less than ${config.REACT_APP_MAX_TTL_IN_HOURS}`)
          .min(Number(config.REACT_APP_CAMPAIGN_TTL), `Number must be greater than ${config.REACT_APP_CAMPAIGN_TTL}`)
          .required('This field is required'),
        otherwise: Yup.number().max(Number(config.REACT_APP_MAX_TTL_IN_DAYS), `Number must be less than ${config.REACT_APP_MAX_TTL_IN_DAYS}`)
          .min(Number(config.REACT_APP_CAMPAIGN_TTL), `Number must be greater than ${config.REACT_APP_CAMPAIGN_TTL}`)
          .required('This field is required'),
      }),


      uploadedCSV: campaignCSVSchema,
    }),
    onSubmit: async (
      values,
      { setErrors, setStatus, setSubmitting, resetForm }
    ) => {
      try {

        let checkIsSMSVariablesValidated = true;
        if (values.isSMSFallbackRequired) {
          checkIsSMSVariablesValidated = smsVariablesValidation();
        };


        if (!formik.values.ttl) {
          formik.setFieldError(`ttl`, "Required");
          return
        }




        if (formik.values.ttl < 1) {
          formik.setFieldError(`ttl`, "Greater than 1");
          return
        }

        if (values.ttlInHours && formik.values.ttl > 730) {
          formik.setFieldError(`ttl`, "Less than 730");
          return
        }

        if (!values.ttlInHours && formik.values.ttl > 30) {
          formik.setFieldError(`ttl`, "Less than 30");
          return
        }

        if (checkIsSMSVariablesValidated) {
          const dataToSend = {
            botId: values.selectedBotId,
            templateId: values.selectedTemplateId,
            campaignName: values.campaignName,
            botName: values.selectedBotName,
            templateName: values.selectedTemplateName,
            campaignCSVUrl: values.campaignCSVUrl,
            isSMSFallbackRequired: values.isSMSFallbackRequired,
            isDuplicateNumberAccepted: values.isDuplicateNumberAccepted,

            smsVariables: values.smsVariables,
          };
          if (values.ttlInHours) {
            dataToSend['ttl'] = `${(values.ttl * 60 * 60)?.toFixed(0)}s`
          } else {
            dataToSend['ttl'] = `${(values.ttl * 60 * 60 * 24)?.toFixed(0)}s`

          }

          const formData = new FormData();
          for (let fieldName in csvFileFormData) {
            formData.append(fieldName, csvFileFormData[fieldName]);
          }
          formData.append("data", JSON.stringify(dataToSend));
          formData.append("orgData", JSON.stringify(user.orgDetails));
          dispatch(
            addNewCampaignThunk({
              data: formData,
              botId: values.selectedBotId,
              templateId: values.selectedTemplateId
            })
          );
        }
      } catch (error) {

        const message = error.message || "Something went wrong";
        setStatus({ success: false });
        setErrors({ submit: message });
        setSubmitting(false);
      }
    },
  });



  const onClickDeleteCSVFile = () => {
    formik.setFieldValue("uploadedCSV", {});
  }

  const onChangeSMSVariable = (e, index) => {

    let list = formik.values.smsVariables;


    list[index] = e.target.value;
    // setSMSVariables(list);
    formik.setFieldValue("smsVariables", list)

    var str = formik.values.selectedTemplate.smsFallbackTemplateDetails.message

    var replacements = list;
    let i = -1;
    var replacedStr = str.replace(/{#var#}/g, function (match, index) {
      i++;
      if (formik.values.smsVariables[i]) {

        return ` ${formik.values.smsVariables[i]} `;
      } else {
        return '{#var#}'
      }
    });

    setSMSMessage(replacedStr);


  }

  /**
   * 1. Dropdown -> Get all bots by recipient ID
   * 2. Dropdown -> Get all the approved templates by bot Id
   * 3. Button Schedule Campaign
   */


  const handleChangeFormik = (fieldName, value) => {
    formik.setFieldValue(fieldName, value)

  }
  useEffect(() => {

  }, [smsMessage, formik.values]);


  // console.log(`${(formik.values.ttl * 60 * 60)?.toFixed(0)}s`)


  // console.log(formik.errors, "ERRORs")
  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container sx={{ display: "flex" }}>
          <Grid item md={8}   >
            <Grid
              container
              className='listHeader'
              mb={2}
            >
              <Grid
                item
                md={6}
                display={'flex'}
                alignItems={'center'}
              >
                <IconButton
                  color='secondary'
                  onClick={onClickCancel}
                  size='medium'
                >
                  <ArrowBackIosIcon
                    sx={{ cursor: 'pointer' }}
                  />
                </IconButton>
                <Grid role="presentation">
                  <Breadcrumbs aria-label="breadcrumb" fontSize="large">
                    <Link
                      underline="hover"
                      color="inherit"
                      className='breadcrumbItem'
                      // href="/rcs-management/manage-bots/"
                      onClick={onClickCancel}
                    >
                      Campaigns
                    </Link>
                    <Typography
                      className='breadcrumbItem'
                      color="text.primary"
                    >
                      Add Campaign
                    </Typography>
                  </Breadcrumbs>
                </Grid>
              </Grid>
            </Grid>
            <Card>
              <CardContent>
                {/* <Grid container sx={{ display: "flex" }}>
              <Grid item md={8}> */}
                <Grid container sx={{ display: "flex" }} gap={4}>

                  {/* ------------------------------Campaign Name--------------------------------------- */}
                  <Grid container sx={{ display: "flex", alignItems: "center" }} >
                    <Grid md={2} sx={{ display: "flex", alignItems: "center" }} >
                      <Typography fontWeight={"bold"} sx={{ display: "flex", alignItems: "center" }} >Campaign Name :</Typography>
                    </Grid>
                    <Grid md={10} sx={{ display: "flex", alignItems: "center" }} >
                      <FormControl fullWidth>
                        <TextField
                          disabled={campaignState.controls.isCreatingCampaignLoading}
                          name="campaignName"
                          fullWidth
                          label="Campaign Name"
                          variant="outlined"
                          error={Boolean(
                            formik.touched.campaignName && formik.errors.campaignName
                          )}
                          helperText={
                            formik.touched.campaignName && formik.errors.campaignName
                          }
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* ------------------------------Campaign Name End--------------------------------------- */}

                  {/* -----------------------------------Select Bot----------------------------------------- */}
                  <Grid container sx={{ display: "flex", justifyContent: "left", alignItems: "center" }} >
                    <Grid md={2} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }} >
                      <Typography fontWeight={"bold"} sx={{ display: "flex", justifyContent: "left", alignItems: "left" }} >Select Bot :</Typography>
                    </Grid>
                    <Grid md={10} sx={{ display: "flex", alignItems: "center" }} >
                      <FormControl
                        // className="p-1"
                        fullWidth
                        error={Boolean(
                          formik.touched.selectedBotName && formik.errors.selectedBotName
                        )}>
                        <InputLabel id="select-bot-label">Select Bot</InputLabel>
                        <Select
                          disabled={campaignState.controls.isCreatingCampaignLoading}
                          // onChange={onSelectStatus}
                          label="Select Bot"
                          labelId="select-bot-label"
                          id="select-bot-for-campaign"
                          onChange={onChangeSelectBot}
                          error={Boolean(
                            formik.touched.selectedBotName && formik.errors.selectedBotName
                          )}
                          helperText={
                            formik.touched.selectedBot && formik.errors.selectedBot
                          }
                          onBlur={formik.handleBlur}
                          value={formik.values.selectedBot}
                          fullWidth
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                boxShadow:
                                  " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                              },
                            },
                          }}
                        >
                          {botState.verifiedBotListToRunCampaign &&
                            botState.verifiedBotListToRunCampaign.map((bot, index) => {
                              return (
                                <MenuItem value={bot} >
                                  <Grid
                                    container
                                  // sx={{ marginBottom: "2px" }}
                                  >
                                    <Grid display={"flex"} item gap={3} md={8}>
                                      {bot.botLogoImage.url ?

                                        <img src={bot.botLogoImage.url} height={"20px"} width={"20px"} style={{ objectFit: "scale-down" }} />
                                        :
                                        <SmartToyOutlinedIcon />

                                      }
                                      <Typography variant="h6">
                                        {
                                          bot.botName
                                        }
                                      </Typography>
                                    </Grid>

                                    <Grid item md={3}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Typography variant="body1">
                                        Type :{" "}
                                        {
                                          bot.botMessageType == BotMessageType.OTP ?
                                            <b>
                                              {BotMessageType.OTP}
                                            </b> :
                                            bot.botMessageType == BotMessageType.Promotional ?
                                              <b>
                                                {BotMessageType.Promotional}
                                              </b> :
                                              bot.botMessageType == BotMessageType.Transactional ?
                                                <b>
                                                  {BotMessageType.Transactional}
                                                </b>
                                                : "NA"
                                        }
                                      </Typography>
                                    </Grid>
                                    <hr />
                                  </Grid>
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText>
                          {formik.touched.selectedBotName && formik.errors.selectedBotName}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* -----------------------------------Select Bot End-------------------------------------- */}
                  {/* -----------------------------------Select Template------------------------------------- */}
                  <Grid container sx={{ display: "flex", justifyContent: "left", alignItems: "center" }} >
                    <Grid md={2} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }} >
                      <Typography fontWeight={"bold"} sx={{ display: "flex", justifyContent: "left", alignItems: "left" }} >Select Template :</Typography>
                    </Grid>
                    <Grid md={10} sx={{ display: "flex", alignItems: "center" }} >
                      <FormControl fullWidth error={Boolean(
                        formik.touched.selectedTemplateName && formik.errors.selectedTemplateName
                      )}>
                        <InputLabel id="select-template-label">
                          Select Template
                        </InputLabel>
                        <Select
                          fullWidth
                          disabled={campaignState.controls.isCreatingCampaignLoading}
                          value={formik.values.selectedTemplate}
                          onChange={onChangeSelectTemplate}
                          label="Select Template"
                          labelId="select-template-label"
                          id="select-template-label"
                          error={Boolean(
                            formik.touched.selectedTemplateName &&
                            formik.errors.selectedTemplateName
                          )}
                          helperText={
                            formik.touched.selectedTemplate &&
                            formik.errors.selectedTemplate
                          }
                          onBlur={formik.handleBlur}
                          validate={validateSelectTemplate}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                overflow: "scroll",
                                maxHeight: "40%",
                                boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                alignItems: formik.values.templatesListByBotId.length > 0 ? "unset" : "center",
                                // display: "flex",
                                justifyContent: formik.values.templatesListByBotId.length > 0 ? "unset" : "center"
                              },

                            },
                          }}
                        >
                          {formik.values.templatesListByBotId.length > 0 ?
                            formik.values.templatesListByBotId.map((template, index) => {
                              if (template.status === "approved")
                                return (
                                  <MenuItem value={template} >
                                    <Grid
                                      container
                                      // width={"100%"}
                                      display={"flex"}
                                      justifyContent={"space-between"}
                                      sx={{ paddingTop: "2px" }}

                                    >
                                      <Grid item md={7}>
                                        <Typography variant="h6">
                                          {
                                            template.templateName
                                          }
                                        </Typography>
                                      </Grid>

                                      <Grid
                                        item
                                        md={4}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Typography variant="body1">
                                          Type :{" "}
                                          {
                                            template.templateType == TemplateTypes.TEXT_MESSAGE ?
                                              <b>
                                                Text Message
                                              </b> :
                                              template.templateType == TemplateTypes.RICH_CARD ?
                                                <b>
                                                  Rich Card Stand-alone
                                                </b> :
                                                template.templateType == TemplateTypes.CAROUSEL ?
                                                  <b>
                                                    Rich Card Carousel
                                                  </b>
                                                  : "NA"
                                          }
                                        </Typography>
                                      </Grid>
                                      <hr />
                                    </Grid>
                                  </MenuItem>
                                )
                            })
                            :
                            <MenuItem disabled>
                              <Typography variant="h5">
                                Please Select Bot
                              </Typography>
                            </MenuItem>
                          }
                        </Select>
                        <FormHelperText>
                          {formik.touched.selectedTemplateName && formik.errors.selectedTemplateName}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* --------------------------------Select Template End------------------------------------ */}

                  {/* ----------------------------------------- TTL ----------------------------------------- */}

                  <Grid container sx={{ display: "flex", justifyContent: "left", alignItems: "center" }} >
                    <Grid md={2} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }} >
                      <Typography fontWeight={"bold"} sx={{ display: "flex" }} >TTL : </Typography>
                    </Grid>
                    <Grid md={10} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      <FormControl sx={{ width: "100%", display: "flex", flexDirection: "row", placeContent: "space-between" }} >
                        <Grid md={5} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}  >
                          <TextField
                            fullWidth
                            disabled={campaignState.controls.isCreatingCampaignLoading}
                            name="ttl"
                            label={`Campaign TTL (In ${formik.values.ttlInHours ? "Hours" : "Days"})`}
                            variant="outlined"
                            error={Boolean(
                              formik.touched.ttl && formik.errors.ttl
                            )}
                            helperText={
                              formik.touched.ttl && formik.errors.ttl
                            }
                            onWheel={e => e.target.blur()}
                            // type='number'
                            type='number'
                            inputProps={{
                              step: "any",
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.ttl}
                            onChange={formik.handleChange}
                          // InputProps={{ inputProps: { min: 3600 } }}  // min value for input is 1 hour
                          />
                        </Grid>
                        {/* <Grid className="col-2" item sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}> */}
                        <Grid md={5} sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                          In Days
                          {/* <FormControlLabel style={{}} control={} /> */}
                          <Switch checked={formik.values.ttlInHours} onChange={() => { formik.setFieldValue("ttlInHours", !formik.values.ttlInHours); formik.setFieldValue("ttl", 1) }} />
                          In Hours
                          {/* </Grid/</FormControl>> */}
                        </Grid>

                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* ----------------------------------------- TTL END ----------------------------------------- */}
                  <Grid container sx={{ display: "flex", justifyContent: "left", alignItems: "center" }} >
                    <Grid md={2} sx={{ display: "flex", justifyContent: "left", alignItems: "center" }} >
                      <Typography fontWeight={"bold"} sx={{ display: "flex" }} >Test Message : </Typography>
                    </Grid>
                    <Grid md={5} sx={{ display: "flex", alignItems: "center" }}  >
                      {(!selectedBot.botName || selectedBot?.testDevices?.length > 0) ?
                        (
                          <FormControl sx={{ width: "100%" }} >
                            <InputLabel id="select-template-label">
                              Select Number
                            </InputLabel>
                            <Select
                              fullWidth
                              disabled={campaignState.controls.isCreatingCampaignLoading}
                              value={formik.values.selectedTestNumber}
                              onChange={onChangeSelectTestNumber}
                              // onChange={onChangeSelectTemplate}
                              label=" Select Number"
                              labelId="select-template-label"
                              id="select-template-label"
                              error={Boolean(
                                formik.touched.testNumber &&
                                formik.errors.testNumber
                              )}
                              helperText={
                                formik.touched.testNumber &&
                                formik.errors.testNumber
                              }
                              onBlur={formik.handleBlur}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    overflow: "scroll",
                                    maxHeight: "40%",
                                    boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",

                                  },

                                },
                              }}
                            >
                              {selectedBot?.testDevices?.length > 0 ?
                                selectedBot.testDevices.map((testDevice, index) => (
                                  <MenuItem value={testDevice} >
                                    {testDevice}
                                  </MenuItem>
                                )
                                )

                                :
                                <MenuItem disabled>
                                  <Typography variant="h5">
                                    Please Select Bot
                                  </Typography>
                                </MenuItem>
                              }
                            </Select>
                          </FormControl>
                        ) : (
                          <FormControl sx={{ width: "100%" }} >
                            <Select
                              fullWidth
                              disabled
                              value={"No Test Devices for this Bot"}
                              onBlur={formik.handleBlur}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    overflow: "scroll",
                                    maxHeight: "40%",
                                    boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",

                                  },

                                },
                              }}
                            >
                              <MenuItem value={"No Test Devices for this Bot"}>
                                <Typography variant="h5">
                                  No Test Devices for this Bot
                                </Typography>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                    </Grid>
                    <Grid md={5}>
                      <Button variant="outlined" className="m-2" disabled={campaignState.controls.isSendingTestMessage || formik.values.selectedTestNumber === '' || formik.values.selectedBotId === '' || formik.values.selectedTemplateId === ''} onClick={() => { handleSendTestMessage(formik.values.selectedBotId, formik.values.selectedTemplateId, formik.values.selectedTestNumber) }}>
                        Send Test Message
                      </Button>
                    </Grid>


                  </Grid>

                </Grid>
                <Divider />
                <br />
                <Grid>
                  {/* <img src={imageSrc} alt="image.png" /> */}
                  <br />
                  <br />

                  <Typography variant="h6" color={'#2962ff'}>
                    For RCS  Upload list of phone numbers : (Get sample: CSV) maximun file size: 50MB
                  </Typography>

                  <pr>
                    The number should be prefixed with the country code (e.g., 919876543210 for India - Country Code 91). The recipient numbers should be under the column named 'Phone Number'. This should be the first column. The other fields should be in the other columns, for example, 'NAME', 'AMOUNT', 'BRANCH', etc., in no specific order. During campaign execution (at runtime), the custom parameters that you have added (if any) in the Rich Card Title and Rich Card Description (e.g., NAME, AMOUNT, etc.) will be replaced with the values in the columns of this file having the same headings (column names), for example, NAME, AMOUNT in this case.
                  </pr>

                  <br />
                  <br />
                  <Typography variant="h6" color={'#2962ff'}>
                    For SMS :
                  </Typography>
                  <pr>
                    In an SMS, variables are in this pattern:{" {#var#}"}. This means that whatever is contained within the # is an SMS variable, and you have to assign your variable to the SMS variable. For example, this is an SMS message before variable allocation: Dear Customer, your OTP is {"{#var#}"}. If you select 'phone number' as the variable, it will show the SMS message after variable allocation as: Dear Customer, your OTP is phone number. If the SMS variable is not present in the CSV file, add that variable (column) to the CSV file and assign your variable to the SMS variable.
                  </pr>

                </Grid>
                <br />
                <Grid>

                  <Button

                    onClick={getSampleCsvSignedUrl} disabled={Boolean(!formik.values.selectedTemplateId)}
                  >
                    Download Sample CSV
                  </Button>

                  <Button disabled={campaignState.controls.isCreatingCampaignLoading} onClick={() => uploadFileRef.current.click()}>
                    Upload CSV
                  </Button>
                  <Box className="btn-container">
                    <input
                      name="uploadedCSV"
                      ref={uploadFileRef}
                      accept=".csv"
                      // onChange={(e) =>
                      //   previewFile(
                      //     e.target.files[0],
                      //     "uploadedCSV.csvFileUrl",
                      //     "uploadedCSV.csvFileType"
                      //   )
                      // }
                      onChange={handleFileChange}
                      onClick={(e) => e.target.value = ""}
                      type="file"
                      hidden
                    />

                    {formik.errors.uploadedCSV?.csvFileUrl && (
                      <FormHelperText sx={{ color: 'error.main' }}>
                        {formik.errors.uploadedCSV?.csvFileUrl}
                      </FormHelperText>
                    )}
                  </Box>
                </Grid>
                {formik.values.uploadedCSV?.csvFileUrl &&
                  <Grid container>
                    <Grid item sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#e4eff7",
                      padding: "10px",
                      borderRadius: "5px"
                    }}>

                      <InsertDriveFileOutlinedIcon sx={{ margin: "5px" }} />
                      <Typography variant="button" sx={{ margin: "5px" }}>
                        {formik.values.uploadedCSV.csvFileName}
                      </Typography>
                      <a href={formik.values.uploadedCSV.csvFileUrl}>
                        <FileDownloadOutlinedIcon color="primary" sx={{ margin: "5px" }} />
                      </a>
                      <DeleteOutlinedIcon onClick={onClickDeleteCSVFile} color="error" sx={{ margin: "5px" }} />

                    </Grid>

                  </Grid>
                }

                {formik.values.uploadedCSV?.csvFileUrl && <> <Grid container padding={2} >
                  <FormControl >
                    <Box
                      item
                      display={"flex"}
                      alignItems={"center"}
                      gap={3}
                    >
                      <input
                        type="checkbox"
                        name="isDuplicateNumberAccepted"
                        onChange={formik.handleChange}
                        value={formik.values.isDuplicateNumberAccepted ? true : false}
                        checked={formik.values.isDuplicateNumberAccepted}

                      />
                      <Typography className='subHeadings' >
                        Enable Duplicate Numbers
                      </Typography>
                    </Box>
                  </FormControl>
                </Grid>
                </>}

                {formik.values.uploadedCSV?.csvFileUrl && <>
                  {formik.values.selectedTemplate.isSMSFallbackRequired && <Grid container padding={2} >
                    <FormControl >
                      <Box
                        item
                        display={"flex"}
                        alignItems={"center"}
                        gap={3}
                      >
                        <input
                          type="checkbox"
                          name="isSMSFallbackRequired"
                          onChange={formik.handleChange}
                          value={formik.values.isSMSFallbackRequired ? true : false}
                          checked={formik.values.isSMSFallbackRequired}

                        />
                        <Typography className='subHeadings' >
                          Enable fallback SMS
                        </Typography>
                      </Box>
                    </FormControl>
                  </Grid>}



                  {formik.values.isSMSFallbackRequired && smsVariablesCount.length > 0 &&
                    smsVariablesCount.map((variables, index) => (
                      <Grid sx={{ display: 'flex', alignItems: "center", width: "100%", padding: "10px" }}>
                        <Typography variant="body2">

                          Variable {index + 1} :
                        </Typography>

                        <FormControl size="small" fullWidth sx={{ m: 1, maxWidth: 200 }} error={Boolean(formik.errors.smsVariables && formik.errors?.smsVariables[index]
                        )} >
                          <InputLabel id={`smsVariables${index} - label`}>
                            Select Variables</InputLabel>
                          <Select
                            width={"500px"}
                            value={(formik.values.smsVariables.length > 0 && formik.values.smsVariables[index]) ? formik.values.smsVariables[index] : ''}
                            onChange={(e) => onChangeSMSVariable(e, index)}
                            label="Select Variables"
                            labelId={`smsVariables${index} - label`}
                            // id={`smsVariables${ index } - id`}
                            name={`smsVariables${index}`}
                            size="small"
                            // error={Boolean(
                            //   !formik.values.selectedBot && formik.errors.smsVariables &&
                            //   formik.touched?.smsVariables[index] &&
                            //   formik.errors?.smsVariables[index]
                            // )}
                            // helperText={formik.errors.smsVariables &&
                            //   formik.touched?.smsVariables[index] &&
                            //   formik.errors?.smsVariables[index]
                            // }
                            onBlur={formik.handleBlur}
                            // validate={validateSelectTemplate}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  boxShadow:
                                    " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",

                                },

                              },
                            }}
                          >
                            {csvVariables.map((val, index) => (
                              <MenuItem value={val}>{val}</MenuItem>)
                            )}

                          </Select>
                          <FormHelperText>
                            {formik.errors?.smsVariables &&
                              formik.errors?.smsVariables[index]}
                          </FormHelperText>
                        </FormControl>


                      </Grid>
                    ))}


                  {formik.values.isSMSFallbackRequired > 0 &&
                    <Typography sx={{ marginTop: "10px", padding: "10px" }} variant="h6" >
                      SMS Message : {smsMessage}
                    </Typography>
                  }
                </>}

                {/* </Grid>
              <Grid item md={4}> */}

                {/* </Grid>
            </Grid> */}

                <Grid container sx={{ alignItems: "center" }}>
                  <Grid item >

                    {campaignState.controls.isCreatingCampaignLoading ?
                      <Button variant="outlined">
                        <CircularProgress size={15} className="spinner" />
                        Creating..
                      </Button>
                      :
                      <Button variant="contained" className="m-2" type="submit">
                        Create Campaign
                      </Button>
                    }
                  </Grid>

                  <Grid item>
                    <Button
                      disabled={campaignState.controls.isCreatingCampaignLoading}
                      variant="contained"
                      color="error"
                      className="m-2"
                      onClick={onClickCancel}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={4} >
            {/* {
              selectedTemplate._id && */}
            <SimulatorComponent formik={{ values: selectedTemplate }} />

            {/* } */}
          </Grid>

        </Grid>
      </form>
      {showVariablesModal.open && (
        <VariablesModal
          open={showVariablesModal.open}
          handleClose={handleVariablesModalClose}
          variables={showVariablesModal.data}
          handleVariablesSubmit={handleVariablesSubmit}
        />
      )}

      {campaignState.controls.addNewCampaignErrorMessage && (

        <AlertMessageComponent
          message={campaignState.controls.addNewCampaignErrorMessage}
          fieldName={"addNewCampaignErrorMessage"}
          handleClose={onCloseErrorMessage}
          show={Boolean(campaignState.controls.addNewCampaignErrorMessage)}
        />
      )}
      {campaignState.controls.sendTestMessageError && (

        <AlertMessageComponent
          message={campaignState.controls.sendTestMessageError}
          fieldName={"sendTestMessageError"}
          handleClose={onCloseSendMessageError}
          show={Boolean(campaignState.controls.sendTestMessageError)}
        />
      )}
    </Box>




  );
}
export default AddCampaign;