import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { TaskAltOutlined } from "@mui/icons-material";
import { Alert, Button, CircularProgress, Grid, IconButton, InputAdornment, TextField as MuiTextField, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import userManagementAxiosInstance, { apiGatewayAxiosInstance } from "../../utils/axios";
import { config } from "../../config";
import SendOTP from "./SendOTP";
import axios from "axios";
import AlertMessageComponent from "../../pages/components/AlertMessageComponent";

// const Alert = styled(MuiAlert)(spacing)

const TextField = styled(MuiTextField)(spacing);
export default function ValidEmailToken ({ orgUserId, clientName, clientData }) {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [otpVerified, setOTPVerified] = useState(false);
    const [otpSent, setOTPsent] = useState(false);

    const [mobileNumberOTP, setMobileNumberOTP] = useState("");
    const [otpVerificationLoading, setOTPVerificationLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState("");

    const [otpLoading, setOTPLoading] = useState(false);

    const [password, setPassword] = useState(false);
    const [passwordLoading, setPasswordLoading] = useState(false);
    let param = useParams();

    const handleClickShowPassword = e => setShowPassword(show => !show);

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };

    const handleClickShowConfirmPassword = e => setShowConfirmPassword(show => !show);

    const handleMouseDownConfirmPassword = event => {
        event.preventDefault();
    };
    const sendOTPTomobileNumber = async () => {
        try {
            setOTPLoading(true);

            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/send-OTP`, {
                params: { registryId: clientData.registryId, orgId: clientData.orgId, roleName: clientData.roleName },
            });

            if (response.status === 200) {
                setOTPsent(true);
            }

            setOTPLoading(false);
        } catch (error) {
            // setPageLoading(false)
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
            setOTPLoading(false);
        }
    };
    const verifyOTPTomobileNumber = async () => {
        try {
            setOTPVerificationLoading(true);

            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/verify-OTP`, {
                params: {registryId: clientData.registryId, orgId: clientData.orgId, roleName: clientData.roleName, mobileNumberOTP },
            });
            if (response.status === 200) {
                setOTPVerified(true);
                setOTPVerificationLoading(false);
            }
        } catch (error) {
            setOTPVerificationLoading(false);
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    useEffect(() => {
        if (param && param.token) {
            sessionStorage.setItem("accessToken", param.token);
        }
    }, [param]);

    return (
        <div>
            {password ? (
                <Grid style={{ color: "green" }} align='center'>
                    <IconButton style={{ color: "green" }}>
                        <TaskAltOutlined />
                    </IconButton>
                    <Typography component='h2' variant='h4'>
                        Password set Successfully
                    </Typography>
                </Grid>
            ) : !otpVerified ? (
                <SendOTP
                    orgUserId={orgUserId}
                    clientData={clientData}
                    sendOTPTomobileNumber={sendOTPTomobileNumber}
                    mobileNumberOTP={mobileNumberOTP}
                    setMobileNumberOTP={setMobileNumberOTP}
                    verifyOTPTomobileNumber={verifyOTPTomobileNumber}
                    otpSent={otpSent}
                    otpLoading={otpLoading}
                    otpVerificationLoading={otpVerificationLoading}
                />
            ) : (
                <Formik
                    initialValues={{
                        password: "",
                        confirmPassword: "",
                        submit: false,
                    }}
                    validationSchema={Yup.object().shape({
                        password: Yup.string().min(10, "Must be at least 10 characters").max(255).required(" Enter Password"),
                        confirmPassword: Yup.string()
                            .min(10, "Must be at least 10 characters")
                            .max(255)
                            .required(" Enter Confirm Password")
                            .when("password", {
                                is: val => (val && val.length > 0 ? true : false),
                                then: Yup.string().oneOf([Yup.ref("password")], "Both password need to be the same"),
                            }),
                    })}
                    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                        try {
                            // setClientPassword(values.password)
                            // navigate('/auth/verify')
                            setPasswordLoading(true);
                            const response = await apiGatewayAxiosInstance.post(`/user_management/clients/set-password`, {
                                password: values.password,
                                orgUserId,
                            });

                            if (response.status === 200) {
                                setPasswordLoading(false);
                                setPassword(true);
                                // setTimeout(() => {
                                //     navigate('/auth/login')
                                // }, 3000)
                                navigate("/auth/login");
                            }
                        } catch (error) {
                            const message = error.message || "Something went wrong";
                            setPasswordLoading(false);
                            setStatus({ success: false });
                            setErrors({ submit: message });
                            setSubmitting(false);
                        }
                    }}
                >
                    {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            {errors.submit && (
                                <Alert mt={2} mb={1} severity='warning'>
                                    {errors.submit}
                                </Alert>
                            )}
                            <Typography component='h1' variant='h4' align='center' gutterBottom>
                                <b>{clientName} </b>
                            </Typography>
                            <Typography component='h2' variant='body1' align='center'>
                                Set Your Password
                            </Typography>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                name='password'
                                label='Password'
                                value={values.password}
                                error={Boolean(touched.password && errors.password)}
                                fullWidth
                                helperText={touched.password && errors.password}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                my={3}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position='end'
                                            style={{
                                                backgroundColor: "transparent",
                                            }}
                                        >
                                            <IconButton
                                                style={{
                                                    fontSize: "20px",
                                                    color: "gray",
                                                }}
                                                aria-label='toggle password visibility'
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge='end'
                                                size='small'
                                            >
                                                {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                type={showConfirmPassword ? "text" : "password"}
                                name='confirmPassword'
                                label='Confirm password'
                                value={values.confirmPassword}
                                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                fullWidth
                                helperText={touched.confirmPassword && errors.confirmPassword}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                my={3}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position='end'
                                            style={{
                                                backgroundColor: "transparent",
                                            }}
                                        >
                                            <IconButton
                                                style={{
                                                    fontSize: "20px",
                                                    color: "gray",
                                                }}
                                                aria-label='toggle password visibility'
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownConfirmPassword}
                                                edge='end'
                                                size='small'
                                            >
                                                {showConfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {passwordLoading ? (
                                <Button fullWidth variant='outlined'>
                                    <CircularProgress size={20} className='spinner' />
                                    Submitting...
                                </Button>
                            ) : (
                                <Button type='submit' fullWidth variant='contained' color='primary' disabled={isSubmitting}>
                                    Submit
                                </Button>
                            )}
                        </form>
                    )}
                </Formik>
            )}

            <AlertMessageComponent message={errorResponse} fieldName={""} handleClose={() => setErrorResponse("")} show={Boolean(errorResponse)} />
        </div>
    );
}
