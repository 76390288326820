import { CacheProvider } from "@emotion/react";
import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import "./i18n";
import routes from "./routes";
import createTheme from "./theme";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";
import createEmotionCache from "./utils/createEmotionCache";

import "react-image-crop/dist/ReactCrop.css";
import { AuthProvider } from "./contexts/JWTContext";
import { BUTTON_BACKGROUND_COLOR, REACT_APP_WHITELABEL_LOGO_URL } from "./utils/axios";
import useAuth from "./hooks/useAuth";

// import { AuthProvider } from "./contexts/FirebaseAuthContext";
// import { AuthProvider } from "./contexts/Auth0Context";
// import { AuthProvider } from "./contexts/CognitoContext";
//trigger build
const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
    const content = useRoutes(routes);

    const { theme } = useTheme();
    // const { user } = useAuth();

    // State to manage dynamic favicon
    const [favicon, setFavicon] = useState("");
    const whiteLabelLogo = REACT_APP_WHITELABEL_LOGO_URL;

    // Dynamically set favicon based on the REACT_APP_REGISTRY_NAME
    // useEffect(() => {
    //     setFavicon(REACT_APP_WHITELABEL_LOGO_URL);
    // }, [REACT_APP_WHITELABEL_LOGO_URL]);


    // localStorage.setItem("background-color", BUTTON_BACKGROUND_COLOR);

    useEffect(() => {
        if (REACT_APP_WHITELABEL_LOGO_URL) {
            setFavicon(REACT_APP_WHITELABEL_LOGO_URL);
        } else {
            setFavicon(whiteLabelLogo);
        }
    }, [REACT_APP_WHITELABEL_LOGO_URL]);

    return (
        <CacheProvider value={emotionCache}>
            <HelmetProvider>
                <Helmet
                    titleTemplate='%s | RCS Panel'
                    defaultTitle='RCS Panel'
                    link={[
                        {
                            rel: "icon",
                            type: "image/png",
                            href: favicon,
                            // href: "https://1spoc-staging-public-bucket.s3.ap-south-1.amazonaws.com/SSO_Registry_Logo_Temp/dummyLogoWL1.jpg",
                        },
                    ]}
                />
                <Provider store={store}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MuiThemeProvider theme={createTheme(theme)}>
                            <AuthProvider>{content}</AuthProvider>
                        </MuiThemeProvider>
                    </LocalizationProvider>
                </Provider>
            </HelmetProvider>
        </CacheProvider>
    );
}

export default App;
