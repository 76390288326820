import { CloseOutlined, CurrencyRupee } from "@mui/icons-material";
import {
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    tableCellClasses
} from "@mui/material";

import { styled } from "@material-ui/core";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OtherFields } from "../../../config";
import { setProviderRateHistory } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import TablePaginationShowPage from "../../../utils/tablePagination";
// import { showSnackbar } from '../store/snackbarSlice';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        fontWeight: 600,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        padding: "10px !important",
        width: "20vw",
    },
    [`&.${tableCellClasses.body}`]: {
        padding: "10px !important",
        fontSize: 14,
    },
    [`&.${tableCellClasses.head.tr}`]: {
        backgroundColor: "#79E0EE",
        width: "45vw",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ViewHistoryOfMasterRates ({
    open,
    handleClose,
    setViewHistoryOfMasterRates,
    providerDetailsByProviderName,
    providerDataByCountry,
    providerName,
}) {
    const dispatch = useDispatch();
    const state = useSelector(state => state.userManagementSlice);
    const [totalCount, setTotalCount] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const getHistoryOfProviderFieldsDetails = async (providerId, countryCode) => {
        const response = await apiGatewayAxiosInstance.get(`/billing_management/master-rate-card/rates-history-by-provider`, {
            params: {
                providerId: providerId,
                countryCode: countryCode,
                pageSize: pageSize,
                currentPage: currentPage,
            },
        });
        dispatch(setProviderRateHistory(response.data.data.findCurrentRates));
        setTotalCount(response.data.data.totalCount);
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    useEffect(() => {
        getHistoryOfProviderFieldsDetails(providerDataByCountry.providerId, providerDataByCountry.countryCode);
    }, [currentPage, pageSize]);

    const checkCurrentRate = (fromDate, toDate) => {
        //This function is to show current rates
        const currentDate = new Date();
        fromDate = fromDate ? new Date(fromDate) : null;
        toDate = toDate ? new Date(toDate) : null;

        // if (!isNaN(fromDate) && !isNaN(toDate)) {

        if (currentDate >= fromDate && currentDate <= toDate) {
            // Condition for Future present rates
            return "CURRENT";
        } else if (!isNaN(fromDate) && (!toDate || isNaN(toDate)) && currentDate >= fromDate) {
            // Condition for current rates
            return "CURRENT";
        } else {
            return null;
        }
        // } else {
        //     return null;
        // }
    };
    return (
        <Dialog open={open} onClose={handleClose} sx={{ padding: "1rem", width: "85%", marginLeft: "15%" }} maxWidth={"large"}>
            {/* <form onSubmit={formik.handleSubmit}> */}
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Grid display={"flex"}>
                    <Typography variant='h5'>
                        <b>History of Master Rates for</b>
                    </Typography>
                    <Typography variant='h5' className='text-primary'>
                        &nbsp;<b> {providerName}</b>
                    </Typography>
                </Grid>
                <IconButton onClick={() => handleClose()}>
                    <CloseOutlined />
                </IconButton>
            </DialogTitle>
            <Divider variant='middle' />
            <DialogContent>
                <Card className='border border-secondary-50 '>
                    <CardContent sx={{ padding: 0 }}>
                        <Grid
                            // mt={2}
                            sx={{
                                display: "flex ",
                                justifyContent: "center",
                                flexDirection: "column",
                                overflowY: "scroll !important",
                                overflowX: "hidden !important",
                            }}
                        >
                            {/* {state.controls.isUpdateUsersLoading ? ( */}
                            {/* <FetchingSpinner /> */}
                            {/* ) : ( */}
                            <>
                                {/* {state.masterRatesList && state.masterRatesList.length > 0 ? ( */}
                                <TableContainer
                                    component={Paper}
                                    className='table-container'
                                    sx={{
                                        height: "420px",
                                        overflowY: "scroll !important",
                                        overflowX: "hidden !important",
                                        width: "auto !important",
                                    }}
                                >
                                    <Table className='center' aria-label='table with sticky header' stickyHeader>
                                        <TableHead className='p-3 mb-2 row'>
                                            <TableRow>
                                                <StyledTableCell className='col-1 tableHeaderFont'>Sr No.</StyledTableCell>
                                                <StyledTableCell className='col-1 tableHeaderFont'>Provider Code</StyledTableCell>
                                                <StyledTableCell className='col-1 tableHeaderFont'>Country Code</StyledTableCell>
                                                {/* <StyledTableCell className='col-1 tableHeaderFont'>Short Code</StyledTableCell> */}
                                                <StyledTableCell className='col-1 tableHeaderFont'>Text Message Rate (INR)</StyledTableCell>
                                                <StyledTableCell className='col-1 tableHeaderFont'>Multi Media Message Rate (INR)</StyledTableCell>
                                                <StyledTableCell className='col-1 tableHeaderFont'>
                                                    A2P Session Conversation Rate (INR)
                                                </StyledTableCell>
                                                <StyledTableCell className='col-1 tableHeaderFont'>Feedback Rate (INR)</StyledTableCell>
                                                {providerDataByCountry &&
                                                    Object.keys(providerDataByCountry?.baseRate?.otherFields).map((provider, index) => (
                                                        <StyledTableCell className='col-1 tableHeaderFont'>
                                                            {OtherFields[provider] ?? provider} (INR)
                                                        </StyledTableCell>
                                                    ))}
                                                <StyledTableCell className='col-1 tableHeaderFont'>Effective from</StyledTableCell>
                                                <StyledTableCell className='col-1 tableHeaderFont'>Effective to</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        {
                                            <TableBody>
                                                {state.providerRateHistory &&
                                                    state.providerRateHistory.length > 0 &&
                                                    state.providerRateHistory.map((app, index) => (
                                                        <StyledTableRow>
                                                            <StyledTableCell className='tableContentFont' align='center'>
                                                                <b>{currentPage * pageSize + index + 1}</b>
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' className='tableContentFont'>
                                                                {app.providerCode}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' className='tableContentFont'>
                                                                {app.countryCode}
                                                            </StyledTableCell>

                                                            <StyledTableCell align='left' className='tableContentFont'>
                                                                <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                {app?.baseRate?.A2pMessageRate?.textMessageRate}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' className='tableContentFont'>
                                                                <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                {app?.baseRate?.A2pMessageRate?.multiMediaMessageRate}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' className='tableContentFont'>
                                                                <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                {app?.baseRate?.A2pMessageRate?.A2pSessionConversationRate}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='left' className='tableContentFont'>
                                                                <CurrencyRupee sx={{ fontSize: "14px" }} />

                                                                {app?.baseRate?.A2pMessageRate?.feedbackRate}
                                                            </StyledTableCell>

                                                            {providerDataByCountry?.baseRate?.otherFields &&
                                                                Object.keys(providerDataByCountry?.baseRate?.otherFields).map((provider, index) => (
                                                                    <StyledTableCell align='left'>
                                                                        <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                        {app?.baseRate?.otherFields && app?.baseRate?.otherFields[provider]}
                                                                    </StyledTableCell>
                                                                ))}

                                                            <StyledTableCell align='left' className='tableContentFont' sx={{ width: "800px !important" }}>
                                                                {app?.effectiveFrom &&
                                                                    (() => {
                                                                        const date = new Date(app.effectiveFrom);
                                                                        const options = {
                                                                            year: "numeric",
                                                                            month: "2-digit",
                                                                            day: "2-digit",
                                                                            hour: "2-digit",
                                                                            minute: "2-digit",
                                                                            hour12: true,
                                                                        };
                                                                        const formattedDate = date.toLocaleString("en-US", options);
                                                                        return formattedDate.replace(",", "");
                                                                    })()}
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                align='left'
                                                                className='tableContentFont'
                                                                sx={{
                                                                    width: "700px",
                                                                    border: checkCurrentRate(app.effectiveFrom, app.effectiveTo)
                                                                        ? "2px solid green"
                                                                        : "none",
                                                                    padding: "16px",
                                                                    borderRadius: "4px",
                                                                }}
                                                            >
                                                                <Grid>
                                                                    <Typography className='tableContentFont'>
                                                                        {app?.effectiveTo
                                                                            ? (() => {
                                                                                  const date = new Date(app.effectiveTo);
                                                                                  const options = {
                                                                                      year: "numeric",
                                                                                      month: "2-digit",
                                                                                      day: "2-digit",
                                                                                      hour: "2-digit",
                                                                                      minute: "2-digit",
                                                                                      hour12: true,
                                                                                  };
                                                                                  const formattedDate = date.toLocaleString("en-US", options);
                                                                                  return formattedDate.replace(",", "");
                                                                              })()
                                                                            : ""}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid>
                                                                    <Typography fontSize={"0.8rem"} fontWeight={600} color={"green"}>
                                                                        {
                                                                            // app?.effectiveFrom &&
                                                                            //     app?.effectiveTo &&
                                                                            checkCurrentRate(app.effectiveFrom, app.effectiveTo)
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))}
                                            </TableBody>
                                        }
                                    </Table>
                                </TableContainer>
                                {/* // ) : ( */}
                                {/* <NoRecords /> */}
                                {/* // )} */}
                            </>
                            {/* // )} */}
                        </Grid>

                        {totalCount > 0 && (
                            <Grid
                                className='my-2'
                                style={{
                                    zIndex: "2",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: 2,
                                }}
                            >
                                <Grid
                                    style={{
                                        marginTop: "15px",
                                        padding: 5,
                                    }}
                                >
                                    Total Master Rates: {totalCount}
                                </Grid>
                                <Grid>
                                    <TablePaginationShowPage
                                        rowsPerPageOptions={[5, 10, 25, 100]}
                                        colSpan={3}
                                        count={totalCount}
                                        rowsPerPage={pageSize}
                                        page={currentPage}
                                        slotProps={{
                                            select: {
                                                "aria-label": "Rows per page",
                                            },
                                            actions: {
                                                showFirstButton: true,
                                                showLastButton: true,
                                                slots: {
                                                    firstPageIcon: FirstPageRoundedIcon,
                                                    lastPageIcon: LastPageRoundedIcon,
                                                    nextPageIcon: ChevronRightRoundedIcon,
                                                    backPageIcon: ChevronLeftRoundedIcon,
                                                },
                                            },
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions
                style={{
                    padding: "1rem 1.5rem",
                    display: "flex",
                    justifyContent: "flex-end",
                }}
            ></DialogActions>
        </Dialog>
    );
}
