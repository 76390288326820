import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import EditIcon from "@mui/icons-material/Edit";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import _debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { ClientStatus } from "../../../../config";
import useAuth from "../../../../hooks/useAuth";
import { clearErrorMessagesForAllUsers, setShowAddResellerForm } from "../../../../redux/userManagement/userManagementSlice";
import { getAllResellerByFilterThunk } from "../../../../redux/userManagement/userManagementThunk";
import { canShowForSuperAdminOrResellerAdmin } from "../../../../utils/canShowForSuperAdminOrResellerAdmin";
import TablePaginationShowPage from "../../../../utils/tablePagination";
import Page401 from "../../../auth/Page401";
import AlertMessageComponent from "../../../components/AlertMessageComponent";
import FetchingSpinner from "../../../components/FetchingSpinner";
import NoRecords from "../../../components/NoRecords";
import FilterAllResellerModel from "./FilterAllResellerModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ListReseller ({
    searchParams,
    setSearchParams,
    handleEditDetailsChange,
    setClientDetails,
    isLoadingClientDetails,
    setIsBrandNotVerified,
}) {
    const state = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();
    const [searchedClientName, setSearchedClientName] = useState("");
    const [branch, setBranch] = useState(null);
    const [executive, setExecutive] = useState(null);
    const [manager, setManager] = useState(null);
    const [status, setStatus] = useState("Active");
    const [currentPage, setCurrentPage] = useState(0);
    const [debouncedValue, setDebouncedValue] = useState("");
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();

    const [pageSize, setPageSize] = React.useState(10);
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState("");

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // setSearchParams({});
        setOpen(false);
    };

    const onClickProviders = reseller => {
        const dataToSend = {
            registryId: reseller.reseller.registryId,
            resellerName: reseller.reseller.resellerDetails.resellerName,
            countryCode: reseller.reseller.countryCode,
            supportedProviders:reseller.reseller.resellerDetails.supportedProviders,
            orgName: reseller?.orgDetails?.orgName,
        };
        navigate("/admin/manage-reseller-provider", { state: dataToSend });

    };



    const onClickAddNewReseller = () => {
        setClientDetails({});
        setIsBrandNotVerified(true);
        dispatch(setShowAddResellerForm(true));
    };

    const onClickEditResellerDetails = async ({ client, isEditable }) => {
        try {
            handleEditDetailsChange({
                clientData: client,
                val: isEditable,
            });
        } catch (error) {
            setLoading(false);
            let errorMessage = "Failed to resend mail.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    const debouncedSearch = _debounce(value => {
        setDebouncedValue(value);
    }, 1000);

    useEffect(() => {
        dispatch(
            getAllResellerByFilterThunk({
                currentPage: currentPage + 1,
                pageSize,
                searchParams,
            }),
        );
    }, [currentPage, pageSize]);

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForAllUsers({ fieldName }));
    };
    const handleClickSearch = async searchParams => {
        setCurrentPage(0);

        setSearchParams(searchParams);

        dispatch(
            getAllResellerByFilterThunk({
                currentPage: currentPage + 1,
                pageSize,
                searchParams,
            }),
        );
        handleClose();
    };

    return canShowForSuperAdminOrResellerAdmin(user) ? (
        <Box>
            <Grid container mb={4} className='listHeader'>
                <Grid item md={6}>
                    <Typography variant='h3'>Resellers</Typography>
                </Grid>
                <Grid item md={6} className='listHeaderFilter'>
                    <Grid width={"100%"} justifyContent={"flex-end"} display={"flex"}>
                        <Button sx={{ marginRight: "10px" }} variant='contained' onClick={handleClickOpen}>
                            Filter
                        </Button>
                        <Button variant='contained' onClick={onClickAddNewReseller}>
                            Add New Reseller
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <Card className='border border-secondary-50'>
                    <CardContent sx={{ padding: 0 }}>
                        {state.controls.isGetAllResellerLoading ? (
                            <FetchingSpinner />
                        ) : state?.getAllResellers && state.getAllResellers?.length > 0 ? (
                            <TableContainer component={Paper} className='table-container' sx={{ maxHeight: "70vh" }}>
                                <Table stickyHeader aria-label='customized table'>
                                    <TableHead className='p-3 mb-2 row'>
                                        <TableRow>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                                Sr No
                                            </StyledTableCell>
                                            <StyledTableCell className='col-2 tableHeaderFont' align='left'>
                                                Reseller Name
                                            </StyledTableCell>

                                            <StyledTableCell className='col-2 tableHeaderFont' align='left'>
                                                Admin
                                            </StyledTableCell>

                                            <StyledTableCell className='col-2 tableHeaderFont' align='left'>
                                                Reseller Website URL
                                            </StyledTableCell>

                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Mobile
                                            </StyledTableCell>

                                            <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                                Status
                                            </StyledTableCell>

                                            {/* <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                                WhiteLabel
                                            </StyledTableCell> */}
                                            <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                                Action
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                                Providers
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.getAllResellers?.map((reseller, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    {currentPage * pageSize + index + 1}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {reseller.resellerDetails.resellerName}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    <Grid display={"flex"} flexDirection={"column"}>
                                                        <Grid>
                                                            Name: <Typography variant={"subtitle"}>{reseller.name}</Typography>
                                                        </Grid>
                                                        <Grid>
                                                            <Typography variant={"body1"}>Email: {reseller.userName}</Typography>
                                                            {reseller.email !== reseller.userName && (
                                                                <Typography variant={"body1"}>(Not Verified)</Typography>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {reseller.resellerDetails.resellerWebsiteUrl}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {reseller.mobileNumber}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {reseller.resellerDetails.status == ClientStatus.Active ? (
                                                        <label
                                                            className='text-success centered'
                                                            style={{
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            <Grid className='active-dot'></Grid>
                                                            {reseller.resellerDetails.status}
                                                        </label>
                                                    ) : (
                                                        <label
                                                            className='text-danger centered'
                                                            style={{
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            <Grid className='inactive-dot'></Grid>
                                                            {reseller.resellerDetails.status}
                                                        </label>
                                                    )}
                                                </StyledTableCell>

                                                {/* <StyledTableCell className='tableContentFont' align='center'>
                                                    {reseller?.orgDetails?.privileges?.isWhiteLabel === true ? (
                                                        <Chip variant='outlined' className='button_width chip' label={"Enabled"} color='success' />
                                                    ) : (
                                                        <Chip variant='outlined' className='button_width chip' label={"Disabled"} color='error' />
                                                    )}
                                                </StyledTableCell> */}

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    <Stack
                                                        direction='row'
                                                        spacing={2}
                                                        sx={{
                                                            alignItems: "center",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        {isLoadingClientDetails.status && isLoadingClientDetails.id == reseller._id ? (
                                                            <CircularProgress size={20} />
                                                        ) : (
                                                            <Button
                                                                onClick={() => {
                                                                    onClickEditResellerDetails({
                                                                        client: reseller,
                                                                        isEditable: true,
                                                                    });
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </Button>
                                                        )}
                                                    </Stack>
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    <Button
                                                        onClick={() => {
                                                            onClickProviders({
                                                                reseller,
                                                            });
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <NoRecords />
                        )}
                       
                        {state.totalResellerCount > 0 && (
                            <Grid
                                className='my-2'
                                style={{
                                    zIndex: "2",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: 2,
                                }}
                            >
                                <Grid
                                    style={{
                                        zIndex: "2",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        padding: 5,
                                    }}
                                >
                                    <Grid
                                        style={{
                                            marginTop: "15px",
                                        }}
                                    >
                                        Total Resellers: {state.totalResellerCount}
                                    </Grid>
                                    <Grid justifyContent={"center"} mt={3}>
                                        {/* <TablePagination
                                            count={state.totalResellerCount}
                                            component='Grid'
                                            variant='outlined'
                                            color='primary'
                                            page={currentPage}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={pageSize}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        /> */}

                                        <TablePaginationShowPage
                                            rowsPerPageOptions={[5, 10, 25, 100]}
                                            colSpan={3}
                                            count={state.totalResellerCount}
                                            rowsPerPage={pageSize}
                                            page={currentPage}
                                            slotProps={{
                                                select: {
                                                    "aria-label": "Rows per page",
                                                },
                                                actions: {
                                                    showFirstButton: true,
                                                    showLastButton: true,
                                                    slots: {
                                                        firstPageIcon: FirstPageRoundedIcon,
                                                        lastPageIcon: LastPageRoundedIcon,
                                                        nextPageIcon: ChevronRightRoundedIcon,
                                                        backPageIcon: ChevronLeftRoundedIcon,
                                                    },
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>

                    {state.controls.getAllClientsErrorMessage && (
                        <AlertMessageComponent
                            message={state.controls.getAllClientsErrorMessage}
                            fieldName={"getAllClientsErrorMessage"}
                            handleClose={onCloseErrorMessage}
                            show={Boolean(state.controls.getAllClientsErrorMessage)}
                        />
                    )}
                    {open && (
                        <FilterAllResellerModel
                            open={open}
                            searchParams={searchParams}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            setSearchParams={setSearchParams}
                            handleClickSearch={handleClickSearch}
                        />
                    )}
                </Card>
            </Grid>
        </Box>
    ) : (
        <Page401 />
    );
}
