import { useTheme } from "@emotion/react";
import {CurrencyRupee, ArrowBackIos, Edit } from "@mui/icons-material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import HistoryIcon from "@mui/icons-material/History";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Checkbox,
    Grid,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OtherFields } from "../../../config";
import { clearErrorMessageForBaseRate } from "../../../redux/userManagement/userManagementSlice";
import TablePaginationShowPage from "../../../utils/tablePagination";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import FetchingSpinner from "../../components/FetchingSpinner";
import NoRecords from "../../components/NoRecords";
import EditCountryRateDetails from "./EditCountryRateDetails";
import ViewHistoryOfMasterRates from "./ViewHistoryOfMasterRates";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 14,
        fontWeight: 600,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        padding: "10px !important",
        width: "20vw",
    },
    [`&.${tableCellClasses.body}`]: {
        padding: "10px !important",
        fontSize: 14,
    },
    [`&.${tableCellClasses.head.tr}`]: {
        backgroundColor: "#79E0EE",
        width: "45vw",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function ListMasterRate ({
    setRateForm,
    setUpdateRateCard,
    setCountriesToCustomizeRateCard,
    countriesToCustomizeRateCard,
    selectProviderName,
    setSelectProviderName,
    providerDetailsByProviderName,
    getAllMasterRates,
    setOtherFields,
    otherFields,
    setPageSize,
    setCurrentPage,
    currentPage,
    pageSize,
    providerName,
    providerCurrency,
}) {
    const state = useSelector(state => state.userManagementSlice);
    const botState = useSelector(state => state.botManagementSlice);

    const [editCountryRateDetails, setEditCountryRateDetails] = useState(false);
    const [countryRateDetails, setCountryRateDetails] = useState({});

    const [selected, setSelected] = useState([]);
    const [rateData, setRateData] = useState(state.masterRatesList);
    const [uploadCsvModal, setUploadCsvModal] = useState(false);
    const [viewHistoryOfMasterRates, setViewHistoryOfMasterRates] = useState(false);
    const [providerDataByCountry, setProviderDataByCountry] = useState();
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const openAddCountryRate = () => {
        let providerOtherFieldToNumber = {};
        if (providerDetailsByProviderName && Object.keys(providerDetailsByProviderName?.otherFields).length > 0) {
            Object.keys(providerDetailsByProviderName?.otherFields).map((fieldElement, index) => {
                providerOtherFieldToNumber[fieldElement] = 0;
            });
        }

        setOtherFields(providerOtherFieldToNumber);
        setRateForm(true);
    };
    const openUpdateRate = () => {
        setUpdateRateCard(true);
    };

    const closeViewHistoryMasterRates = () => {
        setViewHistoryOfMasterRates(false);
    };

    const openViewHistoryMasterRates = () => {
        setViewHistoryOfMasterRates(true);
    };

    // useEffect(() => {
    //     fetchBaseRate()
    // }, [updateCountryRateCardModel])
    const onClickEdit = app => {
        setEditCountryRateDetails(true);
        setCountryRateDetails(app);
        // window.reload()
    };

    const onClickViewHistory = app => {
        setViewHistoryOfMasterRates(true);
        setProviderDataByCountry(app);
    };
    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessageForBaseRate({ fieldName }));
    };

    const handleSelectAllClick = () => {
        if (selected.length === state?.masterRatesList?.length) {
            setSelected([]);
            setCountriesToCustomizeRateCard([]);
            for (let index = 0; index < state?.masterRatesList.length; index++) {
                const element = state?.masterRatesList[index];
            }
        } else {
            setSelected(Array.from({ length: state?.masterRatesList?.length }, (_, index) => index));
            setCountriesToCustomizeRateCard([...state?.masterRatesList]);
        }
    };

    const handleSelect = (index, app, e) => {
        const selectedIndex = selected.indexOf(index);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        if (e.target.checked) {
            setCountriesToCustomizeRateCard([...countriesToCustomizeRateCard, app]);
        } else {
            const a = countriesToCustomizeRateCard.filter(selectedItem => selectedItem._id !== app._id);
            setCountriesToCustomizeRateCard(a);
        }
    };

    const onChangeSelectProvider = event => {
        const { name, value } = event.target;
        setSelectProviderName(value);
    };

    const handleImport = () => {
        setUploadCsvModal(true);
    };

    const onCloseUploadCsvModal = () => {
        setUploadCsvModal(false);
        // handleClose();
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const onClickCancelButton = e => {
        navigate("/admin/manage-provider");
    };

    // useEffect(() => {
    //     if (botState.providersList.length > 0) {
    //         const providerDetails = botState.providersList.filter(
    //             provider => provider.name === 'VI'
    //         )[0]
    //         setSelectProviderName(providerDetails)
    //         getAllMasterRates(providerDetails.name)
    //         getProviderFieldsDetails(providerDetails.name)
    //     } else {
    //         dispatch(getAllProvidersThunk())
    //     }
    // }, [botState.providersList, updateCountryRateCardModel])

    return (
        <>
            <Box>
                <Grid container mb={4} className='listHeader' justifyContent={"space-between"}>
                    <Grid item md={6} display={"flex"} alignItems={"center"}>
                        <IconButton color='secondary' onClick={onClickCancelButton} size='medium'>
                            <ArrowBackIos sx={{ cursor: "pointer" }} />
                        </IconButton>

                        <Grid role='presentation'>
                            <Breadcrumbs aria-label='breadcrumb' fontSize='large'>
                                <Link
                                    underline='hover'
                                    color='inherit'
                                    className='breadcrumbItem breadcrumbFontSize'
                                    // href="/rcs-management/manage-bots/"
                                    onClick={onClickCancelButton}
                                >
                                    Provider
                                </Link>
                                <Typography className='breadcrumbItem' color='text.primary'>
                                    {providerName}
                                </Typography>
                                <Typography className='breadcrumbItem' color='text.primary'>
                                    Master Rates
                                </Typography>
                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    {/* <Grid item >
                        <Typography variant='h3'>Master Rates</Typography>
                    </Grid> */}
                    <Grid item gap={2} className='listHeaderFilter'>
                        {/* {selectProviderName && (
                            <Grid>
                                <FormControl
                                    size='small'
                                    sx={{
                                        display: "flex",
                                        width: "225px",
                                        // marginLeft: '390px'
                                    }}
                                >
                                    <InputLabel id='provider-label'>Select Provider</InputLabel>
                                    <Select
                                        name='provider_name'
                                        labelId='provider_name'
                                        id='provider_name'
                                        label='Select Provider'
                                        value={selectProviderName}
                                        onChange={onChangeSelectProvider}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                },
                                            },
                                        }}
                                    >
                                        {botState?.providersList?.map((provider, index) => (
                                            <MenuItem key={index} value={provider}>
                                                {provider.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        )} */}

                        <Button disabled={countriesToCustomizeRateCard.length === 0} variant='contained' onClick={() => openUpdateRate()}>
                            Increase / Decrease Rates
                        </Button>

                        <Button sx={{ marginRight: "10px" }} variant='contained' onClick={() => openAddCountryRate()}>
                            Add Country Rate
                        </Button>
                    </Grid>
                </Grid>
                <div>
                    <Card className='border border-secondary-50 '>
                        <CardContent sx={{
                            padding: 0,
                        }}>
                            <Grid
                                // mt={2}
                                sx={{
                                    display: "flex ",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                {state.controls.isGetAllRatesLoading ? (
                                    <FetchingSpinner />
                                ) : (
                                    <>
                                        {state.masterRatesList && state.masterRatesList.length > 0 ? (
                                            <TableContainer
                                                component={Paper}
                                                className='table-container'
                                                sx={{
                                                    height: "420px",
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                    width: "auto !important",
                                                }}
                                            >
                                                <Table className='center' aria-label='table with sticky header' stickyHeader>
                                                    <TableHead className='p-3 mb-2 row'>
                                                        <TableRow>
                                                            <StyledTableCell className='tableHeaderFont' align='left'>
                                                                <Checkbox
                                                                    sx={{
                                                                        color: "rgba(255, 255, 255, 0.7)", // Default color (when not checked)
                                                                        "&.Mui-checked": {
                                                                            color: "white", // Color when checked
                                                                            backgroundColor: "rgba(255, 255, 255, 0.1)", // Background color when checked
                                                                            borderRadius: "4px", // Rounded corners
                                                                        },
                                                                    }}
                                                                    // checked={!!selected}
                                                                    checked={countriesToCustomizeRateCard.length === state.masterRatesList.length}
                                                                    onChange={handleSelectAllClick}
                                                                    color='primary'
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                                Sr No
                                                            </StyledTableCell>
                                                            {/* <StyledTableCell className='col-1 tableHeaderFont'>Provider</StyledTableCell> */}
                                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                                Country
                                                            </StyledTableCell>
                                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                                Country Code
                                                            </StyledTableCell>
                                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                                Short Code
                                                            </StyledTableCell>
                                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                                Text Message Rate ({providerCurrency})
                                                            </StyledTableCell>
                                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                                Multi Media Message Rate ({providerCurrency})
                                                            </StyledTableCell>
                                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                                A2P Session Conversation Rate ({providerCurrency})
                                                            </StyledTableCell>
                                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                                Feedback Rate ({providerCurrency})
                                                            </StyledTableCell>
                                                            {providerDetailsByProviderName &&
                                                                Object.keys(providerDetailsByProviderName).length > 0 &&
                                                                Object.keys(providerDetailsByProviderName).includes("otherFields") &&
                                                                Object.keys(providerDetailsByProviderName?.otherFields).length > 0 &&
                                                                Object.keys(providerDetailsByProviderName?.otherFields).map((provider, index) => (
                                                                    <StyledTableCell className='col-1 tableHeaderFont'>
                                                                        {OtherFields[provider] ?? provider} ({providerCurrency})
                                                                    </StyledTableCell>
                                                                ))}

                                                            <StyledTableCell className='col-1 tableHeaderFont'>Edit</StyledTableCell>
                                                            <StyledTableCell className='col-1 tableHeaderFont'>History</StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {
                                                        <TableBody>
                                                            {state.masterRatesList.length > 0 &&
                                                                state.masterRatesList.map((app, index) => (
                                                                    <StyledTableRow>
                                                                        <TableCell className='tableContentFont' align='left'>
                                                                            <Checkbox
                                                                                checked={
                                                                                    countriesToCustomizeRateCard.filter(
                                                                                        selectedCountry => selectedCountry._id === app._id,
                                                                                    ).length > 0
                                                                                }
                                                                                onChange={e => handleSelect(index, app, e)}
                                                                                color='primary'
                                                                            />
                                                                        </TableCell>

                                                                        <StyledTableCell align='left' className='tableContentFont'>
                                                                            {currentPage * pageSize + index + 1}
                                                                        </StyledTableCell>
                                                                        {/* <StyledTableCell align='left' className='tableContentFont'>
                                                                            {app.providerName}
                                                                        </StyledTableCell> */}
                                                                        <StyledTableCell align='left' className='tableContentFont'>
                                                                            {app.countryName}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align='left' className='tableContentFont'>
                                                                            {app.countryCode}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align='left' className='tableContentFont'>
                                                                            {app.shortCode}
                                                                        </StyledTableCell>

                                                                        <StyledTableCell align='left' className='tableContentFont'>
                                                                            {/* <CurrencyRupee sx={{ fontSize: "14px" }} /> */}
                                                                            {app?.baseRate?.A2pMessageRate?.textMessageRate}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align='left' className='tableContentFont'>
                                                                            {/* <CurrencyRupee sx={{ fontSize: "14px" }} /> */}
                                                                            {app?.baseRate?.A2pMessageRate?.multiMediaMessageRate}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align='left' className='tableContentFont'>
                                                                            {/* <CurrencyRupee sx={{ fontSize: "14px" }} /> */}
                                                                            {app?.baseRate?.A2pMessageRate?.A2pSessionConversationRate}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align='left' className='tableContentFont'>
                                                                            {/* <CurrencyRupee sx={{ fontSize: "14px" }} /> */}

                                                                            {app?.baseRate?.A2pMessageRate?.feedbackRate}
                                                                        </StyledTableCell>

                                                                        {providerDetailsByProviderName?.otherFields &&
                                                                            Object.keys(providerDetailsByProviderName?.otherFields).map(
                                                                                (provider, index) => (
                                                                                    <StyledTableCell align='left'>
                                                                                        {/* <CurrencyRupee sx={{ fontSize: "14px" }} /> */}
                                                                                        {app?.baseRate?.otherFields &&
                                                                                            app?.baseRate?.otherFields[provider]}
                                                                                    </StyledTableCell>
                                                                                ),
                                                                            )}

                                                                        <StyledTableCell className='tableContentFont'>
                                                                            <Tooltip title='Edit Rate Details'>
                                                                                <IconButton onClick={() => onClickEdit(app)}>
                                                                                    <Edit fontSize='small' />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className='tableContentFont'>
                                                                            <Tooltip title='View History'>
                                                                                <IconButton onClick={() => onClickViewHistory(app)}>
                                                                                    <HistoryIcon fontSize='small' />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </StyledTableCell>
                                                                    </StyledTableRow>
                                                                ))}
                                                        </TableBody>
                                                    }
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <NoRecords />
                                        )}
                                    </>
                                )}
                            </Grid>
                            {state.masterRatesTotalCount > 0 && (
                                <Grid
                                    className='my-2'
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: 10
                                    }}
                                >
                                    <Grid
                                        style={{
                                            marginTop: "15px",
                                            padding: 5,
                                        }}
                                    >
                                        Total Master Rates: {state.masterRatesTotalCount}
                                    </Grid>
                                    <Grid>
                                        {/* <TablePagination
                                        count={
                                            state.masterRatesTotalCount
                                            /// rowsPerPage
                                        }
                                        component='Grid'
                                        variant='outlined'
                                        color='primary'
                                        page={botDetails.currentPage - 1}
                                        // onChange={handlePageChange}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={pageSize}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    /> */}

                                        <TablePaginationShowPage
                                            rowsPerPageOptions={[5, 10, 25, 100]}
                                            colSpan={3}
                                            count={state.masterRatesTotalCount}
                                            rowsPerPage={pageSize}
                                            page={currentPage}
                                            slotProps={{
                                                select: {
                                                    "aria-label": "Rows per page",
                                                },
                                                actions: {
                                                    showFirstButton: true,
                                                    showLastButton: true,
                                                    slots: {
                                                        firstPageIcon: FirstPageRoundedIcon,
                                                        lastPageIcon: LastPageRoundedIcon,
                                                        nextPageIcon: ChevronRightRoundedIcon,
                                                        backPageIcon: ChevronLeftRoundedIcon,
                                                    },
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </CardContent>
                    </Card>
                    {editCountryRateDetails && (
                        <EditCountryRateDetails
                            updateCountryRateCardModel={editCountryRateDetails}
                            setEditCountryRateDetails={setEditCountryRateDetails}
                            countryRateDetails={countryRateDetails}
                            selectProviderName={selectProviderName}
                            getAllMasterRates={getAllMasterRates}
                            otherFields={otherFields}
                            providerDetailsByProviderName={providerDetailsByProviderName}
                        />
                    )}
                    {viewHistoryOfMasterRates && (
                        <ViewHistoryOfMasterRates
                            open={openViewHistoryMasterRates}
                            handleClose={closeViewHistoryMasterRates}
                            setViewHistoryOfMasterRates={setViewHistoryOfMasterRates}
                            providerDetailsByProviderName={providerDetailsByProviderName}
                            providerDataByCountry={providerDataByCountry}
                            providerName={providerName}
                        />
                    )}
                </div>
                {state.controls.getMasterRateListErrorMessage && (
                    <AlertMessageComponent
                        message={state.controls.getMasterRateListErrorMessage}
                        fieldName={"getMasterRateListErrorMessage"}
                        handleClose={onCloseErrorMessage}
                        show={Boolean(state.controls.getMasterRateListErrorMessage)}
                    />
                )}
                {/* state.controls.getMasterRateListErrorMessage */}
            </Box>
        </>
    );
}

export default ListMasterRate;
