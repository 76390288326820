import { CloseOutlined } from "@mui/icons-material";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorMessageForBaseRate, setErrorMessageForUser } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance, orgId } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import useAuth from "../../../hooks/useAuth";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ERoles } from "../../../config";

export default function UpdateRateByClientIdModal({
    open,
    handleClose,
    clientData,
    providerDetailsByProviderName,
    otherFields,
    // selectProviderName,
    providerId,
    providerCode,
    getAllCustomizedRate,
}) {
    const dispatch = useDispatch();
    const currentDateTime = new Date();
    const [isUpdateCustomizedRateLoading, setIsUpdateCustomizedRateLoading] = useState(false);
    const state = useSelector(state => state.userManagementSlice);
    const { user } = useAuth();
    const [selectedDate, setSelectedDate] = useState(currentDateTime);
    const handleDateChange = date => {
        setSelectedDate(date);
    };

    const initialValues = {
        registryId: clientData._id,
        _id: clientData._id,
        updatedTextMessageRate: clientData?.rate?.customizedRate?.A2pMessageRate?.textMessageRate ?? "",
        updatedMultiMediaMessageRate: clientData?.rate?.customizedRate?.A2pMessageRate?.multiMediaMessageRate ?? "",
        updatedA2pSessionConversationRate: clientData?.rate?.customizedRate?.A2pMessageRate?.A2pSessionConversationRate ?? "",
        updatedFeedbackRate: clientData?.rate?.customizedRate?.A2pMessageRate?.feedbackRate ?? "",
        otherFields: clientData?.rate?.customizedRate?.otherFields ?? otherFields,
    };
    const updateCustomizedRate = async ({ data }) => {
        try {
            setIsUpdateCustomizedRateLoading(true);
            const response = await apiGatewayAxiosInstance.post(`/user_management/customized-rate/update`, data, {
                // params: { providerName: selectProviderName.name },
                params: {
                    providerId: providerId,
                    providerCode: providerCode,
                    countryCode: clientData?.rate?.countryCode,
                    currentBaseRateId: clientData?.rate?.currentBaseRateId,
                },
            });

            if (response.status === 200) {
                setIsUpdateCustomizedRateLoading(false);
                handleClose();
                getAllCustomizedRate({ providerId: providerId, providerCode: providerCode });
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Failed to update customized rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setIsUpdateCustomizedRateLoading(false);
            dispatch(
                setErrorMessageForUser({
                    fieldName: "updateCustomizedRateErrorMessage",
                    errorMessage,
                }),
            );
            throw error;
        }
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessageForBaseRate({ fieldName }));
    };
    const validateIfValueIsEmpty = fieldName => {
        const falsyValuesToValidate = [null, undefined, NaN];

        const fieldValue = formik.values[fieldName];
        if (falsyValuesToValidate.includes(fieldValue) || fieldValue === "") {
            formik.setFieldError(fieldName, `Required`);
            return false;
        }
        return true;
    };

    const validateIfValueIsEmptyForOtherFields = fieldName => {
        const fieldValue = formik.values.otherFields[fieldName];
        if (fieldValue === "" || fieldValue === null) {
            formik.setFieldError(`otherFields.${fieldName}`, `Required`);
            return false;
        } else {
            return true;
        }
    };
    const validation = () => {
        let result = true;

        if (!validateIfValueIsEmpty("updatedTextMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedMultiMediaMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedA2pSessionConversationRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedFeedbackRate")) {
            result = false;
        }

        for (let index = 0; index < Object.keys(initialValues.otherFields).length; index++) {
            const otherRate = Object.keys(initialValues.otherFields)[index];
            if (!validateIfValueIsEmptyForOtherFields(otherRate)) {
                result = false;
            }
        }

        return result;
    };

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                if (validation()) {
                    // let dataToSend = {

                    //     _id: clientData.orgId,
                    //     A2pMessageRate: {
                    //         textMessageRate: values.updatedTextMessageRate,
                    //         multiMediaMessageRate: values.updatedMultiMediaMessageRate,
                    //         A2pSessionConversationRate: values.updatedA2pSessionConversationRate,
                    //         feedbackRate: values.updatedFeedbackRate,
                    //     },
                    //     otherFields: values.otherFields,
                    // };
                    let registryIdToSend;
                    let orgIdToSend;
                    if (clientData.roleName === ERoles.ORG_ADMIN) {
                        registryIdToSend = clientData.registryId;
                        orgIdToSend = clientData.orgId;
                    } else {
                        registryIdToSend = clientData._id;
                        orgIdToSend = "";
                    }

                    let dataToSend = {
                        registryId: registryIdToSend,
                        orgId: orgIdToSend,
                        effectiveFrom: selectedDate,
                        customizedProviderRate: {
                            [providerCode]: {
                                providerId: providerId, // Dynamically use the existing providerId
                                providerCode: providerCode, // Dynamically use the existing providerCode
                                A2pMessageRate: {
                                    textMessageRate: values.updatedTextMessageRate, // Use updated values
                                    multiMediaMessageRate: values.updatedMultiMediaMessageRate,
                                    A2pSessionConversationRate: values.updatedA2pSessionConversationRate,
                                    feedbackRate: values.updatedFeedbackRate,
                                },
                                otherFields: values.otherFields, // Use updated otherFields
                            },
                        },
                    };

                    updateCustomizedRate({ data: dataToSend });
                }
            } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
            }
        },
    });

    useEffect(() => { }, [user]);

    return (
        <Dialog open={open} onClose={handleClose} sx={{ padding: "1rem" }} fullWidth maxWidth={"sm"}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Grid display={"flex"}>
                        <Typography variant='h5'>
                            <b>Update rates for </b>
                        </Typography>
                        <Typography variant='h5' className='text-primary'>
                            <b>&nbsp;{clientData.resellerName ? clientData.resellerName : clientData.name}</b>
                        </Typography>
                    </Grid>

                    <IconButton onClick={() => handleClose()}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <Divider variant='middle' />
                <DialogContent>
                    <Grid
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            // gap: "0.8rem",
                        }}
                    >
                        <Grid display={"flex"} flexDirection={"column"} gap={".6rem"}>
                            <Typography>Text message rate :</Typography>
                            <Grid display={"flex"} gap={"1rem"}>
                                <TextField
                                    fullWidth
                                    label='Master rate'
                                    size='small'
                                    type='number'
                                    disabled={true}
                                    defaultValue={clientData?.rate?.baseRate?.A2pMessageRate?.textMessageRate}
                                />

                                <TextField
                                    fullWidth
                                    size='small'
                                    type='number'
                                    inputProps={{
                                        step: "any",
                                    }}
                                    onWheel={e => e.target.blur()}
                                    label='Customized rate'
                                    name='updatedTextMessageRate'
                                    defaultValue={clientData?.rate?.customizedRate?.A2pMessageRate?.textMessageRate}
                                    // onChange={handleChangeUpdatedRateCardDetails}
                                    error={Boolean(formik.touched.updatedTextMessageRate && formik.errors.updatedTextMessageRate)}
                                    helperText={formik.touched.updatedTextMessageRate && formik.errors.updatedTextMessageRate}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />

                                <TextField
                                    fullWidth
                                    label='Total rate'
                                    size='small'
                                    type='number'
                                    disabled={true}
                                    value={(
                                        (clientData?.rate?.baseRate?.A2pMessageRate?.textMessageRate || 0) +
                                        (formik.values.updatedTextMessageRate || 0)
                                    ).toFixed(4)}
                                />
                            </Grid>
                        </Grid>
                        <Grid display={"flex"} flexDirection={"column"} gap={".6rem"}>
                            <Typography>Multi Media message rate :</Typography>
                            <Grid display={"flex"} gap={"1rem"}>
                                <TextField
                                    fullWidth
                                    label='Master rate'
                                    size='small'
                                    type='number'
                                    disabled={true}
                                    defaultValue={clientData?.rate?.baseRate?.A2pMessageRate?.multiMediaMessageRate}
                                />

                                <TextField
                                    fullWidth
                                    size='small'
                                    type='number'
                                    inputProps={{
                                        step: "any",
                                    }}
                                    onWheel={e => e.target.blur()}
                                    label='Customized rate'
                                    name='updatedMultiMediaMessageRate'
                                    defaultValue={clientData?.rate?.customizedRate?.A2pMessageRate?.multiMediaMessageRate}
                                    // onChange={handleChangeUpdatedRateCardDetails}
                                    error={Boolean(formik.touched.updatedMultiMediaMessageRate && formik.errors.updatedMultiMediaMessageRate)}
                                    helperText={formik.touched.updatedMultiMediaMessageRate && formik.errors.updatedMultiMediaMessageRate}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />

                                <TextField
                                    fullWidth
                                    label='Total rate'
                                    size='small'
                                    type='number'
                                    disabled={true}
                                    value={(
                                        (clientData?.rate?.baseRate?.A2pMessageRate?.multiMediaMessageRate || 0) +
                                        (formik.values.updatedMultiMediaMessageRate || 0)
                                    ).toFixed(4)}
                                />
                            </Grid>
                        </Grid>
                        <Grid display={"flex"} flexDirection={"column"} gap={".6rem"}>
                            <Typography>A2p session conversation rate :</Typography>
                            <Grid display={"flex"} gap={"1rem"}>
                                <TextField
                                    fullWidth
                                    label='Master rate'
                                    size='small'
                                    type='number'
                                    disabled={true}
                                    defaultValue={clientData?.rate?.baseRate?.A2pMessageRate?.A2pSessionConversationRate}
                                />
                                <TextField
                                    fullWidth
                                    size='small'
                                    type='number'
                                    inputProps={{
                                        step: "any",
                                    }}
                                    onWheel={e => e.target.blur()}
                                    label='Customized rate'
                                    name='updatedA2pSessionConversationRate'
                                    defaultValue={clientData?.rate?.customizedRate?.A2pMessageRate?.A2pSessionConversationRate}
                                    // onChange={handleChangeUpdatedRateCardDetails}
                                    error={Boolean(
                                        formik.touched.updatedA2pSessionConversationRate && formik.errors.updatedA2pSessionConversationRate,
                                    )}
                                    helperText={formik.touched.updatedA2pSessionConversationRate && formik.errors.updatedA2pSessionConversationRate}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />

                                <TextField
                                    fullWidth
                                    label='Total rate'
                                    size='small'
                                    type='number'
                                    disabled={true}
                                    value={(
                                        (clientData?.rate?.baseRate?.A2pMessageRate?.A2pSessionConversationRate || 0) +
                                        (formik.values.updatedA2pSessionConversationRate || 0)
                                    ).toFixed(4)}
                                />
                            </Grid>
                        </Grid>
                        <Grid display={"flex"} flexDirection={"column"} gap={".6rem"}>
                            <Typography>Feedback rate :</Typography>
                            <Grid display={"flex"} gap={"1rem"}>
                                <TextField
                                    fullWidth
                                    label='Master rate'
                                    size='small'
                                    type='number'
                                    disabled={true}
                                    defaultValue={clientData?.rate?.baseRate?.A2pMessageRate?.feedbackRate}
                                />

                                <TextField
                                    fullWidth
                                    size='small'
                                    type='number'
                                    inputProps={{
                                        step: "any",
                                    }}
                                    onWheel={e => e.target.blur()}
                                    label='Customized rate'
                                    name='updatedFeedbackRate'
                                    defaultValue={clientData?.rate?.customizedRate?.A2pMessageRate?.feedbackRate}
                                    // onChange={handleChangeUpdatedRateCardDetails}
                                    error={Boolean(formik.touched.updatedFeedbackRate && formik.errors.updatedFeedbackRate)}
                                    helperText={formik.touched.updatedFeedbackRate && formik.errors.updatedFeedbackRate}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />

                                <TextField
                                    fullWidth
                                    label='Total rate'
                                    size='small'
                                    type='number'
                                    disabled={true}
                                    value={(
                                        (clientData?.rate?.baseRate?.A2pMessageRate?.feedbackRate || 0) + (formik.values.updatedFeedbackRate || 0)
                                    ).toFixed(4)}
                                />
                            </Grid>
                        </Grid>
                        {Object.keys(clientData?.rate?.baseRate?.otherFields).length > 0 &&
                            Object.keys(clientData?.rate?.baseRate?.otherFields).map((fieldElement, index) => (
                                <Grid key={index} display={"flex"} flexDirection={"column"} gap={".6rem"}>
                                    <Typography>{fieldElement} :</Typography>
                                    <Grid display={"flex"} gap={"1rem"}>
                                        <TextField
                                            fullWidth
                                            label='Master rate'
                                            size='small'
                                            type='number'
                                            disabled={true}
                                            defaultValue={clientData?.rate?.baseRate?.otherFields[fieldElement]}

                                        // name={`otherFields.${fieldElement}`}
                                        // value={
                                        //     formik.values.otherFields[
                                        //         fieldElement
                                        //     ]
                                        // }
                                        />

                                        <TextField
                                            fullWidth
                                            size='small'
                                            type='number'
                                            inputProps={{
                                                step: "any",
                                            }}
                                            onWheel={e => e.target.blur()}
                                            label='Customized rate'
                                            name={`otherFields.${fieldElement}`}
                                            defaultValue={clientData?.rate?.customizedRate?.otherFields[fieldElement]}
                                            // onChange={handleChangeUpdatedRateCardDetails}
                                            error={Boolean(formik.errors?.otherFields && formik.errors?.otherFields[fieldElement])}
                                            helperText={formik.errors?.otherFields && formik.errors?.otherFields[fieldElement]}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                        <TextField
                                            fullWidth
                                            label='Total rate'
                                            size='small'
                                            type='number'
                                            disabled={true}
                                            value={(
                                                (clientData?.rate?.baseRate?.otherFields[fieldElement] || 0) +
                                                (formik.values.otherFields[fieldElement] || 0)
                                            ).toFixed(4)}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                    </Grid>
                    <Grid mt={3}>
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label='Select Effective From Date and Time'
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    minDateTime={
                                        selectedDate &&
                                            selectedDate.getDate() === currentDateTime.getDate() &&
                                            selectedDate.getMonth() === currentDateTime.getMonth() &&
                                            selectedDate.getFullYear() === currentDateTime.getFullYear()
                                            ? new Date(
                                                currentDateTime.getFullYear(),
                                                currentDateTime.getMonth(),
                                                currentDateTime.getDate(),
                                                currentDateTime.getHours(),
                                                currentDateTime.getMinutes(),
                                            ) // Restrict to current date and time
                                            : new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate()) // Allow any time for future dates
                                    }
                                    renderInput={params => <TextField {...params} />}
                                    error={Boolean(formik.errors.effectiveFrom)}
                                    helperText={formik.errors.effectiveFrom && formik.touched.effectiveFrom ? formik.errors.effectiveFrom : ""}
                                />
                            </LocalizationProvider>
                            <FormHelperText>
                                {formik.errors.effectiveFrom && formik.touched.effectiveFrom ? formik.errors.effectiveFrom : ""}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        padding: "0rem 1.5rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: ".25rem",
                    }}
                >
                    <Grid container paddingBottom={3}>
                        <Grid padding={1}>
                            {isUpdateCustomizedRateLoading ? (
                                <Button variant='outlined'>
                                    <CircularProgress size={15} className='spinner' />
                                    Updating..
                                </Button>
                            ) : (
                                <Button type='submit' variant='contained'>
                                    Update
                                </Button>
                            )}
                        </Grid>

                        <Grid padding={1}>
                            <Button variant='contained' color='error' onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </form>
            {state.controls.updateCustomizedRateErrorMessage && (
                <AlertMessageComponent
                    message={state.controls.updateCustomizedRateErrorMessage}
                    fieldName={"updateCustomizedRateErrorMessage"}
                    handleClose={onCloseErrorMessage}
                    show={Boolean(state.controls.updateCustomizedRateErrorMessage)}
                />
            )}
        </Dialog>
    );
}
