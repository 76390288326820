import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
    tableCellClasses
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrandStatus, ERoles } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import { clearErrorMessagesOfBrands, setShowAddBrandForm } from "../../../redux/brandManagement/brandManagementSlice";
import { getAllBrandByFilterThunk, getAllBrandsByPageSize } from "../../../redux/brandManagement/brandManagementThunk";
import TablePaginationShowPage from "../../../utils/tablePagination";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import FetchingSpinner from "../../components/FetchingSpinner";
import NoRecords from "../../components/NoRecords";
import StyledMenu from "../../tables/StyledMenu";
import FilterAllBrandModel from "./FilterBrandModel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 25,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ListRoles ({ handleViewBrandDetails, searchParams, setSearchParams }) {
    const state = useSelector(state => state.brandManagementSlice);
    const { user } = useAuth();
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchedBrandName, setSearchedBrandName] = useState("");
    const brandState = useSelector(state => state.brandManagementSlice);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = anchorEl?.id.split("@")[1];
    const [tableRole, SetTableRole] = useState(true);
    const [openFilter, setOpenFilter] = React.useState(false);

    const [pageSize, setPageSize] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage + 1);

        // setcampaignDetails({ currentPage: newPage });
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(1);
        // setCampaignDetails({ ...campaignDetails, currentPage: 1 });
    };
    const handleClickOpenFilterModel = () => {
        setOpenFilter(true);
    };

    const handleCloseFilterModel = () => {
        // setSearchParams({});
        setOpenFilter(false);
    };

    const onClickAddRoleButton = () => {
        dispatch(setShowAddBrandForm(true));
    };
    const handlePageChange = page => {
        setCurrentPage(page);
        dispatch(getAllBrandsByPageSize({ currentPage: page + 1, pageSize }));
    };
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleTableRole = e => {
        SetTableRole(!tableRole);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const onClickSearch = (event, page) => {};

    useEffect(() => {
        // dispatch(
        //     getAllBrandsByPageSize({ pageSize, currentPage: currentPage + 1 })
        // );
        // dispatch(
        //     getAllBrandByFilterThunk({
        //         currentPage: 1,
        //         pageSize,
        //         searchParams,
        //     })
        // );
        dispatch(
            getAllBrandByFilterThunk({
                currentPage,
                pageSize,
                searchParams,
                registryId: user?.registryId,
                roleName: user?.roleName,
            }),
        );
        // dispatch(getAllBrands());
    }, [currentPage, pageSize, user]);

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesOfBrands({ fieldName }));
    };
    const handleClickSearch = searchParams => {
        setCurrentPage(1);
        setSearchParams(searchParams);
        dispatch(
            getAllBrandByFilterThunk({
                currentPage: 1,
                pageSize,
                searchParams,
                roleName: user?.roleName,
            }),
        );
        handleCloseFilterModel();
    };

    return (
        <Grid>
            <Grid container mb={4} className='listHeader'>
                <Grid item md={6}>
                    <Typography variant='h3'>Brands</Typography>
                </Grid>
                <Grid item md={6} className='listHeaderFilter'>
                    <Grid width={"100%"} justifyContent={"flex-end"} display={"flex"}>
                        <Button sx={{ marginRight: "10px" }} variant='contained' onClick={handleClickOpenFilterModel}>
                            Filter
                        </Button>
                        {/* {user &&
                                        user.roleName.toLowerCase() !==
                                        ERoles.SUPER_ADMIN && (
                                            <Button
                                                variant="contained"
                                                onClick={onClickAddRoleButton}
                                            >
                                                Add New Brand
                                            </Button>
                                        )} */}
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <Card className='border border-secondary-50 '>
                    <CardContent sx={{
                        padding: 0,
                    }}>
                        {state.controls.isGetAllBrandsLoading ? (
                            <FetchingSpinner />
                        ) : (
                            // tableRole ?
                            <>
                                {" "}
                                {state.brandListByPageSize.length > 0 ? (
                                    <TableContainer component={Paper} className='table-container' sx={{ maxHeight: "60vh" }}>
                                        <Table stickyHeader aria-label='simple table'>
                                            <TableHead className='p-3 mb-2 bg-secondary text-white row'>
                                                <TableRow>
                                                    <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                                        Sr No
                                                    </StyledTableCell>
                                                    <StyledTableCell className='col-3 tableHeaderFont' align='left'>
                                                        Brand Name
                                                    </StyledTableCell>
                                                    {user?.roleName && user?.roleName === ERoles.SUPER_ADMIN && (
                                                        <StyledTableCell className='col-3 tableHeaderFont' align='left'>
                                                            Admin Email Id
                                                        </StyledTableCell>
                                                    )}
                                                    <StyledTableCell className='col-3 tableHeaderFont' align='left'>
                                                        Status
                                                    </StyledTableCell>
                                                    <StyledTableCell className='col-2 tableHeaderFont' align='left'>
                                                        Action
                                                    </StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {state.brandListByPageSize.map((brand, index) => (
                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                            {(currentPage - 1) * pageSize + index + 1}
                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                            {brand.brandName}
                                                        </StyledTableCell>
                                                        {user?.roleName && user?.roleName == ERoles.SUPER_ADMIN && (
                                                            <StyledTableCell className='tableContentFont' align='left'>
                                                                {brand.contactPersonDetails.emailId}
                                                            </StyledTableCell>
                                                        )}
                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                            <Typography
                                                                variant='subtitle2'
                                                                color={
                                                                    brand.brandStatus.toUpperCase() == BrandStatus.NotVerified
                                                                        ? "error"
                                                                        : brand.brandStatus.toUpperCase() == BrandStatus.UnderReview
                                                                            ? "orange"
                                                                            : "green"
                                                                }
                                                            >
                                                                {brand.brandStatus.replaceAll("_", " ").toUpperCase()}
                                                            </Typography>




                                                        </StyledTableCell>
                                                        <StyledTableCell className='tableContentFont' align='left'>
                                                            <IconButton
                                                                id={`menu-button@${brand._id}`}
                                                                aria-controls={open === brand._id ? `customized-menu@${brand._id}` : undefined}
                                                                aria-haspopup='true'
                                                                aria-expanded={open === brand._id ? "true" : undefined}
                                                                variant='contained'
                                                                disableElevation
                                                                onClick={handleClick}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>

                                                            <StyledMenu
                                                                id={`customized-menu@${brand._id}`}
                                                                MenuListProps={{
                                                                    "aria-labelledby": `menu-button@${brand._id}`,
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={open === brand._id}
                                                                onClose={handleClose}
                                                            >
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        handleViewBrandDetails(brand);
                                                                    }}
                                                                >
                                                                    <IconButton title='View Details'>
                                                                        <VisibilityIcon className='text-primary' />
                                                                    </IconButton>
                                                                    View Details
                                                                </MenuItem>
                                                            </StyledMenu>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <Grid></Grid>
                                    </TableContainer>
                                ) : (
                                    <NoRecords />
                                )}{" "}
                            </>
                            // )
                            // : (
                            //     <>
                            //         {state.listOfBrandBySuperAdmin.length > 0 ? (
                            //             <TableContainer
                            //                 component={Paper}
                            //                 className="table-container"
                            //             >
                            //                 <Table
                            //                     stickyHeader
                            //                     aria-label="simple table"
                            //                 >
                            //                     <TableHead className="p-3 mb-2 bg-secondary text-white row">
                            //                         <TableRow>
                            //                             <StyledTableCell
                            //                                 className="col-1 tableHeaderFont"
                            //                                 align="center"
                            //                             >
                            //                                 Sr No
                            //                             </StyledTableCell>
                            //                             <StyledTableCell
                            //                                 className="col-5 tableHeaderFont"
                            //                                 align="left"
                            //                             >
                            //                                 Brands Name
                            //                             </StyledTableCell>
                            //                             <StyledTableCell
                            //                                 className="col-3 tableHeaderFont"
                            //                                 align="left"
                            //                             >
                            //                                 Status
                            //                             </StyledTableCell>
                            //                             {/* <StyledTableCell
                            //     className="col-2 tableHeaderFont"
                            //     align="left"
                            //   >
                            //     Action
                            //   </StyledTableCell> */}
                            //                         </TableRow>
                            //                     </TableHead>
                            //                     <TableBody>
                            //                         {state.listOfBrandBySuperAdmin.map(
                            //                             (brand, index) => (
                            //                                 <StyledTableRow
                            //                                     key={index}
                            //                                 >
                            //                                     <StyledTableCell
                            //                                         className="tableContentFont"
                            //                                         align="center"
                            //                                     >
                            //                                         {currentPage *
                            //                                             10 +
                            //                                             index +
                            //                                             1}
                            //                                     </StyledTableCell>
                            //                                     <StyledTableCell
                            //                                         className="tableContentFont"
                            //                                         align="left"
                            //                                     >
                            //                                         {
                            //                                             brand.brandName
                            //                                         }
                            //                                     </StyledTableCell>
                            //                                     <StyledTableCell
                            //                                         className="tableContentFont"
                            //                                         align="left"
                            //                                     >
                            //                                         <Chip
                            //                                             variant="outlined"
                            //                                             className="chip"
                            //                                             label={
                            //                                                 brand.brandStatus
                            //                                             }
                            //                                             color={
                            //                                                 brand.brandStatus ===
                            //                                                 "Not Verified"
                            //                                                     ? "error"
                            //                                                     : "info"
                            //                                             }
                            //                                         />
                            //                                     </StyledTableCell>
                            //                                     {/* <StyledTableCell
                            //         className="tableContentFont"
                            //         align="left"
                            //       >
                            //         <IconButton
                            //           id={`menu-button@${brand._id}`}
                            //           aria-controls={
                            //             open ===
                            //               brand._id
                            //               ? `customized-menu@${brand._id}`
                            //               : undefined
                            //           }
                            //           aria-haspopup="true"
                            //           aria-expanded={
                            //             open ===
                            //               brand._id
                            //               ? "true"
                            //               : undefined
                            //           }
                            //           variant="contained"
                            //           disableElevation
                            //           onClick={
                            //             handleClick
                            //           }
                            //         >
                            //           <MoreVertIcon />
                            //         </IconButton>

                            //         <StyledMenu
                            //           id={`customized-menu@${brand._id}`}
                            //           MenuListProps={{
                            //             "aria-labelledby": `menu-button@${brand._id}`,
                            //           }}
                            //           anchorEl={
                            //             anchorEl
                            //           }
                            //           open={
                            //             open ===
                            //             brand._id
                            //           }
                            //           onClose={
                            //             handleClose
                            //           }
                            //         >
                            //           <MenuItem
                            //             onClick={() => {
                            //               handleViewBrandDetails(
                            //                 brand
                            //               );
                            //             }}
                            //           >
                            //             <IconButton title="View Details">
                            //               <VisibilityIcon className="text-primary" />
                            //             </IconButton>
                            //             View
                            //             Details
                            //           </MenuItem>
                            //         </StyledMenu>
                            //       </StyledTableCell> */}
                            //                                 </StyledTableRow>
                            //                             )
                            //                         )}
                            //                     </TableBody>
                            //                 </Table>
                            //                 <Grid></Grid>
                            //             </TableContainer>
                            //         ) : (
                            //             <NoRecords />
                            //         )}
                            //     </>
                        )}
                        {state.totalBrandsCount > 0 && (
                            <Grid
                                className='my-2'
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingLeft: 10
                                }}
                            >
                                <Grid
                                    style={{
                                        marginTop: "15px",
                                        padding: 5,
                                    }}
                                >
                                    Total Brands: {state.totalBrandsCount}
                                </Grid>

                                {/* <TablePagination
                                    count={
                                        state.totalBrandsCount
                                        /// rowsPerPage
                                    }
                                    component='Grid'
                                    variant='outlined'
                                    color='primary'
                                    page={currentPage - 1}
                                    // onChange={handlePageChange}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={pageSize}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                /> */}

                                <TablePaginationShowPage
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    colSpan={3}
                                    count={state.totalBrandsCount}
                                    rowsPerPage={pageSize}
                                    page={currentPage - 1}
                                    slotProps={{
                                        select: {
                                            "aria-label": "Rows per page",
                                        },
                                        actions: {
                                            showFirstButton: true,
                                            showLastButton: true,
                                            slots: {
                                                firstPageIcon: FirstPageRoundedIcon,
                                                lastPageIcon: LastPageRoundedIcon,
                                                nextPageIcon: ChevronRightRoundedIcon,
                                                backPageIcon: ChevronLeftRoundedIcon,
                                            },
                                        },
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        )}
                    </CardContent>

                    <AlertMessageComponent
                        message={state.controls.listAllBrandsByPageSizeErrorMessage}
                        fieldName={"listAllBrandsByPageSizeErrorMessage"}
                        handleClose={onCloseErrorMessage}
                        show={Boolean(state.controls.listAllBrandsByPageSizeErrorMessage)}
                    />
                    {openFilter && (
                        <FilterAllBrandModel
                            open={openFilter}
                            searchParams={searchParams}
                            handleClickOpenFilterModel={handleClickOpenFilterModel}
                            handleCloseFilterModel={handleCloseFilterModel}
                            setSearchParams={setSearchParams}
                            handleClickSearch={handleClickSearch}
                        />
                    )}
                </Card>
            </Grid>
        </Grid>
    );
}
