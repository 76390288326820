export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

export const auth0Config = {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN
}

export const cognitoConfig = {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    clientId: process.env.REACT_APP_COGNITO_CLIENT_ID
}

export const config = {
    UserManagementServerUrl: process.env.REACT_APP_USER_MANAGEMENT_BACKEND_URL,
    APIGatewayServerUrl: process.env.REACT_APP_API_GATEWAY_BACKEND_URL,
    BotManagementServerUrl: process.env.REACT_APP_BOT_MANAGEMENT_BACKEND_URL,
    S3_MAIN_BUCKET_NAME: process.env.REACT_APP_S3_MAIN_BUCKET_NAME,
    REACT_APP_IS_SMS_API_KEY_REQUIRED: process.env.REACT_APP_IS_SMS_API_KEY_REQUIRED,
    REACT_APP_CAMPAIGN_TTL: process.env.REACT_APP_CAMPAIGN_TTL,

    REACT_APP_POSTBACK_VARIABLE: process.env.REACT_APP_POSTBACK_VARIABLE,
    REACT_APP_MAX_TTL_IN_HOURS: process.env.REACT_APP_MAX_TTL_IN_HOURS,
    REACT_APP_MAX_TTL_IN_DAYS: process.env.REACT_APP_MAX_TTL_IN_DAYS


}

export const ERoles = {
    SUPER_ADMIN: "super_admin",
    ORG_ADMIN: "org_admin",
    RESELLER_ADMIN: "reseller_admin",
}

export const ClientStatus = {
    Active: 'Active',
    InActive: 'InActive'
}


export const BrandStatus = {
    Verified: "VERIFIED",
    NotVerified: "NOT_VERIFIED",
    UnderReview: "UNDER_REVIEW"
}

export const BotStatus = {
    New: 'new',
    Development: 'development',
    VerificationSubmitted: 'verification_submitted',
    VerificationInProcess: 'verification_in_process',
    Verified: 'verified',
    LaunchingInProcess: 'launching_in_process',
    LaunchFailed: 'launch_failed',
    Launched: 'launched',
    BotCreationRejected: 'bot_creation_rejected',
    VerificationFailed: 'verification_failed'
}

// currently used only for org admin
export const CanShowBotMenuAction = {
    EditBotVerificationDetails: (botStatus) => {
        return botStatus !== BotStatus.Development &&
            botStatus !== BotStatus.New &&
            botStatus !== BotStatus.VerificationInProcess &&
            botStatus !== BotStatus.Launched &&
            botStatus !== BotStatus.LaunchingInProcess &&
            botStatus !== BotStatus.Launched &&
            botStatus !== BotStatus.Verified &&
            botStatus !== BotStatus.VerificationSubmitted &&
            botStatus !== BotStatus.BotCreationRejected
    },
    VerifyBot: (botStatus) => {
        return botStatus === BotStatus.Development

    },

    EditBot: (botStatus) => {
        return botStatus === BotStatus.BotCreationRejected
    },
    ShowVerificationDetails: (botStatus) => {
        return botStatus !== BotStatus.Development &&
            botStatus !== BotStatus.New
    },
    ShowAddTestDevice: (botStatus) => {
        return botStatus !== BotStatus.New
    }

}

export const BotMessageType = {
    OTP: 'OTP',
    Promotional: 'PROMOTIONAL',
    Transactional: 'TRANSACTIONAL'
}

export const TemplateStatus = {
    Pending: 'pending',
    Approved: 'approved',
    Rejected: 'rejected'
}

export const TemplateTypes = {
    TEXT_MESSAGE: 'text_message',
    CAROUSEL: 'carousel',
    RICH_CARD: 'rich_card'
}

export const CampaignStatus = {
    Created: 'created',
    Pending: 'pending',
    Validation_Failed: 'validation_failed',
    Executed: 'executed',
    SMS_Executed: 'sms_executed',
    Execution_Failed: 'execution_failed',
    Running: 'running',
    Scheduled: 'scheduled'
}


export const CapCheck = {
    On: 'On',
    Off: 'Off'
}

export const OtherFields = {
    userMessageRate: 'User Message Rate',
    P2aConversationRate: 'P2A Conversation Rate'
}


export const CampaignReportStatus = {
    Failed: 'FAILED',
    Read: 'READ',
    Delivered: 'DELIVERED',
    Sent: 'SENT',
    Retry: 'RETRY',
    Pending: 'PENDING',
    TTLExpirationRevoked: "TTL_EXPIRATION_REVOKED",
    Sms: 'SMS',
    TotalCount: 'TOTAL_COUNT',
    CampaignLastTime: 'CAMPAIGN_LAST_TIME',
    CampaignFirstTime: 'CAMPAIGN_FIRST_TIME',
    // for sms
    TotalSMSCount: 'TOTAL_SMS',
    SmsDELIVERED: 'SMS-DELIVERED',
    SmsEXPIRED: 'SMS-EXPIRED',
    SmsREJECTED: 'SMS-REJECTED',
    SmsUNDELIVERED: 'SMS-UNDELIVERED',
    SmsDND: 'SMS-DND',
    SmsINVALID: 'SMS-INVALID',
    SmsBLACKLISTMSISD: 'SMS-BLACKLISTMSISD',
    SmsFAILED: 'SMS-FAILED',
    TotalRCSCount: 'TOTAL_RCS',
    TotalSMSFailed: 'TOTAL_SMS_FAILED'
}


export const smsMessageStatus = {
    DELIVRD: "DELIVRD",
    EXPIRED: "EXPIRED",
    REJECTD: "REJECTD",
    UNDELIV: "UNDELIV",
    DND: "DND",
    INVALID: "INVALID",
    BLACKLISTMSISD: "BLACKLISTMSISD",
    SMSFailed: "SMS_FAILED"
}
export const smsStatusDisplayName = {
    [smsMessageStatus.DELIVRD]: "SMS-DELIVERED",
    [smsMessageStatus.EXPIRED]: "SMS-EXPIRED",
    [smsMessageStatus.REJECTD]: "SMS-REJECTED",
    [smsMessageStatus.UNDELIV]: "SMS-UNDELIVERED",
    [smsMessageStatus.DND]: "SMS-DND",
    [smsMessageStatus.INVALID]: "SMS-INVALID",
    [smsMessageStatus.BLACKLISTMSISD]: "SMS-BLACKLISTMSISD"
}


export const getStatusToDisplay = (status) => {
    if (smsStatusDisplayName.hasOwnProperty(status)) {
        return smsStatusDisplayName[status];
    }
    return status;
}

export const MessageStatus = {
    Failed: 'FAILED',
    Read: 'READ',
    Delivered: 'DELIVERED',
    Sent: 'SENT',
    Retry: 'RETRY',
    Pending: 'PENDING',
    TTLExpirationRevoked: "TTL_EXPIRATION_REVOKED",
    ...smsMessageStatus

}

export const messageLastStatus = status => {

    //** Multiple if statements helps to maintain the order of the last message status to be returned */
    if (status.EXPIRED) return smsStatusDisplayName[smsMessageStatus.EXPIRED];
    if (status.REJECTD) return smsStatusDisplayName[smsMessageStatus.REJECTD];
    if (status.UNDELIV) return smsStatusDisplayName[smsMessageStatus.UNDELIV];
    if (status.DND) return smsStatusDisplayName[smsMessageStatus.DND];
    if (status.INVALID) return smsStatusDisplayName[smsMessageStatus.INVALID];
    if (status.BLACKLISTMSISD) return smsStatusDisplayName[smsMessageStatus.BLACKLISTMSISD];
    if (status.DELIVRD) return smsStatusDisplayName[smsMessageStatus.DELIVRD];


    if (status.TTL_EXPIRATION_REVOKED) return "TTL_EXPIRATION_REVOKED";
    if (status.READ) return "READ";
    if (status.DELIVERED) return "DELIVERED";
    if (status.SENT) return "SENT";
    if (status.FAILED) return "FAILED";
    if (status.RETRY) return "RETRY";
    if (status.PENDING) return "PENDING";
    if (status.RECEIVED) return "RECEIVED";

    return "-";
};

/**
 * takes a status object and returns the color in string for the last message status. if no match is found default black is returned
 * @param status
 * @returns "error" | "green" | "orange" | "darkblue" | "primary" | "black"
 */
export const getColourByMessageStatus = (status) => {
    let errorColorStatuses = [
        MessageStatus.Failed,
        MessageStatus.TTLExpirationRevoked,
        smsStatusDisplayName[smsMessageStatus.INVALID],
        smsStatusDisplayName[smsMessageStatus.REJECTD],
        smsStatusDisplayName[smsMessageStatus.EXPIRED],
        smsStatusDisplayName[smsMessageStatus.DND],
        smsStatusDisplayName[smsMessageStatus.UNDELIV]
    ];

    let greenColorStatuses = [MessageStatus.Delivered, smsStatusDisplayName[smsMessageStatus.DELIVRD]];
    let orangeColorStatuses = [MessageStatus.Pending];
    let primaryColorStatuses = [MessageStatus.Sent];
    let darkblueColorStatuses = [MessageStatus.Read];

    let defaultColor = "black";
    let lastStatus = messageLastStatus(status);

    if (errorColorStatuses.includes(lastStatus)) return "error";
    if (greenColorStatuses.includes(lastStatus)) return "green";
    if (orangeColorStatuses.includes(lastStatus)) return "orange";
    if (primaryColorStatuses.includes(lastStatus)) return "primary";
    if (darkblueColorStatuses.includes(lastStatus)) return "darkblue";

    return defaultColor;

}
