import { ArrowBackIos, CurrencyRupee, Edit, History } from "@mui/icons-material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    Dialog,
    Grid,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { apiGatewayAxiosInstance } from "../../../../utils/axios";
import { clearErrorMessagesForAllUsers, setAllCustomizedData, setErrorMessageForUser } from "../../../../redux/userManagement/userManagementSlice";
import { ERoles, OtherFields } from "../../../../config";
import IncreaseDecreaseCustomizeRateForSelectedClients from "../../CustomiseRateCard/IncreaseDecreaseCustomizeRateForSelectedClients";
import ViewHistoryOfCustomizedRates from "../../RateCardComponents/ViewHistoryOfCustomizedRates";
import UpdateRateByClientIdModal from "../../CustomiseRateCard/UpdateRateByClientIdModal";
import AlertMessageComponent from "../../../components/AlertMessageComponent";
import TablePaginationShowPage from "../../../../utils/tablePagination";
import NoRecords from "../../../components/NoRecords";
import FetchingSpinner from "../../../components/FetchingSpinner";
import { getAllProvidersThunk } from "../../../../redux/botManagement/botManagementThunk";

// import { apiGatewayAxiosInstance } from "../../../utils/axios";
// import TablePaginationShowPage from "../../../utils/tablePagination";
// import AlertMessageComponent from "../../components/AlertMessageComponent";
// import FetchingSpinner from "../../components/FetchingSpinner";
// import NoRecords from "../../components/NoRecords";
// import ViewHistoryOfCustomizedRates from "../RateCardComponents/ViewHistoryOfCustomizedRates";
// import IncreaseDecreaseCustomizeRateForSelectedClients from "./IncreaseDecreaseCustomizeRateForSelectedClients";
// import UpdateRateByClientIdModal from "./UpdateRateByClientIdModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
    },
}));

const StyledTableCellSticky = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
        backgroundColor: theme.palette.common.white,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ResellerCustomizedRatesForOrg() {
    const userState = useSelector(state => state.userManagementSlice);
    const botState = useSelector(state => state.botManagementSlice);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(0);
    const [clientData, setClientData] = useState({});
    const [updateCustomizedRateModel, setUpdateCustomizedRateModel] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [errorResponse, setErrorResponse] = useState("");
    const [selectProviderName, setSelectProviderName] = useState({});
    const [providerDetailsByProviderName, setProviderDetailsByProviderName] = useState();
    const [otherFields, setOtherFields] = useState({});
    const [searchedClientName, setSearchedClientName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [getAllProviderList, setGetAllProviderList] = useState();
    const [openUpdateRateCardForSelectedClient, setOpenUpdateRateCardForSelectedClient] = useState(false);
    const [viewHistoryOfCustomizedRates, setViewHistoryOfCustomizedRates] = useState(false);
    const { user } = useAuth();
    const [searchParams, setSearchParams] = React.useState({
        name: "",
        email: "",
        mobileNumber: "",
        branchName: "",
        status: "",
    });

    const [pageSize, setPageSize] = React.useState(10);
    const [selected, setSelected] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [customizeRateCardForClients, setCustomizeRateCardForClients] = useState([]);
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const { state } = useLocation(); //receiving data from listProvider on navigate
    const { providerId, providerCode, providerName, orgId, orgName, countryCode } = state || {};

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // setSearchParams({});
        setOpen(false);
    };
    const onChangeSearchedClientName = e => {
        setSearchedClientName(e.target.value);
    };

    const onClickEditCustomizedRate = client => {
        setClientData(client);
        setUpdateCustomizedRateModel(true);
    };

    const handleCloseEditCustomizedRate = () => {
        setUpdateCustomizedRateModel(false);
    };

    const onClickViewHistoryOfCustomizedRate = client => {
        setViewHistoryOfCustomizedRates(true);
        setClientData(client);
    };

    const closeViewHistoryCustomizedRates = () => {
        setViewHistoryOfCustomizedRates(false);
    };

    const openViewHistoryCustomizedRates = () => {
        setViewHistoryOfCustomizedRates(true);
    };
    const getAllProvidersList = async () => {
        try {
            const response = await apiGatewayAxiosInstance.get(`/billing_management/provider-csv-fields/get-all-providers`);
            if (response.status === 200) {
                setGetAllProviderList(response.data.data.providerList);
            }
        } catch (error) {
            let errorMessage = "Server not reachable";
            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "getClientEditDetailsErrorMessages",
                    errorMessage,
                }),
            );
        }
    };

    const checkCurrentRate = (fromDate, toDate) => {
        //This function is to show current rates
        const currentDate = new Date();
        fromDate = fromDate ? new Date(fromDate) : null;
        toDate = toDate ? new Date(toDate) : null;

        // if (!isNaN(fromDate) && !isNaN(toDate)) {

        if (currentDate >= fromDate && currentDate <= toDate) {
            // Condition for Future present rates
            return true;
        } else if (!isNaN(fromDate) && (!toDate || isNaN(toDate)) && currentDate >= fromDate) {
            // Condition for current rates
            return true;
        } else {
            return false;
        }
    };

    const getAllCustomizedRate = async () => {
        try {
            setIsLoading(true);
            const token = sessionStorage.getItem("accessToken");
            const response = await apiGatewayAxiosInstance.get(`/user_management/customized-rate/get-by-client-id`, {
                headers: { token },
                params: {
                    providerId,
                    providerCode,
                    pageSize,
                    currentPage: currentPage + 1,
                    orgId: orgId,
                    registryId: user.registryId
                    // countryCode: countryCode
                },
            });
            if (response.status === 200) {
                const clientData = response.data.data.clientData;
                await getHistoryOfClientCustomizedRates(providerId, providerCode, countryCode, orgId, clientData);
                setTotalCount(response.data.data.totalCount);
                setIsLoading(false);
            } else {
                // setIsLoading(false);
                throw new Error(response.data);
            }
        } catch (error) {
            setIsLoading(false);
            let errorMessage = "Failed to get all customised rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    const getHistoryOfClientCustomizedRates = async (providerId, providerCode, countryCode, orgId, clientData) => {
        const response = await apiGatewayAxiosInstance.get(`/billing_management/customized-rate-card/get-provider-customized-rate-history`, {
            params: {
                providerId: providerId,
                providerCode: providerCode,
                pageSize: pageSize,
                countryCode: countryCode,
                currentPage: currentPage,
                orgId: orgId,
                registryId: user.registryId
            },
        });
        let clientCustomizedRates = response.data.data.findCurrentRates;
        let currentCustomizedRate = null;
        for (let index = 0; index < clientCustomizedRates.length; index++) {
            const element = clientCustomizedRates[index];
            let isCurrentCustomizedRate = checkCurrentRate(element.effectiveFrom, element.effectiveTo);
            if (isCurrentCustomizedRate) {
                currentCustomizedRate = element;
            }
        }
        if (userState.customizedRate && currentCustomizedRate) {
            clientData[0] = { ...clientData[0], currentRate: currentCustomizedRate };
            dispatch(setAllCustomizedData(clientData));
            // setClientCurrentCustomizedRate(dataToSave);
        }

        setTotalCount(response.data.data.totalCount);
    };


    useEffect(async () => {
        await getProviderFieldsDetails({ providerId, providerCode });
        await getAllProvidersList();
        await getAllCustomizedRate();
        if (user) {
            if (getAllProviderList?.length === 0) {
                dispatch(getAllProvidersThunk());
            }
            // else {
            //     if (updateCustomizedRateModel === false) {
            //         const providerDetails = getAllProviderList?.filter(provider => provider.providerName);
            //         setSelectProviderName(providerDetails.providerName);
            //         onChangeSelectProvider(providerDetails);
            //     }
            // }
        }
    }, [updateCustomizedRateModel, botState.providersList, currentPage, pageSize, user]);

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForAllUsers({ fieldName }));
    };
    const onClickCancelButton = e => {
        const dataToSend = {
            orgId: orgId,
            orgName: orgName,
            providerId: providerId,
            providerCode: providerCode,
            providerName: providerName,
        };
        navigate("/admin/manage-clients", { state: dataToSend });
    };

    const handleSelectAllClick = () => {
        if (selected.length === userState?.customizedRate?.length) {
            setSelected([]);
            setCustomizeRateCardForClients([]);
            for (let index = 0; index < userState?.customizedRate.length; index++) {
                const element = userState?.customizedRate[index];
            }
        } else {
            setSelected(Array.from({ length: userState?.customizedRate?.length }, (_, index) => index));
            setCustomizeRateCardForClients([...userState?.customizedRate]);
        }
    };

    const handleSelect = (index, client, e) => {
        const selectedIndex = selected.indexOf(index);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, index);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        if (e.target.checked) {
            setCustomizeRateCardForClients([...customizeRateCardForClients, client]);
        } else {
            const a = customizeRateCardForClients.filter(
                selectedItem => selectedItem._id !== client._id && selectedItem.rate.countryCode === client.rate.countryCode,
            );
            setCustomizeRateCardForClients(a);
        }
    };

    const getProviderFieldsDetails = async ({ providerId, providerCode }) => {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/config/get-provider-details-by-provider`, {
            params: { providerId: providerId, providerCode: providerCode },
        });

        if (response.status === 200) {
            setProviderDetailsByProviderName(response.data.data);
            let providerOtherFieldToNumber = {};
            if (Object.keys(response.data.data?.otherFields).length > 0) {
                Object.keys(response.data.data?.otherFields).map((fieldElement, index) => {
                    providerOtherFieldToNumber[fieldElement] = 0;
                });
            }

            setOtherFields(providerOtherFieldToNumber);
        }
    };

    // const onChangeSelectProvider = value => {
    //     setSelectProviderName(value);
    //     getProviderFieldsDetails(value.name);
    //     getAllCustomizedRate(value.name);
    // };

    // const onChangeSelectProvider = async ({ name, value }) => {
    //     if (value && name) {
    //         const selectedProviderName = getAllProviderList.find(provider => {
    //             if (provider.providerName == value.providerName) return provider;
    //         });
    //         // const providerListToUpdate = [...formik.values.supportedProvidersList, value];
    //         // setSelectProviderName(selectedProviderName.providerName);
    //         setSelectProviderName({
    //             providerId: selectedProviderName._id,
    //             providerCode: selectedProviderName.providerCode,
    //             providerName: selectedProviderName.providerName,
    //         });
    //         // const providerDetails = {
    //         //     providerId: selectProviderName._id,
    //         //     providerCode: selectProviderName.providerCode,
    //         //     providerName: selectProviderName.providerName,
    //         // };

    //         getProviderFieldsDetails({ providerId: selectProviderName._id, providerCode: selectProviderName.providerCode });
    //         getAllCustomizedRate({ providerId: selectedProviderName._id, providerCode: selectedProviderName.providerCode });
    //     }
    // };

    // if (loading) {
    //     return <FetchingSpinner />;
    // }
    const updateRateForSelectedClients = () => {
        setOpenUpdateRateCardForSelectedClient(true);
    };
    const handleCloseUpdateRateCardForSelectedClient = () => {
        setOpenUpdateRateCardForSelectedClient(false);
    };

    return (
        <>
            <Box width={"100%"}>
                <Grid container mb={4} className='listHeader'>
                    <Grid item md={6}>
                        {/* <Typography variant='h3'>
                            {" "}
                            {user?.roleName === ERoles.SUPER_ADMIN || user?.roleName === ERoles.RESELLER_ADMIN
                                ? "Customized Rates"
                                : "Applicable Rates"}
                        </Typography> */}
                        <Grid item md={10} display={"flex"} alignItems={"center"}>
                            <IconButton color='secondary' onClick={onClickCancelButton} size='medium'>
                                <ArrowBackIos sx={{ cursor: "pointer" }} />
                            </IconButton>

                            <Grid role='presentation'>
                                <Breadcrumbs aria-label='breadcrumb' fontSize='large'>
                                    <Link
                                        underline='hover'
                                        color='inherit'
                                        className='breadcrumbItem breadcrumbFontSize'
                                        // href="/rcs-management/manage-bots/"
                                        onClick={onClickCancelButton}
                                    >
                                        Organization
                                    </Link>
                                    <Typography className='breadcrumbItem' color='text.primary'>
                                        {orgName}
                                    </Typography>
                                    <Typography className='breadcrumbItem' color='text.primary'>
                                        Customized Rates
                                    </Typography>
                                    {/* <Typography className='breadcrumbItem' color='text.primary'>
                                        {providerName}
                                    </Typography>  */}
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} className='listHeaderFilter'>
                        <Grid display={"flex"} gap={3}>
                            <Grid display={"flex"}>
                                {user?.roleName === ERoles.RESELLER_ADMIN &&
                                    (customizeRateCardForClients.length > 0 ? (
                                        <Button sx={{ marginRight: "10px" }} variant='contained' onClick={() => updateRateForSelectedClients()}>
                                            Increase / Decrease Rates
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled
                                            sx={{ marginRight: "10px" }}
                                            variant='contained'
                                            onClick={() => updateRateForSelectedClients()}
                                        >
                                            Increase / Decrease Rates
                                        </Button>
                                    ))}

                                {/* <FormControl
                                    size='small'
                                    sx={{
                                        display: "flex",
                                        width: "225px",
                                        // marginLeft: '390px'
                                    }}
                                >
                                    <InputLabel id='provider-label'>Select Provider</InputLabel>
                                    <Select
                                        name='provider_name'
                                        labelId='provider_name'
                                        id='provider_name'
                                        label='Select Provider'
                                        value={selectProviderName.providerName}
                                        onChange={e =>
                                            onChangeSelectProvider({
                                                value: e.target.value,
                                                name: e.target.name,
                                            })
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                },
                                            },
                                        }}
                                    >
                                        {getAllProviderList?.map((provider, index) => (
                                            <MenuItem key={index} value={provider}>
                                                {provider.providerName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Card className='border border-secondary-50' sx={{ padding: 0, margin: 0 }}>
                        <CardContent sx={{ padding: 0, margin: 0 }}>
                            {selectProviderName ? (
                                isLoading ? (
                                    <FetchingSpinner />
                                ) : (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            // margin: "10px",
                                            minWidth: "100%",
                                            // maxWidth: "90rem",
                                            overflowX: "scroll",
                                        }}
                                    >
                                        {userState.customizedRate.length > 0 ? (
                                            <TableContainer component={Paper} className='table-container'>
                                                <Table aria-label='customized table'>
                                                    <>
                                                        <TableHead className='p-3 mb-2 row'>
                                                            <TableRow>
                                                                {(user?.roleName === ERoles.SUPER_ADMIN ||
                                                                    user?.roleName === ERoles.RESELLER_ADMIN) && (
                                                                        <StyledTableCell className='tableHeaderFont sticky'>
                                                                            <Checkbox
                                                                                sx={{
                                                                                    color: "rgba(255, 255, 255, 0.7)", // Default color (when not checked)
                                                                                    "&.Mui-checked": {
                                                                                        color: "white", // Color when checked
                                                                                        backgroundColor: "rgba(255, 255, 255, 0.1)", // Background color when checked
                                                                                        borderRadius: "4px", // Rounded corners
                                                                                    },
                                                                                }}
                                                                                checked={
                                                                                    customizeRateCardForClients.length === userState.customizedRate.length
                                                                                }
                                                                                onChange={handleSelectAllClick}
                                                                                color='primary'
                                                                            />
                                                                        </StyledTableCell>
                                                                    )}
                                                                {(user?.roleName === ERoles.SUPER_ADMIN ||
                                                                    user?.roleName === ERoles.RESELLER_ADMIN) && (
                                                                        <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                            Sr No
                                                                        </StyledTableCell>
                                                                    )}
                                                                {user?.roleName === ERoles.SUPER_ADMIN ? (
                                                                    <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                        Reseller Admin
                                                                    </StyledTableCell>
                                                                ) : (
                                                                    <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                        Org Admin
                                                                    </StyledTableCell>
                                                                )}
                                                                <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                    Country
                                                                </StyledTableCell>
                                                                <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                    Text Message Rate (INR)
                                                                </StyledTableCell>
                                                                <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                    Multi Media Message Rate (INR)
                                                                </StyledTableCell>
                                                                {(user?.roleName === ERoles.SUPER_ADMIN ||
                                                                    user?.roleName === ERoles.RESELLER_ADMIN) && (
                                                                        <>
                                                                            <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                                A2P Session Conversation Rate (INR)
                                                                            </StyledTableCell>
                                                                            <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                                Feedback Rate (INR)
                                                                            </StyledTableCell>

                                                                            {providerDetailsByProviderName &&
                                                                                Object.keys(providerDetailsByProviderName?.otherFields).map(
                                                                                    (provider, index) => (
                                                                                        <StyledTableCell className='col-1 tableHeaderFont'>
                                                                                            {OtherFields[provider] ?? provider} (INR)
                                                                                        </StyledTableCell>
                                                                                    ),
                                                                                )}
                                                                        </>
                                                                    )}

                                                                <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                    Payment Type
                                                                </StyledTableCell>
                                                                {(user?.roleName === ERoles.SUPER_ADMIN ||
                                                                    user?.roleName === ERoles.RESELLER_ADMIN) && (
                                                                        <>
                                                                            <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                                Edit
                                                                            </StyledTableCell>
                                                                        </>
                                                                    )}
                                                                {(user?.roleName === ERoles.SUPER_ADMIN ||
                                                                    user?.roleName === ERoles.RESELLER_ADMIN) && (
                                                                        <>
                                                                            <StyledTableCell className=' tableHeaderFont' align='center'>
                                                                                History
                                                                            </StyledTableCell>
                                                                        </>
                                                                    )}
                                                            </TableRow>
                                                        </TableHead>

                                                        <TableBody>
                                                            <>
                                                                {userState.customizedRate?.map((client, index) => (
                                                                    <StyledTableRow key={index}>
                                                                        {(user?.roleName === ERoles.SUPER_ADMIN ||
                                                                            user?.roleName === ERoles.RESELLER_ADMIN) && (
                                                                                <StyledTableCellSticky className='tableContentFont sticky'>
                                                                                    {/* <Checkbox
                                                                                    checked={
                                                                                        customizeRateCardForClients.filter(
                                                                                            selectedClient => selectedClient._id === client._id,
                                                                                        ).length > 0
                                                                                    }
                                                                                    onChange={e => handleSelect(index, client, e)}
                                                                                    color='primary'
                                                                                /> */}
                                                                                    <Checkbox
                                                                                        checked={
                                                                                            customizeRateCardForClients.filter(
                                                                                                selectedClient =>
                                                                                                    selectedClient._id === client._id &&
                                                                                                    selectedClient.rate.countryCode ===
                                                                                                    client.rate.countryCode,
                                                                                            ).length > 0
                                                                                        }
                                                                                        onChange={e => handleSelect(index, client, e)}
                                                                                        color='primary'
                                                                                    />
                                                                                </StyledTableCellSticky>
                                                                            )}
                                                                        {(user?.roleName === ERoles.SUPER_ADMIN ||
                                                                            user?.roleName === ERoles.RESELLER_ADMIN) && (
                                                                                <StyledTableCell className='tableContentFont' align='center'>
                                                                                    {currentPage * 10 + index + 1}
                                                                                </StyledTableCell>
                                                                            )}
                                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                                            {client?.name ? client?.name : client?.resellerName}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                                            {client?.rate?.countryName}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                                            <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                            {client?.currentRate?.customizedProviderRate[
                                                                                providerCode
                                                                            ]?.A2pMessageRate?.textMessageRate?.toFixed(4) ?? "-"}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                                            <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                            {client?.currentRate?.customizedProviderRate[
                                                                                providerCode
                                                                            ]?.A2pMessageRate?.multiMediaMessageRate?.toFixed(4) ?? "-"}
                                                                        </StyledTableCell>{" "}
                                                                        {(user?.roleName === ERoles.SUPER_ADMIN ||
                                                                            user?.roleName === ERoles.RESELLER_ADMIN) && (
                                                                                <>
                                                                                    <StyledTableCell className='tableContentFont' align='center'>
                                                                                        <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                                        {client?.currentRate?.customizedProviderRate[
                                                                                            providerCode
                                                                                        ]?.A2pMessageRate
                                                                                            ?.A2pSessionConversationRate?.toFixed(4) ?? "-"}
                                                                                    </StyledTableCell>
                                                                                    <StyledTableCell className='tableContentFont' align='center'>
                                                                                        <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                                        {client?.currentRate?.customizedProviderRate[
                                                                                            providerCode
                                                                                        ]?.A2pMessageRate?.feedbackRate?.toFixed(4) ?? "-"}
                                                                                    </StyledTableCell>
                                                                                    {providerDetailsByProviderName &&
                                                                                        Object.keys(providerDetailsByProviderName?.otherFields).map(
                                                                                            (provider, index) => (
                                                                                                <StyledTableCell
                                                                                                    className='tableContentFont'
                                                                                                    align='center'
                                                                                                >
                                                                                                    <CurrencyRupee sx={{ fontSize: "14px" }} />
                                                                                                    {client?.currentRate?.customizedProviderRate[
                                                                                                        providerCode
                                                                                                    ]?.otherFields[provider]?.toFixed(4) ?? "-"}
                                                                                                </StyledTableCell>
                                                                                            ),
                                                                                        )}
                                                                                </>
                                                                            )}
                                                                        <StyledTableCell className='tableContentFont' align='center'>
                                                                            {client?.rate?.billingPaymentType ??
                                                                                client?.rate?.billingDetails?.billingPaymentType ??
                                                                                "-"}
                                                                        </StyledTableCell>
                                                                        {(user?.roleName === ERoles.SUPER_ADMIN ||
                                                                            user?.roleName === ERoles.RESELLER_ADMIN) && (
                                                                                <StyledTableCell>
                                                                                    <Tooltip title='Edit Customize Rate'>
                                                                                        <IconButton onClick={() => onClickEditCustomizedRate(client)}>
                                                                                            <Edit />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </StyledTableCell>
                                                                            )}
                                                                        {(user?.roleName === ERoles.SUPER_ADMIN ||
                                                                            user?.roleName === ERoles.RESELLER_ADMIN) && (
                                                                                <StyledTableCell>
                                                                                    <Tooltip title='History Customize Rate'>
                                                                                        <IconButton
                                                                                            onClick={() => onClickViewHistoryOfCustomizedRate(client)}
                                                                                        >
                                                                                            <History />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                </StyledTableCell>
                                                                            )}
                                                                    </StyledTableRow>
                                                                ))}
                                                            </>
                                                        </TableBody>
                                                    </>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <NoRecords />
                                        )}
                                    </Box>
                                )
                            ) : (
                                <Grid sx={{ display: "flex", justifyContent: "center" }}>
                                    <Typography variant='h6'>
                                        <b>PLEASE SELECT PROVIDER</b>
                                    </Typography>
                                </Grid>
                            )}

                            {(user?.roleName === ERoles.SUPER_ADMIN || user?.roleName === ERoles.RESELLER_ADMIN) && totalCount > 0 && (
                                <Grid
                                    className='my-2'
                                    style={{
                                        zIndex: "2",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: 2,
                                    }}
                                >
                                    <Grid
                                        style={{
                                            marginTop: "15px",
                                            padding: 5,
                                        }}
                                    >
                                        Total Customize Rates: {totalCount}
                                    </Grid>
                                    <Grid>
                                        <TablePaginationShowPage
                                            rowsPerPageOptions={[1, 5, 10, 25, 100]}
                                            colSpan={3}
                                            count={totalCount}
                                            rowsPerPage={pageSize}
                                            page={currentPage}
                                            slotProps={{
                                                select: {
                                                    "aria-label": "Rows per page",
                                                },
                                                actions: {
                                                    showFirstButton: true,
                                                    showLastButton: true,
                                                    slots: {
                                                        firstPageIcon: FirstPageRoundedIcon,
                                                        lastPageIcon: LastPageRoundedIcon,
                                                        nextPageIcon: ChevronRightRoundedIcon,
                                                        backPageIcon: ChevronLeftRoundedIcon,
                                                    },
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </CardContent>
                        <CardActions sx={{ padding: 0, margin: 0 }}></CardActions>
                    </Card>
                </Grid>
                <AlertMessageComponent message={errorResponse} handleClose={() => setErrorResponse("")} show={Boolean(errorResponse)} />
            </Box>
            {updateCustomizedRateModel && (
                <UpdateRateByClientIdModal
                    open={onClickEditCustomizedRate}
                    handleClose={handleCloseEditCustomizedRate}
                    clientData={clientData}
                    // selectProviderName={selectProviderName}
                    providerId={providerId}
                    providerCode={providerCode}
                    providerDetailsByProviderName={providerDetailsByProviderName}
                    otherFields={otherFields}
                    getAllCustomizedRate={getAllCustomizedRate}
                />
            )}
            {viewHistoryOfCustomizedRates && (
                <ViewHistoryOfCustomizedRates
                    open={openViewHistoryCustomizedRates}
                    handleClose={closeViewHistoryCustomizedRates}
                    clientData={clientData}
                />
            )}
            <Dialog open={openUpdateRateCardForSelectedClient}>
                {openUpdateRateCardForSelectedClient && (
                    <IncreaseDecreaseCustomizeRateForSelectedClients
                        open={openUpdateRateCardForSelectedClient}
                        handleClose={handleCloseUpdateRateCardForSelectedClient}
                        setOpenUpdateRateCardForSelectedClient={setOpenUpdateRateCardForSelectedClient}
                        customizeRateCardForClients={customizeRateCardForClients}
                        otherFields={otherFields}
                        selectProviderName={selectProviderName}
                        getAllCustomizedRate={getAllCustomizedRate}
                        providerId={providerId}
                        providerCode={providerCode}
                    />
                )}
            </Dialog>
        </>
    );
}
