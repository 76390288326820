import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGatewayAxiosInstance } from "../../utils/axios";

export const addNewCampaignThunk = createAsyncThunk("add-new-campaign", async ({ data, botId, templateId, registryId }) => {
    try {
        const response = await apiGatewayAxiosInstance.post("/bot_management/campaign/create-campaign", data, {
            headers: {
                "Content-Type": "multipart/form-data",
                botId,
                templateId,
            },
            params: { registryId },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllCampaignsByPageSize = createAsyncThunk("get-all-campaigns-by-pagesize", async ({ currentPage, pageSize }) => {
    try {
        const response = await apiGatewayAxiosInstance.get("/bot_management/campaign/get-all-campaigns-by-pagesize", {
            params: {
                pageSize,
                currentPage,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});
export const getAllCampaignByFilterThunk = createAsyncThunk(
    "get-all-campaign-by-filter,",
    async ({ pageSize, currentPage, searchParams, registryId, roleName }) => {
        try {
            const response = await apiGatewayAxiosInstance.get(`/bot_management/campaign`, {
                // headers: {
                //     "Content-Type": "application/json",
                // },
                params: {
                    pageSize,
                    currentPage,
                    botName: searchParams.botName,
                    campaignName: searchParams.campaignName,
                    templateName: searchParams.templateName,
                    createdAtStartDate: searchParams.createdAtStartDate,
                    // createdAtEndDate: searchParams.createdAtEndDate,
                    executedAtStartDate: searchParams.executedAtStartDate,
                    // executedAtEndDate: searchParams.executedAtEndDate,
                    status: searchParams.status,
                    registryId,
                    roleName,
                },
            });
            if (response.status === 200) {
                return response.data;
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            throw errorMessage;
        }
    },
);

export const runCampaign = createAsyncThunk("run-campaign", async campaignData => {
    try {
        const response = await apiGatewayAxiosInstance.post("/bot_management/campaign/run-campaign", campaignData);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getCampaignReportData = createAsyncThunk(
    "get-campaign-report-data",
    async ({ botId, campaignId, fetchCampgainStatus, executedDate, startDate }) => {
        try {
            const response = await apiGatewayAxiosInstance.get(`/conversation_logger/logger/get-campaign-reports-paralley`, {
                headers: {
                    "Content-Type": "application/json",
                },
                params: {
                    campaignId,
                    status: fetchCampgainStatus,
                    botId,
                    executedDate,
                    startDate: startDate,
                },
            });

            if (response.status === 200) {
                return response.data
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            throw errorMessage;
        }
    }
);

export const sendTestMessageThunk = createAsyncThunk(
    "send-test-message",
    async ({ botId, templateId, number, variables }) => {
        try {
            const body = {
                botId,
                templateId,
                number,
                variables
            }
            const response = await apiGatewayAxiosInstance.post(`/bot_management/test-device/send-test-message`, {
                ...body,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                return response.data
            } else {
                throw new Error(response);
            }
        } catch (error) {
            let errorMessage = "Server not reachable.";
            if (error.response) {
                if (error.response) {
                    console.log(error.response.data.message);
                    errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
                }
                throw errorMessage;
            }
        }
    }
);
