import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGatewayAxiosInstance } from "../../utils/axios";

//--------------------------------- Create New Bot ---------------------------------------------------------------------------

export const addNewBotThunk = createAsyncThunk("add-new-bot", async ({ botId, formDataToSend, registryId }) => {
    try {
        const response = await apiGatewayAxiosInstance.post(`/bot_management/bot/create-bot`, formDataToSend, {
            headers: {
                "Content-Type": "multipart/form-data",
                botId,
            },
            params: {
                registryId: registryId
            }
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//--------------------------------- Get All Bots Thunk ---------------------------------------------------------------------------

export const getAllBotsThunk = createAsyncThunk("get-all-bots", async () => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/bot/get-all-bots`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllBotsConversationLogsReports = createAsyncThunk("get-all-bot-conversation-logs", async ({ botId }) => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/bot/get-all-bot-conversation-logs`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//--------------------------------- Get All Verified Bots Thunk ---------------------------------------------------------------------------

export const getAllVerifiedBotsThunk = createAsyncThunk("get-all-verified-bots", async ({ registryId }) => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/bot/get-all-verified-bots-by-orgId`, { params: { registryId } });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllVerifiedBotsToRunCampaignThunk = createAsyncThunk("get-all-verified-bots-to-run-campaign", async () => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/bot/get-all-verified-promotional-bots-by-orgid-to-run-campaign`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//--------------------------------- Get All Bots By Page Size Thunk  ---------------------------------------------------------------------------

export const getAllBotsThunkByPageSize = createAsyncThunk("get-all-bots-by-pagesize", async ({ pageSize, currentPage }) => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/bot/get-all-bots-by-pagesize`, {
            params: {
                pageSize,
                currentPage,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});
export const getAllBotByFilterThunk = createAsyncThunk("get-all-bot-by-filter,", async ({ pageSize, currentPage, searchParams, registryId }) => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/bot`, {
            headers: {
                "Content-Type": "application/json",
            },
            params: {
                pageSize,
                currentPage,
                botName: searchParams.botName,
                brandName: searchParams.brandName,
                clientName: searchParams.clientName,
                botMessageType: searchParams.botMessageType,
                status: searchParams.status,
                registryId,
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//--------------------------------- Verify Bot Thunk  ---------------------------------------------------------------------------
export const verifyBotThunk = createAsyncThunk("verify-bot", async ({ formDataToSend }) => {
    try {
        const response = await apiGatewayAxiosInstance.post("/bot_management/bot/verify-bot", formDataToSend, {
            headers: {
                "Content-Type": "multipart/form-data",
                folderToCreateForScreenshots: "screenshots",
            },
        });
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//--------------------------------- Verify Client Secret Data Thunk  ---------------------------------------------------------------------------
export const VerifyClientSecretThunk = createAsyncThunk("verify-client-secret", async ({ data }) => {
    try {
        const response = await apiGatewayAxiosInstance.post("/bot_management/bot/verify-client-secret", data);
        if (response.status === 200) {
            return response;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//--------------------------------------change bot status to pending--------------------------------------------------------------------------------------

export const changeBotStatusThunk = createAsyncThunk("change-bot-status", async ({ data }) => {
    try {
        const response = await apiGatewayAxiosInstance.post("/bot_management/bot/update-bot-status", data);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//--------------------------------------autofill bot-details---------------------------------------
export const autoFillBotDetailsThunk = createAsyncThunk("auto-fill-bot-details", async ({ botDetails }) => {
    try {
        const response = await apiGatewayAxiosInstance.post("/bot_management/bot/auto-fill-bot-details", botDetails);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

//-------------------------------------- Test Devices ---------------------------------------

export const addNewTestDeviceThunk = createAsyncThunk("add-new-test-device", async ({ botId, testDeviceNumber }) => {
    try {
        const response = await apiGatewayAxiosInstance.post(
            `/bot_management/test-device/add`,
            {
                botId,
                phoneNumber: testDeviceNumber,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllTestDeviceListByBotIdByPageSize = createAsyncThunk("get-test-devices-by-page-size", async ({ botId, pageSize, currentPage }) => {
    try {
        const response = await apiGatewayAxiosInstance.get(
            `/bot_management/test-device/get-all-test-devices-by-pagesize`,
            {
                params: {
                    pageSize,
                    currentPage,
                    botid: botId,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllAnalyticsDataThunk = createAsyncThunk("get-all-analytics-data", async () => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/logger/get-campaign-reports-for-dashboard`);
        if (response.status === 200) {
            return response.data.campaignListWithReportDetails;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});

export const getAllProvidersThunk = createAsyncThunk("get-all-providers-from-db", async () => {
    try {
        const response = await apiGatewayAxiosInstance.get(`/bot_management/config/get-all-providers-from-db`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response);
        }
    } catch (error) {
        console.dir(error);
        let errorMessage = "Server not reachable.";

        if (error.response) {
            errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
        }
        throw errorMessage;
    }
});
