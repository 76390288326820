import axios from "axios";
import { config } from "../config";
import { useEffect } from "react";

export let orgId = "";
export let REACT_APP_API_URL = "";
export let REACT_APP_WHITELABEL_NAME = "";
export let REACT_APP_WHITELABEL_LOGO_URL = "";
export let REACT_APP_FRONTEND_URL = "";
export let BUTTON_TEXT_COLOR;
export let BUTTON_BACKGROUND_COLOR;
export let NORMAL_TEXT_COLOR;
export let BACKGROUND_IMAGE;

export let domain = window.location.hostname;
const apiGatewayBaseUrl = config.APIGatewayServerUrl;
// const apiGatewayBaseUrl = "http://172.30.128.1:5022/"
const getEnvVariables = async () => {
    try {
        const response = await axios.get(`${apiGatewayBaseUrl}user_management/clients/get-env-variables`, { params: { domain } });
        orgId = response.data.data.orgId;
        REACT_APP_API_URL = response.data.data.whiteLabelBackendURL;
        REACT_APP_FRONTEND_URL = response.data.data.whiteLabelFrontendURL;
        REACT_APP_WHITELABEL_NAME = response.data.data.whiteLabelName;
        if (response.data.data.whiteLabelLogo.url) {
            REACT_APP_WHITELABEL_LOGO_URL = response.data.data.whiteLabelLogo.url;
        } else {
            REACT_APP_WHITELABEL_LOGO_URL = response.data.data.whiteLabelLogo;
        }
        BUTTON_TEXT_COLOR = response.data.data.buttonTextColor.color ? response.data.data.buttonTextColor.color : "#FFFFF";
        BUTTON_BACKGROUND_COLOR = response.data.data.buttonBackgroundColor.color ? response.data.data.buttonBackgroundColor.color : "#1984D9";
        NORMAL_TEXT_COLOR = response.data.data.normalTextColor.color ?? response.data.data.normalTextColor;
        BACKGROUND_IMAGE = response.data.data.backgroundImage.url ?? response.data.data.backgroundImage;
        setFavicon(REACT_APP_WHITELABEL_LOGO_URL);
        // document.getElementById("root").style.backgroundImage = `url(${BACKGROUND_IMAGE})`;
        // document.getElementById("root").style.border = "1px solid red";

        // document.getElementById("root").style.backgroundImage = `url(${BACKGROUND_IMAGE})`;
        // document.getElementById("root").style.backgroundRepeat = "no-repeat"; // Prevents the image from repeating
        // document.getElementById("root").style.backgroundSize = "cover"; // Makes the image cover the entire element
        // document.getElementById("root").style.backgroundPosition = "center"; // Centers the image
    } catch (error) {
        console.log(error);
    }
};
getEnvVariables();

const setFavicon = url => {
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = url;

    // Remove old favicon if it exists
    const existingFavicon = document.querySelector("link[rel*='icon']");
    if (existingFavicon) {
        document.head.removeChild(existingFavicon);
    }
    document.head.appendChild(link);
};

export const useEnvVariables = () => {
    useEffect(() => {
        getEnvVariables();
    }, []);
};

const userManagementAxiosInstance = axios.create({
    baseURL: config.UserManagementServerUrl,
});

userManagementAxiosInstance.interceptors.response.use(
    response => response,
    error => Promise.reject(error),
);

userManagementAxiosInstance.interceptors.request.use(
    config => {
        const accessToken = window.sessionStorage.getItem("accessToken");
        const userId = window.sessionStorage.getItem("userId");
        config.headers.Authorization = `Bearer ${accessToken}`;
        config.headers.orgId = userId;
        return config;
    },
    error => Promise.reject(error),
);

export const apiGatewayAxiosInstance = axios.create({
    baseURL: apiGatewayBaseUrl,
    params: { domain },
});

// apiGatewayAxiosInstance.defaults.withCredentials = true;

apiGatewayAxiosInstance.interceptors.response.use(
    response => response,
    error => Promise.reject(error),
);

apiGatewayAxiosInstance.interceptors.request.use(
    config => {
        // console.log(config);
        // let accessToken = null;
        // if (config.params && config.params?.token) {
        //     accessToken = config.params.token;
        // } else {
        const accessToken = window.sessionStorage.getItem("accessToken");
        // }
        config.headers.Authorization = `Bearer ${accessToken}`;
        return config;
    },
    error => Promise.reject(error),
);

export const botManagementAxiosInstance = axios.create({
    baseURL: config.BotManagementServerUrl,
});

botManagementAxiosInstance.interceptors.response.use(
    response => response,

    error => Promise.reject(error),
);

botManagementAxiosInstance.interceptors.request.use(
    config => {
        const accessToken = window.sessionStorage.getItem("accessToken");
        // const accessToken = "fasd"
        const userId = window.sessionStorage.getItem("userId");
        config.headers.Authorization = `Bearer ${accessToken}`;
        // config.headers.orgId = userId;
        return config;
    },
    error => Promise.reject(error),
);

export default userManagementAxiosInstance;
