import { Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllVerifiedBotsToRunCampaignThunk } from "../../../redux/botManagement/botManagementThunk";
import {
    onRejectGetBotConversationLogsReportDetails
} from "../../../redux/campaignReportsManagment/campaignReportsManagmentSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import BotsList from "./BotsList";
import UserChats from "./UserChats";
import UsersList from "./UsersList";

export default function ConversationLogs() {
    // const campaignReportState = useSelector(state => state.campaignReportsManagementSlice);
    // const dispatch = useDispatch();
    const [selectedBot, setSelectedBot] = useState();
    const [selectedUserNumber, setSelectedUserNumber] = useState("");
    const [usersList, setUsersList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const pageSize = 5;
    const [selectedUserDetails, setSelectedUserDetails] = useState([]);
    // const [selectedBotDetails, setSelectedBotDetails] = useState({});
    const [numberListOfUser, setNumberListOfUsers] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);

    // const onSelectBot = botId => {
    //     setSelectedBotId(botId);
    //     setSelectedUserNumber("");
    //     const selectedBot = campaignReportState.botConversationLogsReportDetails.find(bot => bot.config.rbmClientId === botId);
    //     setSelectedBotDetails(selectedBot);

    //     let numbersList = [];

    //     for (let index = 0; index < selectedBot.otherDetails.reportDetails.length; index++) {
    //         const element = selectedBot.otherDetails.reportDetails[index];
    //         if (!numbersList.find(user => user.receiverNumber === element.receiverNumber)) {
    //             numbersList = [...numbersList, element];
    //         }
    //     }
    //     setNumberListOfUsers(numbersList);

    //     setUsersList(selectedBot.otherDetails.reportDetails);
    // };

    // const onSelectUser = userNumber => {
    //     setSelectedUserNumber(userNumber);
    //     const selectedNumberDetails = usersList.filter(user => user.receiverNumber === userNumber);
    //     setSelectedUserDetails(selectedNumberDetails);
    // };

    // useEffect(() => {
    //     getAllBotsConversationLogsReports();
    // }, []);

    // const onCloseErrorMessage = ({ fieldName }) => {
    //     dispatch(clearErrorMessagesForReports({ fieldName }));
    // };

    // const getAllBotsConversationLogsReports = async () => {
    //     try {
    //         dispatch(onPendingBotConversationLogsReportDetailsLoading(true));
    //         const response = await apiGatewayAxiosInstance.get(`/bot_management/bot/get-all-bot-conversation-logs`);
    //         if (response.status === 200) {
    //             dispatch(onSuccessBotConversationLogReportDetails(response.data.botListWithReportDetails));
    //         } else {
    //             throw new Error(response);
    //         }
    //     } catch (error) {
    //         let errorMessage = "Failed to get all logs.";

    //         if (error.response) {
    //             errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
    //         }
    //         dispatch(onRejectGetBotConversationLogsReportDetails(errorMessage));
    //     }

    // };
    const dispatch = useDispatch();


    const state = useSelector(state => state.botManagementSlice);
    useEffect(() => {
        // dispatch(getAllVerifiedBotsThunk());
        dispatch(getAllVerifiedBotsToRunCampaignThunk());

    }, []);


    const onSelectBot = async (bot) => {
        setLoading(true);
        setSearchValue("")
        setSelectedUserNumber("")
        try {


            // const campaignReportDetailsResponse = await apiGatewayAxiosInstance.get(`conversation_logger/logger/get-numbers-by-botId`, {
            //     params: { botId: bot?.config?.rbmClientId },
            // });

            // if (campaignReportDetailsResponse.status === 200) {

            //     if (campaignReportDetailsResponse?.data?.NumberList) {

            //         setNumberListOfUsers(campaignReportDetailsResponse.data.NumberList);
            //     } else {
            //         setNumberListOfUsers([])
            //     }
            // }

            setSelectedBot(bot);

            setLoading(false);

        } catch (error) {
            let errorMessage = "Failed to get all logs.";
            setLoading(false);

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(onRejectGetBotConversationLogsReportDetails(errorMessage));
        }


    }
    const onSelectUser = async (number) => {
        setLoadingMessage(true)
        setSelectedUserNumber(number)
        setPageNumber(0);
        try {

            const campaignReportDetailsResponse = await apiGatewayAxiosInstance.get(`conversation_logger/logger/get-conversation-logs-by-botId-number`, {
                params: { botId: selectedBot?.config?.rbmClientId, number, pageSize, pageNumber: 0 },
            });


            if (campaignReportDetailsResponse.status === 200) {

                if (campaignReportDetailsResponse?.data?.ConversationLogs) {

                    setSelectedUserDetails(campaignReportDetailsResponse.data.ConversationLogs);
                } else {
                    setSelectedUserDetails([])
                }
            }

            // setSelectedBot(bot);


        } catch (error) {
            let errorMessage = "Failed to get all logs.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(onRejectGetBotConversationLogsReportDetails(errorMessage));
        }
        setLoadingMessage(false);

    }
    const onScrollTop = async (NextpageNumber) => {
        // setLoadingMessage(true)
        // setSelectedUserNumber(number)
        try {


            const campaignReportDetailsResponse = await apiGatewayAxiosInstance.get(`conversation_logger/logger/get-conversation-logs-by-botId-number`, {
                params: { botId: selectedBot?.config?.rbmClientId, number: selectedUserNumber, pageSize, pageNumber: NextpageNumber },
            });


            if (campaignReportDetailsResponse.status === 200) {

                if (campaignReportDetailsResponse?.data?.ConversationLogs) {

                    setSelectedUserDetails([...campaignReportDetailsResponse.data.ConversationLogs, ...selectedUserDetails]);
                }
            }

            // setSelectedBot(bot);


        } catch (error) {
            let errorMessage = "Failed to get all logs.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(onRejectGetBotConversationLogsReportDetails(errorMessage));
        }
        // setLoadingMessage(false);

    }

    return (
        <Grid minHeight={"75vh"} maxHeight={"75vh"}>
            {/* <Card sx={{ marginBottom: "20px" }}> */}
            <Grid item md={6} className='align-items-center'>
                <Typography variant='h3' padding={5}>
                    Conversation Logs
                </Typography>
            </Grid>
            {/* </Card> */}
            <Card sx={{ height: "61vh" }}>
                {/* {campaignReportState.controls.isGetBotConversationLogsReportLoading ? (
                    <FetchingSpinner />
                ) : campaignReportState.botConversationLogsReportDetails && campaignReportState.botConversationLogsReportDetails.length > 0 ? ( */}
                <Grid container height={"61vh"}>
                    <Grid
                        item
                        md={3}
                        minHeight={"100%"}
                        height={"100%"}
                        sx={{
                            borderRight: "1px solid grey",
                        }}
                    >
                        <BotsList
                            botList={state.verifiedBotListToRunCampaign}
                            onSelectBot={onSelectBot}
                            selectedBot={selectedBot}
                        />
                    </Grid>
                    <Grid
                        item
                        md={3}
                        height={"61vh"}
                        sx={{
                            borderRight: "1px solid grey",
                        }}
                    >
                        <UsersList
                            selectedUserNumber={selectedUserNumber}
                            usersList={usersList}
                            selectedBot={selectedBot}
                            botId={selectedBot}
                            loading={loading}
                            onSelectUser={onSelectUser}
                            numberListOfUser={numberListOfUser}
                            setSearchValue={setSearchValue}
                            searchValue={searchValue}
                        />
                    </Grid>
                    <Grid
                        item
                        md={6}
                    // sx={{
                    //     border: "1px solid grey",
                    // }}
                    >
                        <UserChats
                            selectedUserDetails={selectedUserDetails}
                            selectedBotId={selectedBot?.config?.rbmClientId}
                            selectedUserNumber={selectedUserNumber}
                            botDetails={selectedBot}
                            loadingMessage={loadingMessage}
                            pageSize={pageSize}
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                            onScrollTop={onScrollTop}
                        />
                    </Grid>
                    {/* <Grid item md={4}></Grid> */}
                </Grid>
                {/* ) : (
                    <NoRecords />
                )} */}
            </Card>
        </Grid>
    );
}
