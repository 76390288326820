import { useTheme } from "@emotion/react";
import { Box, Button, Card, CardContent, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorMessageForBaseRate, setErrorMessageForUser } from "../../../redux/userManagement/userManagementSlice";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import { ERoles } from "../../../config";
// import { showSnackbar } from '../store/snackbarSlice';

export default function IncreaseDecreaseCustomizeRateForSelectedClients ({
    openUpdateRateCardForSelectedClient,
    setOpenUpdateRateCardForSelectedClient,
    customizeRateCardForClients,
    selectProviderName,
    otherFields,
    getAllCustomizedRate,
    providerId,
    providerCode,
}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const state = useSelector(state => state.userManagementSlice);
    const [isUpdateRatesLoading, setIsUpdateRatesLoading] = useState(false);
    const initialValues = {
        updatedTextMessageRate: 0,
        updatedMultiMediaMessageRate: 0,
        updatedA2pSessionConversationRate: 0,
        updatedFeedbackRate: 0,
        updatedOtherFields: otherFields,
    };
    const validateIfValueIsEmpty = fieldName => {
        const fieldValue = formik.values[fieldName];
        if (fieldValue === "" || fieldValue === null) {
            formik.setFieldError(fieldName, `Required`);
            return false;
        }
        return true;
    };
    const validateIfValueIsEmptyForOtherFields = fieldName => {
        const fieldValue = formik.values.updatedOtherFields[fieldName];
        if (fieldValue === "" || fieldValue === null) {
            formik.setFieldError(`updatedOtherFields.${fieldName}`, `Required`);
            return false;
        } else {
            return true;
        }
    };

    const validation = () => {
        let result = true;

        if (!validateIfValueIsEmpty("updatedTextMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedMultiMediaMessageRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedA2pSessionConversationRate")) {
            result = false;
        }
        if (!validateIfValueIsEmpty("updatedFeedbackRate")) {
            result = false;
        }

        for (let index = 0; index < Object.keys(initialValues.updatedOtherFields).length; index++) {
            const otherRate = Object.keys(initialValues.updatedOtherFields)[index];
            if (!validateIfValueIsEmptyForOtherFields(otherRate)) {
                result = false;
            }
        }
        // if (!validateIfValueIsEmpty('updatedUserMessageRate')) {
        //     result = false
        // }
        // if (!validateIfValueIsEmpty('updatedP2aConversationRate')) {
        //     result = false
        // }
        return result;
    };

    const updateCustomizedRatesForSelectedClients = async ({ data }) => {
        try {
            setIsUpdateRatesLoading(true);
            const response = await apiGatewayAxiosInstance.post(`/user_management/customized-rate/increase-decrease-customized-rate`, data);
            if (response.status === 200) {
                setIsUpdateRatesLoading(false);
                onClickCancelButton();
                getAllCustomizedRate({ providerId: providerId, providerCode: providerCode });
            } else {
                throw new Error(response.data);
            }
        } catch (error) {
            setIsUpdateRatesLoading(false);
            let errorMessage = "Failed to update rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "updateMasterRateForAllCountryErrorMessage",
                    errorMessage,
                }),
            );
        }
    };

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                if (validation()) {
                    let clientsToUpdateRates = [];

                    customizeRateCardForClients.map(clientElement => {
                        let registryIdToSend;
                        let orgIdToSend;
                        let countryCode;
                        if (clientElement.roleName === ERoles.ORG_ADMIN) {
                            const objectOfCountriesToAdd = {
                                registryIdToSend: clientElement.registryId,
                                orgIdToSend: clientElement.orgId,
                                countryCode: clientElement?.rate?.countryCode,
                            };
                            clientsToUpdateRates.push(objectOfCountriesToAdd);
                        } else {
                            const objectOfCountriesToAdd = {
                                registryIdToSend: clientElement._id,
                                orgIdToSend: "",
                                countryCode: clientElement?.rate?.countryCode,
                            };
                            clientsToUpdateRates.push(objectOfCountriesToAdd);
                        }
                    });

                    let dataToSend = {
                        updatedTextMessageRate: values.updatedTextMessageRate,
                        updatedMultiMediaMessageRate: values.updatedMultiMediaMessageRate,
                        updatedA2pSessionConversationRate: values.updatedA2pSessionConversationRate,
                        updatedFeedbackRate: values.updatedFeedbackRate,
                        updatedOtherFields: values.updatedOtherFields,
                        // providerName: name,
                        providerId: providerId,
                        providerCode: providerCode,
                        clientsToUpdateRates: clientsToUpdateRates,
                    };

                    updateCustomizedRatesForSelectedClients({
                        data: dataToSend,
                    });
                }
            } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
                dispatch(
                    setErrorMessageForUser({
                        fieldName: "updateMasterRateForAllCountryErrorMessage",
                        errorMessage: message,
                    }),
                );
            }
        },
    });

    const onClickCancelButton = () => {
        setOpenUpdateRateCardForSelectedClient(false);
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessageForBaseRate({ fieldName }));
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                <Grid>
                    <Card className='border border-secondary-50 '>
                        <CardContent>
                            <Grid
                                container
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Grid item>
                                    <Typography variant='h4' className='text-primary'>
                                        <b>Increase / Decrease Customize Rates</b>
                                    </Typography>
                                </Grid>

                                <Grid container display={"flex"}>
                                    <Grid item md={5} mt={5}>
                                        <Typography
                                            variant='h6'
                                            // className='text-primary'
                                        >
                                            Selected clients to update :
                                        </Typography>
                                        {customizeRateCardForClients && customizeRateCardForClients.length > 0 && (
                                            <Card
                                                sx={{
                                                    height: "80%",
                                                    border: "1px solid black",
                                                    marginRight: "10px",
                                                    marginTop: "5px",
                                                    overflow: "scroll",
                                                }}
                                            >
                                                <CardContent>
                                                    {customizeRateCardForClients.map((clientDetails, index) => (
                                                        <>
                                                            <Grid display={"flex"}>
                                                                <Typography>{index + 1}.</Typography> &nbsp;
                                                                <Typography>
                                                                    {clientDetails.resellerName ? clientDetails.resellerName : clientDetails.name}{" "}
                                                                </Typography>
                                                                &nbsp;-&nbsp;
                                                                <Typography>
                                                                    {clientDetails.rate.countryName
                                                                        ? clientDetails.rate.countryName
                                                                        : clientDetails.name}{" "}
                                                                </Typography>
                                                            </Grid>
                                                            <hr />
                                                        </>
                                                    ))}
                                                </CardContent>
                                            </Card>
                                        )}
                                    </Grid>

                                    <Grid item md={5}>
                                        <Grid mt={4}>
                                            <TextField
                                                className='mb-2'
                                                fullWidth
                                                size='small'
                                                type='number'
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                label='Text Message Rate '
                                                name='updatedTextMessageRate'
                                                defaultValue={0}
                                                error={Boolean(formik.touched.updatedTextMessageRate && formik.errors.updatedTextMessageRate)}
                                                helperText={formik.touched.updatedTextMessageRate && formik.errors.updatedTextMessageRate}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>

                                        <Grid mt={3}>
                                            <TextField
                                                className='mb-2'
                                                fullWidth
                                                size='small'
                                                type='number'
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                label='Multi Media Message Rate'
                                                name='updatedMultiMediaMessageRate'
                                                defaultValue={0}
                                                error={Boolean(
                                                    formik.touched.updatedMultiMediaMessageRate && formik.errors.updatedMultiMediaMessageRate,
                                                )}
                                                helperText={formik.touched.updatedMultiMediaMessageRate && formik.errors.updatedMultiMediaMessageRate}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>

                                        <Grid mt={3}>
                                            <TextField
                                                className='mb-2'
                                                fullWidth
                                                size='small'
                                                type='number'
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                label='A2p SessionConversation Rate'
                                                name='updatedA2pSessionConversationRate'
                                                defaultValue={0}
                                                error={Boolean(
                                                    formik.touched.updatedA2pSessionConversationRate &&
                                                        formik.errors.updatedA2pSessionConversationRate,
                                                )}
                                                helperText={
                                                    formik.touched.updatedA2pSessionConversationRate &&
                                                    formik.errors.updatedA2pSessionConversationRate
                                                }
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>

                                        <Grid mt={3}>
                                            <TextField
                                                className='mb-2'
                                                fullWidth
                                                size='small'
                                                type='number'
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                label='Feedback rate'
                                                name='updatedFeedbackRate'
                                                defaultValue={0}
                                                error={Boolean(formik.touched.updatedFeedbackRate && formik.errors.updatedFeedbackRate)}
                                                helperText={formik.touched.updatedFeedbackRate && formik.errors.updatedFeedbackRate}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>

                                        {Object.keys(otherFields).map(otherField => (
                                            <Grid mt={3}>
                                                <TextField
                                                    className='mb-2'
                                                    fullWidth
                                                    size='small'
                                                    type='number'
                                                    inputProps={{
                                                        step: "any",
                                                    }}
                                                    onWheel={e => e.target.blur()}
                                                    label={otherField}
                                                    name={`updatedOtherFields.${otherField}`}
                                                    defaultValue={0}
                                                    error={Boolean(
                                                        formik.touched?.updatedOtherFields &&
                                                            formik.touched?.updatedOtherFields[otherField] &&
                                                            formik.errors?.updatedOtherFields &&
                                                            formik.errors?.updatedOtherFields[otherField],
                                                    )}
                                                    helperText={
                                                        formik.touched?.updatedOtherFields &&
                                                        formik.touched?.updatedOtherFields[otherField] &&
                                                        formik.errors?.updatedOtherFields &&
                                                        formik.errors?.updatedOtherFields[otherField]
                                                    }
                                                    // error={Boolean(formik.touched.updatedUserMessageRate && formik.errors.updatedUserMessageRate)}
                                                    // helperText={formik.touched.updatedUserMessageRate && formik.errors.updatedUserMessageRate}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                        ))}
                                        <Grid
                                            container
                                            mt={2}
                                            style={{
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            {/* {isBotEditable && ( */}
                                            <Grid padding={1}>
                                                {isUpdateRatesLoading ? (
                                                    <Button variant='outlined'>
                                                        <CircularProgress size={15} className='spinner' />
                                                        Updating..
                                                    </Button>
                                                ) : (
                                                    <Button type='submit' variant='contained'>
                                                        Update
                                                    </Button>
                                                )}
                                            </Grid>
                                            {/* )} */}

                                            <Grid padding={1}>
                                                <Button variant='contained' color='error' onClick={() => onClickCancelButton()}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Box>
            {state.controls.updateMasterRateForAllCountryErrorMessage && (
                <AlertMessageComponent
                    message={state.controls.updateMasterRateForAllCountryErrorMessage}
                    fieldName={"updateMasterRateForAllCountryErrorMessage"}
                    handleClose={onCloseErrorMessage}
                    show={Boolean(state.controls.updateMasterRateForAllCountryErrorMessage)}
                />
            )}
        </form>
    );
}
