import {
    Box,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientStatus, ERoles } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import { clearErrorMessagesForAllUsers, setShowAddUserForm } from "../../../redux/userManagement/userManagementSlice";
import { getAllUsersByFilterThunk, getAllUsersByPageSizeThunk } from "../../../redux/userManagement/userManagementThunk";
import Page401 from "../../auth/Page401";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import FetchingSpinner from "../../components/FetchingSpinner";
import NoRecords from "../../components/NoRecords";
import FilterAllUserModel from "./FilterAllUserModel";
import { CustomTablePagination } from "../../../utils/CustomPagination";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import TablePaginationShowPage from "../../../utils/tablePagination";
import { canShowForSuperAdminOrResellerAdmin } from "../../../utils/canShowForSuperAdminOrResellerAdmin";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function ListUsers() {
    const [role, setRole] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const state = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [searchParams, setSearchParams] = React.useState({
        userName: "",
        email: "",
        mobileNumber: "",
        status: "",
    });

    const [pageSize, setPageSize] = React.useState(10);
    const { user } = useAuth();
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // setSearchParams({});
        setOpen(false);
    };

    const onSelectRole = e => {
        setRole(e.target.value);
    };

    const onClickAddNewUser = () => {
        dispatch(setShowAddUserForm(true));
    };

    // const handlePageChange = page => {
    //     setCurrentPage(page);
    //     dispatch(getAllUsersByPageSizeThunk({ currentPage: page + 1, pageSize }));
    // };

    useEffect(() => {
        // dispatch(
        //     getAllUsersByPageSizeThunk({
        //         currentPage: currentPage + 1,
        //         pageSize,
        //     })
        // );

        dispatch(
            getAllUsersByFilterThunk({
                currentPage: currentPage + 1,
                pageSize,
                searchParams,
                registryId: user?.registryId,
            }),
        );
    }, [currentPage, pageSize, user]);

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForAllUsers({ fieldName }));
    };
    const handleClickSearch = async () => {
        setCurrentPage(0);
        setPageSize(10);
        dispatch(
            getAllUsersByFilterThunk({
                currentPage: currentPage + 1,
                pageSize,
                searchParams,
                registryId: user?.registryId,
            }),
        );
        handleClose();
        // const response = await axios.get(`${config.rcsBackendServerUrl}users/get-all-by-filter`, {
        //   headers: {
        //     "Content-Type": "application/json",
        //     currentpage: currentPage,
        //     pagesize: 10,
        //     username: searchParams.userName,
        //     email: searchParams.email,
        //     mobilenumber: searchParams.mobileNumber,
        //     status: searchParams.status
        //   },
        // });
    };
    return canShowForSuperAdminOrResellerAdmin(user) ? (
        <Box>
            <Grid mb={4} item>
                <Typography variant='h3' style={{ color: "black" }}>
                    All Users
                </Typography>
            </Grid>
            <div>
                <Card className='border border-secondary-50 '>
                    <CardContent sx={{ padding: 0 }}>
                        {state.controls.isGetAllUsersLoading ? (
                            <FetchingSpinner />
                        ) : state.userListByPageSize.length > 0 ? (
                            <TableContainer component={Paper} className='table-container' sx={{ maxHeight: "65vh" }}>
                                <Table stickyHeader aria-label='customized table'>
                                    <TableHead className='p-3 mb-2'>
                                        <TableRow>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                                Sr No
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                User
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Email
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Organization
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Mobile
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='center'>
                                                Status
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.userListByPageSize?.map((user, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    <b>{currentPage * pageSize + index + 1}</b>
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    <div>{user.name}</div>
                                                    <div>
                                                        <b>Role : </b>
                                                        {user?.roleName}
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {user.email}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {user?.orgDetails ? user?.orgDetails?.orgName : (user?.resellerDetails ? user?.resellerDetails?.resellerName : '')}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {user.mobileNumber}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='center'>
                                                    {user.orgDetails ? (
                                                        user.orgDetails.status === ClientStatus.Active ? (
                                                            <label
                                                                className='text-success centered'
                                                                style={{
                                                                    fontWeight: "600",
                                                                }}
                                                            >
                                                                <div className='active-dot'></div>
                                                                {user.orgDetails.status}
                                                            </label>
                                                        ) : (
                                                            <label
                                                                className='text-danger centered'
                                                                style={{
                                                                    fontWeight: "600",
                                                                }}
                                                            >
                                                                <div className='inactive-dot'></div>
                                                                {user.orgDetails.status}
                                                            </label>
                                                        )
                                                    ) : (
                                                        "-"
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <NoRecords />
                        )}

                        {state.totalUsersCount > 0 && (
                            <div
                                className='my-2'
                                style={{
                                    marginTop: "15px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: 2,
                                }}
                            >
                                <Grid
                                    style={{
                                        marginTop: "15px",
                                        paddingLeft: 10
                                    }}
                                >
                                    Total Users: {state.totalUsersCount}
                                </Grid>

                                {/* <TablePagination
                                    count={state.totalUsersCount}
                                    component='div'
                                    variant='outlined'
                                    color='primary'
                                    page={currentPage}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={pageSize}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                /> */}

                                <TablePaginationShowPage
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    colSpan={3}
                                    count={state.totalUsersCount}
                                    rowsPerPage={pageSize}
                                    page={currentPage}
                                    slotProps={{
                                        select: {
                                            "aria-label": "Rows per page",
                                        },
                                        actions: {
                                            showFirstButton: true,
                                            showLastButton: true,
                                            slots: {
                                                firstPageIcon: FirstPageRoundedIcon,
                                                lastPageIcon: LastPageRoundedIcon,
                                                nextPageIcon: ChevronRightRoundedIcon,
                                                backPageIcon: ChevronLeftRoundedIcon,
                                            },
                                        },
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        )}
                    </CardContent>
                    {state.controls.getAllUsersErrorMessge && (
                        <AlertMessageComponent
                            message={state.controls.getAllUsersErrorMessge}
                            fieldName={"getAllUsersErrorMessge"}
                            handleClose={onCloseErrorMessage}
                            show={Boolean(state.controls.getAllUsersErrorMessge)}
                        />
                    )}
                    {open && (
                        <FilterAllUserModel
                            open={open}
                            searchParams={searchParams}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            setSearchParams={setSearchParams}
                            handleClickSearch={handleClickSearch}
                        />
                    )}
                </Card>
            </div>
        </Box>
    ) : (
        <Page401 />
    );
}

export default ListUsers;
