import React from "react";
import { useLocation } from "react-router-dom";

import { ERoles } from "../../config";
import reduceChildRoutes from "./reduceChildRoutes";

const SidebarNavList = props => {
    const { pages, depth } = props;
    const router = useLocation();
    const currentRoute = router.pathname;

    let childRoutes = pages.reduce((items, page) => reduceChildRoutes({ items, page, currentRoute, depth }), []);
    if (sessionStorage.getItem("userRole") !== ERoles.SUPER_ADMIN && sessionStorage.getItem("userRole") !== ERoles.RESELLER_ADMIN) {
        const findIndexOfAdminRoutes = childRoutes.findIndex(route => route.key === "Admin");
        if (findIndexOfAdminRoutes !== -1) {
            childRoutes.splice(findIndexOfAdminRoutes, 1);
        }

        // Find master rate route and show for only super admin role
        const findIndexOfMasterRateRoutes = childRoutes.findIndex(route => route.key === "Master Rates");

        if (findIndexOfMasterRateRoutes !== -1) {
            childRoutes.splice(findIndexOfMasterRateRoutes, 1);
        }

        const findIndexOfCustomizedRoutes = childRoutes.findIndex(route => route.key === "Customized Rates");

        if (findIndexOfCustomizedRoutes !== -1) {
            childRoutes.splice(findIndexOfCustomizedRoutes, 1);
        }

        // const findIndexOfAPIMKanagementRoutes = childRoutes.findIndex(route => route.key === "API Keys");
        // if (findIndexOfAPIMKanagementRoutes !== -1) {
        //     childRoutes.splice(findIndexOfAPIMKanagementRoutes, 1);
        // }

    } else {
        const findIndexOfApplicableRoutes = childRoutes.findIndex(route => route.key === "Applicable Rates");

        if (findIndexOfApplicableRoutes !== -1) {
            childRoutes.splice(findIndexOfApplicableRoutes, 1);
        }
    }

    if (sessionStorage.getItem("userRole") !== ERoles.SUPER_ADMIN) {
        const findIndexOfApplicableRoutes = childRoutes.findIndex(route => route.key === "Resellers");

        if (findIndexOfApplicableRoutes !== -1) {
            childRoutes.splice(findIndexOfApplicableRoutes, 1);
        }
    }

    if (sessionStorage.getItem("userRole") !== ERoles.SUPER_ADMIN) {
        const findIndexOfApplicableRoutes = childRoutes.findIndex(route => route.key === "Roles");

        if (findIndexOfApplicableRoutes !== -1) {
            childRoutes.splice(findIndexOfApplicableRoutes, 1);
        }
    }
    // const findIndexOfMISRoutes = childRoutes.findIndex(
    //   (route) => route.key === "MIS"
    // );

    // if (findIndexOfMISRoutes !== -1) {
    //   childRoutes.splice(findIndexOfMISRoutes, 1);
    // }

    if (sessionStorage.getItem("userRole") !== ERoles.SUPER_ADMIN) {
        const findIndexOfApplicableRoutes = childRoutes.findIndex(route => route.key === "Master Rates");

        if (findIndexOfApplicableRoutes !== -1) {
            childRoutes.splice(findIndexOfApplicableRoutes, 1);
        }
    }

    if (sessionStorage.getItem("userRole") !== ERoles.RESELLER_ADMIN) {
        const findIndexOfApplicableRoutes = childRoutes.findIndex(route => route.key === "Clients");

        if (findIndexOfApplicableRoutes !== -1) {
            childRoutes.splice(findIndexOfApplicableRoutes, 1);
        }
    }

    if (sessionStorage.getItem("userRole") !== ERoles.SUPER_ADMIN) {
        const findIndexOfApplicableRoutes = childRoutes.findIndex(
            route => route.key === "Providers" && route.props.href === "/admin/manage-provider",
        );

        if (findIndexOfApplicableRoutes !== -1) {
            childRoutes.splice(findIndexOfApplicableRoutes, 1);
        }
    }

    if (sessionStorage.getItem("userRole") !== ERoles.RESELLER_ADMIN) {
        const findIndexOfApplicableRoutes = childRoutes.findIndex(
            route => route.key === "Providers" && route.props.href === "/reseller/manage-reseller-provider",
        );

        if (findIndexOfApplicableRoutes !== -1) {
            childRoutes.splice(findIndexOfApplicableRoutes, 1);
        }
    }
    if (sessionStorage.getItem("userRole") === ERoles.SUPER_ADMIN) {
        const findIndexOfApplicableRoutes = childRoutes.findIndex(route => route.key === "RCS Management");

        if (findIndexOfApplicableRoutes !== -1) {
            childRoutes.splice(findIndexOfApplicableRoutes, 1);
        }
    }
    if (sessionStorage.getItem("userRole") === ERoles.SUPER_ADMIN) {
        const findIndexOfApplicableRoutes = childRoutes.findIndex(route => route.key === "Reports");

        if (findIndexOfApplicableRoutes !== -1) {
            childRoutes.splice(findIndexOfApplicableRoutes, 1);
        }
    }


    return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
