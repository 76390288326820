import { PersonAdd, CampaignOutlined, CurrencyRupeeOutlined, ShoppingCart, StarsRounded, ContactMail } from "@mui/icons-material";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import { BookOpen, CheckSquare, Grid, Heart, List, Map, MessageCircle, MessageSquare, PieChart, Sliders, TrendingUp, Users } from "react-feather";
import BackupTableIcon from '@mui/icons-material/BackupTable';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddIcon from '@mui/icons-material/Add';

const pagesSection = [
    {
        href: "/dashboard/",
        icon: Sliders,
        title: "Dashboard",
        // children: [
        //   {
        //     href: "/dashboard/default",
        //     title: "Analytics",
        //   },
        // {
        //   href: "/dashboard/analytics",
        //   title: "Analytics",
        // },
        // {
        //   href: "/dashboard/saas",
        //   title: "SaaS",
        // },
        // ],
    },

    // {
    //     href: "/admin",
    //     icon: Users,
    //     title: "Admin",
    //     children: [
    // {
    //     href: "/admin/manage-users",
    //     title: "All Users",
    //     icon: ContactsOutlinedIcon,
    // },
    // {
    //   href: "/admin/manage-branches",
    //   title: "Branches",
    //   icon: ApartmentOutlinedIcon
    // },
    {
        href: "/admin/manage-provider",
        title: "Providers",
        icon: ContactMail,
    },

    {
        href: "/reseller/manage-reseller-provider",
        title: "Providers",
        icon: ContactMail,
    },
    {
        href: "/admin/manage-reseller",
        title: "Resellers",
        icon: PersonAdd,
    },

    // {
    //     href: "/admin/manage-roles",
    //     title: "Roles",
    //     icon: ManageAccountsOutlinedIcon,
    // },

    {
        href: "/admin/manage-clients",
        title: "Clients",
        icon: PortraitOutlinedIcon,
    },

    // {
    //     href: "/admin/whiteLabel-details",
    //     title: "WhiteLabelDetails",
    //     icon: DetailsIcon,
    // },

    // {
    //   href: "/admin/manage-executives",
    //   title: "Executives",
    //   icon: HailOutlinedIcon
    // },
    // {
    //   href: "/admin/manage-BM-TL",
    //   title: "BM/TL",
    //   icon: Groups2OutlinedIcon
    // },

    {
        href: "/admin/manage-api-keys",
        title: "API Keys",
        icon: AddIcon
    },
    // {
    //   href: "/admin/rates-master",
    //   title: "Rates Master",
    // },
    // {
    //   href: "/admin/customize-rate",
    //   title: "Customize Rate",
    // },
    // {
    //   href: "/admin/daily-reports",
    //   title: "Daily Reports",
    // },
    // {
    //   href: "/admin/prepaid-recharge",
    //   title: "Prepaid Recharge",
    // },
    //     ],
    // },

    // {
    //     href: "/reseller-admin",
    //     icon: Users,
    //     title: "Admin",
    //     children: [
    //         {
    //             href: "/admin/manage-users",
    //             title: "All Users",
    //             icon: ContactsOutlinedIcon,
    //         },
    //         // {
    //         //   href: "/admin/manage-branches",
    //         //   title: "Branches",
    //         //   icon: ApartmentOutlinedIcon
    //         // },
    //         {
    //             href: "/admin/manage-roles",
    //             title: "Roles",
    //             icon: ManageAccountsOutlinedIcon,
    //         },
    //         {
    //             href: "/admin/manage-clients",
    //             title: "Clients",
    //             icon: PortraitOutlinedIcon,
    //         },

    //         {
    //             href: "/admin/whiteLabel-details",
    //             title: "WhiteLabelDetails",
    //             icon: PortraitOutlinedIcon,
    //         },
    //         // {
    //         //   href: "/admin/manage-executives",
    //         //   title: "Executives",
    //         //   icon: HailOutlinedIcon
    //         // },
    //         // {
    //         //   href: "/admin/manage-BM-TL",
    //         //   title: "BM/TL",
    //         //   icon: Groups2OutlinedIcon
    //         // },

    //         // {
    //         //   href: "/admin/manage-api-keys",
    //         //   title: "Manage API Keys",
    //         // },
    //         // {
    //         //   href: "/admin/rates-master",
    //         //   title: "Rates Master",
    //         // },
    //         // {
    //         //   href: "/admin/customize-rate",
    //         //   title: "Customize Rate",
    //         // },
    //         // {
    //         //   href: "/admin/daily-reports",
    //         //   title: "Daily Reports",
    //         // },
    //         // {
    //         //   href: "/admin/prepaid-recharge",
    //         //   title: "Prepaid Recharge",
    //         // },
    //     ],
    // },

    {
        href: "/rcs-management",
        icon: ConnectWithoutContactOutlinedIcon,
        title: "RCS Management",
        children: [
            {
                href: "/rcs-management/manage-brands",
                title: "Brands",
                icon: LocalOfferOutlinedIcon,
            },
            {
                href: "/rcs-management/manage-bots",
                title: "Bots",
                icon: SmartToyOutlinedIcon,
            },

            {
                href: "/rcs-management/templates/manage-templates",
                title: "Templates",
                icon: ChatOutlinedIcon,
            },
            {
                href: "/rcs-management/manage-campaigns",
                title: "Campaigns",
                icon: CampaignOutlined,
            },
        ],
    },
    {
        href: "/reports",
        icon: TrendingUp,
        title: "Reports",
        children: [
            {
                href: "/reports/conversation-logs",
                title: "Conversation Logs",
                icon: MessageCircle,
            },
            {
                href: "/reports/api-conversation-logs",
                title: "API Report",
                icon: MessageSquare,
            },
            // {
            //   href: "/reports/campaigns-reports",
            //   title: "Campaigns Reports",
            //   icon: PieChart
            // },
        ],
    },
    {
        href: "/billing/applicable-rates",
        icon: Sliders,
        title: "Applicable Rates"
    },
    {
        href: "/mis",
        icon: BackupTableIcon,
        title: "MIS",
        children: [
            {
                href: "/mis/billing-mis",
                title: "Billing",
                icon: AccountBalanceIcon,

            },
            // {
            //     href: "/mis/campaign-mis",
            //     title: "Campaign",
            //     icon: Campaign,

            // },
        ]
        // {
        //   href: "/dashboard/analytics",
        //   title: "Analytics",
        // },
        // {
        //   href: "/dashboard/saas",
        //   title: "SaaS",
        // },
        // ],
    },

    //Add APIKEYS for APIs route
    // {
    //     href: "/billing",
    //     icon: ShoppingCart,
    //     title: "Billing",
    //     children: [
    //         // {
    //         //     href: "/billing/master-rates",
    //         //     title: "Master Rates",
    //         //     icon: StarsRounded,
    //         // },
    //         {
    //             href: "/billing/customized-rates",
    //             title: "Customized Rates",
    //             icon: CurrencyRupeeOutlined,
    //         },

    //         {
    //             href: "/billing/applicable-rates",
    //             title: "Applicable Rates",
    //             icon: CurrencyRupeeOutlined,
    //         },
    //     ],
    // },
    // {
    //   href: "/pages",
    //   icon: Layout,
    //   title: "Pages",
    //   children: [
    //     {
    //       href: "/pages/profile",
    //       title: "Profile",
    //     },
    //     {
    //       href: "/pages/settings",
    //       title: "Settings",
    //     },
    //     {
    //       href: "/pages/pricing",
    //       title: "Pricing",
    //     },
    //     {
    //       href: "/pages/chat",
    //       title: "Chat",
    //     },
    //     {
    //       href: "/pages/blank",
    //       title: "Blank Page",
    //     },
    //   ],
    // },
    // {
    //   href: "/projects",
    //   icon: Briefcase,
    //   title: "Projects",
    //   badge: "8",
    // },
    // {
    //   href: "/orders",
    //   icon: ShoppingCart,
    //   title: "Orders",
    // },
    // {
    //   href: "/invoices",
    //   icon: CreditCard,
    //   title: "Invoices",
    //   children: [
    //     {
    //       href: "/invoices",
    //       title: "List",
    //     },
    //     {
    //       href: "/invoices/detail",
    //       title: "Detail",
    //     },
    //   ],
    // },
    // {
    //   href: "/tasks",
    //   icon: CheckSquare,
    //   title: "Tasks",
    //   badge: "17",
    // },
    // {
    //   href: "/calendar",
    //   icon: Calendar,
    //   title: "Calendar",
    // },
    // {
    //   href: "/auth",
    //   icon: Users,
    //   title: "Auth",
    //   children: [
    //     {
    //       href: "/auth/login",
    //       title: "Sign In",
    //     },
    //     {
    //       href: "/auth/sign-up",
    //       title: "Sign Up",
    //     },
    //     {
    //       href: "/auth/reset-password",
    //       title: "Reset Password",
    //     },
    //     {
    //       href: "/auth/404",
    //       title: "404 Page",
    //     },
    //     {
    //       href: "/auth/500",
    //       title: "500 Page",
    //     },
    //   ],
    // },
];

const elementsSection = [
    {
        href: "/components",
        icon: Grid,
        title: "Components",
        children: [
            {
                href: "/components/alerts",
                title: "Alerts",
            },
            {
                href: "/components/accordion",
                title: "Accordion",
            },
            {
                href: "/components/avatars",
                title: "Avatars",
            },
            {
                href: "/components/badges",
                title: "Badges",
            },
            {
                href: "/components/buttons",
                title: "Buttons",
            },
            {
                href: "/components/cards",
                title: "Cards",
            },
            {
                href: "/components/chips",
                title: "Chips",
            },
            {
                href: "/components/dialogs",
                title: "Dialogs",
            },
            {
                href: "/components/lists",
                title: "Lists",
            },
            {
                href: "/components/menus",
                title: "Menus",
            },
            {
                href: "/components/pagination",
                title: "Pagination",
            },
            {
                href: "/components/progress",
                title: "Progress",
            },
            {
                href: "/components/snackbars",
                title: "Snackbars",
            },
            {
                href: "/components/tooltips",
                title: "Tooltips",
            },
        ],
    },
    {
        href: "/charts",
        icon: PieChart,
        title: "Charts",
        children: [
            {
                href: "/charts/chartjs",
                title: "Chart.js",
            },
            {
                href: "/charts/apexcharts",
                title: "ApexCharts",
            },
        ],
    },
    {
        href: "/forms",
        icon: CheckSquare,
        title: "Forms",
        children: [
            {
                href: "/forms/pickers",
                title: "Pickers",
            },
            {
                href: "/forms/selection-controls",
                title: "Selection Controls",
            },
            {
                href: "/forms/selects",
                title: "Selects",
            },
            {
                href: "/forms/text-fields",
                title: "Text Fields",
            },
            {
                href: "/forms/editors",
                title: "Editors",
            },
            {
                href: "/forms/formik",
                title: "Formik",
            },
        ],
    },
    {
        href: "/tables",
        icon: List,
        title: "Tables",
        children: [
            {
                href: "/tables/simple-table",
                title: "Simple Table",
            },
            {
                href: "/tables/advanced-table",
                title: "Advanced Table",
            },
            {
                href: "/tables/data-grid",
                title: "Data Grid",
            },
        ],
    },
    {
        href: "/icons",
        icon: Heart,
        title: "Icons",
        children: [
            {
                href: "/icons/material-icons",
                title: "Material Icons",
            },
            {
                href: "/icons/feather-icons",
                title: "Feather Icons",
            },
        ],
    },
    {
        href: "/maps",
        icon: Map,
        title: "Maps",
        children: [
            {
                href: "/maps/google-maps",
                title: "Google Maps",
            },
            {
                href: "/maps/vector-maps",
                title: "Vector Maps",
            },
        ],
    },
];

const docsSection = [
    {
        href: "/documentation/welcome",
        icon: BookOpen,
        title: "Documentation",
    },
    {
        href: "/changelog",
        icon: List,
        title: "Changelog",
        badge: "v4.1.0",
    },
];

const navItems = [
    {
        title: "",
        pages: pagesSection,
    },
    // {
    //   title: "Elements",
    //   pages: elementsSection,
    // },
    // {
    //   title: "Mira Pro",
    //   pages: docsSection,
    // },
];

export default navItems;
