import {
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../../hooks/useAuth";
import { setErrorMessageForUser, setShowAddApikeyForm } from "../../../../redux/userManagement/userManagementSlice";
import { addApikeysForResellersThunk, getAllClientByFilterThunk } from "../../../../redux/userManagement/userManagementThunk";
import { apiGatewayAxiosInstance } from "../../../../utils/axios";
import { v4 as uuidv4 } from 'uuid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from "react-copy-to-clipboard";

export default function AddApiKeys() {
    const state = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();
    const [resellerData, setResellerData] = useState();
    const [orgData, setOrgData] = useState();
    const [apiPermissions, setApiPermissions] = useState();
    const [checked, setChecked] = useState(false);
    const [validTill, setValidTill] = useState(null);
    const [apiKey, setApiKey] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);
    const currentDateTime = new Date();
    const [selectedDate, setSelectedDate] = useState(currentDateTime);
    const { user } = useAuth();
    const roleName = user.roleName;

    const validatingApiKeyData = () => {
        let result = true;
        if (roleName === 'super_admin' && formik.values.resellerName === "") {
            result = false
            formik.setFieldError("resellerName", `Reseller Name is required`);
        }
        if (roleName === 'reseller_admin' && formik.values.orgName === "") {
            result = false
            formik.setFieldError("orgName", `Organization Name is required`);
        }

        return result
    }


    const validateIfValueIsEmpty = fieldName => {
        const fieldValue = formik.values[fieldName];
        if (fieldValue === "" || fieldValue === null || !fieldValue) {
            formik.setFieldError(fieldName, `Required`);
            return true;
        } else {
            return false;
        }
    };

    const formik = useFormik({
        initialValues: {
            resellerName: '',
            orgName: '',
            registryId: null,
            orgId: null,
            apiKey: '',
            description: '',
            permissions: [],
            ipAdresses: [],
            isInfinite: true,
            validTill: '',
        },
        validate: values => {
            const errors = {};

            if (roleName === 'super_admin' && !values.resellerName) {
                errors.resellerName = 'Reseller Name is required';
            }
            if (roleName === 'reseller_admin' && !values.orgName) {
                errors.orgName = 'Organization Name is required';
            }

            if (!values.description) {
                errors.description = 'Description is required';
            }

            if (!values.apiKey) {
                errors.apiKey = 'API Key is required';
            }

            if (checked && !values.validTill) {
                errors.validTill = 'Valid till date is required!';
            }

            if (checked && values.validTill) {
                const currentDate = new Date();
                if (values.validTill < currentDate) {
                    errors.validTill = 'Valid till date cannot be previous date!';
                }
            }
            return errors;
        },
        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                const validate = validatingApiKeyData()
                if (validate) {
                    const dataToSend = {
                        resellerName: values.resellerName,
                        registryId: values.registryId,
                        orgName: values.orgName,
                        orgId: values.orgId,
                        apiKey: values.apiKey,
                        description: values.description,
                        permissions: values.permissions,
                        ipAdresses: values.ipAdresses,
                        isInfinite: values.isInfinite,
                        validTill: values.validTill,
                    }
                    const result = dispatch(
                        addApikeysForResellersThunk({
                            data: dataToSend,
                        })
                    );
                }
            } catch (error) {
                const message = formik.errors.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
            }
        },
    });

    const onChangeReseller = (e) => {
        const { name, value } = e.target;
        const resellerDetails = resellerData.filter(reseller => reseller._id === value)[0];
        formik.setFieldValue('registryId', resellerDetails._id);
        formik.setFieldValue('resellerName', resellerDetails.resellerName);
    }

    const onChangeOrganization = (e) => {
        const { name, value } = e.target;
        const orgDetails = orgData.filter(org => org._id === value)[0];
        formik.setFieldValue('registryId', user.registryId);
        formik.setFieldValue('resellerName', user.resellerName);
        formik.setFieldValue('orgId', orgDetails._id);
        formik.setFieldValue('orgName', orgDetails.orgName);
    }


    const handleCheckboxChange = (event) => {
        formik.setFieldValue('isInfinite', !event.target.checked);
        setChecked(event.target.checked);
        if (event.target.checked) {
            setValidTill(null);
        }
    };

    const handleDateChange = (date, event) => {
        setSelectedDate(date);
    };

    const getAllResellers = async () => {
        try {
            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/get-all-resellers-for-dropdown`)
            setResellerData(response.data.data);

        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "getClientEditDetailsErrorMessages",
                    errorMessage,
                }),
            );
        }
    }

    const getAllOrganization = async () => {
        try {
            const registryId = user?.registryId;
            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/get-all-org-for-dropdown`, {
                params: { registryId }
            })
            setOrgData(response.data.data);

        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
        }
    }

    const getAllApiPermissions = async () => {
        try {

            const response = await apiGatewayAxiosInstance.get(`/user_management/user-permission/get-all`)
            setApiPermissions(response.data.data);
        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "getClientEditDetailsErrorMessages",
                    errorMessage,
                }),
            );
        }
    }

    const onClickCancelButton = e => {
        dispatch(setShowAddApikeyForm(false));
    };

    const handleGenerateApiKey = async () => {
        try {
            const generatedKey = uuidv4()
            setApiKey(generatedKey);
            formik.setFieldValue('apiKey', generatedKey);
        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "getClientEditDetailsErrorMessages",
                    errorMessage,
                }),
            );
        }
    }

    const handleCopyApiKey = () => {
        setCopySuccess(true);
        setTimeout(() => {
            setCopySuccess(false);
        }, 2000);
    };

    useEffect(() => {
        getAllResellers();
        getAllOrganization();
        getAllApiPermissions();
    }, [user])

    return (
        <form onSubmit={formik.handleSubmit}>
            {/* <Grid container mb={4} className='listHeader'>
                <Grid item md={6}>
                    <Typography variant='h3'>Api Key</Typography>
                </Grid>
            </Grid> */}

            <Grid item md={6} display={"flex"} alignItems={"center"}>
                <IconButton color='secondary' onClick={onClickCancelButton} size='medium'>
                    <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                </IconButton>

                <Grid role='presentation'>
                    <Breadcrumbs aria-label='breadcrumb' fontSize='large'>
                        <Link
                            underline='hover'
                            color='inherit'
                            className='breadcrumbItem breadcrumbFontSize'
                            // href="/rcs-management/manage-bots/"
                            onClick={onClickCancelButton}
                        >
                            API Keys
                        </Link>
                        <Typography className='breadcrumbItem' color='text.primary'>
                            Add New API Key
                        </Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Card sx={{ padding: "0.8rem" }}>
                <CardContent sx={{ padding: "10px" }} >
                    <Grid className='d-flex'>
                        <Grid item width={"49%"}>


                            {roleName === 'super_admin' ?
                                <FormControl
                                    fullWidth
                                    sx={{ margin: "auto", minWidth: "100%" }}
                                    error={Boolean(formik.errors.resellerName && formik.touched.resellerName)}
                                >
                                    <InputLabel id='reseller'>Select Reseller</InputLabel>
                                    <Select
                                        name='resellerName'
                                        label='Select Reseller'
                                        labelId='reseller'
                                        id='reseller'
                                        error={Boolean(formik.errors.resellerName && formik.touched.resellerName)}
                                        onChange={onChangeReseller}
                                    >
                                        {resellerData &&
                                            resellerData.map((reseller, index) => {
                                                return (
                                                    <MenuItem
                                                        sx={{ height: "25px", padding: "15px 0px !important" }}
                                                        key={index}
                                                        value={reseller._id}
                                                    >
                                                        {reseller?.resellerName}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                    <FormHelperText>
                                        {formik?.errors?.resellerName}
                                    </FormHelperText>
                                </FormControl>
                                :
                                <FormControl
                                    fullWidth
                                    sx={{ margin: "auto", minWidth: "100%" }}
                                // error={Boolean(formik.errors.supportedProvidersList && formik.touched.supportedProvidersList)}
                                >
                                    <InputLabel id='organization'>Select Organization</InputLabel>
                                    <Select
                                        // sx={{ maxWidth: "230px", overflowX: "auto" }}
                                        name='organizationName'
                                        label='Select Organization'
                                        labelId='organization'
                                        id='organization'
                                        error={Boolean(formik.errors.orgName && formik.touched.orgName)}
                                        helperText={formik.errors.orgName && formik.touched.orgName ? formik.errors.orgName : ''}
                                        onChange={onChangeOrganization}
                                    >
                                        {
                                            orgData &&
                                            orgData.map((org, index) => {
                                                return (
                                                    <MenuItem
                                                        sx={{ height: "25px", padding: "15px 0px !important" }}
                                                        key={index}
                                                        value={org?._id}
                                                    >
                                                        {org?.orgName}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            }

                            <Grid className='d-flex' mt={3}>
                                <TextField
                                    name='description'
                                    fullWidth
                                    label='Description'
                                    variant='outlined'
                                    className='field-spacing'
                                    error={Boolean(formik.errors.description && formik.touched.description)}
                                    helperText={formik.errors.description && formik.touched.description ? formik.errors.description : ''}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                />

                            </Grid>

                            <Grid container mt={1} spacing={3} alignItems="center">
                                <Grid item md={10}>
                                    <TextField
                                        name='generatedapikey'
                                        id='generated apikey'
                                        label='Generated Api Key'
                                        variant='outlined'
                                        className='field-spacing'
                                        value={apiKey}
                                        error={Boolean(formik.errors.apiKey)}
                                        helperText={formik.errors.apiKey && formik.touched.apiKey ? formik.errors.apiKey : ''}
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {apiKey && (
                                                        <CopyToClipboard text={apiKey} onCopy={handleCopyApiKey}>
                                                            <Tooltip title={copySuccess ? "Copied!" : "Copy to clipboard"}>
                                                                <IconButton color="primary" size="small">
                                                                    <ContentCopyIcon fontSize="small" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </CopyToClipboard>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }}

                                    />
                                </Grid>

                                <Grid item md={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleGenerateApiKey}
                                        size="small"
                                        fullWidth
                                    >
                                        Generate API Key
                                    </Button>
                                </Grid>
                            </Grid>


                            <Grid className='d-flex' mt={3}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            onChange={handleCheckboxChange}
                                            name="addApikey"
                                            fontSize="small"
                                        />
                                    }
                                    label="Add Validity for apikey"
                                />
                            </Grid>

                            {/* {checked && (
                                <Grid item xs={12} mt={3}>
                                    <FormControl fullWidth error={Boolean(formik.errors.validTill)}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                label="Select Valid till"
                                                defaultValue={selectedDate}
                                                onChange={(date, event) => {
                                                    handleDateChange(date, event);
                                                    formik.setFieldValue('validTill', date);
                                                }}
                                                minTime={
                                                    selectedDate && selectedDate.getDate() === currentDateTime.getDate()
                                                        ? new Date(
                                                            currentDateTime.getFullYear(),
                                                            currentDateTime.getMonth(),
                                                            currentDateTime.getDate(),
                                                            currentDateTime.getHours(),
                                                            currentDateTime.getMinutes()
                                                        )
                                                        : null
                                                }
                                                // minDateTime={
                                                //     selectedDate && selectedDate.getDate() === currentDateTime.getDate() && selectedDate.getMonth() === currentDateTime.getMonth() && selectedDate.getFullYear() === currentDateTime.getFullYear()
                                                //         ? new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(), currentDateTime.getHours(), currentDateTime.getMinutes()) // Restrict to current date and time
                                                //         : new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate()) // Allow any time for future dates
                                                // }
                                                renderInput={params => <TextField {...params} />}
                                                error={Boolean(formik.errors.validTill)}
                                                helperText={formik.errors.validTill && formik.touched.validTill ? formik.errors.validTill : ''}
                                            />

                                        </LocalizationProvider>
                                        <FormHelperText>
                                            {formik.errors.validTill && formik.touched.validTill ? formik.errors.validTill : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            )} */}
                            {checked && (
                                <Grid item xs={12} mt={3}>
                                    <FormControl fullWidth error={Boolean(formik.errors.validTill)}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                label="Select Valid till"
                                                value={formik.values.validTill || null}
                                                onChange={(date, event) => {
                                                    handleDateChange(date, event);
                                                    formik.setFieldValue('validTill', date);
                                                }}

                                                minDateTime={
                                                    selectedDate && selectedDate.getDate() === currentDateTime.getDate() && selectedDate.getMonth() === currentDateTime.getMonth() && selectedDate.getFullYear() === currentDateTime.getFullYear()
                                                        ? new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate(), currentDateTime.getHours(), currentDateTime.getMinutes()) // Restrict to current date and time
                                                        : new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate()) // Allow any time for future dates
                                                }
                                                renderInput={params => <TextField {...params} />}
                                                error={Boolean(formik.errors.validTill)}
                                                helperText={formik.errors.validTill && formik.touched.validTill ? formik.errors.validTill : ''}
                                            />
                                        </LocalizationProvider>
                                        <FormHelperText>
                                            {formik.errors.validTill && formik.touched.validTill ? formik.errors.validTill : ''}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            )}

                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{ padding: "0px" }}>
                    <Grid container sx={{ alignItems: "center" }}>
                        <Grid item>
                            <Button variant='contained' color='primary' className='m-2' type="submit" >
                                Submit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='error' onClick={onClickCancelButton} className='m-2' >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </form>
    );
}
