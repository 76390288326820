import React, { useState } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Grid,
    Typography,
    IconButton,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import useAuth from "../../../../hooks/useAuth";

function FilterAllApiKeysModel({ open, searchParams, setSearchParams, handleClose, handleClickSearch }) {
    const [search, setSearch] = useState(searchParams);
    const handleChange = e => {
        const value = e.target.value;
        const name = e.target.name;
        setSearch({ ...search, [name]: value });
    };
    const { user } = useAuth();

    const onClickClear = () => {
        setSearchParams({
            uniqueId: "",
            status: "",
            name: "",
            userRole: ""
        });
        handleClickSearch({
            uniqueId: "",
            status: "",
            name: "",
            userRole: ""
        });
    };
    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant='h5'>ApiKeys Filter</Typography>
                    <IconButton onClick={() => handleClose()}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent
                    sx={{
                        overflowY: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                        width: "500px",
                    }}
                >
                    <Grid container fullWidth justifyContent={"space-between"}>
                        <Grid item md={5.5}>
                            <TextField
                                value={search.uniqueId}
                                name='uniqueId'
                                onChange={handleChange}
                                label='Unique Id'
                                fullWidth
                                placeholder='Enter Unique Id'
                            />
                        </Grid>
                        <Grid item md={5.5}>
                            <FormControl fullWidth>
                                <InputLabel id='demo-controlled-open-select-label'>Status</InputLabel>
                                <Select
                                    labelId='demo-controlled-open-select-label'
                                    id='demo-controlled-open-select'
                                    name='status'
                                    value={search.status}
                                    label='Status'
                                    onChange={handleChange}
                                >
                                    <MenuItem value={"Active"}>Active</MenuItem>
                                    <MenuItem value={"InActive"}>InActive</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container fullWidth justifyContent={"space-between"}>
                        <Grid item md={5.5}>
                            <TextField
                                value={search.name}
                                name='name'
                                onChange={handleChange}
                                label='Name'
                                fullWidth
                                placeholder=' Enter Client Name'
                            />
                        </Grid>
                        {user.roleName !== 'org_admin' &&
                            <Grid item md={5.5}>
                                <FormControl fullWidth>
                                    <InputLabel id='demo-controlled-open-select-label'>User Role</InputLabel>
                                    <Select
                                        labelId='demo-controlled-open-select-label'
                                        id='demo-controlled-open-select'
                                        name='userRole'
                                        value={search.userRole}
                                        label='userRole'
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"ORG_ADMIN"}>ORG_ADMIN</MenuItem>
                                        <MenuItem value={"RESELLER_ADMIN"}>RESELLER_ADMIN</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{
                        padding: "1rem 1.5rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: ".25rem",
                    }}
                >
                    <Button variant='contained' color='error' onClick={() => onClickClear()}>
                        Clear
                    </Button>
                    <Button variant='contained' color='primary' onClick={() => handleClickSearch(search)} autoFocus>
                        Search
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default FilterAllApiKeysModel;
