import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../../../hooks/useAuth";
import AlertMessageComponent from "../../../components/AlertMessageComponent";
import AddApiKeys from "./AddApiKeys";
import ListApiKeys from "./ListApiKeys";


export default function ManageApiKeys() {
    const state = useSelector(state => state.userManagementSlice);
    const [errorResponse, setErrorResponse] = useState("");
    const [listApikeysLoading, setListApikeysLoading] = useState(false);
    const [searchParams, setSearchParams] = React.useState({
        uniqueId: "",
        name: "",
        status: "",
        userRole: ""
    });

    return (
        <div>
            {state.controls.showAddApiKeysForm ? (
                <>
                    <AddApiKeys />
                </>
            ) : (
                <ListApiKeys
                    listApikeysLoading={listApikeysLoading}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                />
            )}

            <AlertMessageComponent
                message={errorResponse}
                fieldName={"errorResponse"}
                handleClose={() => setErrorResponse("")}
                show={Boolean(errorResponse)}
            />
        </div>
    );
}
