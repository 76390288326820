
import { CloseOutlined } from "@mui/icons-material";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Typography
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useState } from "react";
import { ERoles } from "../../config";
import useAuth from "../../hooks/useAuth";

dayjs.extend(utc);


function FilterMISModel({
    open, searchParams, handleClickOpenModel, handleCloseFilterModel, getMISReports, handleClickSearch
}) {
    const { user } = useAuth();

    const [search, setSearch] = useState({
        resellerName: searchParams.resellerName,
        organizationName: searchParams.organizationName,
        fromDate: searchParams.fromDate ? dayjs(searchParams.fromDate) : dayjs(),
        toDate: searchParams.toDate ? dayjs(searchParams.toDate) : dayjs(),
    });
    const handleChange = (e) => {
        const { value, name } = e.target;
        setSearch({ ...search, [name]: value });
    };

    const handleFromDateChange = (date) => {
        setSearch((prev) => ({ ...prev, fromDate: date }));
    };

    const handleToDateChange = (date) => {
        setSearch((prev) => ({ ...prev, toDate: date }));
    };

    const onClickClear = async () => {
        const dayOne = dayjs().startOf('month').format('YYYY-MM-DD').toString();
        const today = dayjs().format('YYYY-MM-DD').toString();
        setSearch({
            resellerName: "",
            organizationName: "",
            fromDate: dayjs().startOf('month'),
            toDate: dayjs(),
        });
        await getMISReports({
            resellerName: "",
            organizationName: "",
            fromDate: dayOne,
            toDate: today,
        });
        handleCloseFilterModel();
    };

    const onSearch = async () => {
        const formattedFromDate = search.fromDate ? dayjs(search.fromDate).format('YYYY-MM-DD') : null;
        const formattedToDate = search.toDate ? dayjs(search.toDate).format('YYYY-MM-DD') : null;
        // await getMISReports({
        //     resellerName: search.resellerName,
        //     organizationName: search.organizationName,
        //     fromDate: formattedFromDate,
        //     toDate: formattedToDate,
        // });

        await handleClickSearch({
            resellerName: search.resellerName,
            organizationName: search.organizationName,
            fromDate: formattedFromDate,
            toDate: formattedToDate,
        });
        handleCloseFilterModel();
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseFilterModel}
            aria-labelledby="filter-dialog-title"
            fullWidth
        >
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Typography variant="h5">MIS Reports Filter</Typography>
                <IconButton onClick={handleCloseFilterModel}>
                    <CloseOutlined />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent >
                <Grid container >
                    {user?.roleName.toLowerCase() === ERoles.RESELLER_ADMIN &&
                        <Grid item md={12}>
                            <TextField
                                value={search.organizationName}
                                name="organizationName"
                                onChange={handleChange}
                                label="Organization Name"
                                fullWidth
                                placeholder="Enter Organization Name"
                            />
                        </Grid>}
                    {user?.roleName.toLowerCase() === ERoles.SUPER_ADMIN &&
                        <Grid item md={12}>
                            <TextField
                                value={search.resellerName}
                                name="resellerName"
                                onChange={handleChange}
                                label="Reseller Name"
                                fullWidth
                                placeholder="Enter Reseller Name"
                            />
                        </Grid>}
                    <Grid container mt={5} sx={{
                        display: "flex",
                        justifyContent: "center",
                        placeContent: "space-between"
                    }}>

                        <Grid item fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="From Date"
                                    value={search.fromDate}
                                    format="DD-MM-YYYY"
                                    onChange={handleFromDateChange}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    disableFuture
                                    minDate={dayjs('2024-09-01')}
                                    maxDate={search.toDate}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="To Date"
                                    value={search.toDate}
                                    format="DD-MM-YYYY"
                                    onChange={handleToDateChange}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    disableFuture
                                    minDate={search.fromDate}
                                    maxDate={dayjs()}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: "1rem 1.5rem", justifyContent: "flex-end", gap: ".25rem" }}>
                <Button variant="outlined" color="error" onClick={onClickClear}>
                    Clear
                </Button>
                <Button variant="contained" color="primary" onClick={onSearch}>
                    Search
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default FilterMISModel;
