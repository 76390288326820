import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useFormik } from "formik";
import { isValidPhoneNumber } from "libphonenumber-js";
import React, { useEffect, useRef, useState } from "react";
import { GetCity, GetCountries, GetState } from "react-country-state-city";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CountryCode from "../../../assets/CountryCode.json";
import { BrandStatus, ClientStatus, ERoles, config } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import { clearErrorMessagesForAllUsers, setErrorMessageForUser, setShowAddClientForm } from "../../../redux/userManagement/userManagementSlice";
import { addNewClientThunk, getMasterRatesThunk } from "../../../redux/userManagement/userManagementThunk";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import FilePreviewComponent from "../../botBuilder/FilePreviewComponent";
import { Require } from "../../botBuilder/addBot";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import StatusRadioButtons from "../../components/StatusRadioButtons";
import WhiteLabelRadioButtons from "../../components/WhiteLabelRadioButtons";
import BillingPaymentRadioButtons from "../../components/paymentRadioButtons";
import AddBrandDetails from "./AddBrandDetails";
import AddBusinessVerificationDetails from "./AddBusinessVerificationDetails";
import AddSMSApiKey from "./AddSMSApiKey";

export default function AddClient({
    clientData,
    isBrandNotVerified,
    setClientDetails,
    isClientEditable,
    setEditable,
    customizeRatesByClientId,
    setCustomizeRatesByClientId,
}) {
    const state = useSelector(state => state.userManagementSlice);
    const botState = useSelector(state => state.botManagementSlice);
    const [errorResponse, setErrorResponse] = useState("");
    const [otherFields, setOtherFields] = useState({});
    const [providerDetailsByProviderName, setProviderDetailsByProviderName] = useState(null);
    const [isUpdateClientLoading, setIsUpdateClientLoading] = useState(false);
    const dispatch = useDispatch();
    const brandLogoRef = useRef(null);
    const orgLogoRef = useRef(null);
    const businessVerificationFileRef1 = useRef({});
    const businessVerificationFileRef2 = useRef({});
    const [imageFormData, setImageFormData] = useState({});
    const [businessVerificationFormData, setBusinessVerificationFormData] = useState({});
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [brandLogoPreviewImage, setBrandLogoPreviewImage] = useState({ url: null, type: null, file: null, fieldName: null });
    const [getAllProviderList, setGetAllProviderList] = useState();
    const [IsEditDetailsLoading, setIsEditDetailsLoading] = useState(false);
    const [orgLogoPreviewImage, setOrgLogoPreviewImage] = useState({ url: null, type: null, file: null, fieldName: null });
    const [backgroundImagePreview, setBackgroundImagePreview] = useState({ url: null, type: null, file: null, fieldName: null });
    const [countryShortCode, setCountryShortCode] = useState();
    const BackgroundImageRef = useRef(null);

    const [providerFields, setProviderFields] = useState({
        providerId: "",
        providerCode: "",
        providerName: "",
    });
    const verifyBusinessNameObjectToAdd = {
        documentType: "",
        selectedFile: {
            type: "file",
            url: "",
        },
    };

    const businessAddressObjectToAdd = {
        documentType: "",
        selectedFile: {
            type: "file",
            url: "",
        },
    };

    const [countryid, setCountryid] = useState(0);
    const [stateid, setStateid] = useState(0);
    const [cityid, setCityid] = useState(0);

    const { user } = useAuth();

    const handleCityName = e => {
        const { name, value } = e.target;
        formik.setFieldValue("companyAddress.city", value);
    };

    const onClickRadio = (name, e) => {
        formik.setFieldValue(name, e.target.value);
        if (name === "billingPaymentType" && !isClientEditable) {
            if (e.target.value === "Postpaid") {
                formik.setFieldValue("currentBalance", 0);
            } else {
                formik.setFieldValue("capCheck", false);
                formik.setFieldValue("maxBalanceLimit", 0);
            }
        }
    };

    const fileType = {
        DOC: "application/msword",

        DOCX: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        PDF: "application/pdf",
    };

    const onClickCancelButton = e => {
        setCustomizeRatesByClientId(null);
        setClientDetails({});
        dispatch(setShowAddClientForm(false));
    };

    const onClickCapCheck = e => {
        formik.setFieldValue("capCheck", e.target.checked);
    };

    const initialValues = {
        // selectedParentClientId: "",
        // selectedParentClient: null,
        clientName: clientData?.orgDetails ? clientData.orgDetails.orgName : "",
        orgWebsiteUrl: clientData?.orgDetails?.orgWebsiteUrl ?? "",
        adminFirstName: clientData?.brandDetails?.contactPersonDetails?.firstName ? clientData?.brandDetails?.contactPersonDetails?.firstName : "",
        adminLastName: clientData?.brandDetails?.contactPersonDetails?.lastName ? clientData?.brandDetails?.contactPersonDetails?.lastName : "",
        _id: clientData?._id ? clientData?._id : "",
        email: clientData?.email ? clientData?.email : "",
        mobileNumber: clientData?.mobileNumber ? clientData?.mobileNumber : "",
        userName: clientData?.userName ? clientData?.userName : "",
        status: clientData?.orgDetails?.status ? clientData?.orgDetails?.status : ClientStatus.InActive,
        // assignStatus: "executive",
        // selectedBranch: null,
        // selectedBranchId: "",
        countryCode: clientData?.countryCode ? clientData?.countryCode : "",
        countryName: clientData?.countryName ? clientData?.countryName : "",
        supportedProvidersList: clientData?.orgDetails?.supportedProviders ? clientData?.orgDetails.supportedProviders : [],
        // isWhiteLabel: clientData?.orgDetails ? clientData.orgDetails.isWhiteLabel : "",
        orgLogo: clientData?.whiteLabelDetails?.whiteLabelLogo?.url
            ? clientData?.whiteLabelDetails?.whiteLabelLogo
            : {
                type: "image",
                url: null,
            },

        buttonTextColor: {
            color: clientData?.whiteLabelDetails ? clientData.whiteLabelDetails.buttonTextColor?.color : "#ffffff",
            description: clientData?.whiteLabelDetails ? clientData.whiteLabelDetails.buttonTextColor?.description : "",
        },
        buttonBackgroundColor: {
            color: clientData?.whiteLabelDetails ? clientData.whiteLabelDetails.buttonBackgroundColor?.color : "#1984D9",
            description: clientData?.whiteLabelDetails ? clientData.whiteLabelDetails.buttonBackgroundColor?.description : "",
        },
        normalTextColor: {
            color: clientData?.whiteLabelDetails ? clientData.whiteLabelDetails.normalTextColor?.color : "#000000",
            description: clientData?.whiteLabelDetails ? clientData.whiteLabelDetails.normalTextColor?.description : "",
        },
        backgroundImage: clientData?.whiteLabelDetails?.backgroundImage
            ? clientData?.whiteLabelDetails?.backgroundImage
            : {
                type: "image",
                url: null,
            },

        // textMessageRate:
        //     customizeRatesByClientId &&
        //         customizeRatesByClientId?.customizedProviderRate[clientData?.orgDetails?.supportedProviders[0]]?.A2pMessageRate?.textMessageRate
        //         ? customizeRatesByClientId?.customizedProviderRate[clientData?.orgDetails?.supportedProviders[0]]?.A2pMessageRate?.textMessageRate
        //         : 0.0,
        // multiMediaMessageRate:
        //     customizeRatesByClientId &&
        //         customizeRatesByClientId?.customizedProviderRate[clientData?.orgDetails?.supportedProviders[0]]?.A2pMessageRate?.multiMediaMessageRate
        //         ? customizeRatesByClientId?.customizedProviderRate[clientData?.orgDetails?.supportedProviders[0]]?.A2pMessageRate
        //             ?.multiMediaMessageRate
        //         : 0.0,
        // A2pSessionConversationRate:
        //     customizeRatesByClientId &&
        //         customizeRatesByClientId?.customizedProviderRate[clientData?.orgDetails?.supportedProviders[0]]?.A2pMessageRate
        //             ?.A2pSessionConversationRate
        //         ? customizeRatesByClientId?.customizedProviderRate[clientData?.orgDetails?.supportedProviders[0]]?.A2pMessageRate
        //             ?.A2pSessionConversationRate
        //         : 0.0,
        // feedbackRate:
        //     customizeRatesByClientId &&
        //         customizeRatesByClientId?.customizedProviderRate[clientData?.orgDetails?.supportedProviders[0]]?.A2pMessageRate?.feedbackRate
        //         ? customizeRatesByClientId?.customizedProviderRate[clientData?.orgDetails?.supportedProviders[0]]?.A2pMessageRate?.feedbackRate
        //         : 0.0,
        billingPaymentType:
            customizeRatesByClientId && customizeRatesByClientId?.billingDetails?.billingPaymentType
                ? customizeRatesByClientId?.billingDetails?.billingPaymentType
                : "Prepaid",
        currentBalance:
            customizeRatesByClientId && customizeRatesByClientId?.billingDetails?.currentBalance
                ? customizeRatesByClientId?.billingDetails?.currentBalance
                : 0.0,
        maxBalanceLimit:
            customizeRatesByClientId && customizeRatesByClientId?.billingDetails?.maxBalanceLimit
                ? customizeRatesByClientId?.billingDetails?.maxBalanceLimit
                : 0.0,
        capCheck:
            customizeRatesByClientId && customizeRatesByClientId?.billingDetails?.capCheck
                ? customizeRatesByClientId?.billingDetails?.capCheck
                : false,
        currentUsage:
            customizeRatesByClientId && customizeRatesByClientId?.billingDetails?.currentUsage
                ? customizeRatesByClientId?.billingDetails?.currentUsage
                : 0,
        recharge:
            customizeRatesByClientId && customizeRatesByClientId?.billingDetails?.recharge ? customizeRatesByClientId?.billingDetails?.recharge : 0,

        // -----------------------------brandDetails-------------------------------------
        brandName: clientData?.brandDetails?.brandName ? clientData?.brandDetails?.brandName : "",
        industryType: clientData?.brandDetails?.industryType ? clientData?.brandDetails?.industryType : "",
        officialBrandWebsite: clientData?.brandDetails?.officialBrandWebsite ? clientData?.brandDetails?.officialBrandWebsite : "",
        brandLogo: clientData?.brandDetails?.brandLogo
            ? clientData?.brandDetails?.brandLogo
            : {
                type: "image",
                url: null,
            },
        contactPersonFirstName: clientData?.brandDetails?.contactPersonDetails?.firstName
            ? clientData?.brandDetails?.contactPersonDetails?.firstName
            : "",
        contactPersonLastName: clientData?.brandDetails?.contactPersonDetails?.lastName
            ? clientData?.brandDetails?.contactPersonDetails?.lastName
            : "",
        contactPersonDesignation: clientData?.brandDetails?.contactPersonDetails?.designation
            ? clientData?.brandDetails?.contactPersonDetails?.designation
            : "",
        contactPersonEmailId: clientData?.brandDetails?.contactPersonDetails?.emailId ? clientData?.brandDetails?.contactPersonDetails?.emailId : "",
        contactPersonPhoneNumber: {
            number: clientData?.brandDetails?.contactPersonDetails?.mobileNumber ? clientData?.brandDetails?.contactPersonDetails?.mobileNumber : "",
            code: clientData?.brandDetails?.contactPersonDetails?.countryCode ? clientData?.brandDetails?.contactPersonDetails?.countryCode : "",
            country: clientData?.brandDetails?.contactPersonDetails?.country ? clientData?.brandDetails?.contactPersonDetails?.country : "",
        },
        companyAddress: {
            addressLine1: clientData?.brandDetails?.companyAddressDetails?.addressLine1
                ? clientData?.brandDetails?.companyAddressDetails?.addressLine1
                : "",
            addressLine2: clientData?.brandDetails?.companyAddressDetails?.addressLine2
                ? clientData?.brandDetails?.companyAddressDetails?.addressLine2
                : "",
            country: clientData?.brandDetails?.companyAddressDetails?.country ? clientData?.brandDetails?.companyAddressDetails?.country : "",
            state: clientData?.brandDetails?.companyAddressDetails?.state ? clientData?.brandDetails?.companyAddressDetails?.state : "",
            city: clientData?.brandDetails?.companyAddressDetails?.city ? clientData?.brandDetails?.companyAddressDetails?.city : "",
            zipCode: clientData?.brandDetails?.companyAddressDetails?.zipCode ? clientData?.brandDetails?.companyAddressDetails?.zipCode : "",
        },

        // -----------------------------------------------Business Verification Details-------------------------------------
        verifyBusinessName: clientData?.brandDetails?.businessVerificationDetails?.verifyBusinessName
            ? clientData?.brandDetails?.businessVerificationDetails?.verifyBusinessName
            : [verifyBusinessNameObjectToAdd],
        verifyBusinessAddress: clientData?.brandDetails?.businessVerificationDetails?.verifyBusinessAddress
            ? clientData?.brandDetails?.businessVerificationDetails?.verifyBusinessAddress
            : [businessAddressObjectToAdd],
        termsAndConditions: false,
        agreeToPayVerificationFee: false,
        // phoneNumbers:"",

        // selectedExecutiveId: "",
        // selectedExecutive: null,
        // executivesListByBranchId: [],
        // selectedBranchManagerId: "",
        // branchManagersListByBranchId: [],
        // selectedBranchManager: null,
        // -----------------------------------------------Sms Fallback Details-------------------------------------
        // smsFallbackDetails: { senderId: clientData?.orgDetails?.senderId ?? "", apiKey: clientData?.orgDetails?.apiKey ?? "" },
        smsFallbackDetails: { senderIds: clientData?.orgDetails?.senderIds ?? [], sms_apiKey: clientData?.orgDetails?.sms_apiKey ?? "" },

        // otherFields:
        //     customizeRatesByClientId && customizeRatesByClientId?.customizedProviderRate[clientData?.orgDetails?.supportedProviders[0]]?.otherFields
        //         ? customizeRatesByClientId?.customizedProviderRate[clientData?.orgDetails?.supportedProviders[0]]?.otherFields
        //         : Object.keys(otherFields).length > 0
        //             ? otherFields
        //             : {},

        privileges: {
            isWhiteLabel: clientData?.orgDetails?.privileges?.isWhiteLabel ?? "Disabled",
        },
        domain: clientData?.domain ? clientData?.domain : "",
    };

    const formik = useFormik({
        initialValues: initialValues,

        onSubmit: async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
            try {
                const validatePersonalDetailsResult = validatePersonalDetails();
                const validateWhiteLabelResult = validateWhiteLabelDetails();

                const validateBrandDetailsResult = validateBrandDetails();
                const validateBusinessVerificationResult = validateBusinessVerification();
                // const validateBillingDetailsResult = validateBillingDetails();
                if (
                    validateWhiteLabelResult &&
                    validatePersonalDetailsResult &&
                    validateBrandDetailsResult &&
                    validateBusinessVerificationResult
                ) {
                    // if (formik?.values?.privileges?.isWhiteLabel === "Enabled") {
                    //     const validateWhiteLabelURLResult = validateWhiteLabelURL();
                    //     if (validateWhiteLabelURLResult) return;
                    // }
                    // brandName: string;
                    // brandStatus?: string;
                    // botIds?: [string];
                    // botName?: string;
                    // orgId?: string;

                    // industryType: (typeof supportedIndustryTypesForBrand)[number];
                    // officialBrandWebsite: string;
                    // brandLogo: IMedia;
                    // contactPersonDetails: IContactPersonDetails;
                    // companyAddressDetails: ICompanyAddressDetails;
                    // brandVerificationDetails?: object;

                    // firstName: string;
                    // lastName: string;
                    // emailId: string;
                    // designation: string;
                    // mobileNumber: string;
                    // country: string;
                    // countryCode: string;

                    const brandDetailsToSend = {
                        brandName: formik.values.brandName,
                        brandStatus: BrandStatus.NotVerified,
                        industryType: formik.values.industryType,
                        officialBrandWebsite: formik.values.officialBrandWebsite,
                        contactPersonDetails: {
                            firstName: values.adminFirstName,
                            lastName: values.adminLastName,
                            emailId: formik.values.email,
                            designation: formik.values.contactPersonDesignation,
                            mobileNumber: formik.values.mobileNumber,
                            country: formik.values.countryName,
                            countryCode: formik.values.countryCode,
                        },
                        companyAddressDetails: {
                            addressLine1: formik.values.companyAddress.addressLine1,
                            addressLine2: formik.values.companyAddress.addressLine2,
                            country: formik.values.countryName,
                            state: formik.values.companyAddress.state,
                            city: formik.values.companyAddress.city,
                            zipCode: formik.values.companyAddress.zipCode,
                        },
                        businessVerificationDetails: {
                            verifyBusinessName: values.verifyBusinessName,
                            verifyBusinessAddress: values.verifyBusinessAddress,
                            termsAndConditions: values.termsAndConditions,
                            agreeToPayVerificationFee: values.agreeToPayVerificationFee,
                        },
                    };

                    let clientDetailsToSend = {
                        clientName: values.clientName,
                        adminFirstName: values.adminFirstName,
                        adminLastName: values.adminLastName,
                        email: values.email,
                        mobileNumber: values.mobileNumber,
                        roleName: ERoles.ORG_ADMIN,
                        userName: values.email,
                        client: {
                            // clientName: values.clientName,
                            assignStatus: values.assignStatus,
                            branchId: values.selectedBranchId,
                            // assignedExecutive: values.selectedExecutive,
                            // assignedBranchManager: values.selectedBranchManager,
                            // parentClientId: values.selectedParentClientId,
                            supportedProviders: values.supportedProvidersList,
                            orgWebsiteUrl: values.orgWebsiteUrl,
                        },
                        status: values.status,
                        countryName: values.countryName,
                        countryCode: "+" + values.countryCode,
                        smsFallbackDetails: values.smsFallbackDetails,
                        privileges: {
                            isWhiteLabel: values.privileges.isWhiteLabel,
                        },
                        domain: values.domain,
                        buttonTextColor: {
                            color: values.buttonTextColor.color,
                            description: values.buttonTextColor.description,
                        },
                        buttonBackgroundColor: {
                            color: values.buttonBackgroundColor.color,
                            description: values.buttonBackgroundColor.description,
                        },
                        normalTextColor: {
                            color: values.normalTextColor.color,
                            description: values.normalTextColor.description,
                        },
                        backgroundImage: values.backgroundImage,
                    };

                    if (values.billingPaymentType === "Postpaid") {
                        clientDetailsToSend.billingDetails = {
                            billingPaymentType: values.billingPaymentType,
                            currentBalance: 0,
                            maxBalanceLimit: values.maxBalanceLimit,
                            capCheck: values.capCheck,
                            currentUsage: 0,
                        };
                    } else {
                        clientDetailsToSend.billingDetails = {
                            billingPaymentType: values.billingPaymentType,
                            currentBalance: values.currentBalance,
                            maxBalanceLimit: 0,
                            capCheck: false,
                            currentUsage: 0,
                        };
                    }

                    let customizedProviderRate = {};
                    customizedProviderRate[`${values.supportedProvidersList[0].providerCode}`] = {
                        providerId: providerFields.providerId,
                        providerCode: providerFields.providerCode,
                        A2pMessageRate: {
                            textMessageRate: 0,
                            multiMediaMessageRate: 0,
                            A2pSessionConversationRate: 0,
                            feedbackRate: 0,
                        },
                        // P2aMessageRate: {
                        //     userMessageRate: values.userMessageRate,
                        //     P2aSessionConversationRate: values.P2aSessionConversationRate,
                        // },
                        otherFields: values.otherFields,
                    };

                    clientDetailsToSend["customizedProviderRate"] = customizedProviderRate;
                    // brandData: brandDetailsToSend
                    let formDataToSend = new FormData();

                    formDataToSend.append("brandLogo", brandLogoPreviewImage.file);

                    for (let fieldName in businessVerificationFormData) {
                        formDataToSend.append(fieldName, businessVerificationFormData[fieldName]);
                    }

                    // also, adding botdata(payload) to formDataToSend
                    formDataToSend.append("clientData", JSON.stringify(clientDetailsToSend));

                    formDataToSend.append("brandData", JSON.stringify(brandDetailsToSend));
                    formDataToSend.append("orgLogo", orgLogoPreviewImage.file);
                    formDataToSend.append("backgroundImage", backgroundImagePreview.file);
                    // console.log("==================================================================================================================================================================================================")
                    dispatch(addNewClientThunk({ formDataToSend, registryId: user?.registryId, providerCode: providerFields.providerCode }));
                }
            } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
            }
        },
    });

    const onSelectImage = ({ file, url, fieldName }) => {
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = readerEvent => {
            if (file.type.includes("image")) {
                let mimeType = file.type;
                const supportedMimeTypeArray = ["image/jpeg", "image/png", "image/jpg"];
                if (supportedMimeTypeArray.includes(mimeType)) {
                    //   setting both the images in imageFormData state on fn call
                    setBrandLogoPreviewImage({
                        url: readerEvent.target.result,
                        type: file.type,
                        file: file,
                        fieldName: "brandLogoImageUrl",
                    });
                }
            }
        };
        formik.setFieldValue("brandLogo.url", url);
        formik.setFieldValue("brandLogo.type", file.type);
    };

    const onSelectOrgLogoImage = ({ file, url, fieldName }) => {
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = readerEvent => {
            if (file.type.includes("image")) {
                let mimeType = file.type;
                const supportedMimeTypeArray = ["image/jpeg", "image/png", "image/jpg"];
                if (supportedMimeTypeArray.includes(mimeType)) {
                    if (fieldName === "orgLogo") {
                        //   setting both the images in imageFormData state on fn call
                        setOrgLogoPreviewImage({
                            url: readerEvent.target.result,
                            type: file.type,
                            file: file,
                            fieldName: "orgLogoImageUrl",
                        });
                    }
                    if (fieldName === "backgroundImage") {
                        if (supportedMimeTypeArray.includes(mimeType)) {
                            //   setting both the images in imageFormData state on fn call
                            setBackgroundImagePreview({
                                url: readerEvent.target.result,
                                type: file.type,
                                file: file,
                                fieldName: "backgroundImageUrl",
                            });
                        }
                    }
                }
            }
        };
        if (fieldName === "orgLogo") {
            formik.setFieldValue("orgLogo.url", url);
            formik.setFieldValue("orgLogo.type", file.type);
        }

        if (fieldName === "backgroundImage") {
            formik.setFieldValue("backgroundImage.url", url);
            formik.setFieldValue("backgroundImage.type", file.type);
        }
    };

    const updateClientDetails = async () => {
        try {
            setIsUpdateClientLoading(true);
            // const validateBrandDetailsResult = validateBrandDetails();
            // const validateBusinessVerificationResult = validateBusinessVerification();
            const validateWhiteLabelResult = validateWhiteLabelDetails();
            const validatePersonalDetailsResult = validatePersonalDetails();
            const validateBrandDetailsResult = validateBrandDetails();
            const validateBusinessVerificationResult = validateBusinessVerification();
            const validateBillingDetailsResult = validateBillingDetails();
            if (
                !(validateBrandDetailsResult && validateBusinessVerificationResult && validatePersonalDetailsResult && validateBillingDetailsResult && validateWhiteLabelResult)
            ) {
                setIsUpdateClientLoading(false);
                return;
            }

            const brandDetailsToSend = {
                brandName: formik.values.brandName,
                brandStatus: BrandStatus.NotVerified,
                industryType: formik.values.industryType,
                officialBrandWebsite: formik.values.officialBrandWebsite,
                contactPersonDetails: {
                    firstName: formik.values.adminFirstName,
                    lastName: formik.values.adminLastName,
                    emailId: formik.values.email,
                    designation: formik.values.contactPersonDesignation,
                    mobileNumber: formik.values.mobileNumber,
                    country: formik.values.countryName,
                    countryCode: formik.values.countryCode,
                },
                companyAddressDetails: {
                    addressLine1: formik.values.companyAddress.addressLine1,
                    addressLine2: formik.values.companyAddress.addressLine2,
                    country: formik.values.countryName,
                    state: formik.values.companyAddress.state,
                    city: formik.values.companyAddress.city,
                    zipCode: formik.values.companyAddress.zipCode,
                },
                businessVerificationDetails: {
                    verifyBusinessName: formik.values.verifyBusinessName,
                    verifyBusinessAddress: formik.values.verifyBusinessAddress,
                    termsAndConditions: formik.values.termsAndConditions,
                    agreeToPayVerificationFee: formik.values.agreeToPayVerificationFee,
                },
            };
            let clientDetailsToSend = {
                orgId: clientData.orgId,
                clientName: formik.values.clientName,
                clientId: clientData?._id,
                orgLogo: formik.values.orgLogo,
                adminFirstName: formik.values.adminFirstName,
                adminLastName: formik.values.adminLastName,
                email: formik.values.email,
                mobileNumber: formik.values.mobileNumber,
                roleName: ERoles.ORG_ADMIN,
                roleId: clientData?.roleId,
                userName: clientData?.userName,
                client: {
                    // clientName: formik.values.clientName,
                    assignStatus: formik.values.assignStatus,
                    branchId: formik.values.selectedBranchId,
                    // assignedExecutive: formik.values.selectedExecutive,
                    // assignedBranchManager: formik.values.selectedBranchManager,
                    // parentClientId: formik.values.selectedParentClientId,
                    supportedProviders: formik.values.supportedProvidersList,
                    orgWebsiteUrl: formik.values.orgWebsiteUrl,
                },
                // status: clientData?.orgDetails?.status,
                status: formik.values.status,
                countryName: formik.values.countryName,
                countryCode: "+" + formik.values.countryCode,
                smsFallbackDetails: formik.values.smsFallbackDetails,
                privileges: {
                    isWhiteLabel: formik.values.privileges.isWhiteLabel,
                },
                domain: formik.values.domain,
                buttonTextColor: {
                    color: formik.values.buttonTextColor.color,
                    description: formik.values.buttonTextColor.description,
                },
                buttonBackgroundColor: {
                    color: formik.values.buttonBackgroundColor.color,
                    description: formik.values.buttonBackgroundColor.description,
                },
                normalTextColor: {
                    color: formik.values.normalTextColor.color,
                    description: formik.values.normalTextColor.description,
                },
                backgroundImage: formik.values.backgroundImage,
                // billingDetails: {
                //     billingPaymentType: formik.values.billingPaymentType,
                //     currentBalance: formik.values.currentBalance + formik.values.recharge,
                //     maxBalanceLimit: formik.values.maxBalanceLimit,
                //     // recharge: formik.values.recharge,
                // },
            };

            if (formik.values.billingPaymentType === "Postpaid") {
                clientDetailsToSend.billingDetails = {
                    billingPaymentType: formik.values.billingPaymentType,
                    currentBalance: 0,
                    maxBalanceLimit: formik.values.maxBalanceLimit,
                    capCheck: formik.values.capCheck,
                    currentUsage: formik.values.currentUsage,
                };
            } else {
                clientDetailsToSend.billingDetails = {
                    billingPaymentType: formik.values.billingPaymentType,
                    currentBalance: formik.values.currentBalance + formik.values.recharge,
                    maxBalanceLimit: 0,
                    capCheck: false,
                    currentUsage: 0,
                };
            }

            let customizedProviderRate = {};
            customizedProviderRate[`${formik.values.supportedProvidersList[0].providerCode}`] = {
                A2pMessageRate: {
                    textMessageRate: formik.values.textMessageRate,
                    multiMediaMessageRate: formik.values.multiMediaMessageRate,
                    A2pSessionConversationRate: formik.values.A2pSessionConversationRate,
                    feedbackRate: formik.values.feedbackRate,
                },
                // P2aMessageRate: {
                //     userMessageRate: formik.values.userMessageRate,
                //     P2aSessionConversationRate: formik.values.P2aSessionConversationRate,
                // },
                otherFields: formik.values.otherFields,
                // domain: formik.values.domain,
            };

            clientDetailsToSend["customizedProviderRate"] = customizedProviderRate;
            // brandData: brandDetailsToSend

            let formDataToSend = new FormData();

            formDataToSend.append("brandLogo", brandLogoPreviewImage.file);

            for (let fieldName in businessVerificationFormData) {
                formDataToSend.append(fieldName, businessVerificationFormData[fieldName]);
            }

            // also, adding botdata(payload) to formDataToSend
            formDataToSend.append("clientData", JSON.stringify(clientDetailsToSend));
            formDataToSend.append("brandData", JSON.stringify(brandDetailsToSend));
            formDataToSend.append("orgLogo", orgLogoPreviewImage.file);
            formDataToSend.append("backgroundImage", backgroundImagePreview.file);

            const response = await apiGatewayAxiosInstance.post(`/user_management/clients/update-client-data`, formDataToSend, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 200) {
                setIsUpdateClientLoading(false);
                onClickCancelButton();
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            setIsUpdateClientLoading(false);
            let errorMessage = "Failed to update client.";
            console.log(error);
            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    const branchManagerSchema = Yup.object().shape({
        branchManagerId: Yup.string().required("Branch Manager Id is required"),
        name: Yup.string().required("Manager Name is required"),
        email: Yup.string().email("Must be valid email").required("Email is required"),
        mobileNumber: Yup.string()
            .matches(/^\d{10}$/, "Valid number required")
            .required("Mobile is required"),
        branchId: Yup.string().required("Branch Id is required"),
        status: Yup.string().required("Executive Status is required"),
        userName: Yup.string().required("Username is required"),
    });

    const onChangeMobileNumber = event => {
        const { value, name } = event.target;
        // formik.setFieldValue("mobileNumber", value);
        formik.setFieldValue("mobileNumber", value);
        formik.handleChange(event);
    };

    const onChangeEmailId = event => {
        const { value, name } = event.target;
        formik.setFieldValue("contactPersonEmailId", value);
        formik.handleChange(event);
    };

    const validateIfValueIsEmpty = fieldName => {
        const fieldValue = formik.values[fieldName]?.trim();
        if (fieldValue === "" || fieldValue === null || fieldValue == undefined) {
            formik.setFieldError(`${fieldName}`, `Required`);
            return true;
        } else {
            return false;
        }
    };

    const validateForUrl = fieldName => {
        const websiteRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

        if (!formik.values[fieldName].match(websiteRegex)) {
            formik.setFieldError(fieldName, "Invalid url");
            return true;
        } else {
            return false;
        }
    };

    const validateForEmail = fieldName => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

        if (!formik.values[fieldName].match(emailRegex)) {
            formik.setFieldError(fieldName, "Invalid Email");
            return true;
        } else {
            return false;
        }
    };

    const validateForPhoneNumber = fieldName => {
        const mobileNumberWithCountryCode = formik.values[fieldName]; //no need to send with countryCode

        if (formik.values[fieldName] === "" || formik.values[fieldName] === null) {
            formik.setFieldError(fieldName, `This field can’t be empty`);
            return true;
        }

        const numberRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        // const emailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

        const isValid = isValidPhoneNumber(mobileNumberWithCountryCode, countryShortCode);
        if (!isValid) {
            formik.setFieldError(`mobileNumber`, `Invalid number`);
        }
        // if (!formik.values[fieldName].number.match(numberRegex)) {
        //     formik.setFieldError(`contactPersonPhoneNumber.number`, "Invalid number");
        //     return true;
        // }
        else {
            return false;
        }
    };

    const validateForCompanyAddress = () => {
        let result = true;

        // companyAddress
        // addressLine1
        if (formik.values.companyAddress.addressLine1 === "" || formik.values.companyAddress.addressLine1 === null) {
            formik.setFieldError(`companyAddress.addressLine1`, `This field can’t be empty`);
            result = false;
        }

        // addressLine2
        if (formik.values.companyAddress.addressLine2 === "" || formik.values.companyAddress.addressLine2 === null) {
            formik.setFieldError(`companyAddress.addressLine2`, `This field can’t be empty`);
            result = false;
        }

        // city
        if (formik.values.companyAddress.city === "" || formik.values.companyAddress.city === null) {
            formik.setFieldError(`companyAddress.city`, `This field can’t be empty`);
            result = false;
        }
        // country
        if (formik.values.companyAddress.country === "" || formik.values.companyAddress.country === null) {
            formik.setFieldError(`companyAddress.country`, `This field can’t be empty`);
            result = false;
        }
        // state
        if (formik.values.companyAddress.state === "" || formik.values.companyAddress.state === null) {
            formik.setFieldError(`companyAddress.state`, `This field can’t be empty`);
            result = false;
        }
        // zipCode
        if (formik.values.companyAddress.zipCode === "" || formik.values.companyAddress.zipCode === null) {
            formik.setFieldError(`companyAddress.zipCode`, `This field can’t be empty`);
            result = false;
        } else {
            const zipCodeRegex = /\b\d{6}\b/;
            if (!formik.values.companyAddress.zipCode.match(zipCodeRegex)) {
                formik.setFieldError(`companyAddress.zipCode`, "Invalid Zip Code");
                result = false;
            }
        }

        return result;
    };

    const validatePersonalDetails = () => {
        let result = true;

        if (validateIfValueIsEmpty("clientName")) {
            result = false;
        }

        if (validateIfValueIsEmpty("adminFirstName")) {
            result = false;
        }

        if (validateIfValueIsEmpty("adminLastName")) {
            result = false;
        }

        if (validateIfValueIsEmpty("email")) {
            result = false;
        } else {
            if (validateForEmail("email")) {
                result = false;
            }
        }

        if (!(formik.values?.supportedProvidersList.length > 0 && formik.values?.supportedProvidersList[0]?.providerCode !== "")) {
            formik.setFieldError("supportedProvidersList", `Required`);
            result = false;
        }

        if (validateIfValueIsEmpty("countryName")) {
            result = false;
        }

        // if (validateIfValueIsEmpty("mobileNumber")) {
        //     result = false;
        // }
        if (validateForPhoneNumber("mobileNumber")) {
            result = false;
        }
        if (config.REACT_APP_IS_SMS_API_KEY_REQUIRED) {
            // if (!formik.values?.smsFallbackDetails?.senderId) {
            //     formik.setFieldError(`smsFallbackDetails.senderId`, `Required`);

            //     result = false;
            // }

            if (formik.values?.smsFallbackDetails?.senderIds.length === 0) {
                formik.setFieldError(`smsFallbackDetails.senderIds`, `Required`);

                result = false;
            }
            if (!formik.values?.smsFallbackDetails?.sms_apiKey) {
                formik.setFieldError(`smsFallbackDetails.sms_apiKey`, `Required`);

                result = false;
            }

            // if (formik.values.orgLogo.url === "" || formik.values.orgLogo.url === null) {
            //     formik.setFieldError("orgLogo.url", "Select Image");
            //     result = false;
            // }
        }

        return result;
    };


    const validateWhiteLabelDetails = () => {
        let result = true;
        if (formik.values.privileges?.isWhiteLabel !== "Enabled") {
            return true
        }
        if (validateIfValueIsEmpty("domain")) {
            result = false;
        } else {
            if (validateForUrl("domain")) {
                result = false;
            }
        }

        if (!formik.values?.buttonTextColor?.color) {
            formik.setFieldError("buttonTextColor.color", `Required`);
            result = false;
        }
        if (!formik.values?.buttonTextColor?.description) {
            formik.setFieldError("buttonTextColor.description", `Required`);
            result = false;
        }
        if (!formik.values?.buttonBackgroundColor?.color) {
            formik.setFieldError("buttonBackgroundColor.color", `Required`);
            result = false;
        }
        if (!formik.values?.buttonBackgroundColor?.description) {
            formik.setFieldError("buttonBackgroundColor.description", `Required`);
            result = false;
        }

        if (!formik.values?.normalTextColor?.color) {
            formik.setFieldError("normalTextColor.color", `Required`);
            result = false;
        }
        if (!formik.values?.normalTextColor?.description) {
            formik.setFieldError("normalTextColor.description", `Required`);
            result = false;
        }

        if (formik.values?.orgLogo?.url === "" || formik.values?.orgLogo?.url === null) {
            formik.setFieldError("orgLogo.url", "Select Image");
            result = false;
        }




        return result;
    };

    const validateBillingDetails = () => {
        let result = true;
        return true;
        if (formik.values.billingPaymentType === "Prepaid") {
            if (validateIfValueIsEmpty("currentBalance")) {
                result = false;
            }
        } else {
            if (formik.values.capCheck) {
                if (validateIfValueIsEmpty("maxBalanceLimit")) {
                    result = false;
                }
            }
        }
        return result;
    };

    const validateBrandDetails = () => {
        let result = true;

        if (validateIfValueIsEmpty("orgWebsiteUrl")) {
            result = false;
        } else {
            if (validateForUrl("orgWebsiteUrl")) {
                result = false;
            }
        }

        if (validateIfValueIsEmpty("brandName")) {
            result = false;
        }

        if (validateIfValueIsEmpty("industryType")) {
            result = false;
        }

        if (validateIfValueIsEmpty("officialBrandWebsite")) {
            result = false;
        } else {
            if (validateForUrl("officialBrandWebsite")) {
                result = false;
            }
        }

        if (formik.values.brandLogo.url === "" || formik.values.brandLogo.url === null) {
            formik.setFieldError("brandLogo.url", "Select Image");
            result = false;
        }

        // if (validateIfValueIsEmpty("contactPersonFirstName")) {
        //     result = false;
        // }

        // if (validateIfValueIsEmpty("contactPersonLastName")) {
        //     result = false;
        // }

        if (validateIfValueIsEmpty("contactPersonDesignation")) {
            result = false;
        }

        if (validateIfValueIsEmpty("contactPersonEmailId")) {
            result = false;
        } else {
            if (validateForEmail("contactPersonEmailId")) {
                result = false;
            }
        }

        // if (validateForPhoneNumber("contactPersonPhoneNumber")) {
        //     result = false;
        // }

        if (!validateForCompanyAddress()) {
            result = false;
        }

        return result;
    };

    const validateWhiteLabelURL = () => {
        let result = true;
        if (validateIfValueIsEmpty("domain")) {
            result = false;
        } else {
            if (validateForUrl("domain")) {
                result = false;
            }
        }
        return result;
    };

    const validateBusinessVerification = () => {
        let result = true;

        // if (formik.values.termsAndConditions === false) {
        //   formik.setFieldError(`termsAndConditions`, `Please check the checkbox`);
        //   result = false;
        // }

        // if (formik.values.agreeToPayVerificationFee === false) {
        //   formik.setFieldError(`agreeToPayVerificationFee`, `Please check the checkbox`);
        //   result = false;
        // }

        formik.values.verifyBusinessName.map((document, index) => {
            if (document.documentType === "") {
                formik.setFieldError(`verifyBusinessName[${index}].documentType`, `This field can’t be empty`);
            }

            if (document.selectedFile.url === "" || document.selectedFile.url === null) {
                formik.setFieldError(`verifyBusinessName[${index}].selectedFile.url`, `Select File`);
            }
        });
        formik.values.verifyBusinessAddress.map((document, index) => {
            if (document.documentType === "") {
                formik.setFieldError(`verifyBusinessAddress[${index}].documentType`, `This field can’t be empty`);
            }

            if (document.selectedFile.url === "" || document.selectedFile.url === null) {
                formik.setFieldError(`verifyBusinessAddress[${index}].selectedFile.url`, `Select File`);
            }
        });
        return result;
    };

    const onChangeSelectDocumentForBusinessNameVerification = ({ index, name, value, documentType }) => {
        let mimeType = value.type;

        const supportedMimeTypeArray = [".pdf", fileType.DOC, fileType.DOCX, fileType.PDF];
        if (!supportedMimeTypeArray.includes(mimeType)) {
            formik.setFieldError(`verifyBusinessName[${index}].selectedFile.url`, `Only pdf and .doc`);

            return;
        }
        formik.setFieldValue(`verifyBusinessName[${index}].${name}.url`, value);
        setBusinessVerificationFormData({
            ...businessVerificationFormData,
            [`verifyBusinessName[${index}].${name}.url`]: value,
        });
    };

    const addDocumentForBusinessNameVerification = () => {
        formik.setFieldValue("verifyBusinessName", [...formik.values.verifyBusinessName, verifyBusinessNameObjectToAdd]);
    };

    const onChangeAddDocumentTypeForNameVerification = ({ index, value, name }) => {
        if (formik.values.verifyBusinessName.filter(document => document.documentType === value).length <= 0) {
            formik.setFieldValue(`verifyBusinessName[${index}].${name}`, value);
        } else {
            formik.setFieldError(`verifyBusinessName[${index}].${name}`, "Document Type Already Selected");
        }
    };

    const deleteDocumentForBusinessNameVerification = index => {
        //TODO:check if only 1 file is present then do not allow to delete

        // formik.setFieldValue(`verifyBusinessName[${index}].selectedFile.url`, "", true)
        let allDocuments = businessVerificationFormData;

        delete allDocuments[`verifyBusinessName[${index}].selectedFile.url`];
        let nextIndex = index;
        while (allDocuments[`verifyBusinessName[${nextIndex + 1}].selectedFile.url`]) {
            allDocuments[`verifyBusinessName[${nextIndex}].selectedFile.url`] = allDocuments[`verifyBusinessName[${nextIndex + 1}].selectedFile.url`];
            delete allDocuments[`verifyBusinessName[${nextIndex + 1}].selectedFile.url`];
            nextIndex = nextIndex + 1;
        }

        setBusinessVerificationFormData(allDocuments);
        const newPhones = [...formik.values.verifyBusinessName];

        newPhones.splice(index, 1);
        formik.setFieldValue("verifyBusinessName", newPhones);
    };

    // document type
    const onChangeAddDocumentForAddressVerification = ({ index, value, name }) => {
        // if (formik.values.verifyBusinessName.filter(document => document.documentType === value).length <= 0) {
        if (formik.values.verifyBusinessAddress.filter(document => document.documentType === value).length <= 0) {
            formik.setFieldValue(`verifyBusinessAddress[${index}].${name}`, value);
        } else {
            formik.setFieldError(`verifyBusinessAddress[${index}].${name}`, "Document Type Already Selected");
        }
    };

    const onChangeBusinessAddressVerification = ({ index, name, value }) => {
        let mimeType = value.type;

        const supportedMimeTypeArray = [".pdf", fileType.DOC, fileType.DOCX, fileType.PDF];
        if (!supportedMimeTypeArray.includes(mimeType)) {
            formik.setFieldError(`verifyBusinessAddress[${index}].selectedFile.url`, `Only pdf and .doc`);

            return;
        }
        formik.setFieldValue(`verifyBusinessAddress[${index}].${name}.url`, value);
        setBusinessVerificationFormData({
            ...businessVerificationFormData,
            [`verifyBusinessAddress[${index}].${name}.url`]: value,
        });
    };

    function deleteDocument(index) {
        //TODO:check if only 1 file is present then do not allow to delete
        // formik.setFieldValue(`verifyBusinessAddress[${index}].selectedFile.url`, "", true)

        let allDocuments = businessVerificationFormData;

        delete allDocuments[`verifyBusinessAddress[${index}].selectedFile.url`];
        let nextIndex = index;
        while (allDocuments[`verifyBusinessAddress[${nextIndex + 1}].selectedFile.url`]) {
            allDocuments[`verifyBusinessAddress[${nextIndex}].selectedFile.url`] =
                allDocuments[`verifyBusinessAddress[${nextIndex + 1}].selectedFile.url`];
            delete allDocuments[`verifyBusinessAddress[${nextIndex + 1}].selectedFile.url`];

            nextIndex = nextIndex + 1;
        }

        setBusinessVerificationFormData(allDocuments);
        const newPhones = [...formik.values.verifyBusinessAddress];
        newPhones.splice(index, 1);
        formik.setFieldValue("verifyBusinessAddress", newPhones);
    }

    const handleChangeCheckBox = e => {
        formik.setFieldValue(e.target.name, e.target.checked);
    };

    const addDocument = () => {
        formik.setFieldValue("verifyBusinessAddress", [...formik.values.verifyBusinessAddress, verifyBusinessNameObjectToAdd]);
    };

    const handleSubmit = () => {
        // formik.submitForm();
        const validateBrandDetailsResult = validateBrandDetails();
        validateBusinessVerification();
        formik.handleSubmit();
        // if (validateBrandDetailsResult) {
        // }
    };

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForAllUsers({ fieldName }));
    };
    const onChangeSelectCountry = ({ name, value }) => {
        const foundCountry = CountryCode.find(country => country.name === value);
        formik.setFieldValue(name, value);
        formik.setFieldValue("contactPersonPhoneNumber.country", value);

        formik.setFieldValue(`countryCode`, foundCountry.dial_code);
        formik.setFieldValue(`contactPersonPhoneNumber.code`, foundCountry.dial_code);
    };

    const onChangeSelectProvider = async ({ name, value }) => {
        // if (value && name) {
        //     // const providerListToUpdate = [...formik.values.supportedProvidersList, value];
        //     formik.setFieldValue(name, [value]);

        //     await getProviderFieldsDetails(value);
        //     await getAllMasterRates(value);
        // }
        if (value && name) {
            const selectedProvider = getAllProviderList.find(provider => {
                if (provider.providerCode == value) return provider;
            });
            // const providerListToUpdate = [...formik.values.supportedProvidersList, value];
            const providerDetails = {
                providerId: selectedProvider._id,
                providerCode: selectedProvider.providerCode,
                // providerName: selectedProvider.providerName,
            };

            // Set the value in Formik as an array containing the provider object
            formik.setFieldValue(name, [providerDetails]);

            // formik.setFieldValue(name, [value]);
            setProviderFields({
                providerId: selectedProvider._id,
                providerCode: selectedProvider.providerCode,
                // providerName: selectedProvider.providerName,
            });
            await getProviderFieldsDetails({ providerId: selectedProvider._id, providerCode: selectedProvider.providerCode });
            await getAllMasterRates({ providerId: selectedProvider._id, providerCode: selectedProvider.providerCode });
        }
    };

    const previewFile = (targetFile, urlFieldName, typeFieldName) => {
        // Reading New File (open file Picker Box)
        const reader = new FileReader();
        if (targetFile) {
            reader.readAsDataURL(targetFile);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = readerEvent => {
            if (targetFile.type.includes("image")) {
                formik.setFieldValue(urlFieldName, readerEvent.target.result);
                formik.setFieldValue(typeFieldName, targetFile.type);

                let mimeType = targetFile.type;
                const supportedMimeTypeArray = ["image/jpeg", "image/png", "image/jpg"];
                if (supportedMimeTypeArray.includes(mimeType)) {
                    //   setting both the images in imageFormData state on fn call
                    if (urlFieldName == "orgLogo") {
                        setOrgLogoPreviewImage({
                            url: readerEvent.target.result,
                            type: targetFile.type,
                            file: targetFile,
                            fieldName: "orgLogoImageUrl",
                        });
                    }
                    if (urlFieldName === "brandLogo") {
                        setBrandLogoPreviewImage({
                            url: readerEvent.target.result,
                            type: targetFile.type,
                            file: targetFile,
                            fieldName: "brandLogoImageUrl",
                        });
                    }
                    if (urlFieldName === "backgroundImage") {
                        if (supportedMimeTypeArray.includes(mimeType)) {
                            //   setting both the images in imageFormData state on fn call
                            setBackgroundImagePreview({
                                url: readerEvent.target.result,
                                type: targetFile.type,
                                file: targetFile,
                                fieldName: "backgroundImageUrl",
                            });
                        }
                    }
                }
            }
        };
    };

    const handleCountryName = value => {
        // const { name, value } = e.target;

        const country = countriesList.filter(country => {
            return country.name === value;
        })[0]; //here you will get full country object.
        GetState(country.id).then(result => {
            setStateList(result);
        });

        setCountryid(country.id);
        // onChangeSelectCountryForBrandDetails({
        //     value: country,
        //     // name: e.target.name,
        // })
        formik.setFieldValue("contactPersonPhoneNumber.country", country.name);
        formik.setFieldValue("companyAddress.country", country.name);
        formik.setFieldValue(`countryCode`, country.phone_code);
        formik.setFieldValue(`countryName`, country.name);
        formik.setFieldValue(`contactPersonPhoneNumber.code`, country.phone_code);
        setCountryShortCode(country.iso2);
    };

    const handleStateName = e => {
        const { name, value } = e.target;
        const state = stateList.filter(state => state.name == value)[0]; //here you will get full state object.
        GetCity(countryid, state.id).then(result => {
            setCityList(result);
        });

        formik.setFieldValue("companyAddress.state", value);
    };

    const getCountriesName = async () => {
        try {
            setIsEditDetailsLoading(true);
            await GetCountries().then(result => {
                setCountriesList(result);

                // handleCountryName("India")

                let countryName = "";
                if (clientData.brandDetails?.companyAddressDetails?.country) {
                    countryName = clientData.brandDetails.companyAddressDetails.country;

                    const country = result.filter(country => {
                        return country.name === countryName;
                    })[0]; //here you will get full country object.
                    GetState(country.id).then(result => {
                        if (clientData.brandDetails.companyAddressDetails.state) {
                            setStateList(result);
                            const state = result.filter(state => {
                                return state.name === clientData.brandDetails.companyAddressDetails.state;
                            })[0];
                            GetCity(country.id, state.id).then(result => {
                                setCityList(result);
                            });
                        }
                    });

                    setCountryid(country.id);

                    // onChangeSelectCountryForBrandDetails({
                    //     value: country,
                    //     // name: e.target.name,
                    // })
                    formik.setFieldValue("contactPersonPhoneNumber.country", country.name);
                    formik.setFieldValue("companyAddress.country", country.name);
                    formik.setFieldValue(`countryCode`, country.phone_code);
                    formik.setFieldValue(`countryName`, country.name);
                    formik.setFieldValue(`contactPersonPhoneNumber.code`, country.phone_code);
                    setCountryShortCode(country.iso2);
                }
            });

            if (clientData && clientData?.orgDetails?.supportedProviders[0]) {
                // getProviderFieldsDetails(clientData.orgDetails.supportedProviders[0]);
                await getProviderFieldsDetails(clientData && clientData?.orgDetails?.supportedProviders[0]);
            }
            setIsEditDetailsLoading(false);
        } catch (error) {
            let errorMessage = "Failed to get countries.";
            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
            dispatch(
                setErrorMessageForUser({
                    fieldName: "getClientEditDetailsErrorMessages",
                    errorMessage,
                }),
            );
        }
    };

    function onDeleteImage(url, mimeType, fieldName) {
        formik.setFieldValue(url, null);
        formik.setFieldValue(mimeType, "");
        if (fieldName === "brandLogo") {
            setBrandLogoPreviewImage({
                url: null,
                file: null,
                fieldName: null,
                type: null,
            });
        }
        if (fieldName === "orgLogo") {
            setOrgLogoPreviewImage({
                url: null,
                file: null,
                fieldName: null,
                type: null,
            });
        }

        if (fieldName === "backgroundImage") {
            setBackgroundImagePreview({
                url: null,
                file: null,
                fieldName: null,
                type: null,
            });
        }
    }

    const getProviderFieldsDetails = async ({ providerId, providerCode }) => {
        try {
            setIsEditDetailsLoading(true);
            const response = await apiGatewayAxiosInstance.get(`/bot_management/config/get-provider-details-by-provider`, {
                params: { providerId, providerCode },
            });

            if (response.status === 200) {
                setIsEditDetailsLoading(false);
                const dataToSet = response.data.data;
                setProviderDetailsByProviderName(dataToSet);
                let providerOtherFieldToNumber = {};

                if (Object.keys(dataToSet?.otherFields).length > 0) {
                    Object.keys(dataToSet?.otherFields).map((fieldElement, index) => {
                        providerOtherFieldToNumber[fieldElement] = 0;
                        // providerOtherFieldToNumber[fieldElement] = dataToSet?.otherFields[fieldElement]
                    });
                }
                if (!formik.values.otherFields || Object.keys(formik.values.otherFields).length <= 0) {
                    formik.setFieldValue("otherFields", providerOtherFieldToNumber);
                }
                setOtherFields(providerOtherFieldToNumber);
            }
        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "getClientEditDetailsErrorMessages",
                    errorMessage,
                }),
            );
        }
    };
    const getAllProvidersList = async () => {
        try {
            setIsEditDetailsLoading(true);
            const response = await apiGatewayAxiosInstance.get(`/user_management/clients/get-provider-by-registry-id`, {
                params: { registryId: user?.registryId },
            });

            if (response.status === 200) {
                setIsEditDetailsLoading(false);
                setGetAllProviderList(response.data.data);
                getCountriesName();
            }
        } catch (error) {
            let errorMessage = "Server not reachable";
            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "getClientEditDetailsErrorMessages",
                    errorMessage,
                }),
            );
        }
    };

    // if (IsEditDetailsLoading) {
    //     dispatch(setShowAddClientForm(false));
    //     <FetchingSpinner />;
    // } else {
    //     dispatch(setShowAddClientForm(true));
    // }

    const getAllMasterRates = async ({ providerId, providerCode }) => {
        try {
            // setLoading(true);
            dispatch(getMasterRatesThunk({ providerId, providerCode }));
        } catch (error) {
            let errorMessage = "Failed to get master rates.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    useEffect(async () => {
        // dispatch(getAllProvidersThunk());
        await getAllProvidersList();
        // await getCountriesName();
        if (clientData?.orgDetails?.supportedProviders[0]) {
            // getProviderFieldsDetails(clientData.orgDetails.supportedProviders[0]);
            getAllMasterRates(clientData?.orgDetails?.supportedProviders[0]);
        }
    }, [clientData?.orgDetails?.supportedProviders[0]]);

    console.log(formik.errors)
    console.log(formik.values)


    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid item md={6} display={"flex"} alignItems={"center"}>
                <IconButton color="secondary" onClick={onClickCancelButton} size="medium">
                    <ArrowBackIosIcon sx={{ cursor: "pointer" }} />
                </IconButton>

                <Grid role="presentation">
                    <Breadcrumbs aria-label="breadcrumb" fontSize="large">
                        <Link
                            underline="hover"
                            color="inherit"
                            className="breadcrumbItem breadcrumbFontSize"
                            // href="/rcs-management/manage-bots/"
                            onClick={onClickCancelButton}
                        >
                            Clients
                        </Link>
                        {clientData?.name && isClientEditable ? (
                            <Typography className="breadcrumbItem" color="text.primary">
                                Update {clientData.orgDetails.orgName} Details
                            </Typography>
                        ) : (
                            <Typography className="breadcrumbItem" color="text.primary">
                                Add New Client
                            </Typography>
                        )}
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Card sx={{ padding: "0.8rem" }}>
                <CardContent sx={{ padding: "10px" }}>
                    <Grid className="d-flex" gap={5}>
                        <FormControl fullWidth className="field-spacing" error={Boolean(formik.touched.clientName && formik.errors.clientName)}>
                            <TextField
                                disabled={state.controls.isAddClientLoading || !isBrandNotVerified}
                                name="clientName"
                                fullWidth
                                // label="Organization Name"
                                label={<span>Organization Name <span style={{ color: 'red' }}>*</span></span>}
                                id="clientName"
                                error={Boolean(formik.touched.clientName && formik.errors.clientName)}
                                helperText={formik.touched.clientName && formik.errors.clientName}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.clientName}
                            />
                        </FormControl>
                        <FormControl fullWidth className="field-spacing" error={Boolean(formik.touched.orgWebsiteUrl && formik.errors.orgWebsiteUrl)}>
                            <TextField
                                disabled={state.controls.isAddClientLoading || !isBrandNotVerified}
                                name="orgWebsiteUrl"
                                fullWidth
                                // label="Organization Website Url"
                                label={<span>Organization Website Url <span style={{ color: 'red' }}>*</span></span>}
                                id="orgWebsiteUrl"
                                error={Boolean(formik.touched.orgWebsiteUrl && formik.errors.orgWebsiteUrl)}
                                helperText={formik.touched.orgWebsiteUrl && formik.errors.orgWebsiteUrl}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.orgWebsiteUrl}
                            />
                        </FormControl>
                    </Grid>
                    <Grid className="d-flex" gap={5}>
                        <TextField
                            disabled={state.controls.isAddClientLoading || !isBrandNotVerified}
                            name="adminFirstName"
                            fullWidth
                            // label="Organization Admin First Name"
                            label={<span>Organization Admin First Name <span style={{ color: 'red' }}>*</span></span>}
                            variant="outlined"
                            className="field-spacing"
                            error={Boolean(formik.touched.adminFirstName && formik.errors.adminFirstName)}
                            helperText={formik.touched.adminFirstName && formik.errors.adminFirstName}
                            onBlur={formik.handleBlur}
                            value={formik.values.adminFirstName}
                            onChange={formik.handleChange}
                        />

                        <TextField
                            disabled={state.controls.isAddClientLoading || !isBrandNotVerified}
                            name="adminLastName"
                            fullWidth
                            // label="Organization Admin Last Name"
                            label={<span>Organization Admin Last Name <span style={{ color: 'red' }}>*</span></span>}
                            variant="outlined"
                            className="field-spacing"
                            error={Boolean(formik.touched.adminLastName && formik.errors.adminLastName)}
                            helperText={formik.touched.adminLastName && formik.errors.adminLastName}
                            onBlur={formik.handleBlur}
                            value={formik.values.adminLastName}
                            onChange={formik.handleChange}
                        />

                        {/* <FormControl
                            sx={{ margin: "auto" }}
                            fullWidth
                            disabled={state.controls.isAddClientLoading || !isBrandNotVerified}
                            error={Boolean(formik.errors.supportedProvidersList && formik.touched.supportedProvidersList)}
                        >
                            <InputLabel id='provider'>Select Provider</InputLabel>
                            <Select
                                fullWidth
                                disabled={state.controls.isAddClientLoading || !isBrandNotVerified}
                                name='supportedProvidersList'
                                label='Select Provider'
                                labelId='provider'
                                id='provider'
                                value={formik.values.supportedProvidersList[0]}
                                error={Boolean(formik.touched.supportedProvidersList && formik.errors.supportedProvidersList)}
                                helperText={formik.touched.supportedProvidersList && formik.errors.supportedProvidersList}
                                onBlur={formik.handleBlur}
                                onChange={e =>
                                    onChangeSelectProvider({
                                        value: e.target.value,
                                        name: e.target.name,
                                    })
                                }
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                        },
                                    },
                                }}
                            // disabled={
                            //   !isBotEditable
                            // }
                            >
                                {getAllProviderList?.map((provider, index) => (
                                    <MenuItem key={index} value={provider.name}>
                                        {provider.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>{formik.touched.supportedProvidersList && formik.errors.supportedProvidersList}</FormHelperText>
                        </FormControl> */}
                    </Grid>
                    <Grid className="d-flex" gap={5}>
                        <TextField
                            disabled={state.controls.isAddClientLoading || !isBrandNotVerified}
                            name="email"
                            fullWidth
                            label="Organization Admin Email"
                            variant="outlined"
                            className="field-spacing"
                            error={Boolean(formik.touched.email && formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            onChange={e => onChangeEmailId(e)}
                        />
                        <FormControl fullWidth disabled={state.controls.isAddClientLoading || !isBrandNotVerified}>
                            <TextField
                                focused
                                className="field-spacing"
                                disabled={true}
                                name="userName"
                                fullWidth
                                label="Username"
                                variant="outlined"
                                // error={Boolean(
                                //     formik.touched.userName &&
                                //         formik.errors.userName
                                // )}
                                // helperText={
                                //     formik.touched.userName &&
                                //     formik.errors.userName
                                // }

                                onBlur={formik.handleBlur}
                                // value={formik.values.userName}
                                value={clientData?.userName ? clientData?.userName : formik.values.email}
                            // onChange={formik.handleChange}
                            />
                        </FormControl>
                    </Grid>
                    <Box display={"flex"}>
                        <FormControl fullWidth>
                            {/* <Typography variant="body1" mb={3} fontWeight={"bold"}>
                              Contact Person Phone Number{" "}
                              <span className="text-danger">*</span>
                          </Typography> */}

                            <Box container gap={5} display={"flex"} width={"100%"} className="field-spacing">
                                <Grid width={"49%"} container sx={{ display: "flex", justifyContent: "space-between" }}>
                                    <FormControl error={Boolean(formik.errors.contactPersonDesignation)} fullWidth>
                                        <TextField
                                            // className='mt-2'
                                            name="contactPersonDesignation"
                                            fullWidth
                                            // label="Company Person Designation"
                                            label={<span>Company Person Designation <span style={{ color: 'red' }}>*</span></span>}
                                            variant="outlined"
                                            disabled={!isBrandNotVerified}
                                            error={Boolean(formik.errors.contactPersonDesignation)}
                                            helperText={formik.errors.contactPersonDesignation}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            defaultValue={formik.values.contactPersonDesignation}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* <Grid width={"49%"} container display={"flex"} justifyContent={"space-between"}>
                                    <Grid item md={2}>
                                        <TextField
                                            className='field-spacing'
                                            fullWidth
                                            name='contactPersonPhoneNumber.code'
                                            label='Code'
                                            error={Boolean(formik.errors.contactPersonPhoneNumber && formik.errors.contactPersonPhoneNumber?.code)}
                                            // disabled={brandVerified}
                                            helperText={formik.errors.contactPersonPhoneNumber && formik.errors.contactPersonPhoneNumber?.code}
                                            value={formik.values.contactPersonPhoneNumber?.code}
                                            onBlur={formik.handleBlur}
                                        />
                                    </Grid>
                                    <Grid item md={9.9}>
                                        <TextField
                                            fullWidth
                                            name='mobileNumber'
                                            label='Mobile Number'
                                            className='field-spacing'
                                            error={Boolean(formik.errors?.mobileNumber && formik.errors?.mobileNumber)}
                                            // disabled={brandVerified}
                                            helperText={formik.errors?.mobileNumber && formik.errors?.mobileNumber}
                                            defaultValue={formik.values.mobileNumber}
                                            value={formik.values.mobileNumber}
                                            onBlur={formik.handleBlur}
                                            onChange={e => onChangeMobileNumber(e)}
                                        />
                                    </Grid>
                                </Grid> */}
                                <Grid item width={"49%"}>
                                    <FormControl
                                        fullWidth
                                        sx={{ margin: "auto", minWidth: "100%" }}
                                        disabled={state.controls.isAddClientLoading}
                                        error={Boolean(formik.errors.supportedProvidersList)}
                                    >
                                        <InputLabel id="provider">Select Provider</InputLabel>
                                        <Select
                                            // fullWidth
                                            disabled={state.controls.isAddClientLoading}
                                            name="supportedProvidersList"
                                            label="Select Provider"
                                            labelId="provider"
                                            id="provider"
                                            value={formik.values.supportedProvidersList[0]?.providerCode}
                                            error={Boolean(formik.errors.supportedProvidersList)}
                                            helperText={formik.errors.supportedProvidersList}
                                            onBlur={formik.handleBlur}
                                            onChange={e =>
                                                onChangeSelectProvider({
                                                    value: e.target.value,
                                                    name: e.target.name,
                                                })
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                                    },
                                                },
                                            }}
                                        // disabled={
                                        //   !isBotEditable
                                        // }
                                        >
                                            {getAllProviderList?.map((provider, index) => (
                                                <MenuItem key={index} value={provider.providerCode}>
                                                    {provider.providerCode.split("_").join("-")}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>
                                            {formik.errors.supportedProvidersList}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Box>
                        </FormControl>
                    </Box>
                    <Box container sx={{ width: "100%" }} display={"flex"} gap={5} className="field-spacing">
                        <Grid width={"49%"} container sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Grid item md={3.9} display={"flex"}>
                                <FormControl fullWidth error={formik.touched.countryName && formik.errors.countryName}>
                                    <InputLabel id="countryId">Country</InputLabel>
                                    <Select
                                        id="countryId"
                                        name="country"
                                        label="Country"
                                        disabled={!isBrandNotVerified}
                                        value={formik.values.countryName}
                                        fullWidth
                                        // labelId='countryId'
                                        // value={}
                                        onChange={e => {
                                            handleCountryName(e.target.value);
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "250px", // Adjust the maxHeight as per your requirement
                                                    width: "230px",
                                                },
                                            },
                                        }}
                                    >
                                        {/* <Grid height={'250px'} width={'225px'}> */}
                                        {/* {state.masterRatesList.map(item => ( */}
                                        {countriesList.map(item => (
                                            <MenuItem value={item.name} sx={{ display: "grid", placeItems: "center", fontSize: "0.8rem" }}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                        {/* </Grid> */}
                                    </Select>
                                    <FormHelperText>{formik.touched.countryName && formik.errors.countryName}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={3.9} display={"flex"}>
                                <FormControl fullWidth error={formik.touched?.companyAddress?.state && formik.errors?.companyAddress?.state}>
                                    <InputLabel
                                        id="state"
                                    // style={{
                                    //     marginRight: "20px",
                                    // }}
                                    >
                                        State
                                    </InputLabel>

                                    <Select
                                        id="state"
                                        name="state"
                                        label="Country"
                                        fullWidth
                                        disabled={!isBrandNotVerified}
                                        onChange={e => {
                                            handleStateName(e);
                                        }}
                                        defaultValue={formik.values.companyAddress.state}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "250px", // Adjust the maxHeight as per your requirement
                                                    width: "230px",
                                                },
                                            },
                                        }}
                                    >
                                        {stateList.map((item, key) => (
                                            <MenuItem key={key} value={item.name} sx={{ display: "grid", placeItems: "center", fontSize: "0.8rem" }}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{formik.touched?.companyAddress?.state && formik.errors?.companyAddress?.state}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item md={3.9} display={"flex"}>
                                <FormControl fullWidth error={formik.touched?.companyAddress?.city && formik.errors?.companyAddress?.city}>
                                    <InputLabel id="city">City</InputLabel>
                                    <Select
                                        fullWidth
                                        id="city"
                                        name="city"
                                        label="City"
                                        defaultValue={formik.values.companyAddress?.city}
                                        disabled={!isBrandNotVerified}
                                        onChange={e => {
                                            handleCityName(e);
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "250px", // Adjust the maxHeight as per your requirement
                                                    width: "230px",
                                                },
                                            },
                                        }}
                                    >
                                        {cityList.map((item, key) => (
                                            <MenuItem key={key} value={item.name} sx={{ display: "grid", placeItems: "center", fontSize: "0.8rem" }}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{formik.touched?.companyAddress?.city && formik.errors?.companyAddress?.city}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid width={"49%"} container display={"flex"} justifyContent={"space-between"}>
                            <Grid item md={2}>
                                <TextField
                                    fullWidth
                                    name="contactPersonPhoneNumber.code"
                                    label="Code"
                                    error={Boolean(formik.errors.contactPersonPhoneNumber && formik.errors.contactPersonPhoneNumber?.code)}
                                    disabled={!isBrandNotVerified}
                                    helperText={formik.errors.contactPersonPhoneNumber && formik.errors.contactPersonPhoneNumber?.code}
                                    value={formik.values.contactPersonPhoneNumber?.code}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item md={9.9}>
                                <TextField
                                    fullWidth
                                    disabled={!countryShortCode}
                                    name="mobileNumber"
                                    // label="Mobile Number"
                                    label={<span>Mobile Number <span style={{ color: 'red' }}>*</span></span>}
                                    error={Boolean(formik.errors?.mobileNumber && formik.errors?.mobileNumber)}
                                    helperText={formik.errors?.mobileNumber && formik.errors?.mobileNumber}
                                    defaultValue={formik.values.mobileNumber}
                                    value={formik.values.mobileNumber}
                                    onBlur={formik.handleBlur}
                                    onChange={e => onChangeMobileNumber(e)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box display={"flex"} gap={5}>
                        <FormControl fullWidth>
                            <TextField
                                disabled={!isBrandNotVerified}
                                className="mt-2"
                                name="companyAddress.addressLine1"
                                fullWidth
                                // label="Company Address Line 1"
                                label={<span>Company Address Line 1 <span style={{ color: 'red' }}>*</span></span>}
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.addressLine1}
                                error={Boolean(formik.errors?.companyAddress?.addressLine1)}
                                helperText={formik.errors?.companyAddress?.addressLine1}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                disabled={!isBrandNotVerified}
                                className="mt-2"
                                name="companyAddress.addressLine2"
                                fullWidth
                                // label="Company Address Line 2"
                                label={<span>Company Address Line 2 <span style={{ color: 'red' }}>*</span></span>}
                                variant="outlined"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                defaultValue={formik.values.companyAddress.addressLine2}
                                error={Boolean(formik.errors?.companyAddress?.addressLine2)}
                                helperText={formik.errors?.companyAddress?.addressLine2}
                            />
                        </FormControl>
                    </Box>
                    <Grid width={"49%"} className="field-spacing" sx={{ paddingTop: "8px" }}>
                        <TextField
                            disabled={!isBrandNotVerified}
                            fullWidth
                            name="companyAddress.zipCode"
                            // label="Zip Code"
                            label={<span>Zip Code <span style={{ color: 'red' }}>*</span></span>}
                            variant="outlined"
                            error={Boolean(formik.errors?.companyAddress?.zipCode)}
                            helperText={formik.errors?.companyAddress?.zipCode}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            defaultValue={formik.values.companyAddress.zipCode}
                        />
                    </Grid>

                    <Grid style={{ width: "49%", marginTop: "20px" }}>
                        <WhiteLabelRadioButtons
                            onChangeRadio={onClickRadio}
                            isDisabled={state.controls.isAddClientLoading}
                            isWhiteLabel={formik.values.privileges.isWhiteLabel}
                        />
                    </Grid>

                    {/* <Box display={"flex"} gap={5}>
                        {formik?.values?.privileges?.isWhiteLabel === "Enabled" && (
                            <>
                                <TextField
                                    autoFocus
                                    disabled={!isBrandNotVerified}
                                    fullWidth
                                    name='domain'
                                    label='Domain for Organization WhiteLabel'
                                    variant='outlined'
                                    placeholder='abc.com'
                                    error={Boolean(formik.errors?.domain)}
                                    helperText={formik.errors?.domain}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    defaultValue={formik.values.domain}
                                />
                                <FormControl error={Boolean(formik.errors.brandLogo)} className='w-50'>
                                    <Typography variant='h6' fontWeight={"bold"}>
                                        Brand Logo
                                    </Typography>
                                    <Typography className='text-muted mb-3'>
                                        Provide a logo for your bot that will be displayed in connection with the bot's name.
                                    </Typography>
                                    {(formik.values.brandLogo.url || brandLogoPreviewImage?.url) && (
                                        <Grid sx={{ display: "flex" }}>
                                            <FilePreviewComponent
                                                type={brandLogoPreviewImage.type}
                                                url={brandLogoPreviewImage.url}
                                                previewImage={brandLogoPreviewImage}
                                                formikImage={formik.values.brandLogo}
                                                aspect={1 / 1}
                                                onSelectImage={onSelectImage}
                                                imageHeightRatio={1}
                                                imageWidthRatio={1}
                                                fieldName={"brandLogo"}
                                                isDisabled={!isBrandNotVerified}
                                                deleteFile={() => onDeleteImage("brandLogo.url", "brandLogo.type")}
                                                width={"240px"}
                                                height={"100px"}
                                            />
                                        </Grid>
                                    )}

                                    {!(formik.values.brandLogo.url || brandLogoPreviewImage?.url) && (
                                        <Button variant='outlined' sx={{ width: "250px" }} onClick={() => fileRef.current.click()}>
                                            Choose
                                        </Button>
                                    )}
                                    <FormHelperText>{formik.errors?.brandLogo?.url}</FormHelperText>

                                    <Box className='btn-container'>
                                        <input
                                            name='brandLogo'
                                            ref={fileRef}
                                            accept='image/*'
                                            onChange={e => previewFile(e.target.files[0], "brandLogo", "brandLogo.type")}
                                            onClick={e => (e.target.value = "")}
                                            type='file'
                                            hidden
                                        />
                                    </Box>
                                </FormControl>
                            </>
                        )}
                    </Box> */}

                    {formik?.values?.privileges?.isWhiteLabel === "Enabled" && (
                        <>
                            <hr style={{ marginTop: "10px !important" }} />
                            <Typography variant="h6" marginTop={"10px"} marginBottom={"10px"}>
                                <b className="text-primary">WhiteLabel Details</b>
                            </Typography>
                            <Box paddingBottom={2} display={"flex"} gap={5}>
                                <FormControl error={Boolean(formik.errors.officialBrandWebsite)} className="w-50">
                                    <TextField
                                        autoFocus
                                        // disabled={!isBrandNotVerified}
                                        fullWidth
                                        name="domain"
                                        label="Domain for Organization WhiteLabel"
                                        variant="outlined"
                                        placeholder="abc.com"
                                        error={Boolean(formik.errors?.domain)}
                                        helperText={formik.errors?.domain}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        defaultValue={formik.values.domain}
                                    />
                                </FormControl>
                                <FormControl error={Boolean(formik.errors.orgLogo)} className="w-50">
                                    <Typography variant="h6" fontWeight={"bold"}>
                                        Organization Logo
                                    </Typography>
                                    <Typography className="text-muted mb-3">Provide a Organization logo for WhiteLabel.</Typography>
                                    {(formik.values.orgLogo.url || orgLogoPreviewImage?.url) && (
                                        <Grid sx={{ display: "flex", maxHeight: "1000px" }}>
                                            <FilePreviewComponent
                                                type={orgLogoPreviewImage.type}
                                                url={orgLogoPreviewImage.url}
                                                previewImage={orgLogoPreviewImage}
                                                formikImage={formik.values.orgLogo}
                                                aspect={1 / 1}
                                                onSelectImage={onSelectOrgLogoImage}
                                                imageHeightRatio={1}
                                                imageWidthRatio={1}
                                                fieldName={"orgLogo"}
                                                isDisabled={false}
                                                deleteFile={() => onDeleteImage("orgLogo.url", "orgLogo.type", "orgLogo")}
                                                // width={"240px"}
                                                height={"100px"}
                                            />
                                        </Grid>
                                    )}

                                    {!(formik.values.orgLogo.url || orgLogoPreviewImage?.url) && (
                                        <Button variant="outlined" sx={{ width: "250px" }} onClick={() => orgLogoRef.current.click()}>
                                            Choose
                                        </Button>
                                    )}
                                    <FormHelperText>{formik.errors?.orgLogo?.url}</FormHelperText>

                                    <Box className="btn-container">
                                        <input
                                            name="orgLogo"
                                            ref={orgLogoRef}
                                            accept="image/*"
                                            onChange={e => previewFile(e.target.files[0], "orgLogo", "orgLogo.type")}
                                            onClick={e => (e.target.value = "")}
                                            type="file"
                                            hidden
                                        />
                                    </Box>
                                </FormControl>
                            </Box>

                            <Box display={"flex"} gap={5} mt={2}>
                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Button Text Color
                                            <Require />
                                        </Grid>
                                    </Typography>
                                    {/* <Typography className='text-muted mb-4'>
                                          Specify a color for your agent with a minimum 4.5:1 contrast ratio relative to white.
                                        </Typography> */}
                                    <Box display={"inline-flex"} gap={5} alignItems={"center"} mt={5}>
                                        <input
                                            type="color"
                                            id="color"
                                            name="buttonTextColor.color"
                                            value={formik.values.buttonTextColor.color}
                                            onChange={formik.handleChange}
                                            className="border border-1 p-1"
                                        />

                                        <TextField
                                            size="small"
                                            name="buttonTextColor.color"
                                            id="color-value"
                                            label="Button Text Color (# value)"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.buttonTextColor.color}
                                            error={Boolean(formik.errors?.buttonTextColor?.color)}
                                            helperText={formik.errors?.buttonTextColor?.color}
                                            onChange={formik.handleChange}
                                        />

                                        <TextField
                                            size="small"
                                            name="buttonTextColor.description"
                                            label="Description"
                                            variant="outlined"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            defaultValue={formik.values.buttonTextColor.description}
                                            error={Boolean(formik.errors?.buttonTextColor?.description)}
                                            helperText={formik.errors?.buttonTextColor?.description}
                                        />
                                    </Box>
                                </FormControl>

                                <FormControl fullWidth>
                                    <Typography className="subHeadings" variant="h6" fontStyle={"bold"}>
                                        <Grid display={"flex"}>
                                            Button Background Color
                                            <Require />
                                        </Grid>
                                    </Typography>
                                    {/* <Typography className='text-muted mb-4'>
                                Specify a color for your agent with a minimum 4.5:1 contrast ratio relative to white.
                            </Typography> */}
                                    <Box display={"inline-flex"} gap={5} alignItems={"center"} mt={5}>
                                        {/* Color picker input */}
                                        <input
                                            type="color"
                                            id="color"
                                            name="buttonBackgroundColor.color"
                                            value={formik.values.buttonBackgroundColor.color}
                                            onChange={formik.handleChange}
                                            className="border border-1 p-1"
                                        />

                                        <TextField
                                            size="small"
                                            name="buttonBackgroundColor.color"
                                            id="color-value"
                                            label="Button Background Color (# value)"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.buttonBackgroundColor.color}
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.errors?.buttonBackgroundColor?.color)}
                                            helperText={formik.errors?.buttonBackgroundColor?.color}
                                        />

                                        <TextField
                                            size="small"
                                            name="buttonBackgroundColor.description"
                                            label="Description"
                                            variant="outlined"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            defaultValue={formik.values.buttonBackgroundColor.description}
                                            error={Boolean(formik.errors?.buttonBackgroundColor?.description)}
                                            helperText={formik.errors?.buttonBackgroundColor?.description}
                                        />
                                    </Box>
                                </FormControl>
                            </Box>

                            <Box paddingBottom={2} display={"flex"} gap={5} mt={5}>
                                <>
                                    <FormControl className="w-50">
                                        <Typography className="subHeadings" style={{ flexWrap: "wrap" }} variant="h6" fontStyle={"bold"}>
                                            <Grid display={"flex"}>
                                                Normal Text Color
                                                <Require />
                                            </Grid>
                                        </Typography>
                                        {/* <Typography className='text-muted mb-4'>
                                    Specify a color for your agent with a minimum 4.5:1 contrast ratio relative to white.
                                </Typography> */}
                                        <Box display={"inline-flex"} gap={5} alignItems={"center"} mt={5}>
                                            {/* Color picker input */}
                                            <input
                                                type="color"
                                                id="color"
                                                name="normalTextColor.color"
                                                value={formik.values.normalTextColor.color}
                                                onChange={formik.handleChange}
                                                className="border border-1 p-1"
                                            />

                                            {/* TextField to show color hex value */}
                                            <TextField
                                                size="small"
                                                name="normalTextColor.color" // Make sure this matches formik field name
                                                id="color-value"
                                                label="Normal Text Color (# value)"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.normalTextColor?.color} // Show hex value
                                                onChange={formik.handleChange} // Allow manual input of hex value
                                                error={Boolean(formik.errors?.normalTextColor?.color)}
                                                helperText={formik.errors?.normalTextColor?.color}
                                            />

                                            <TextField
                                                size="small"
                                                name="normalTextColor.description"
                                                label="Description"
                                                variant="outlined"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                defaultValue={formik.values.normalTextColor?.description}
                                                error={Boolean(formik.errors?.normalTextColor?.description)}
                                                helperText={formik.errors?.normalTextColor?.description}
                                            />
                                        </Box>
                                    </FormControl>

                                    {/* <FormControl error={Boolean(formik.errors.resellerLogo)} className='w-50'>
                                        <Typography variant='h6' fontWeight={"bold"}>
                                            Background Image
                                        </Typography>
                                        {(formik.values.backgroundImage.url || backgroundImagePreview?.url) && (
                                            <Grid sx={{ display: "flex" }}>
                                                <FilePreviewComponent
                                                    type={backgroundImagePreview.type}
                                                    url={backgroundImagePreview.url}
                                                    previewImage={backgroundImagePreview}
                                                    formikImage={formik.values.backgroundImage}
                                                    aspect={2 / 1}
                                                    onSelectImage={onSelectOrgLogoImage}
                                                    imageHeightRatio={1}
                                                    imageWidthRatio={2}
                                                    fieldName={"backgroundImage"}
                                                    deleteFile={() => onDeleteImage("backgroundImage.url", "backgroundImage.type", "backgroundImage")}
                                                    height={"100px"}
                                                />
                                            </Grid>
                                        )}

                                        {!(formik.values.backgroundImage.url || backgroundImagePreview?.url) && (
                                            <Button
                                                variant='outlined'
                                                sx={{ width: "250px", marginTop: "10px" }}
                                                onClick={() => BackgroundImageRef.current.click()}
                                            >
                                                Choose
                                            </Button>
                                        )}
                                        <FormHelperText>{formik.errors?.backgroundImage?.url}</FormHelperText>

                                        <Box className='btn-container'>
                                            <input
                                                name='backgroundImage'
                                                ref={BackgroundImageRef}
                                                accept='image/*'
                                                onChange={e => previewFile(e.target.files[0], "backgroundImage", "backgroundImage.type")}
                                                onClick={e => (e.target.value = "")}
                                                type='file'
                                                hidden
                                            />
                                        </Box>
                                    </FormControl> */}
                                </>
                            </Box>
                        </>
                    )}

                    <hr style={{ marginTop: "10px !important" }} />
                    <Typography variant="h6" marginTop={"10px"} marginBottom={"10px"}>
                        <b className="text-primary">SMS Account Details</b>
                    </Typography>
                    <AddSMSApiKey formik={formik} state={state} IsSmsCredentialsUsed={clientData?.IsSmsCredentialsUsed} />
                    <hr style={{ marginTop: "10px !important" }} />
                    <Typography variant="h6" marginTop={"10px"} marginBottom={"10px"}>
                        <b className="text-primary">Brand Details</b>
                    </Typography>
                    <AddBrandDetails
                        formik={formik}
                        brandVerified={false}
                        fileRef={brandLogoRef}
                        onDeleteImage={onDeleteImage}
                        isBrandNotVerified={isBrandNotVerified}
                        brandLogoPreviewImage={brandLogoPreviewImage}
                        previewFile={previewFile}
                        onChangeSelectCountry={onChangeSelectCountry}
                        onSelectImage={onSelectImage}
                    />
                    <hr style={{ marginTop: "10px !important" }} />
                    <Typography variant="h6" marginTop={"10px"} marginBottom={"10px"}>
                        <b className="text-primary">Business Verification Details</b>
                    </Typography>

                    <AddBusinessVerificationDetails
                        addDocument={addDocument}
                        formik={formik}
                        handleChangeCheckBox={handleChangeCheckBox}
                        deleteDocument={deleteDocument}
                        onChangeBusinessAddressVerification={onChangeBusinessAddressVerification}
                        fileRef2={businessVerificationFileRef2}
                        fileRef1={businessVerificationFileRef1}
                        brandVerified={false}
                        isBrandNotVerified={isBrandNotVerified}
                        onChangeAddDocumentForAddressVerification={onChangeAddDocumentForAddressVerification}
                        deleteDocumentForBusinessNameVerification={deleteDocumentForBusinessNameVerification}
                        addDocumentForBusinessNameVerification={addDocumentForBusinessNameVerification}
                        onChangeAddDocumentTypeForNameVerification={onChangeAddDocumentTypeForNameVerification}
                        onChangeSelectDocumentForBusinessNameVerification={onChangeSelectDocumentForBusinessNameVerification}
                    />

                    <hr style={{ marginTop: "10px !important" }} />
                    <Typography variant="h6" marginTop={"10px"} marginBottom={"10px"}>
                        <b className="text-primary">Billing Details</b>
                        {/* {!providerDetailsByProviderName && (
                            <Grid maxWidth={"50%"}>
                                <Typography color={"red"}>(Please select Provider to fill Billing Details)</Typography>
                            </Grid>
                        )} */}
                        {/* <Grid mt={2} maxWidth={"50%"}>
                            <FormControl
                                fullWidth
                                sx={{ margin: "auto" }}
                                disabled={state.controls.isAddClientLoading}
                                error={Boolean(formik.errors.supportedProvidersList && formik.touched.supportedProvidersList)}
                            >
                                <InputLabel id='provider'>Select Provider</InputLabel>
                                <Select
                                    // fullWidth
                                    disabled={state.controls.isAddClientLoading}
                                    name='supportedProvidersList'
                                    label='Select Provider'
                                    labelId='provider'
                                    id='provider'
                                    value={formik.values.supportedProvidersList[0]}
                                    error={Boolean(formik.touched.supportedProvidersList && formik.errors.supportedProvidersList)}
                                    helperText={formik.touched.supportedProvidersList && formik.errors.supportedProvidersList}
                                    onBlur={formik.handleBlur}
                                    onChange={e =>
                                        onChangeSelectProvider({
                                            value: e.target.value,
                                            name: e.target.name,
                                        })
                                    }
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                boxShadow: " 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                                            },
                                        },
                                    }}
                                    // disabled={
                                    //   !isBotEditable
                                    // }
                                >
                                    {getAllProviderList?.map((provider, index) => (
                                        <MenuItem key={index} value={provider.name}>
                                            {provider.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>{formik.touched.supportedProvidersList && formik.errors.supportedProvidersList}</FormHelperText>
                            </FormControl>
                        </Grid> */}
                    </Typography>
                    {/* {providerDetailsByProviderName && ( */}
                    <>
                        {/* <Typography variant='subtitle2'>
                                <b className='text-primary'>A2P: (Application to Personal)</b>
                            </Typography> */}
                        {/* <Grid className='d-flex' gap={5}>
                                <TextField
                                    type='number'
                                    inputProps={{
                                        step: "any",
                                    }}
                                    onWheel={e => e.target.blur()}
                                    disabled={state.controls.isAddClientLoading}
                                    name='textMessageRate'
                                    className='field-spacing'
                                    fullWidth
                                    label='Text Message Rate (INR)'
                                    variant='outlined'
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.touched.textMessageRate && formik.errors.textMessageRate)}
                                    helperText={formik.touched.textMessageRate && formik.errors.textMessageRate}
                                    value={formik.values.textMessageRate}
                                    onChange={formik.handleChange}
                                />
                                <TextField
                                    type='number'
                                    inputProps={{
                                        step: "any",
                                    }}
                                    onWheel={e => e.target.blur()}
                                    disabled={state.controls.isAddClientLoading}
                                    name='multiMediaMessageRate'
                                    className='field-spacing'
                                    fullWidth
                                    label='Multi Media Message Rate (INR)'
                                    variant='outlined'
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.touched.multiMediaMessageRate && formik.errors.multiMediaMessageRate)}
                                    helperText={formik.touched.multiMediaMessageRate && formik.errors.multiMediaMessageRate}
                                    value={formik.values.multiMediaMessageRate}
                                    onChange={formik.handleChange}
                                />
                            </Grid> */}
                        {/* <Grid className='d-flex' gap={5}>
                                <TextField
                                    // disabled={state.controls.isAddClientLoading}
                                    disabled={true}
                                    fullWidth
                                    type='number'
                                    inputProps={{
                                        step: "any",
                                    }}
                                    onWheel={e => e.target.blur()}
                                    name='A2pSessionConversationRate'
                                    className='field-spacing'
                                    label='A2P Session Conversation Rate (INR)'
                                    variant='outlined'
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.touched.A2pSessionConversationRate && formik.errors.A2pSessionConversationRate)}
                                    helperText={formik.touched.A2pSessionConversationRate && formik.errors.A2pSessionConversationRate}
                                    value={formik.values.A2pSessionConversationRate}
                                    onChange={formik.handleChange}
                                />
                                <TextField
                                    disabled={true}
                                    fullWidth
                                    name='feedbackRate'
                                    type='number'
                                    inputProps={{
                                        step: "any",
                                    }}
                                    onWheel={e => e.target.blur()}
                                    className='field-spacing'
                                    label='Feedback Rate (INR)'
                                    variant='outlined'
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.touched.feedbackRate && formik.errors.feedbackRate)}
                                    helperText={formik.touched.feedbackRate && formik.errors.feedbackRate}
                                    value={formik.values.feedbackRate}
                                    onChange={formik.handleChange}
                                />
                            </Grid> */}
                        {/* <Typography variant='subtitle2' marginTop={"10px"}>
                                <b className='text-primary'>Other Rates</b>
                            </Typography> */}

                        {/* {providerDetailsByProviderName?.otherFields &&
                                Object.keys(providerDetailsByProviderName?.otherFields).length > 0 &&
                                Object.keys(providerDetailsByProviderName?.otherFields).map((fieldElement, index) => (
                                    <Grid className='d-flex' gap={5} key={index}>
                                        <TextField
                                            type='number'
                                            inputProps={{
                                                step: "any",
                                            }}
                                            onWheel={e => e.target.blur()}
                                            disabled={true}
                                            className='field-spacing'
                                            fullWidth

                                            label={fieldElement.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
                                                return str.toUpperCase();
                                            })}
                                            name={`otherFields.${fieldElement}`}
                                            variant='outlined'
                                            onBlur={formik.handleBlur}
                                            error={formik.errors?.otherFields && formik.errors?.otherFields[fieldElement]}
                                            helperText={formik.errors?.otherFields && formik.errors?.otherFields[fieldElement]}
                                            value={formik.values?.otherFields[fieldElement]}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                ))} */}
                        <Grid className="d-flex" gap={5}>
                            <Grid style={{ width: "49%" }}>
                                <BillingPaymentRadioButtons
                                    onChangeRadio={onClickRadio}
                                    isDisabled={state.controls.isAddClientLoading}
                                    billingPaymentType={formik.values.billingPaymentType}
                                />
                            </Grid>

                            {formik.values.billingPaymentType === "Prepaid" ? (
                                <Grid className="d-flex" gap={3} style={{ width: "49%" }}>
                                    {!(clientData?.name && isClientEditable) ? (
                                        <TextField
                                            disabled={state.controls.isAddClientLoading}
                                            type="number"
                                            inputProps={{
                                                step: "any",
                                            }}
                                            onWheel={e => e.target.blur()}
                                            name="currentBalance"
                                            style={{ width: "100%" }}
                                            className="field-spacing"
                                            // label="Add Balance (INR)"
                                            label={<span>Add Balance (INR) <span style={{ color: 'red' }}>*</span></span>}
                                            variant="outlined"
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.errors.currentBalance)}
                                            helperText={formik.errors.currentBalance}
                                            value={formik.values.currentBalance}
                                            onChange={formik.handleChange}
                                        />
                                    ) : (
                                        <>
                                            <Grid style={{ width: "49%" }}>
                                                <TextField
                                                    disabled={true}
                                                    type="number"
                                                    inputProps={{
                                                        step: "any",
                                                    }}
                                                    onWheel={e => e.target.blur()}
                                                    name="currentBalance"
                                                    style={{ width: "100%" }}
                                                    className="field-spacing"
                                                    label="Current Balance(INR)"
                                                    variant="outlined"
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.errors.currentBalance)}
                                                    helperText={formik.errors.currentBalance}
                                                    value={formik.values.currentBalance}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                            <Grid style={{ width: "49%" }}>
                                                <TextField
                                                    disabled={state.controls.isAddClientLoading}
                                                    type="number"
                                                    inputProps={{ step: "any" }}
                                                    onWheel={e => e.target.blur()}
                                                    name="recharge"
                                                    style={{ width: "100%" }}
                                                    className="field-spacing"
                                                    label="Recharge (INR)"
                                                    variant="outlined"
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.errors.recharge)}
                                                    helperText={formik.errors.recharge}
                                                    value={formik.values.recharge}
                                                    onChange={formik.handleChange}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            ) : (
                                <Grid minHeight={"100%"} display={"flex"} flexDirection={"column"} alignItems={"start"} marginTop={3}>
                                    <Typography variant="body1" color={"gray"} sx={{ maxHeight: "35px", fontWeight: "300px" }}>
                                        Currently your Usage Limit is <b>Unlimited. </b>
                                        <br />
                                        If you want to set Maximum Usage Limit, please enable Cap Check &nbsp;{" "}
                                    </Typography>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={false}
                                                checked={formik.values.capCheck}
                                                onChange={onClickCapCheck}
                                                name="capCheck"
                                                size={"small"}
                                            />
                                        }
                                        label="Cap Check"
                                    />

                                    <Grid display={"flex"} gap={3} style={{ width: "100%" }}>
                                        <Grid>
                                            <TextField
                                                fullWidth
                                                style={{ width: "100%" }}
                                                type="number"
                                                inputProps={{
                                                    step: "any",
                                                }}
                                                onWheel={e => e.target.blur()}
                                                // disabled
                                                name="currentUsage"
                                                defaultValue={formik.values.currentUsage}
                                                className="field-spacing"
                                                label="Total Usage (INR)"
                                                variant="outlined"
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.currentUsage && formik.errors.currentUsage)}
                                                helperText={formik.touched.currentUsage && formik.errors.currentUsage}
                                                value={formik.values.currentUsage}
                                                // onChange={formik.handleChange}
                                                disabled={true} // this should be calculated and fetched from DB,it should not be updatable by client
                                            />
                                        </Grid>

                                        {formik.values.billingPaymentType === "Postpaid" && formik.values.capCheck && (
                                            <Grid display={"flex"} flexDirection={"column"}>
                                                <Grid style={{ width: "100%" }}>
                                                    <TextField
                                                        fullWidth
                                                        style={{ width: "100%" }}
                                                        type="number"
                                                        inputProps={{
                                                            step: "any",
                                                        }}
                                                        onWheel={e => e.target.blur()}
                                                        // disabled
                                                        name="maxBalanceLimit"
                                                        defaultValue={1000}
                                                        className="field-spacing"
                                                        // label="Balance Limit (INR)"
                                                        label={<span>Balance Limit (INR) <span style={{ color: 'red' }}>*</span></span>}
                                                        variant="outlined"
                                                        onBlur={formik.handleBlur}
                                                        helperText={formik.touched.maxBalanceLimit && formik.errors.maxBalanceLimit}
                                                        value={formik.values.maxBalanceLimit}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </>
                    <Grid className="d-flex" gap={5}>
                        <Grid style={{ width: "49%" }}>
                            <StatusRadioButtons onChangeRadio={onClickRadio} isDisabled={!clientData?.name} status={formik.values.status} />
                        </Grid>
                    </Grid>
                </CardContent>

                <CardActions sx={{ padding: "0px" }}>
                    <Grid container sx={{ alignItems: "center" }}>
                        <Grid item className="d-flex">
                            {state.controls.isAddClientLoading ? (
                                <Button variant="outlined">
                                    <CircularProgress size={15} className="spinner" />
                                    Adding..
                                </Button>
                            ) : isUpdateClientLoading ? (
                                <Button variant="outlined">
                                    <CircularProgress size={15} className="spinner" />
                                    Updating..
                                </Button>
                            ) : clientData?.name && isClientEditable ? (
                                <Button
                                    variant="contained"
                                    className="m-2"
                                    // type="submit"
                                    onClick={() => {
                                        updateClientDetails();
                                    }}
                                >
                                    Update
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    className="m-2"
                                    // type='submit'
                                    onClick={handleSubmit}
                                >
                                    Add Client
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={onClickCancelButton}
                                className="m-2"
                                disabled={state.controls.isAddClientLoading}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>

                {state.controls.addNewClientErrorMessage && (
                    <AlertMessageComponent
                        message={state.controls.addNewClientErrorMessage}
                        fieldName={"addNewClientErrorMessage"}
                        handleClose={onCloseErrorMessage}
                        show={Boolean(state.controls.addNewClientErrorMessage)}
                    />
                )}
                <AlertMessageComponent
                    message={errorResponse}
                    fieldName={"getAllTemplatesByPageSizeErrorMessage"}
                    handleClose={() => setErrorResponse("")}
                    show={Boolean(errorResponse)}
                />
            </Card>
        </form>
    );
}
