import EditIcon from "@mui/icons-material/Edit";
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    styled,
    tableCellClasses,
} from "@mui/material";
import _debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientStatus, ERoles } from "../../../config";
import useAuth from "../../../hooks/useAuth";
import { clearErrorMessagesForAllUsers, setShowAddClientForm } from "../../../redux/userManagement/userManagementSlice";
import {
    // getAllBranchesThunk,
    getAllClientByFilterThunk,
    getAllExecutiveThunk,
    getAllManagersThunk,
} from "../../../redux/userManagement/userManagementThunk";
import { apiGatewayAxiosInstance } from "../../../utils/axios";
import Page401 from "../../auth/Page401";
import AlertMessageComponent from "../../components/AlertMessageComponent";
import FetchingSpinner from "../../components/FetchingSpinner";
import NoRecords from "../../components/NoRecords";
import FilterAllClientModel from "./FilterAllClientModel";
import FirstPageRoundedIcon from "@mui/icons-material/FirstPageRounded";
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { CustomTablePagination } from "../../../utils/CustomPagination";
import TablePaginationShowPage from "../../../utils/tablePagination";
import { canShowForSuperAdminOrResellerAdmin } from "../../../utils/canShowForSuperAdminOrResellerAdmin";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: 20,
        // backgroundColor: theme.palette.common.lightBlue,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function ListClient({
    searchParams,
    setSearchParams,
    handleEditDetailsChange,
    setClientDetails,
    isLoadingClientDetails,
    setIsBrandNotVerified,
}) {
    const state = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();
    const [searchedClientName, setSearchedClientName] = useState("");
    const [branch, setBranch] = useState(null);
    const [executive, setExecutive] = useState(null);
    const [manager, setManager] = useState(null);
    const [status, setStatus] = useState("Active");
    const [currentPage, setCurrentPage] = useState(0);
    const [debouncedValue, setDebouncedValue] = useState("");
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const [pageSize, setPageSize] = React.useState(10);
    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const [errorResponse, setErrorResponse] = useState("");
    const [IsOnClickCustomizeRateLoading, setIsOnClickCustomizeRateLoading] = useState({
        clientId: "",
        isLoading: false,
    });
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value));
        setCurrentPage(0);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        // setSearchParams({});
        setOpen(false);
    };

    const onChangeSearchedClientName = e => {
        setSearchedClientName(e.target.value);
    };

    const onSelectBranch = e => {
        setBranch(e.target.value);
    };

    const onSelectExecutive = e => {
        setExecutive(e.target.value);
    };

    const onSelectManager = e => {
        setManager(e.target.value);
    };

    const onSelectStatus = e => {
        setStatus(e.target.value);
    };

    const onClickAddNewClient = () => {
        setClientDetails({});
        setIsBrandNotVerified(true);
        dispatch(setShowAddClientForm(true));
    };

    const onClickEditOrgCustomizedRates = client => {
        setIsOnClickCustomizeRateLoading({
            clientId: client._id,
            isLoading: true,
        });
      

        const dataToSend = {
            orgId: client?.client?.orgDetails?._id,
            orgName: client?.client?.orgDetails?.orgName,
            providerId: client?.client?.orgDetails?.supportedProviders[0]?.providerId,
            providerCode: client?.client?.orgDetails?.supportedProviders[0]?.providerCode,
            countryCode: client?.client?.orgDetails?.supportedProviders[0]?.countryCode ? client?.client?.orgDetails?.supportedProviders[0]?.countryCode : client?.client?.countryCode,
            providerName: client?.client?.orgDetails?.supportedProviders[0]?.providerName,
            // providerCurrency: client.orgDetails.supportedProviders[0].currency
        };
        navigate("/reseller/customized-rates", { state: dataToSend });
        setIsOnClickCustomizeRateLoading({
            clientId: "",
            isLoading: false,
        });
    };

    const onClickEditClientDetails = async ({ client, isEditable }) => {
        try {
            setLoading(true);

            const response = await apiGatewayAxiosInstance.get(`bot_management/template/get-all-sms-fallback-required-templates-by-orgId`, {
                params: { orgId: client.orgId },
            });
            if (response.status === 200) {
                setLoading(false);

                let IsSmsCredentialsUsed = response.data.data.IsSmsCredentialsUsed;
                handleEditDetailsChange({
                    clientData: client,
                    val: isEditable,
                    IsSmsCredentialsUsed: IsSmsCredentialsUsed,
                });
            }

            // setIsOnClickCustomizeRateLoading({
            //     clientId: client._id,
            //     isLoading: false,
            // });
        } catch (error) {
            setLoading(false);
            let errorMessage = "Failed to resend mail.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            setErrorResponse(errorMessage);
        }
    };

    // const handlePageChange = page => {
    //     setCurrentPage(page);
    //     dispatch(getAllClientsByPageSizeThunk({ currentPage: page + 1, pageSize }));
    // };

    const debouncedSearch = _debounce(value => {
        setDebouncedValue(value);
    }, 1000);

    // const onClickSearche = () => {
    //     dispatch(
    //         getAllClientsByPageSizeThunk({
    //             searchedClientName,
    //             branchId: branch?._id,
    //             executiveId: executive?._id,
    //             managerId: manager?._id,
    //             status,
    //             pagesize: 10,
    //             currentPage,
    //         }),
    //     );
    //     debouncedSearch(searchedClientName, branch, executive, manager, status);
    //     return () => {
    //         debouncedSearch.cancel();
    //     };
    // };

    // const onClickResetButton = () => {
    //     setSearchedClientName("");
    //     // setBranch(null);
    //     setExecutive(null);
    //     setManager(null);
    //     setStatus("Active");

    //     dispatch(getAllClientsByPageSizeThunk({ pageSize, currentPage }));
    //     return () => {
    //         debouncedSearch.cancel();
    //     };
    // };

    useEffect(() => {
        // dispatch(
        //     getAllClientsByPageSizeThunk({
        //         currentPage: currentPage + 1,
        //         pageSize,
        //     })
        // );
        if (user) {
            const registryId = user?.registryId;
            dispatch(
                getAllClientByFilterThunk({
                    currentPage: currentPage + 1,
                    pageSize,
                    searchParams,
                    registryId: registryId,
                }),
            );
        }
    }, [currentPage, pageSize, user]);

    const onCloseErrorMessage = ({ fieldName }) => {
        dispatch(clearErrorMessagesForAllUsers({ fieldName }));
    };
    const handleClickSearch = async searchParams => {
        setSearchParams(searchParams);
        setCurrentPage(0);

        const registryId = user?.registryId;
        if (currentPage == 0) {
            dispatch(
                getAllClientByFilterThunk({
                    currentPage: currentPage + 1,
                    pageSize,
                    searchParams,
                    registryId: registryId,
                }),
            );
        }
        handleClose();
    };

    return canShowForSuperAdminOrResellerAdmin(user) ? (
        <Box>
            <Grid container mb={4} className='listHeader'>
                <Grid item md={6}>
                    <Typography variant='h3'>Clients</Typography>
                </Grid>
                <Grid item md={6} className='listHeaderFilter'>
                    <Grid width={"100%"} justifyContent={"flex-end"} display={"flex"}>
                        <Button sx={{ marginRight: "10px" }} variant='contained' onClick={handleClickOpen}>
                            Filter
                        </Button>
                        <Button variant='contained' onClick={onClickAddNewClient}>
                            Add New Client
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <Card className='border border-secondary-50'>
                    <CardContent sx={{ padding: 0 }}>
                        {state.controls.isGetAllClientsLoading ? (
                            <FetchingSpinner />
                        ) : state.clientsListByPageSize && state.clientsListByPageSize.length > 0 ? (
                            <TableContainer component={Paper} className='table-container' sx={{ maxHeight: "70vh" }}>
                                <Table stickyHeader aria-label='customized table'>
                                    <TableHead className='p-3 mb-2 row'>
                                        <TableRow>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Sr No
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Organization Name
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Country
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Admin
                                            </StyledTableCell>
                                            {/* <StyledTableCell
                                                    className="col-2 tableHeaderFont"
                                                    align="left"
                                                >
                                                    Email
                                                </StyledTableCell> */}
                                            {/* <StyledTableCell
                                                    className="col-2 tableHeaderFont"
                                                    align="left"
                                                >
                                                    Parent Client
                                                </StyledTableCell> */}
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Mobile
                                            </StyledTableCell>
                                            {/* <StyledTableCell
                                                    className="col-1 tableHeaderFont"
                                                    align="left"
                                                >
                                                    Branch
                                                </StyledTableCell> */}
                                            {/* <StyledTableCell
                            className="col-2 tableHeaderFont"
                            align="left"
                          >
                            Assigned Employee
                          </StyledTableCell> */}
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Status
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Provider Name
                                            </StyledTableCell>

                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                WhiteLabel
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Action
                                            </StyledTableCell>
                                            <StyledTableCell className='col-1 tableHeaderFont' align='left'>
                                                Customize Rates
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {state.clientsListByPageSize?.map((client, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {currentPage * pageSize + index + 1}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {client.orgDetails.orgName}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {client?.orgDetails?.countryName}
                                                </StyledTableCell>
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    <Grid display={"flex"} flexDirection={"column"}>
                                                        <Grid>
                                                            Name: <Typography variant={"subtitle"}>{client.name}</Typography>
                                                        </Grid>
                                                        <Grid>
                                                            <Typography variant={"body1"}>Email: {client.email}</Typography>
                                                            {client.email !== client.userName && (
                                                                <Typography variant={"body1"}>(Not Verified)</Typography>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell>
                                                {/* <StyledTableCell
                                                            className="tableContentFont"
                                                            align="left"
                                                        >
                                                            {client.email}
                                                        </StyledTableCell> */}
                                                {/* <StyledTableCell
                                                            className="tableContentFont"
                                                            align="left"
                                                        >
                                                            {client
                                                                ?.parentClient
                                                                ?.length > 0 ? (
                                                                <Grid
                                                                    container
                                                                    sx={{
                                                                        display:
                                                                            "flex",
                                                                        flexDirection:
                                                                            "column",
                                                                    }}
                                                                >
                                                                    <Grid item>
                                                                        <b>
                                                                            Client:
                                                                        </b>
                                                                        {
                                                                            client
                                                                                .parentClient[0]
                                                                                .clientName
                                                                        }
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <b>
                                                                            Email
                                                                            :{" "}
                                                                        </b>{" "}
                                                                        {
                                                                            client
                                                                                .parentClient[0]
                                                                                .email
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            ) : (
                                                                <>NA</>
                                                            )}
                                                        </StyledTableCell> */}
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {client.mobileNumber}
                                                </StyledTableCell>
                                                {/* <StyledTableCell
                                                            className="tableContentFont"
                                                            align="left"
                                                        >
                                                            {client?.branch
                                                                ?.length > 0 ? (
                                                                client.branch[0]
                                                                    .branchName
                                                            ) : (
                                                                <>NA</>
                                                            )}
                                                        </StyledTableCell> */}
                                                {/* <StyledTableCell
                              className="tableContentFont"
                              align="left"
                            >
                              {client.client.assignedExecutive ?
                                <>
                                  <b>Executive : </b>{client.client.assignedExecutive?.name}
                                </>
                                :
                                <>
                                  <b>Manager : </b>{client.client.assignedBranchManager?.name}
                                </>
                              }
                            </StyledTableCell> */}
                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {client.orgDetails.status == ClientStatus.Active ? (
                                                        <label
                                                            className='text-success left'
                                                            style={{
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            <Grid className='active-dot'></Grid>
                                                            {client.orgDetails.status}
                                                        </label>
                                                    ) : (
                                                        <label
                                                            className='text-danger left'
                                                            style={{
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            <Grid className='inactive-dot'></Grid>
                                                            {client.orgDetails.status}
                                                        </label>
                                                    )}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {client?.orgDetails?.supportedProviders[0]?.providerCode?.split("_").join("-")}
                                                </StyledTableCell>

                                                {/* <StyledTableCell className='tableContentFont' align='left'>
                                                    {client?.orgDetails?.privileges?.isWhiteLabel === true ? (
                                                        <Chip variant='outlined' className='button_width chip' label={"Enabled"} color='success' />
                                                    ) : (
                                                        <Chip variant='outlined' className='button_width chip' label={"Disabled"} color='error' />
                                                    )}
                                                </StyledTableCell> */}

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    {client?.orgDetails?.privileges?.isWhiteLabel === "Enabled" ? (
                                                        <label
                                                            className='text-success left'
                                                            style={{
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            <Grid className='active-dot'></Grid>
                                                            {"Enabled"}
                                                        </label>
                                                    ) : (
                                                        <label
                                                            className='text-danger left'
                                                            style={{
                                                                fontWeight: "600",
                                                            }}
                                                        >
                                                            <Grid className='inactive-dot'></Grid>
                                                            {"Disabled"}
                                                        </label>
                                                    )}
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    <Stack
                                                        direction='row'
                                                        spacing={2}
                                                        sx={{
                                                            alignItems: "left",
                                                            display: "flex",
                                                            justifyContent: "left",
                                                        }}
                                                    >
                                                        {isLoadingClientDetails.status && isLoadingClientDetails.id == client._id ? (
                                                            <CircularProgress size={20} />
                                                        ) : (
                                                            <Button
                                                                onClick={() => {
                                                                    onClickEditClientDetails({
                                                                        client,
                                                                        isEditable: true,
                                                                    });
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </Button>
                                                        )}
                                                    </Stack>
                                                </StyledTableCell>

                                                <StyledTableCell className='tableContentFont' align='left'>
                                                    <Stack
                                                        direction='row'
                                                        spacing={2}
                                                        sx={{
                                                            alignItems: "left",
                                                            display: "flex",
                                                            justifyContent: "left",
                                                        }}
                                                    >
                                                        {IsOnClickCustomizeRateLoading.isLoading &&
                                                            IsOnClickCustomizeRateLoading.clientId == client._id ? (
                                                            <CircularProgress size={20} />
                                                        ) : (
                                                            <Button
                                                                onClick={() => {
                                                                    onClickEditOrgCustomizedRates({
                                                                        client,
                                                                    });
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </Button>
                                                        )}
                                                    </Stack>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <NoRecords />
                        )}
                        {state.totalClientsCount > 0 && (
                            <Grid
                                className='my-2'
                                style={{
                                    zIndex: "2",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: 2,
                                }}
                            >
                                <Grid
                                    style={{
                                        zIndex: "2",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "100%",
                                        padding: 5,
                                    }}
                                >
                                    <Grid
                                        style={{
                                            marginTop: "15px",
                                        }}
                                    >
                                        Total Clients:{state.totalClientsCount}
                                    </Grid>
                                    <Grid justifyContent={"left"} mt={3}>
                                        {/* <TablePagination
                                            count={state.totalClientsCount}
                                            component='Grid'
                                            variant='outlined'
                                            color='primary'
                                            page={currentPage}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={pageSize}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        /> */}

                                        <TablePaginationShowPage
                                            rowsPerPageOptions={[5, 10, 25, 100]}
                                            colSpan={3}
                                            count={state.totalClientsCount}
                                            rowsPerPage={pageSize}
                                            page={currentPage}
                                            slotProps={{
                                                select: {
                                                    "aria-label": "Rows per page",
                                                },
                                                actions: {
                                                    showFirstButton: true,
                                                    showLastButton: true,
                                                    slots: {
                                                        firstPageIcon: FirstPageRoundedIcon,
                                                        lastPageIcon: LastPageRoundedIcon,
                                                        nextPageIcon: ChevronRightRoundedIcon,
                                                        backPageIcon: ChevronLeftRoundedIcon,
                                                    },
                                                },
                                            }}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>

                    {state.controls.getAlllCientsErrorMessage && (
                        <AlertMessageComponent
                            message={state.controls.getAlllCientsErrorMessage}
                            fieldName={"getAlllCientsErrorMessage"}
                            handleClose={onCloseErrorMessage}
                            show={Boolean(state.controls.getAlllCientsErrorMessage)}
                        />
                    )}
                    {open && (
                        <FilterAllClientModel
                            open={open}
                            searchParams={searchParams}
                            handleClickOpen={handleClickOpen}
                            handleClose={handleClose}
                            setSearchParams={setSearchParams}
                            handleClickSearch={handleClickSearch}
                        />
                    )}
                </Card>
            </Grid>
        </Box>
    ) : (
        <Page401 />
    );
}
