import { Chip, FormControl, FormHelperText, Grid, IconButton, Input, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import AddIcon from '@mui/icons-material/Add';
export default function AddVariablesForBulkCapCheckURL({ formik, state, IsSmsCredentialsUsed }) {
    const [currValue, setCurrValue] = useState("");
    const handleChange = (event) => {
        const { value, name } = event.target;
        setCurrValue(event.target.value);
    };

    const addVariables = () => {
        if (formik?.values?.bulkCapCheckURLVariables.includes(currValue)) {
            formik.setFieldError("bulkCapCheckURLVariables", `Variable already exist.`);
            return;
        }
        formik.setFieldValue("bulkCapCheckURLVariables", [...formik?.values?.bulkCapCheckURLVariables, currValue]);
        setCurrValue("")
    }


    const handleDelete = (item, index) => {
        let arr = [...formik.values.bulkCapCheckURLVariables]
        arr.splice(index, 1)
        formik.setFieldValue("bulkCapCheckURLVariables", arr);

    }
    return (
        <Grid container display={"flex"} width={"100%"}>

            <Grid item padding={2} md={6}>

                {/* <Typography className='text-muted mb-2'>Sender ID should be 6 alpha characters or numeric.</Typography> */}

                <div >
                    {formik?.values?.bulkCapCheckURLVariables?.map((item, index) => (
                        <Chip size="small" onDelete={() => { handleDelete(item, index) }} label={item} sx={{ margin: "4px" }} />
                    ))}
                </div>
                <FormControl error={formik.errors?.bulkCapCheckURLVariables}  >
                    <Grid>

                        <Input
                            value={currValue}
                            onChange={handleChange}
                        // onKeyDown={handleKeyUp}
                        />

                        <IconButton onClick={addVariables} disabled={!currValue}>
                            <AddIcon />
                        </IconButton>
                    </Grid>
                    <FormHelperText>{
                        formik.errors?.bulkCapCheckURLVariables}</FormHelperText>

                </FormControl>

            </Grid>
        </Grid>
    );
}
