import { CloseOutlined } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import React, { useState } from "react";
import { setErrorMessageForUser } from "../../../../redux/userManagement/userManagementSlice";
import { useDispatch } from "react-redux";
import { apiGatewayAxiosInstance } from "../../../../utils/axios";

function DeprecateApiKeysModel({ open, handleClose, apikeyDetails }) {

    const dispatch = useDispatch();
    const handleDeprecateApikeys = async () => {
        try {
            const apiKey = apikeyDetails.apiKey;
            const response = await apiGatewayAxiosInstance.post('/user_management/apikeys/deprecate-apikey', { apiKey: apiKey });
            handleClose();
            if (response.status === 200) {
                return response;
            }

        } catch (error) {
            let errorMessage = "Server not reachable.";

            if (error.response) {
                errorMessage = (error.response.data && error.response.data.message) ?? errorMessage;
            }
            dispatch(
                setErrorMessageForUser({
                    fieldName: "getClientEditDetailsErrorMessages",
                    errorMessage,
                }),
            );
        }
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Grid mt={4} container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant='h5'>Are you sure you want to deprecate the apikey?</Typography>
                        <IconButton onClick={() => handleClose()}>
                            <CloseOutlined />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent
                    sx={{
                        overflowY: "hidden",
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                        width: "500px",
                    }}
                >
                    <Grid container sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                        <Grid item>
                            <Button variant='contained' color='primary' className='m-2' onClick={handleDeprecateApikeys} type="submit" >
                                Yes
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant='contained' color='error' onClick={handleClose} className='m-2' >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions
                    style={{
                        padding: "1rem 1.5rem",
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: ".25rem",
                    }}
                >
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default DeprecateApiKeysModel;
