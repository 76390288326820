import styled from "@emotion/styled";
import * as React from "react";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import { Grid, Menu, MenuItem, IconButton as MuiIconButton, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { fetchCurrentBalanceByClientId } from "../../redux/userManagement/userManagementThunk";
import { ERoles } from "../../config";

const IconButton = styled(MuiIconButton)`
    svg {
        width: 22px;
        height: 22px;
    }
`;

let intervalID = null;
function NavbarUserDropdown() {
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const navigate = useNavigate();
    const { signOut } = useAuth();
    const [currentBalance, setCurrentBalance] = React.useState("-");
    const state = useSelector(state => state.userManagementSlice);
    const dispatch = useDispatch();
    const toggleMenu = event => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };
    const { user } = useAuth();
    const handleSignOut = async () => {
        await signOut();
        navigate("/auth/login");
    };

    React.useEffect(() => { }, [state.userCurrentBalance]);

    React.useEffect(() => {
        // fetchCurrentBalanceByClientId();
        if (user && user?.roleName.toLowerCase() !== ERoles.SUPER_ADMIN) {
            dispatch(fetchCurrentBalanceByClientId());
        }
    }, [user]);

    if (!intervalID) {
        if (user && user?.roleName.toLowerCase() !== ERoles.SUPER_ADMIN) {
            intervalID = setInterval(() => {
                if (user && user?.roleName.toLowerCase() !== ERoles.SUPER_ADMIN) {
                    dispatch(fetchCurrentBalanceByClientId());
                }
            }, 60000);
        }
    }

    return (
        <React.Fragment>
            <Grid display={"flex"} alignItems={"center"} gap={10}>
                {user?.roleName !== ERoles.SUPER_ADMIN && (
                    <Grid display={"flex"}>
                        {state?.userBillingType === "Postpaid" ? (
                            <Grid display={"flex"} flexDirection={"column"}>
                                <Typography sx={{ color: "green", fontWeight: "bold" }}>Billing Type : Postpaid</Typography>

                                <Typography sx={{ color: "green", fontWeight: "bold" }}>
                                    Amount Used : Rs.{" "}
                                    {typeof state.userCurrentBalance !== "string" ? state?.userCurrentBalance?.toFixed(2) : state?.userCurrentBalance}
                                    /-
                                </Typography>

                                {state?.postpaidUserCapcheck && (
                                    <Typography sx={{ color: "green", fontWeight: "bold" }}>
                                        Max balance limit : Rs. {state?.postpaidUserMaxLimit?.toFixed(2)}/-
                                    </Typography>
                                )}
                            </Grid>
                        ) : (
                            <div>
                                <Typography sx={{ color: "green", fontWeight: "bold" }}>Billing Type : Prepaid &nbsp;</Typography>
                                <Typography sx={{ color: "green", fontWeight: "bold" }}>
                                    Amount Used : Rs. {state.userUsage?.toFixed(2)}
                                    /-
                                </Typography>
                                <Typography style={{ color: "green", fontWeight: "bold" }}>
                                    Balance : Rs.{" "}
                                    {typeof state?.userCurrentBalance !== "string"
                                        ? state?.userCurrentBalance?.toFixed(2)
                                        : state?.userCurrentBalance}
                                    /-
                                </Typography>
                            </div>
                        )}
                    </Grid>
                )}

                <Grid>
                    <Tooltip title='Account'>
                        <IconButton
                            aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                            aria-haspopup='true'
                            onClick={toggleMenu}
                            color='error'
                            size='large'
                        >
                            <Power />
                        </IconButton>
                    </Tooltip>

                    <Menu id='menu-appbar' anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
                        {/* <MenuItem onClick={closeMenu}>Profile</MenuItem> */}
                        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default NavbarUserDropdown;
